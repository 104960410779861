import React, { useContext, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
    makeStyles,
    useTheme,
    Divider,
    Dialog,
    useMediaQuery,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, Snackbar,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { LANGUAGES } from "../../constants/languages";
import { SpinnerButton } from "../../components/ConfirmationButton/SpinnerButton";
import { ApiContext } from "../../contexts/ApiContext";
import {deminifyLanguagePacks, minifyLanguagePacks} from "../../utils/formUtils";
import {Alert} from "@material-ui/lab";
import {Provider} from "react-redux";

// TODO: Move dialog component styles to separate components
const useStyles = makeStyles((theme) => ({
    paper: {
        maxHeight: theme.spacing(60),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(75),
        },
    },
    title: {
        padding: [theme.spacing(3), theme.spacing(4)],
    },
    content: {
        padding: [theme.spacing(0), theme.spacing(4)],
        display: "flex",
        flexDirection: "column",
    },
    text: {
        marginBottom: theme.spacing(3),
        ...theme.typography.body2,
    },
    actions: {
        padding: [theme.spacing(4), theme.spacing(4), theme.spacing(3), theme.spacing(4)],
        "&>*:not(:last-child) ": {
            marginRight: theme.spacing(1),
        },
    },
    fieldInput: {
        // height: "14.275rem",
        // alignItems: "initial",
        "& .DraftEditor-root": {
            height: "14.275rem",
        },
    },
    languageList: {
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.border.default}`,
        overflow: "auto",
        flex: "1 1 auto",
        maxHeight: "100%",
    },
    listItemIcon: {
        minWidth: 0,
        marginRight: theme.spacing(1),
    },
}));

const AddFormLanguageDialog = ({ open, languagePacks, onClose, onConfirm }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const api = useContext(ApiContext);
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [availableLanguages, setAvailableLanguages] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [isBusy, setIsBusy] = useState(false);
    const usedLanguages = Object.keys(languagePacks);

    useEffect(() => {
        const languages = LANGUAGES.filter((lang) => !usedLanguages.includes(lang.code)).map((lang) => lang.code);
        setAvailableLanguages(languages);
        setSelectedLanguages([]);
    }, [JSON.stringify(usedLanguages)]);

    const addSelectedLanguage = (value) => {
        setSelectedLanguages((prev) => [...prev, value]);
    };

    const removeSelectedLanguage = (value) => {
        setSelectedLanguages((prev) => {
            const index = prev.findIndex((lang) => lang === value);
            const newState = [...prev];
            newState.splice(index, 1);
            return newState;
        });
    };

    const handleLanguageSelected = (value) => {
        if (selectedLanguages.includes(value)) {
            removeSelectedLanguage(value);
        } else {
            addSelectedLanguage(value);
        }
    };

    const handleLanguageChecked = (event, value) => {
        if (event.target.checked) {
            addSelectedLanguage(value);
        } else {
            removeSelectedLanguage(value);
        }
    };

    const handleConfirmButtonClick = async () => {
        if (selectedLanguages.length === 0) {
            onClose();
            return;
        }

        setIsBusy(true);
        const languages = Object.keys(languagePacks);
        const sourceLanguage = languages[0];
        const minifiedLanguagePack = minifyLanguagePacks([languagePacks[sourceLanguage]])[0];

        // TODO: Cannot do all translations in a single request because it can timeout if too many languages were selected.
        const translatedLanguagePacks = {};
        for (const targetLanguage of selectedLanguages) {
            try {
                const translationResult = await api.query.translateLanguagePacks(
                    minifiedLanguagePack,
                    sourceLanguage,
                    [targetLanguage]
                );
                translatedLanguagePacks[targetLanguage] = translationResult[targetLanguage];
            } catch {
                console.log("language %o failed to translate", targetLanguage);
                translatedLanguagePacks[targetLanguage] = null;
            }
        }

        onConfirm(translatedLanguagePacks);
        setIsBusy(false);
    };

    const handleDialogClose = (event, reason) => {
        // Don't close the dialog if it is waiting for a translation to complete
        if (!isBusy) {
            onClose();
        }
    }

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullScreen={fullScreen}
            open={open}
            onClose={handleDialogClose}
            aria-labelledby="add-form-language-title"
        >
            <DialogTitle className={classes.title} id="add-form-language-title">
                {t("addFormLanguageDialogTitle")}
            </DialogTitle>
            <DialogContent className={classes.content}>
                <DialogContentText className={classes.text}>{t("addFormLanguageDialogText")}</DialogContentText>
                <List className={classes.languageList} dense>
                    {availableLanguages.map((lang) => (
                        <ListItem
                            key={lang}
                            dense
                            button
                            role={undefined}
                            disabled={isBusy}
                            onClick={() => handleLanguageSelected(lang)}
                        >
                            <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                <Checkbox
                                    edge="start"
                                    size="small"
                                    color="primary"
                                    checked={selectedLanguages.includes(lang)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": `language-item-${lang}` }}
                                    onChange={(event) => handleLanguageChecked(event, lang)}
                                />
                            </ListItemIcon>
                            <ListItemText id={`language-item-${lang}`} primary={t(lang)} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button onClick={onClose} color="primary" variant="outlined" disabled={isBusy}>
                    {t("Cancel")}
                </Button>
                <SpinnerButton
                    onClick={handleConfirmButtonClick}
                    color="primary"
                    variant="contained"
                    showSpinner={isBusy}
                >
                    {t("confirm")}
                </SpinnerButton>
            </DialogActions>
        </Dialog>
    );
};

AddFormLanguageDialog.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    languagePacks: PropTypes.object.isRequired,
    onConfirm: PropTypes.func,
};

AddFormLanguageDialog.defaultProps = {
    languagePacks: [],
    onClose: () => {},
    onConfirm: () => {},
};

export default AddFormLanguageDialog;
