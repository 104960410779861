import * as types from "./actionTypes";
import {toId} from "../../utils/apiUtils";

// TODO: Reset whole store
export const resetCurrentUser = () => async (dispatch) => {
    dispatch({ type: types.CURRENT_USER_LOADED, payload: null });
}

export const loadOrUpdateCurrentUser = (userData) => async (dispatch, getState) => {
    const existing = getState().currentUser;
    if (existing) {
        dispatch({ type: types.CURRENT_USER_LOADED, payload: { ...existing, ...userData } });
    } else {
        dispatch({ type: types.CURRENT_USER_LOADED, payload: {...userData, id: toId(userData.pk), lastReads: userData.lastReads}});
    }
}