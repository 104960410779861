import React from 'react';
import {
    Box,
    makeStyles,
    useTheme,
    Button,
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import PropTypes from "prop-types";
import moment from "moment";
import PickerContainer from "./PickerBase";

const useStyles = makeStyles((theme) => ({
    inputButton: {
        ...theme.typography.body2,
        textTransform: "none",
        height: "100%",
        justifyContent: "flex-start",
        '&:focus': {
            outline: 'none',
        },
    }
}));

const renderTriggerButton = ({classes, onClick, inputRef, value, startIcon}) => {
    return (
        <Button
            fullWidth
            className={classes.inputButton}
            onClick={onClick}
            disableFocusRipple
            ref={inputRef}
            startIcon={startIcon}
        >
            {value}
        </Button>
    );
}

// TODO: Not working, need to use Date-Fns instead of MomentUtils
const DueDatePicker = ({selectedDate, onChange}) => {
    const theme = useTheme();
    const [hover, setHover] = React.useState(false);
    const classes = useStyles({hover, selectedDate});

    const handleDateChange = (date) => {
        onChange(date);
        setHover(false);
    };

    return (
        <PickerContainer
            showClearButton={Boolean(hover && selectedDate)}
            tooltip={!selectedDate ? "Add due date" : ""}
            onClear={() => handleDateChange(null)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Box>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <DatePicker
                        autoOk
                        disableToolbar
                        disablePast
                        variant="inline"
                        format="DD/MM/yyyy"
                        margin="normal"
                        value={selectedDate}
                        onChange={handleDateChange}
                        TextFieldComponent={renderTriggerButton}
                        classes={{
                            inputButton: classes.inputButton
                        }}
                        startIcon={!selectedDate && hover && (<ScheduleOutlinedIcon style={{color: theme.palette.text.hint}}/>)}
                    />
                </MuiPickersUtilsProvider>
            </Box>
        </PickerContainer>
    );
};

DueDatePicker.propTypes = {
    selectedDate: PropTypes.any,
    onChange: PropTypes.func.isRequired
}

DueDatePicker.defaultProps = {
    selectedDate: null,
    onDateChange: () => {}
};

export default DueDatePicker;