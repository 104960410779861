import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    CardHeader,
    Grid,
    TextField,
    CardActionArea,
    Container,
    Paper,
    FormControlLabel,
    Checkbox,
    alpha,
    MenuItem, InputAdornment
} from '@material-ui/core';
import {CardShadow2, DEFAULT_FORM_SIDE_PADDING_SPACING} from "../../constants";
import {normalizeLocalizationKey} from "../../utils/formUtils";
import RichTextEditor2 from "../RichTextEditor2/RichTextEditor2";
import {deserializeEditorState} from "../RichTextEditor2/editorState";
import {deminifyDraftEditorState} from "../../utils/draftJsUtils";
import {useTranslation} from "react-i18next";
import {CheckBox} from "@material-ui/icons";
import BlockHeader from "./BlockHeader";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import {s4, s8} from "../../utils/idGenerator";
import OptionField from "./OptionField";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {reorder} from "../../utils/arrayUtils";
import {NestedDragDropContext} from "../../contexts/NestedDragDropContext";

const useStyles = makeStyles((theme) => ({
    optionsList: {
        marginLeft: -theme.spacing(3),
        marginRight: -theme.spacing(0.5),
        // backgroundColor: theme.palette.background.paper,
        // borderRadius: theme.shape.borderRadius,
        // border: `1px solid ${theme.palette.border.default}`,
        // overflowY: "hidden",
        // marginTop: theme.spacing(0.5),
        // padding: [theme.spacing(1), theme.spacing(0)]
    },
    option: {
        padding: [theme.spacing(0.5), theme.spacing(0.5)],
    },
    caption: {
        marginLeft: theme.spacing(3)
        // margin: [0, theme.spacing(DEFAULT_FORM_SIDE_PADDING_SPACING)],
    },
    droppable: {
        overflow: "hidden",
    },
    addButton: {
        // marginLeft: theme.spacing(DEFAULT_FORM_SIDE_PADDING_SPACING - 0.25),
        //marginLeft: theme.spacing(2.75),
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(0.5),
        // color: theme.palette.text.secondary
    }
}));

const OptionFieldsList = ({className, blockTemplate, languagePack, ItemIcon, onTextChange, onTemplateChange, onTextDelete}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dragDropContext = useContext(NestedDragDropContext);
    const optionsKeysRef = useRef(blockTemplate.options ?? []);
    const [, setRenderTrigger] = useState(false);
    const [autoFocusLast, setAutoFocusLast] = useState(false);

    useEffect(() => {
        dragDropContext.subscribe(blockTemplate.key, handleDragEnd);
    }, []);

    const handleTextChange = (key, value) => {
        onTextChange({key: key, value: value});
    }

    const handleAddOptionClick = () => {
        const newId = s8();

        optionsKeysRef.current = [...optionsKeysRef.current, newId];
        setRenderTrigger((prev) => !prev);

        onTemplateChange({key: "options", value: optionsKeysRef.current});
        onTextChange({key: newId, value: ""});
        setAutoFocusLast(true);
    }

    const handleOptionDelete = (key) => {
        optionsKeysRef.current = optionsKeysRef.current.filter(optionKey => optionKey !== key);
        setRenderTrigger((prev) => !prev);

        onTemplateChange({key: "options", value: optionsKeysRef.current});
        onTextDelete(key);
        setAutoFocusLast(false);
    }

    const handleDragEnd = ({source, destination}) => {
        if (!destination) return;

        // We have to use ref variable here, since this is a callback that will be called from a parent component.
        // If we would use state variable for options keys instead, then the handler would only have access to the
        // initial value of the variable that was captured when the callback was created and passed to the parent component.
        optionsKeysRef.current = reorder(optionsKeysRef.current, source.index, destination.index);
        setRenderTrigger((prev) => !prev);
        onTemplateChange({key: "options", value: optionsKeysRef.current});
    }

    return (
        <div className={clsx(classes.optionsList, className)}>
            <Typography className={classes.caption} variant="caption" color="textSecondary" gutterBottom>
                {t("options")}
            </Typography>
            <Droppable droppableId={`block-${blockTemplate.key}`} type={`options-${blockTemplate.key}`}>
                {(provided) => (
                    <div className={classes.droppable} ref={provided.innerRef} {...provided.droppableProps}>
                        {optionsKeysRef.current.map((key, index) => (
                            <OptionField
                                key={key}
                                className={classes.option}
                                index={index}
                                optionKey={key}
                                languagePack={languagePack}
                                autoFocus={autoFocusLast && index === optionsKeysRef.current.length - 1}
                                Icon={ItemIcon}
                                onChange={(value) => handleTextChange(key, value)}
                                onDelete={() => handleOptionDelete(key)}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            <Button
                className={classes.addButton}
                variant="text"
                size="small"
                color="primary"
                startIcon={<AddOutlinedIcon/>}
                onClick={handleAddOptionClick}
            >
                {t("addNewOption")}
            </Button>
        </div>
    )
};

OptionFieldsList.propTypes = {
    className: PropTypes.string,
    blockTemplate: PropTypes.object.isRequired,
    languagePack: PropTypes.object.isRequired,
    ItemIcon: PropTypes.object,
    onTextChange: PropTypes.func,
    onTemplateChange: PropTypes.func,
    onTextDelete: PropTypes.func
};

OptionFieldsList.defaultProps = {
    onTextChange: () => {},
    onTemplateChange: () => {},
    onTextDelete: () => {},
};

export default OptionFieldsList;
