import React, {useContext, useEffect, useState} from 'react';
import {
    Box,
    makeStyles,
    Avatar,
    Typography, useTheme, alpha
} from '@material-ui/core';
import PropTypes from "prop-types";
import PersonIcon from '@material-ui/icons/Person';
import DueDatePicker from "./Pickers/DueDatePicker";
import {
    cyan,
    green,
    grey,
    indigo,
    lightBlue,
    lime,
    purple,
    red
} from '@material-ui/core/colors';
import clsx from "clsx";
import {ApiContext} from "../contexts/ApiContext";
import {getAvatarColor, getInitials} from "../utils/avatarUtils";
import {Skeleton} from "@material-ui/lab";
import {getSignedUrl} from "../utils/apiUtils";
import {useTranslation} from "react-i18next";
import UserAvatar from "./UserAvatar";

const useStyles = makeStyles((theme) => ({
    avatarWithLabelRoot: {
        display: "flex",
        alignItems: "center"
    },
    textContainer: {
        marginLeft: theme.spacing(1),
        lineHeight: 0
    },
    title: {
        //marginLeft: theme.spacing(1),
        //fontSize: props => `${fontSizes[props.size]}`,
    },
    subtitle: {
        color: theme.palette.text.hint,
        lineHeight: 1.33
        //marginLeft: theme.spacing(1)
    }
}));

const UserCard = ({className, name, subtitle, imageKey, size, AvatarProps, ...rest}) => {
    const { t } = useTranslation();
    const headerOrientation = size === "small" || size === "medium" ? "horizontal" : "vertical";
    const classes = useStyles({size});

    return (
        <Box
            className={clsx(classes.avatarWithLabelRoot, className)}
            {...rest}
        >
            <UserAvatar name={name} imageKey={imageKey} size={size} {...AvatarProps}/>
            <div className={classes.textContainer}>
                <Typography color="textPrimary" variant="body2" noWrap>
                    {name ?? t("Anonymous")}
                </Typography>
                { subtitle && (
                    <Typography className={classes.subtitle} variant="caption" noWrap>
                        {subtitle}
                    </Typography>
                )}
            </div>
        </Box>
    );
};

UserCard.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    subtitle: PropTypes.string,
    imageKey: PropTypes.string,
    AvatarProps: PropTypes.object,
    size: PropTypes.string
}

UserCard.defaultProps = {
    size: 'medium'
};

export default UserCard;