import React, {useContext, useEffect, useState} from 'react';
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    Typography,
    Container,
    LinearProgress,
    makeStyles, useTheme, TableSortLabel, TableContainer, Divider, TableHead, IconButton
} from '@material-ui/core';
import clsx from "clsx";
import theme from "../../theme";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {workspaceReports} from "../../state/reports/selectors";
import UserAvatar from "../../components/UserAvatar";
import ReportProgress from "../../components/ReportProgress";
import {ApiContext} from "../../contexts/ApiContext";
import {stableSort} from "../../utils/arrayUtils";
import {getReportName} from "../../utils/textUtils";
import {useTranslation} from "react-i18next";
import UserCard from "../../components/UserCard";
import {activeMembersSelector} from "../../state/members/selectors";
import {AvatarGroup} from "@material-ui/lab";
import {useParams} from "react-router-dom";
import {toWorkspaceKey} from "../../utils/apiUtils";
import TermProgress from "../../components/ReportStatus/TermProgress";

const avatarSize = 40;
const rowHeight = avatarSize + 2 * theme.spacing(2) + 1; // + 1 to include divider height

const useStyles = makeStyles((theme) => ({
    tableRoot: {
        height: "100%",
        width: "100%",
        padding: [0, theme.spacing(2)]
    },
    table: {
        tableLayout: "fixed"
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    progress: {
        height: "6px",
        width: "100%",
        borderRadius: 8,
        marginTop: theme.spacing(0.25)
    },
    idColumn: {
        width: "7%"
    },
    detailsColumn: {
        width: "35%"
    },
    header: {
        '&>th:first-child': {
            borderRadius: "8px 0px 0px 8px"
        },
        '&>th:last-child': {
            borderRadius: "0px 8px 8px 0px"
        }
    },
    cell: {
        padding: [0, theme.spacing(2)],
        borderBottom: "initial"
    },
    row: {
        height: theme.spacing(7)
    },
    headerCell: {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.grey[50],
        borderBottom: "initial"
    }
}));

const ReportTable = ({className, reports}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {workspaceId} = useParams();
    const workspaceKey = toWorkspaceKey(workspaceId);
    const users = useSelector(activeMembersSelector(workspaceKey));

    return (
        <TableContainer className={className}>
            <Table className={clsx(classes.table, className)}>
                <TableHead>
                    <TableRow className={classes.header}>
                        <TableCell className={classes.headerCell}>
                            {t("Report")}
                        </TableCell>
                        <TableCell className={classes.headerCell}>
                            {t("Remaining")}
                        </TableCell>
                        <TableCell className={classes.headerCell}>
                            {t("assigned")}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stableSort(reports, 'asc', 'createdAt')
                        .slice(0, 10)
                        .map((report) => {
                            const assignedUsers = report.owners ? report.owners.map(id => users.find(user => user.id === id)).filter(Boolean) : null;
                            return (
                                <TableRow key={report.pk} hover className={classes.row} tabIndex={-1}>
                                    <TableCell className={classes.cell}>
                                        {getReportName(report, t)}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        <TermProgress report={report} TypographyProps={{variant: 'body2'}}/>
                                    </TableCell>
                                    <TableCell className={classes.cell} style={{height: "100%"}}>
                                        {assignedUsers && (
                                            <AvatarGroup max={5}>
                                                {assignedUsers.map(user => (
                                                    <UserAvatar key={`cardAvatar-${user.pk}`} name={user.name} imageKey={user.picture} component="span" alt={user.name} size="small" />
                                                ))}
                                            </AvatarGroup>
                                        )}
                                    </TableCell>
                                </TableRow>
                        )})
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

ReportTable.propTypes = {
    className: PropTypes.string,
    reports: PropTypes.array.isRequired
}

export default ReportTable;