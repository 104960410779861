import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    alpha,
    ButtonBase, ClickAwayListener,
    Divider, IconButton, InputAdornment, ListItemIcon, ListItemText,
    makeStyles, TextField, Tooltip, Typography, useTheme
} from '@material-ui/core';
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import UserAvatar from "./UserAvatar";
import clsx from "clsx";
import {MoreVert} from "@material-ui/icons";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from "@material-ui/core/MenuItem";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Menu from "@material-ui/core/Menu";
import {useTranslation} from "react-i18next";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import StyledMenu from "./StyledMenu/StyledMenu";
import {StyledMenuItem} from "./StyledMenu";
import {formatReportId, normalizeId} from "../utils/textFormatter";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import {CARD_SHADOW3, CardShadow, CardShadow2, CardShadow3} from "../constants";

const useStyles = makeStyles((theme) => ({
    tileButton: {
        position: "relative",
        borderRadius: 12,
        border: `1px solid ${theme.palette.grey[300]}`,
        overflow: "hidden",
        transition: theme.transitions.create(['background-color', 'box-shadow', 'transform'/*, 'border'*/], {
            duration: theme.transitions.duration.short,
        }),
        color: theme.palette.text.primary,
        //backgroundColor: theme.palette.grey[300],
        //...CARD_SHADOW3,
        ...CardShadow,
        //boxShadow: theme.shadows[7],
        //boxShadow: "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
        '&:hover': {
            //border: `1px solid ${theme.palette.primary.main}`,
            border: `1px solid ${theme.palette.grey[500]}`,
            //color: theme.palette.primary.main,
            //boxShadow: "0 10px 15px -5px rgb(48 54 60 / 15%), 0 1px 2px 0 rgb(48 54 60 / 15%)",
            // transform: "translateY(-1px)",
            //border: `1px solid black`,
            //boxShadow: "rgb(32 40 45 / 8%) 0px 2px 14px 0px",
            //transform: "scale3d(1.02, 1.02, 1)",
            //textDecoration: 'underline',
            //backgroundColor: theme.palette.grey.A100,
            //boxShadow: theme.shadows[4],
            //boxShadow: "rgb(145 158 171 / 16%) 0px 16px 32px -4px",
            //...CardShadow2,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                color: theme.palette.primary.main,
            },
            '&$disabled': {
                border: `1px solid ${theme.palette.action.disabledBackground}`,
            },
        },
        '&$disabled': {
            color: theme.palette.action.disabled,
            border: `1px solid ${theme.palette.action.disabledBackground}`,
        }
    },
    button: {
        flexDirection: "column",
        '&$focusVisible': {
            boxShadow: theme.shadows[6],
        },
        '&:active': {
            boxShadow: theme.shadows[8],
        },
        '&$disabled': {
            color: theme.palette.action.disabled,
            boxShadow: theme.shadows[0],
            backgroundColor: theme.palette.action.disabledBackground,
        },
    },
    preview: {
        width: theme.spacing(27.5),
        height: theme.spacing(17),
        backgroundColor: theme.palette.grey[100]
    },
    divider: {
        width: "100%",
        color: theme.palette.grey[300]
    },
    label: {
        //maxHeight: `${theme.typography.body1.lineHeight * 2}em`,
        //marginTop: theme.spacing(1),
        margin: theme.spacing(3),
        textAlign: "center",
        //margin: "auto",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    moreButton: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1),
        // transition: theme.transitions.create(['visibility'], {
        //     duration: theme.transitions.duration.short,
        // }),
    },
    hidden: {
        visibility: "hidden",
    },
    menuItem: {
        paddingRight: theme.spacing(3)
    },
    menuItemIcon: {
        minWidth: theme.spacing(5),
    },
    textFieldContainer: {
        position: "absolute",
        top: theme.spacing(17) + 1, // +1 to accommodate divider
        bottom: 0,
        left: 0,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    textFieldInput: {
        ...theme.typography.body2
    }
}));

const TileButton2 = ({className, label, children, onLabelChange, onClick, onDelete, ...rest}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const moreButtonRef = useRef();
    const textFieldInputRef = useRef();
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [hover, setHover] = useState(false);
    const [pendingLabel, setPendingLabel] = useState(label);
    const [editMode, setEditMode] = useState(false);

    const handleRenameClick = () => {
        setContextMenuOpen(false);
        setEditMode(true);
        setTimeout(() => textFieldInputRef.current.focus(), 1);
    }

    const handleTextFieldKeyDown = (event) => {
        if(event.keyCode == 13){
            triggerLabelUpdate();
        }
    }

    const triggerLabelUpdate = () => {
        setEditMode(false);
        onLabelChange(pendingLabel);
    }

    return (
        <div
            className={clsx(classes.tileButton, className)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <ButtonBase
                component="div"
                className={classes.button}
                {...rest}
            >
                <div className={classes.preview}>
                    {children}
                </div>
                {/*<Divider orientation="horizontal" className={classes.divider}/>*/}
                <Typography
                    className={clsx(classes.label, {[classes.hidden]: editMode})}
                    variant="subtitle2"
                    color="inherit"
                    align="center"
                >
                    {label}
                </Typography>
            </ButtonBase>
            {editMode && (
                <ClickAwayListener onClickAway={triggerLabelUpdate}>
                    <div className={classes.textFieldContainer}>
                        <TextField
                            required
                            fullWidth
                            hiddenLabel
                            inputRef={textFieldInputRef}
                            value={pendingLabel}
                            onChange={(e) => setPendingLabel(e.target.value)}
                            onFocus={(e) => e.target.select()}
                            onKeyDown={handleTextFieldKeyDown}
                            inputProps={{min: 1, style: { textAlign: 'center', height: "auto" }}}
                            InputProps={{
                                disableUnderline: true,
                                classes: {
                                    root: classes.textFieldInput
                                }
                            }}
                        />
                    </div>
                </ClickAwayListener>
            )}
            <IconButton
                className={clsx(classes.moreButton, {[classes.hidden]: !hover && !contextMenuOpen})}
                ref={moreButtonRef}
                size="small"
                onClick={() => setContextMenuOpen(true)}
            >
                <MoreVertIcon />
            </IconButton>
            <StyledMenu
                keepMounted
                anchorEl={moreButtonRef.current}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                transformOrigin={{vertical: "top", horizontal: "right" }}
                open={contextMenuOpen}
                onClose={() => setContextMenuOpen(false)}
            >
                <StyledMenuItem label={t("rename")} Icon={EditOutlinedIcon} onClick={handleRenameClick}/>
                <StyledMenuItem label={t("delete")} Icon={DeleteOutlineOutlinedIcon} onClick={onDelete}/>
            </StyledMenu>
        </div>
    );
};

TileButton2.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    onLabelChange: PropTypes.func,
    onDelete: PropTypes.func
};

TileButton2.defaultProps = {
    onClick: () => {},
    onLabelChange: () => {},
    onDelete: () => {}
};

export default TileButton2;