import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    makeStyles,
    LinearProgress,
    Tooltip, Typography
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {addDays, daysDiff, millisecondsToDays} from "../../utils/time";
import {isFormKey} from "../../utils/apiUtils";
import {isOverdue, remainingDays} from "../../utils/reportUtils";

const useStyles = makeStyles((theme) => ({
    termProgress: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    progressBar: {
        height: "4px",
        width: "33%",
        borderRadius: "4px",
        backgroundColor: theme.palette.grey[300],
        position: "relative",
        '&:after': {
            content: '""',
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: "4px",
            border: `1px solid ${theme.palette.divider}`
        },
    },
    barColorDefault: {
        backgroundColor: theme.palette.secondary.light
    },
    barColorOverdue : {
        backgroundColor: theme.palette.secondary.dark
    },
    label: {
        color: theme.palette.text.hint,
        lineHeight: "135%",
        marginLeft: theme.spacing(0.5)
    },
}));

const TermProgress = ({className, classes: propClasses, report, TypographyProps}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const createdAt = new Date(report.createdAt);
    // All new reports have form tags. These reports use dueDate and not static 90 days deadline.
    const dueDate = new Date(report.dueDate);
    const termLength = daysDiff(createdAt, dueDate);
    const elapsed = daysDiff(createdAt, new Date());
    const isOverdue = new Date() > dueDate;
    const days = Math.abs(termLength - elapsed);
    const label = isOverdue ? (t("days overdue", {count: days})) : (t("days remaining", {count: days}));

    // TODO: Return Open status indicator??
    if (!report.dueDate) {
        return <></>
    }

    return (
        // <Tooltip title={t("Legal term")}>
            <div className={clsx(classes.termProgress, className)}>
                <LinearProgress
                    className={clsx(classes.progressBar, propClasses.progressBar)}
                    color="secondary"
                    variant="determinate"
                    classes={{
                        barColorSecondary: clsx({
                            [classes.barColorDefault] : !isOverdue,
                            [classes.barColorOverdue] : isOverdue,
                        })
                    }}
                    value={Math.min(elapsed / termLength, 1) * 100}
                />
                <Typography className={clsx(classes.label, propClasses.label)} variant="caption" {...TypographyProps}>
                    {label}
                </Typography>
            </div>
        // </Tooltip>
    )
};

TermProgress.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    TypographyProps: PropTypes.object,
    report: PropTypes.object.isRequired,
}

TermProgress.defaultProps = {
    classes: {},
    TypographyProps: {}
};


export default TermProgress;