import React, {useContext, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    makeStyles,
    useTheme,
    Dialog,
    useMediaQuery, Tooltip
} from '@material-ui/core';
import WorkspaceWizard from "../WorkspaceWizard";
import IconButton from "@material-ui/core/IconButton";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addOrUpdateWorkspace} from "../../state/workspaces/actions";
import {currentUserSelector} from "../../state/currentUser/selectors";
import {useTranslation} from "react-i18next";
import {toId} from "../../utils/apiUtils";
import {addMember} from "../../state/members/actions";
import {loadOrUpdateCurrentUser} from "../../state/currentUser/actions";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    toolbar: {
        flex: "0 0 auto",
        padding: [theme.spacing(2), theme.spacing(3), theme.spacing(0), theme.spacing(3)],
        display: "flex",
        justifyContent: "flex-end"
    },
    content: {
        width: theme.breakpoints.values.sm,

        padding: [theme.spacing(0), theme.spacing(3), theme.spacing(3), theme.spacing(3)],
        overflowY: "auto"
        //padding: [theme.spacing(5), theme.spacing(12)],
    }
}));

const CreateWorkspaceDialog = ({className, open, onClose}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const currentUser = useSelector(currentUserSelector());

    const handleOnCompleted = (workspace) => {
        dispatch(addOrUpdateWorkspace(workspace));
        dispatch(addMember(workspace.pk, {...currentUser, sk: workspace.pk, role: "admin"}));
        const lastReads = {...currentUser.lastReads};
        lastReads[workspace.pk] = {};
        dispatch(loadOrUpdateCurrentUser({lastReads: lastReads}));
        onClose();
        navigate(`/portal/${toId(workspace.pk)}/overview`);
    }

    return (
        <Dialog
            id="create-workspace-dialog"
            className={clsx(classes.root, className)}
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
        >
            <div className={classes.toolbar}>
                <Tooltip title={t("Close Dialog")}>
                    <IconButton size="small" onClick={onClose}>
                        <ClearOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <WorkspaceWizard className={classes.content} onCompleted={handleOnCompleted}/>
            {/*<DialogContent className={classes.content}>*/}
            {/*    <WorkspaceWizard onCompleted={onClose}/>*/}
            {/*</DialogContent>*/}
            {/*<DialogActions>*/}
            {/*    <Button>ss</Button>*/}
            {/*</DialogActions>*/}
        </Dialog>
    )
}

CreateWorkspaceDialog.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func
}

CreateWorkspaceDialog.defaultProps = {
    onClose: () => {}
};

export default CreateWorkspaceDialog;