import React, {useContext, useState} from 'react';
import {Link as RouterLink, NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    AppBar,
    Container,
    Badge,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    alpha,
    List,
    makeStyles,
    Avatar,
    Icon,
    Drawer,
    Divider,
    ListItemIcon,
    ListItemText,
    CssBaseline,
    useTheme,
    FormControl,
    InputLabel, Select, Typography, withStyles, MenuList, Button, ListItem, Tooltip, Switch
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    sectionLabel: {
        display: "flex",
        alignItems: "center"
    },
    // TODO: Set sauvbtitle1 default weight to 500 globaly
    title: {
        fontWeight: 500
    },
    switch: {
        flex: "0 0 auto"
    }
}));

// TODO: Use FormControlLabel -> Makes label clickable
// TODO: Rename, merge with SettingsControl??
const PropertySectionLabel = ({title, subtitle, showSwitch, switchChecked, onSwitchChange}) => {
    const classes = useStyles();

    // TODO: Use marginLeft=auto to
    return (
        <div className={classes.sectionLabel}>
            <Box flex="1 1 auto">
                <Typography className={classes.title} variant="subtitle1">
                    {title}
                </Typography>
                { subtitle && (
                    <Typography variant="body2" color="textSecondary">
                        {subtitle}
                    </Typography>
                )}
            </Box>
            { showSwitch && (
                <Switch
                    className={classes.switch}
                    color="primary"
                    checked={switchChecked}
                    onChange={(event) => onSwitchChange(event.target.checked)}
                />
            )}
        </div>
    );
}

PropertySectionLabel.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    showSwitch: PropTypes.bool,
    switchChecked: PropTypes.bool,
    onSwitchChange: PropTypes.func,
};

PropertySectionLabel.defaultProps = {
    showSwitch: true
};

export default PropertySectionLabel