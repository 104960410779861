import React, {useContext, useRef} from 'react';
import {
    makeStyles,
    useTheme,
    MenuList,
    MenuItem,
    Avatar,
    Box,
    Tooltip,
    IconButton,
    Popover,
    Popper,
    alpha,
    InputBase,
    Typography,
    ButtonBase,
    Badge, withStyles
} from '@material-ui/core';
import PropTypes from "prop-types";
import UserAvatar from "../UserAvatar";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import {useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import {Autocomplete} from "@material-ui/lab";
import AddIcon from '@material-ui/icons/Add';
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import AttachmentUploadProgress from "../attachments/AttachmentUploadProgress";
import AttachmentsContainer from "../AttachmentsContainer";
import CancelIcon from '@material-ui/icons/Cancel';
import UserCard from "../UserCard";
import {activeMembersSelector} from "../../state/members/selectors";
import {useParams} from "react-router-dom";
import {toWorkspaceKey} from "../../utils/apiUtils";
import UserMenu from "../UserMenu";

const useStyles = makeStyles((theme) => ({
    userPickerRoot: {
        '&>*:not(:last-child)': {
            marginRight: theme.spacing(0.5)
        }
    },
    placeholder: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: "1.5rem",
        color: theme.palette.text.hint,
        backgroundColor: "inherit",
        border: "1px dashed rgba(0, 0, 0, 0.23)",
    },
    iconButton: {
        borderRadius: "50%",
        border: "1px dashed rgba(0, 0, 0, 0.23)",
        padding: theme.spacing(0),
    },
    iconButtonEmpty: {
        borderRadius: "50%",
        border: "1px dashed rgba(0, 0, 0, 0.23)",
        padding: theme.spacing(1),
    },
    addIcon: {
        //margin: theme.spacing(0.75),
        margin: theme.spacing(0.75)
    },
    inputBase: {
        padding: 10,
        width: '100%',
        borderBottom: '1px solid #dfe2e5',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 8,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        minWidth: theme.spacing(40),
        zIndex: 1,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
    paper: {
        boxShadow: 'none',
        margin: 0
    },
    popperDisablePortal: {
        position: 'relative',
    },
    popover: {
        minWidth: theme.spacing(80),
    },
    badge: {
        '& button': {
            display: "none"
        },
        '&:hover': {
            '& button': {
                display: "initial"
            }
        }
    },
    readOnlyAvatar: {
        display: "inline-flex",
        verticalAlign: "middle"
    }
}));

const DeleteButton = withStyles((theme) => ({
    root: {
        // width: 22,
        // height: 22,
        // border: `2px solid ${theme.palette.background.paper}`,
        padding: 0,
        color: theme.palette.error.main,
        backgroundColor: theme.palette.error.contrastText,
        '&:hover': {
            color: theme.palette.error.dark,
            backgroundColor: theme.palette.error.contrastText,
        },
    },
}))(IconButton);

const UserPicker = ({className, userIDs, readOnly, onAdded, onRemoved}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {workspaceId} = useParams();
    const workspaceKey = toWorkspaceKey(workspaceId);
    const members = useSelector(activeMembersSelector(workspaceKey)) ?? [];
    const selectedUsers = userIDs.map(id => members.find(user => user.id === id)).filter(Boolean);
    const notAssignedUsers = members.filter(user => !userIDs.includes(user.id)).filter(Boolean);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleUserSelected = (selected) => {
        if (!userIDs.includes(selected.id)) {
            onAdded(selected.id);
        }
        setAnchorEl(null);
    };

    const handleButtonClick = (event) => {
        event.currentTarget.blur(); // removes focus outline from button and hides tooltip
        setAnchorEl(event.currentTarget);
    }

    if (readOnly) {
        return (
            <span className={clsx(classes.userPickerRoot, className)}>
                {selectedUsers.map(user => (
                    <Tooltip key={user.id} title={user.name}>
                        <UserAvatar className={classes.readOnlyAvatar} component="span" name={user.name} imageKey={user.picture} size="medium"/>
                    </Tooltip>
                ))}
            </span>
        )
    }

    return (
        <>
            <span className={clsx(classes.userPickerRoot, className)}>
                {selectedUsers.map(user => (
                    <Tooltip key={user.id} title={user.name}>
                        <Badge
                            className={classes.badge}
                            overlap="circular"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            badgeContent={
                                <DeleteButton
                                    disableRipple
                                    disableFocusRipple
                                    size="small"
                                    onClick={() => onRemoved(user.id)}
                                >
                                    <CancelIcon fontSize="inherit"/>
                                </DeleteButton >
                            }
                        >
                            <UserAvatar component="span" name={user.name} imageKey={user.picture} size="medium"/>
                        </Badge>
                    </Tooltip>
                ))}
                <Tooltip title={t("addUser")}>
                    <IconButton className={classes.iconButton} onClick={handleButtonClick}>
                        <AddOutlinedIcon className={classes.addIcon}/>
                    </IconButton >
                </Tooltip>
            </span>
            <UserMenu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                users={notAssignedUsers}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={() => setAnchorEl(null)}
                onUserSelected={handleUserSelected}
            />
        </>
    );
};

UserPicker.propTypes = {
    userIDs: PropTypes.arrayOf(PropTypes.string),
    readOnly: PropTypes.bool,
    onAdded: PropTypes.func,
    onRemoved: PropTypes.func,
}

UserPicker.defaultProps = {
    userIDs: [],
    onAdded: () => {},
    onRemoved: () => {}
};

export default UserPicker;