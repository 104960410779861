import React from 'react';
import PropTypes from 'prop-types';
import {
    Chip,
    makeStyles,
    useTheme
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {isApproved, isPendingApproval, isRejected} from "../../utils/reportUtils";
import Tag from "../Tag";

const getTagProps = (report, t, theme) => {
    if (isPendingApproval(report)) {
        return {
            label: t("approvalPending"),
            backgroundColor: theme.palette.warning.lightBg,
            textColor: theme.palette.warning.main
        };
    } else if (isRejected(report)) {
        return {
            label: t("approvalRejected"),
            backgroundColor: theme.palette.error.lightBg,
            textColor: theme.palette.error.main
        };
    } else {
        return {
            label: t("approvalAccepted"),
            backgroundColor: theme.palette.success.lightBg,
            textColor: theme.palette.success.main
        };
    }
}

const ApprovalTag = ({className, report, ...rest}) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Tag
            className={className}
            {...getTagProps(report, t, theme)}
            {...rest}
        />
    )
};

ApprovalTag.propTypes = {
    className: PropTypes.string,
    report: PropTypes.shape({
        approval: PropTypes.string.isRequired
    }).isRequired
}

ApprovalTag.defaultProps = {
};


export default ApprovalTag;