export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}

export const groupBy = (array, key) => {
    return array.reduce((accumulator, t) => {
        (accumulator[t[key]] = accumulator[t[key]] || []).push(t);
        return accumulator;
    }, {});
};

export const stableSort = (array, order, orderBy) => {
    const comparator = getComparator(order, orderBy);
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0)
            return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export const deleteElementAtIndex = (array, index) => {
    const newArray = [...array];
    newArray.splice(index, 1);
    return newArray;
}

export const deleteElementByValue = (array, value) => {
    const index = array.indexOf(value);
    const newArray = [...array];
    newArray.splice(index, 1);
    return newArray;
}

export const deleteMatchingElement = (array, predicate) => {
    const index = array.findIndex(predicate);
    const newArray = [...array];
    newArray.splice(index, 1);
    return newArray;
}

export const updateMatchingElementField = (array, predicate, fieldName, newValue) => {
    const index = array.findIndex(predicate);
    const newArray = [...array];
    newArray[index][fieldName] = newValue;
    return newArray;
}

export const updateElementByIndex = (array, index, newValue) => {
    const newArray = [...array];
    newArray[index] = newValue;
    return newArray;
}