import React, {useContext, useState, useEffect, useRef} from 'react';
import {numericId} from "../utils/idGenerator";
import {ApiContext} from "../contexts/ApiContext";

// TODO: Check if avatar already fetched in blob storage !!!!
// TODO: Avatars images are switched with a delay caused by creating blob -> cache urls of created blobs
// TODO: Fix, called to many times
export const useFetchAvatarPicture = (s3Key) => {
    const api = useContext(ApiContext);
    const [url, setUrl] = useState();
    const urlRef = useRef(null);
    const isMounted = useRef(true);

    const revokeBlobUrl = () => {
        URL.revokeObjectURL(urlRef.current);
    }

    useEffect(() => {
        isMounted.current = true;
        if (!s3Key) {
            // Avatar with already loaded images might have their key set to null if a user for example would
            // want to reset the avatar. The blobUrl must be then revoked and the url state set to null to avoid
            // rendering the old image in the avatar.
            if (urlRef.current) {
                revokeBlobUrl();
                setUrl(null);
            }
            return;
        }

        (async () => {
            const downloaded = await api.storage.downloadObject(s3Key);
            // Top avoid react errors we update state only if the component is still mounted. It can happen that
            // the component is unmounted before the data are downloaded. Updating state of unmounted component leads
            // to react errors.
            if (isMounted.current) {
                const blobUrl = URL.createObjectURL(downloaded.Body);
                urlRef.current = blobUrl;
                setUrl(blobUrl);
            }
        })();

        // Return cleanup function
        return () => {
            isMounted.current = false;
            if (urlRef.current) {
                revokeBlobUrl();
                //setUrl(null);
            }
        }
    }, [s3Key]);

    return [url, setUrl];
}