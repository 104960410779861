import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar, Box,
    Chip, alpha,
    makeStyles,
    TextField, Typography, useTheme,
} from '@material-ui/core';
import MUIRichTextEditor from 'mui-rte'
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import TitleIcon from '@material-ui/icons/Title';
import {ThemeProvider} from "@emotion/react";
import {CardShadow, NavBarWidth} from "../../constants";
import {useSelector} from "react-redux";
import {activeMembersSelector} from "../../state/members/selectors";
import {useParams} from "react-router-dom";
import {toWorkspaceKey} from "../../utils/apiUtils";

const useStyles = makeStyles((theme) => ({
    richTextEditorReadOnly: {
        position: "relative",
        '& div[data-contents]': {
            padding: 0
        },
    },
    richTextEditor: {
        position: "relative",
        borderRadius: "4px",
        '&::before': {
            content: '""',
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: "4px"
        },
        '&:hover::before': {
            border: `1px solid ${theme.palette.text.primary}`
        },
        '&:focus-within': {
            '&:before': {
                content: '""',
                position: "absolute",
                width: "100%",
                height: "100%",
                borderRadius: "4px",
                border: `2px solid ${theme.palette.primary.main}`
            },
        },
        '& div[data-contents]': {
            padding: [theme.spacing(2.25), theme.spacing(1.75)]
        },
        '& #mui-rte-root': {
            margin: [0, theme.spacing(0.25)],
        },
        '& #mui-rte-editor': {
            marginTop: "2px",   // Needed for scrollbar so that it doesn't overlap borders
            marginBottom: "2px",
        },
        '& #mui-rte-toolbar': {
            //opacity: props => props.toolbarAnchor ? 1 : 0,
            //display: props => props.toolbarAnchor ? "initial" : "none",
            visibility: props => props.toolbarOffset ? "visible" : "hidden",
            transform: props => props.toolbarOffset ? "none" : "scale(0.75, 0.5625)",
            //pointerEvents: props => props.toolbarAnchor ? "initial" : "none",
            left: props => props.toolbarOffset?.left ?? 0,
            top: props => props.toolbarOffset?.top ?? 0,
            width: props => props.toolbarOffset?.width ?? 0,
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.short,
            })
        },
    },
    fixedBorder: {
        '&::before': {
            border: `1px solid ${theme.palette.divider}`
        },
    }
}));

const Title1ButtonComponent = (t) => (props) => (
    <Button
        id={props.id}
        variant="text"
        onMouseDown={props.onMouseDown}
        color={props.active ? "primary" : "inherit"}
        disabled={props.disabled}
    >
        {`${t("Title")} 1`}
    </Button>
);

const Title2ButtonComponent = (t) => (props) => (
    <Button
        id={props.id}
        variant="text"
        onMouseDown={props.onMouseDown}
        color={props.active ? "primary" : "inherit"}
        disabled={props.disabled}
    >
        {`${t("Title")} 2`}
    </Button>
);

const UserChip = ({blockProps}) => {
    const { value } = blockProps // Get the value provided in the TAutocompleteItem[]

    const handleClick = () => {
        console.log(value.name)
    }

    return (
        <Chip
            label={value.name}
            onClick={handleClick}
        />
    )
}

// TODO: onSave is invoked even if user just clicks in the the text editor
// TODO: Reduce number of flexboxes in overriding styles
const RichTextEditor = ({className, value, fixedBorder, readOnly, placeholder, onChange, onSave}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const editorRef = useRef();
    const rootContainerRef = useRef();
    const [toolbarOffset, setToolbarOffset] = useState( null);
    const classes = useStyles({readOnly, toolbarOffset});

    useMemo(() => {
        Object.assign(theme, {
            overrides: {
                MUIRichTextEditor: {
                    root: {
                    },
                    container: {
                        margin: 0,
                    },
                    editor: {
                    },
                    editorContainer: {
                        margin: 0,
                        padding: 0,
                        color: theme.palette.text.primary,
                        ...theme.typography.body2,
                        // fontFamily: "Inter, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, Sans-Serif",
                        //fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        lineHeight: 1.57143
                    },
                    placeHolder: {
                        position: "initial",
                        color: theme.palette.text.primary,
                        opacity: 0.42,
                        margin: 0,
                        padding: [theme.spacing(2.25), theme.spacing(1.75)]
                    },
                    toolbar: {
                        position: "fixed",
                        // width: "100%",
                        zIndex: theme.zIndex.drawer + 2,
                        backgroundColor: theme.palette.background.paper,
                        // top: -50,
                        // left: 1,
                        borderRadius: "4px",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        ...CardShadow,
                    }
                }
            }
        });
    }, [theme]);

    const handleOnClick = (event) => {
        rootContainerRef.current = event.currentTarget;
        setToolbarOffset({left: event.currentTarget.offsetLeft, top: event.currentTarget.offsetTop - 50, width: event.currentTarget.offsetWidth});
    };

    const handleBlur = (event) => {
        console.log("")
        rootContainerRef.current = null;
        setToolbarOffset(null);
        if (editorRef.current) {
            editorRef.current.save();
        }
    };

    const handleSave = (newValue) => {
        if (newValue !== value) {
            onSave(newValue);
        }
    };

    useEffect(() => {
        function onResizeHandler() {
            if (rootContainerRef.current) {
                const element = rootContainerRef.current;
                element.focus();
                element.blur();
            }
        }

        window.addEventListener('resize', onResizeHandler);

        return function cleanup() {
            window.removeEventListener('resize', onResizeHandler);
        };
    }, []);

    const Title3 = (props) => {
        return (
            <Typography variant="h6">
                {props.children}
            </Typography>
        )
    }

    return (
        <div
            tabIndex={0}
            className={clsx(className, {[classes.richTextEditor]: !readOnly, [classes.richTextEditorReadOnly]: readOnly, [classes.fixedBorder]: fixedBorder})}
            onClick={handleOnClick}
            onBlur={handleBlur}
        >
            <ThemeProvider theme={theme}>
                <MUIRichTextEditor
                    ref={editorRef}
                    defaultValue={value}
                    readOnly={readOnly}
                    toolbar={!readOnly}
                    label={placeholder}
                    inlineToolbar={false}
                    controls={["bold", "italic", "underline", "numberList", "bulletList", "title1", "title2", "title3"/*, "link"*/]}
                    onSave={handleSave}
                    customControls={[
                        {
                            name: "title1",
                            component: Title1ButtonComponent(t),
                            type: "inline",
                            inlineStyle: {
                                ...theme.typography.h3,
                                color: theme.palette.text.primary,
                            }
                        },
                        {
                            name: "title2",
                            component: Title2ButtonComponent(t),
                            type: "inline",
                            inlineStyle: {
                                ...theme.typography.h5,
                                color: theme.palette.text.primary,
                            }
                        },
                        {
                            name: "title3",
                            component: Title2ButtonComponent(t),
                            type: "block",
                            blockWrapper: <Title3/>
                        }
                    ]}
                />
            </ThemeProvider>
        </div>
    );
}

RichTextEditor.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    placeholder: PropTypes.string,
    onSave: PropTypes.func
}

RichTextEditor.defaultProps = {
    placeholder: "",
    fixedBorder: false,
    onChange: () => {},
    onSave: () => {}
};

export default RichTextEditor;