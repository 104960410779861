import { daysDiff, millisecondsToDays } from "./time";

const getFilename = (path) => path.split('/').pop();

export const systemMessage = "__system";
export const termDays = 30;

export function isSystemMessage(message) {
    return message.text === systemMessage;
}

export function getReportExcerpt(message, attachments) {
    return message.substring(0, 100) ?? getFilename(attachments[0]);
}

export function formatReportExcerpt(excerpt, t) {
    if (excerpt === "__voicemail") {
        return t("phoneReport");
    }

    return excerpt;
}

export const PendingApproval = "pending";
export const RejectedApproval = "rejected";
export const AcceptedApproval = "approved";
export const SolvedState = "solved";
export const UnfoundedState = "junk";
export const OpenState = "open";

export function isPendingApproval(report) {
    if (!Boolean(report.approval)) {
        return false;
    }
    return report.approval.startsWith(PendingApproval);
}

export function isRejected(report) {
    return report.approval === RejectedApproval;
}

export function isApproved(report) {
    return report.approval === AcceptedApproval;
}

export function isOverdue(report) {
    return report.dueDate && isOpen(report) && new Date(report.dueDate) < new Date();
}

export function isSolved(report) {
    return report.state === SolvedState;
}

export function isUnfounded(report) {
    return report.state === UnfoundedState;
}

export function isOpen(report) {
    return !report.state || report.state === OpenState;
}

export function isReportClosed(messages) {
    if (!messages || messages.length === 0) {
        return false;
    }

    for (let i = messages.length - 1; i >= 0; i--) {
        if (Boolean(messages[i].state)) {
            return messages[i].state === "solved" || messages[i].state === "junk"
        }
    }

    return false;
}

// TODO: Delete
export function isClosed(report) {
    return !isOpen(report);
}

// TODO: Delete
export function elapsedDays(report) {
    return Math.floor(millisecondsToDays(new Date() - new Date(report.createdAt)));
}

export function remainingDays(report) {
    const termLength = daysDiff(new Date(report.createdAt), new Date(report.dueDate));
    return termLength - elapsedDays(report);
}

// TODO: Rename
// TODO: Solution message is not parsed
export function getSystemMessageContent(message, userKey, t) {
    if (message.approval === PendingApproval) {
        return t("userHasRequestedApproval", {user: userKey});
    } else if (message.state === "solved") {
        return t("userClosedReport", {user: userKey});
    } else if (message.state === "junk") {
        return t("userMarkedReportAsJunk", {user: userKey});
    } else if (!message.state || message.state === "open") {
        return t("userOpenedReport", {user: userKey});
    }
}

export function hasUnreadMessages(report, currentUser) {
    // Return false if it was the current user who has sent the last message in the report. Sender fields
    // holds key of the last user who sent a message to the report.
    // The old reports won't have sender field. We return false for these reports in order to not mark
    // all old reports as unread.
    if (report.sender === currentUser.pk) {
        return false;
    }

    const lastMessageDate = new Date(report.excerptUpdatedAt);
    const lastRead = currentUser.lastReads[report.workspace][report.pk];
    return Boolean(lastRead) ? new Date(lastRead) < lastMessageDate : true;
}