import { Auth } from 'aws-amplify';

// TODO: Move to auth roots
export const  changePassword = async (oldPassword, newPassword) => {
    await Auth.currentAuthenticatedUser({
        bypassCache: false
    }).then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
    })
        .then(data => console.log(data))
        .catch(err => console.warn(err));
}