import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles, CardHeader, Grid, TextField, CardActionArea, Container
} from '@material-ui/core';
import {ApiContext} from "../../../contexts/ApiContext";
import {useDispatch} from "react-redux";
import {loadMessages} from "../../../state/messages/actions";
import {toReportKey} from "../../../utils/apiUtils";
import {formatReportId, normalizeId} from "../../../utils/textFormatter";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        padding: [theme.spacing(1), theme.spacing(3)]
    },
    avatar: {
        height: 100,
        width: 100
    },
    headerIcon: {
        maxWidth: theme.spacing(6),
        marginBottom: theme.spacing(2)
    },
    header: {
        flexDirection: "column",
        justifyContent: "center"
    },
    actionButton: {
    }
}));

const noErrorText = " ";

const ReportLoginDialog = ({ error, onLogin, ...rest }) => {
    const classes = useStyles();
    const api = useContext(ApiContext);
    const { t } = useTranslation();
    const [id, setId] = useState('');
    const [errorText, setErrorText] = useState(noErrorText);

    const handleSubmit = async (event) => {
        event.persist()
        event.preventDefault();
        const normalizedId = id.replace(" ", "");
        if (!Boolean(normalizedId)) {
            return;
        }

        const accessKey = await api.query.getAccessKey(normalizedId);
        // TODO: Don't use temp# to determine whether the report is created
        if (!Boolean(accessKey)) {
            setErrorText(t("reportNotFound"));
            return;
        } else if (accessKey.resourceKey.startsWith("temp#")) {
            setErrorText(t("reportNotCreated"));
            return;
        }
        setErrorText("");
        onLogin({accessId: normalizedId, reportKey: accessKey.resourceKey});
    };

    const handleTextChange = (event) => {
        if (event.target.value) {
            setId(event.target.value.trim());
        } else {
            setId("");
        }

        if (errorText.length != 1) {
            setErrorText(noErrorText);
        }
    }

    return (
        <Container maxWidth="xs">
            <form
                autoComplete="off"
                onSubmit={handleSubmit}
                {...rest}
            >
                <Card className={classes.cardRoot}>
                    <CardHeader
                        className={classes.header}
                        title={t("loginToReport")}
                        titleTypographyProps={{variant: "h4"}}
                        avatar={
                            <img className={classes.headerIcon} alt="Whispero" src="/static/images/whispero.png"/>
                        }
                    />
                    <CardContent>
                        <TextField
                            required
                            fullWidth
                            label={t("reportId")}
                            name="reportId"
                            error={errorText.length !== 1}
                            helperText={errorText}
                            value={formatReportId(normalizeId(id))}
                            onChange={handleTextChange}
                            variant="outlined"
                        />
                    </CardContent>
                    <Box
                        display="flex"
                        justifyContent="center"
                        px={2}
                        pb={2}
                        pt={0}
                    >
                        <Button
                            fullWidth
                            type="submit"
                            size="large"
                            color="primary"
                            variant="contained"
                        >
                            {t("continue")}
                        </Button>
                    </Box>
                </Card>
            </form>
        </Container>
    );
};

ReportLoginDialog.propTypes = {
    className: PropTypes.string
};

export default ReportLoginDialog;
