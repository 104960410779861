import React, {useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    CardHeader,
    Grid,
    TextField,
    CardActionArea,
    Container,
    Paper,
    FormControlLabel,
    Checkbox,
    alpha,
    FormLabel
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import FormTextField from "../FormTextField/FormTextField";
import FormBlock from "./index";

const useStyles = makeStyles((theme) => ({
    editableHeader: {
        display: "flex",
        alignItems: "center",
    },
    checkbox: {
        margin: 0,
        '& .MuiFormControlLabel-label': {
            ...theme.typography.body2,
        }
    },
    presentationalLabel: {
        ...theme.typography.subtitle2,
        color: theme.palette.text.secondary
    },
    focused: {
        background: theme.palette.background.paper
    }
}));

const PresentationalBlockHeader = ({className, languagePack, blockTemplate, defaultValue}) => {
    const classes = useStyles();

    return (!languagePack[blockTemplate.label] && !defaultValue) || languagePack[blockTemplate.label] === "" ? (
        <></>
    ) : (
        <FormLabel className={clsx(classes.presentationalLabel, className)}>
            {languagePack[blockTemplate.label] ?? defaultValue}
        </FormLabel>
    )
}

const EditableBlockHeader = ({className, blockTemplate, languagePack, defaultValue, placeholder, onTextChange, onTemplateChange}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [required, setRequired] = useState(blockTemplate.required ?? false);

    const handleRequiredChange = (event) => {
        setRequired(event.target.checked);
        onTemplateChange({key: 'required', value: event.target.checked});
    }

    return (
        <div className={clsx(classes.editableHeader, className)}>
            <FormTextField
                classes={{focused: classes.focused}}
                templateKey={blockTemplate.label}
                languagePack={languagePack}
                defaultValue={defaultValue}
                textVariant="subtitle2"
                fullWidth
                placeholder={placeholder ?? t("question")}
                onTextChange={onTextChange}
            />
            <FormControlLabel
                className={classes.checkbox}
                label={t("required")}
                control={
                    <Checkbox
                        name="required"
                        color="primary"
                        size="small"
                        checked={required}
                        onChange={handleRequiredChange}
                    />
                }
            />
        </div>
    )
}

const BlockHeader = ({ className, blockTemplate, languagePack, defaultValue, placeholder, editable, onTemplateChange, onTextChange}) => {
    return editable ? (
        <EditableBlockHeader
            className={className}
            blockTemplate={blockTemplate}
            languagePack={languagePack}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onTextChange={onTextChange}
            onTemplateChange={onTemplateChange}
        />
    ) : (
        <PresentationalBlockHeader
            className={className}
            blockTemplate={blockTemplate}
            languagePack={languagePack}
            defaultValue={defaultValue}
        />
    )
}

BlockHeader.propTypes = {
    className: PropTypes.string,
    blockTemplate: PropTypes.object.isRequired,
    languagePack: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    editable: PropTypes.bool,
    placeholder: PropTypes.string,
    onTextChange: PropTypes.func,
    onTemplateChange: PropTypes.func
};

BlockHeader.defaultProps = {
    onTextChange: () => {},
    onTemplateChange: () => {},
};

export default BlockHeader;
