import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles, CardHeader, Grid, TextField, CardActionArea, Container
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import RichTextEditor from "../RichTextEditor";
import DialogContainer from "../DialogContainer";
import StaticDialog from "../staticDialog/StaticDialog";
import StaticDialogHeader from "../staticDialog/StaticDialogHeader";
import StaticDialogContent from "../staticDialog/StaticDialogContent";
import StaticDialogActions from "../staticDialog/StaticDialogActions";
import {useNavigate} from "react-router-dom";
import {toId} from "../../utils/apiUtils";
import {useSelector} from "react-redux";
import {memberSelector} from "../../state/members/selectors";

const getDefaultData = (t, workspace, owner, language) => (
    t("disclaimer.defaultText", {
        ns: "dialogs",
        lng: language,
        contacts: owner?.name ?? "", //owner.name,
        workspaceName: workspace.name ?? "",
        workspaceId: toId(workspace.pk),
        workspacePhone: workspace.phone && workspace.phone !== "" ? workspace.phone : "+420 239 050 505",
        workspaceAddress: workspace.address ?? ""
    })
)

const useStyles = makeStyles((theme) => ({
    textOffset: {
        // to compensate padding of rtf editor when its editable. Horizontal margin is 2px smaller than
        // rtf editor padding, this is to keep border visible when rtf is in scrollable container
        margin: [-theme.spacing(2), -theme.spacing(1.75)]
    },
    actions: {
        pointerEvents: props => props.editable ? "none" : "initial"
    },
}));

// TODO: Refactor fallback language
const DisclaimerDialog = ({ className, classes: propClasses,  workspace, owner, language, layout, editable, onContinue, onSave }) => {
    const classes = useStyles({editable});
    const { t } = useTranslation(['dialogs']);
    const navigate = useNavigate();
    const rtfData = layout ?? getDefaultData(t, workspace, owner, language);

    const handleCancelClick = () => {
        navigate('/submit');
    }

    return (
        <DialogContainer className={className}>
            <StaticDialog id="disclaimer-dialog" className={propClasses.dialog}>
                {/*<StaticDialogHeader workspace={workspace}/>*/}
                <StaticDialogContent>
                    <RichTextEditor className={clsx({[classes.textOffset]: editable})} readOnly={!editable} value={rtfData} onSave={onSave}/>
                </StaticDialogContent>
                <StaticDialogActions className={classes.actions}>
                    <Button
                        fullWidth
                        size="large"
                        color="primary"
                        variant="outlined"
                        onClick={handleCancelClick}
                    >
                        {t("disclaimer.cancelButton", {ns: "dialogs", lng: language})}
                    </Button>
                    <Button
                        fullWidth
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={onContinue}
                    >
                        {t("disclaimer.acceptButton", {ns: "dialogs", lng: language})}
                    </Button>
                </StaticDialogActions>
            </StaticDialog>
        </DialogContainer>
    );
};

DisclaimerDialog.propTypes = {
    className: PropTypes.string,
    workspace: PropTypes.shape({
        pk: PropTypes.string.isRequired,
        name: PropTypes.string,
        picture: PropTypes.string,
    }).isRequired,
    language: PropTypes.string,
    layout: PropTypes.string
};

DisclaimerDialog.defaultProps = {
    classes: {},
    dialogSettings: {},
    onSave: () => {},
    onGoBack: () => {},
    onContinue: () => {},
    language: "en"
};

export default DisclaimerDialog;
