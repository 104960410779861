import React from 'react';
import clsx from 'clsx';
import {
    makeStyles,
} from '@material-ui/core';
import PropTypes from "prop-types";
import StyledTabs from "./StyledTabs";

const useStyles = makeStyles((theme) => ({
    tabPanel: {
        overflow: "auto"
    },
}));

const TabPanel = ({ className, children, value, index, ...other }) => {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.tabPanel, className)}
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

export default TabPanel;

TabPanel.propTypes = {
    className: PropTypes.string,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
}