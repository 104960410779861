import React, {useContext, useEffect, useState} from 'react';
import {
    AppBar,
    Box, Button, CircularProgress,
    Container, Divider,
    Grid, Hidden, IconButton, Link,
    makeStyles, Select, Toolbar, Typography,
    useTheme
} from '@material-ui/core';
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {ApiContext} from "../../contexts/ApiContext";
import {currentUserSelector} from "../../state/currentUser/selectors";
import {allWorkspacesSelector} from "../../state/workspaces/selectors";
import {addOrUpdateWorkspace, loadWorkspaces} from "../../state/workspaces/actions";
import Loader from "../../components/Loader";
import {toId, toWorkspaceKey} from "../../utils/apiUtils";
import {AuthContext} from "../../contexts/AuthContext";
import {loadOrUpdateCurrentUser} from "../../state/currentUser/actions";
import Page from "../../components/Page/Page";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ElevationScroll from "../../components/ElevationScroll";
import UserAvatar from "../../components/UserAvatar";
import ProfileSettingsDialog from "../../components/ProfileSettingsDialog";
import ProfileButton from "../../components/ProfileButton/ProfileButton";
import {TopBarHeight} from "../../constants";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    invitationView: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.text.secondary
    },
    appBar: {
        backgroundColor: theme.palette.background.default
    },
    toolbar: {
        minHeight: TopBarHeight,
        height: TopBarHeight
    },
}));

const InvitationView = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const api = useContext(ApiContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {invitationId} = useParams();
    const [error, setError] = useState("");
    const currentUser = useSelector(currentUserSelector());
    const { store } = useContext(ReactReduxContext);
    const { refreshToken } = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            const invitation = await api.query.getInvitation(invitationId);
            console.log("invitation fetched: %o", invitation);
            if (!invitation) {
                setError("The invitation has expired or does not exist.");
                return;
            }

            if (invitation.email !== currentUser.email) {
                setError("The invitation was intended for a different user.");
                return;
            }

            // Create membership only if the user is not member of the team already
            const isMember = store.members && Boolean(store.members[invitation.workspace]?.find(member => member.pk === currentUser.pk));
            if (!isMember) {
                await api.mutation.acceptInvitation(invitationId, currentUser.name, currentUser.picture);
                await refreshToken();
                const workspace = await api.query.getWorkspace(invitation.workspace);
                dispatch(addOrUpdateWorkspace(workspace))
                const lastReads = {...currentUser.lastReads};
                lastReads[workspace.pk] = {};
                dispatch(loadOrUpdateCurrentUser({lastReads: lastReads}));
            }

            //dispatch(loadOrUpdateCurrentUser(...user, ...{teams: [...user.teams, team]}));
            navigate(`/portal/${toId(invitation.workspace)}`);
            //window.location.reload();
        })();
    }, [invitationId])

    // TODO: Use suspense or loader component
    return (
        <Page
            title={t("invitationRouteTitle")}
            className={classes.invitationView}
        >
            { !error ? (
                <Loader label="Processing Invitation" />
            ) : (
                <Typography variant="h5" color="textSecondary">{error}</Typography>
            )}
        </Page>
    );
};

export default InvitationView;