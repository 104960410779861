import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles, CardHeader, Grid, TextField, CardActionArea, Container, Tooltip
} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const useStyles = makeStyles((theme) => ({
    toolbarButton: {
        color: theme.palette.text.hint
    },
    active: {
        color: theme.palette.primary.main
    },
    textButtonLabel: {
        lineHeight: "initial"
    }
}));

const ToolbarButton = ({ className, icon, label, active, onClick, ...rest }) => {
    const classes = useStyles();

    const handleMouseDown = (e) => {
        e.preventDefault();
        onClick();
    }

    if (icon) {
        return (
            <Tooltip title={label}>
                <IconButton
                    //color={active ? "primary" : "default"}
                    className={clsx(className, classes.toolbarButton, {[classes.active]: active})}
                    size="small"
                    onMouseDown={handleMouseDown}
                    {...rest}
                >
                    {icon}
                </IconButton>
            </Tooltip>
        )
    } else {
        return (
            <Button
                className={clsx(className, classes.toolbarButton, {[classes.active]: active})}
                variant="text"
                onMouseDown={handleMouseDown}
                classes={{
                    label: classes.textButtonLabel
                }}
                {...rest}
            >
                {label}
            </Button>
        )
    }
};

ToolbarButton.propTypes = {
    icon: PropTypes.object,
    label: PropTypes.string,
    size: PropTypes.string,
    active: PropTypes.bool,
    onClick: PropTypes.func
};

ToolbarButton.defaultProps = {
    onClick: () => {}
};

export default ToolbarButton;
