import React, {useContext, useState} from 'react';
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    AppBar,
    Container,
    Badge,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    alpha,
    makeStyles, Avatar, Icon, Typography, CssBaseline, Tooltip, ListItemIcon, ListItemText, Divider
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import ProfileSettingsDialog from "../ProfileSettingsDialog";
import UserAvatar from "../UserAvatar";
import {ApiContext} from "../../contexts/ApiContext";
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {resetCurrentUser} from "../../state/currentUser/actions";
import {useDispatch, useSelector} from "react-redux";
import {currentUserSelector} from "../../state/currentUser/selectors";
import {AuthContext} from "../../contexts/AuthContext";
import {useTranslation} from "react-i18next";
import { clearState } from "../../state/rootActions";

const useStyles = makeStyles((theme) => ({
    menuHeader: {
        flexDirection: "column",
        alignItems: "flex-start",
        marginBottom: theme.spacing(1)
    },
    menuItem: {
        paddingRight: theme.spacing(3)
    },
    menuItemIcon: {
        minWidth: theme.spacing(5)
    }
}));

const ProfileButton =  ({...rest}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(currentUserSelector()) ?? {};
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [settingsDialogOpen, setSettingsDialogOpen] = React.useState(false);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    }

    const handleSettingsItemClick = () => {
        setSettingsDialogOpen(true);
        handleProfileMenuClose();
    }

    const handleLogoutClick = async (event) => {
        event.preventDefault();
        await auth.signOut();
        // TODO: Move to signOut method
        navigate('/portal/signin');
        dispatch(clearState());
        //window.location.reload();
    };

    return (
        <>
            <IconButton edge="end" aria-label="current user" color="inherit" onClick={handleProfileMenuOpen} size="small" {...rest}>
                <UserAvatar name={user.name} imageKey={user.picture} size="medium"/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                getContentAnchorEl={null}
                id="profile-menu"
                keepMounted
                transformOrigin={{vertical: "top", horizontal: "center" }}
                open={Boolean(anchorEl)}
                onClose={handleProfileMenuClose}
            >
                <MenuItem className={clsx(classes.menuHeader, classes.menuItem)} button={false}>
                    <Typography variant="subtitle1" style={{fontWeight: 500}}>{user.name}</Typography>
                    <Typography variant="body2" color="textSecondary">{user.email}</Typography>
                </MenuItem>
                <Divider/>
                <MenuItem className={classes.menuItem} onClick={handleSettingsItemClick}>
                    <ListItemIcon className={classes.menuItemIcon}>
                        <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t("Profile Settings")} />
                </MenuItem>
                <MenuItem className={classes.menuItem} onClick={handleLogoutClick}>
                    <ListItemIcon className={classes.menuItemIcon}>
                        <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t("Logout")} />
                </MenuItem>
            </Menu>
            <ProfileSettingsDialog open={settingsDialogOpen} onClose={() => setSettingsDialogOpen(false)}/>
        </>
    )
}

ProfileButton.propTypes = {
};

ProfileButton.defaultProps = {
};

export default ProfileButton