import React, {useContext, useEffect, useState} from 'react';
import {
    Box, CircularProgress,
    Container, Divider,
    Grid, Link,
    makeStyles, Typography,
    useTheme
} from '@material-ui/core';
import {useNavigate, useParams} from "react-router-dom";
import {ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {ApiContext} from "../../contexts/ApiContext";
import {currentUserSelector} from "../../state/currentUser/selectors";
import {allWorkspacesSelector} from "../../state/workspaces/selectors";
import {addOrUpdateWorkspace, loadWorkspaces} from "../../state/workspaces/actions";
import Loader from "../../components/Loader";
import {toId, toWorkspaceKey} from "../../utils/apiUtils";

const useStyles = makeStyles((theme) => ({
    AttachmentView: {
        height: '100%',
    }
}));

// TODO: Add Page
const AttachmentView = () => {
    const classes = useStyles();

    const key = useParams()['*'];
    const [url, setUrl] = React.useState();
    const api = useContext(ApiContext);
    if (!key) {
        console.warn("attachment key is not valid");
    }

    useEffect(() => {
        (async () => {
            const signedUrl = await api.storage.getSignedUrl(key);
            setUrl(signedUrl);
        })();
    }, )

    return url ? (
        <Link to={url} download target="_self">Download</Link>
    ) : (
        <Loader />
    );
};

export default AttachmentView;