import React, {Children} from 'react';
import {
    Box,
    makeStyles,
    Tooltip,
    IconButton
} from '@material-ui/core';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    component: {
        height: "100%",
        width: "100%",
        flex: "1 1 auto",
        overflow: "hidden",
    },
    clearButton: {
        minWidth: 0,
        flex: "0 0 auto",
        color: theme.palette.text.secondary,
    },
    inputButton: {
        ...theme.typography.body2,
        textTransform: "none",
        height: "100%",
        justifyContent: "flex-start",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        '&:focus': {
            outline: 'none',
        },
    }
}));

const PickerContainer = ({tooltip, children, onClear, ...rest}) => {
    const classes = useStyles();

    return (
        <Box
            display="flex"
            alignItems="center"
            style={{height: "100%"}}
            {...rest}
        >
            <Tooltip title={tooltip ? tooltip : ""}>
                { React.cloneElement(Children.only(children), {className: classes.component}) }
            </Tooltip>
            { onClear && (
                    <IconButton className={classes.clearButton} onClick={onClear} size="small" >
                        <ClearOutlinedIcon fontSize="small"/>
                    </IconButton>
            )}

        </Box>
    );
};

PickerContainer.propTypes = {
    tooltip: PropTypes.string,
    component: PropTypes.elementType,
    onClear: PropTypes.func
}

export default PickerContainer;