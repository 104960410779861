import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import {
    makeStyles,
    Divider,
    Hidden,
    Typography,
    TextField,
    Button,
    Paper,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    List,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { WorkspaceKeyContext } from "../../contexts/WorkspaceKeyContext";
import { allFormsSelector, formSelector } from "../../state/forms/selectors";
import TileButton2 from "../../components/TileButton2";
import TileButton from "../../components/TileButton/TileButton";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import AddTileButton from "../../components/AddTileButton";
import { Navigate, useParams } from "react-router-dom";
import { toFormKey, toId, toUserKey } from "../../utils/apiUtils";
import { formatFormId } from "../../utils/textFormatter";
import FormEditorToolbar from "./FormEditorToolbar";
import FormPage from "../../components/FormPage/FormPage";
import {
    deleteBlock,
    deletePage,
    deminifyLanguagePacks,
    duplicateBlock,
    getDefaultFormLanguage,
    getFormBlockId,
    hydrateFormTemplate,
    hydrateLanguagePacks,
    minifyLanguagePacks,
    newPage,
} from "../../utils/formUtils";
import { ApiContext } from "../../contexts/ApiContext";
import { workspaceSelector } from "../../state/workspaces/selectors";
import { memberSelector } from "../../state/members/selectors";
import Loader from "../../components/Loader";
import { s8 } from "../../utils/idGenerator";
import cloneDeep from "lodash/cloneDeep";
import ElevationScroll from "../../components/ElevationScroll";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FormComponentsPanel from "./FormComponentsPanel";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import ShortTextOutlinedIcon from "@material-ui/icons/ShortTextOutlined";
import FormEditorPanel from "./FormEditorPanel";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import SubjectIcon from "@material-ui/icons/Subject";
import { newFormBlock } from "../../utils/formBlockUtils";
import FormSettingsPanel from "./FormSettingsPanel";
import FormPresentationView from "../FormPresentationView";
import { NestedDragDropContext } from "../../contexts/NestedDragDropContext";
import { newNotificationService } from "../../contexts/NotificationContext";
import FormPreviewPanel from "./FormPreviewPanel";
import Page from "../../components/Page/Page";
import WorkspacePage from "../../components/WorkspacePage/WorkspacePage";
import { scrollToElement } from "../../utils/ui";
import {addOrUpdateAccessCredentials, deleteAccessCredentials} from "../../state/accessCredentials/actions";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    content: {
        flex: "1 1 auto",
        overflow: "hidden",
        display: "flex",
    },
    editModeContainer: {
        display: "flex",
        width: "100%"
    },
    preview: {
        overflow: "auto",
        flex: "1 1 auto",
        backgroundColor: theme.palette.grey[50],
        // '&>*:not(:last-child) ': {
        //     marginBottom: theme.spacing(4)
        // }
    },
    pagePaper: {
        padding: [theme.spacing(2), 0],
    },
    divider: {
        backgroundColor: theme.palette.grey[300],
    },
    hidden: {
        display: "none"
    }
}));

// TODO: Refactor preview and edit panel containers + css
const FormEditView = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const api = useContext(ApiContext);
    const { formId } = useParams();
    const formKey = toFormKey(formId);
    const workspaceKey = useContext(WorkspaceKeyContext);
    const workspace = useSelector(workspaceSelector(workspaceKey));
    const form = useSelector(formSelector(workspaceKey, formKey));
    const [changed, setChanged] = useState(false);
    const [_, setRenderTrigger] = useState(false);
    const [showConfirmationPageOverlay, setShowConfirmationPageOverlay] = useState(false);
    const [mode, setMode] = useState("edit");
    const languageRef = useRef();
    const templateRef = useRef();
    const languagePacksRef = useRef();
    const updatedSettingsRef = useRef();
    const nestedDragDropSubscriptionsRef = useRef({});
    const nestedDragDropContextValue = useMemo(
        () => ({
            subscribe: (blockKey, handler) => {
                nestedDragDropSubscriptionsRef.current[blockKey] = handler;
            },
        }),
        []
    );
    const defaultFormSettings = { ...form, template: undefined, languagePacks: undefined };
    const editorPanelRef = useRef();
    const formAccessCodeRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!Boolean(form) || Boolean(templateRef.current)) {
            return;
        }

        languageRef.current = getDefaultFormLanguage(form.languagePacks);
        templateRef.current = cloneDeep(form.template);
        languagePacksRef.current = cloneDeep(form.languagePacks);
        updatedSettingsRef.current = {};
        setRenderTrigger((prev) => !prev);
    }, [form]);

    // if (!form) {
    //     console.log("FormEditor - form not found, redirecting back to forms")
    //     return (
    //         <Navigate to="../" />
    //     )
    // }

    const addBlockToPage = (blockType, pageIndex, destinationBlockIndex) => {
        const block = newFormBlock(blockType);
        const destinationPage = templateRef.current.pages[pageIndex];

        destinationPage.blocks.splice(destinationBlockIndex, 0, block);
    };

    const handleDuplicateBlock = useCallback((pageIndex, blockIndex) => {
        const [newTemplate, newLanguagePacks] = duplicateBlock(
            templateRef.current,
            languagePacksRef.current,
            pageIndex,
            blockIndex
        );
        templateRef.current = newTemplate;
        languagePacksRef.current = newLanguagePacks;
        setRenderTrigger((prev) => !prev);
        setChanged(true);
    }, []);

    const handleDeleteBlock = useCallback((pageIndex, blockIndex) => {
        const [newTemplate, newLanguagePacks] = deleteBlock(
            templateRef.current,
            languagePacksRef.current,
            pageIndex,
            blockIndex
        );
        templateRef.current = newTemplate;
        languagePacksRef.current = newLanguagePacks;
        setRenderTrigger((prev) => !prev);
        setChanged(true);
    }, []);

    const handleBlockTextChange = useCallback(({ key, value }) => {
        languagePacksRef.current[languageRef.current][key] = value;
        setChanged(true);
    }, []);

    const handleBlockTemplateChange = useCallback((pageIndex, blockIndex, change) => {
        templateRef.current.pages[pageIndex].blocks[blockIndex][change.key] = change.value;
        setChanged(true);
    }, []);

    const handleBlockTextDelete = useCallback((key) => {
        for (const languagePack of Object.values(languagePacksRef.current)) {
            if (languagePack[key]) {
                delete languagePack[key];
            }
        }
    }, []);

    const handlePageDelete = useCallback((index) => {
        const [newTemplate, newLanguagePacks] = deletePage(templateRef.current, languagePacksRef.current, index);
        templateRef.current = newTemplate;
        languagePacksRef.current = newLanguagePacks;

        setRenderTrigger((prev) => !prev);
        setChanged(true);
    }, []);

    const handleSaveChanges = () => {
        setChanged(false);
        const minifiedLanguagePacks = minifyLanguagePacks(languagePacksRef.current);
        api.mutation.updateForm(
            form.pk,
            form.sk,
            templateRef.current,
            minifiedLanguagePacks,
            updatedSettingsRef.current
        );

        if ('passwordProtected' in updatedSettingsRef.current && !updatedSettingsRef.current.passwordProtected) {
            console.log("deleting user credentials");
            api.mutation.deleteResourceAccessCredentials(form.pk);
            dispatch(deleteAccessCredentials(workspaceKey, formKey));
        } else if (formAccessCodeRef.current) {
            console.log("updating user credentials");
            api.mutation.updateResourceAccessCredentials(formKey, formAccessCodeRef.current);
            dispatch(addOrUpdateAccessCredentials(workspaceKey, formKey, formAccessCodeRef.current));
        }
    };

    const handleDragStart = ({ draggableId, source }) => {
        if (source.droppableId === "components") {
            //setConfirmationPageDropDisabled(draggableId !== "component-text");
            //setShowConfirmationPageOverlay(true);
        }
    };

    const handleDragEnd = (result) => {
        const { draggableId, type, destination, source } = result;
        // dropped outside the list
        if (!destination) {
            setShowConfirmationPageOverlay(false);
            return;
        }

        if (type.startsWith("options")) {
            // Nested drag&drops are not supported by the beautiful-dnd library. To work around this limitation each
            // child component that required a nested drag&drop will subscribe its event handler via the
            // NestedDragDropContext. The global drag&drop event handler will call the corresponding child handlers.
            // More info about the issue: https://github.com/atlassian/react-beautiful-dnd/issues/302
            const blockKey = source.droppableId.replace("block-", "");
            const handler = nestedDragDropSubscriptionsRef.current[blockKey];
            if (handler) {
                handler(result);
            }
        } else {
            if (source.droppableId === "components") {
                // A new component was added to a form page
                const blockType = draggableId.replace("component-", "");
                const destinationPageIndex = destination.droppableId.split("#")[1];
                addBlockToPage(blockType, destinationPageIndex, destination.index);
            } else {
                // A block was moved between the form pages
                const sourcePageIndex = source.droppableId.split("#")[1];
                const destinationPageIndex = destination.droppableId.split("#")[1];
                const sourcePage = templateRef.current.pages[sourcePageIndex];
                const destinationPage = templateRef.current.pages[destinationPageIndex];

                const [removed] = sourcePage.blocks.splice(source.index, 1);
                destinationPage.blocks.splice(destination.index, 0, removed);
            }

            setShowConfirmationPageOverlay(false);
            setRenderTrigger((prev) => !prev);
            setChanged(true);
        }
    };

    const handleLanguagesAdded = (translatedLanguagePacks) => {
        languagePacksRef.current = { ...languagePacksRef.current, ...translatedLanguagePacks };
        setChanged(true);
    };

    const handleLanguageDeleted = (value) => {
        delete languagePacksRef.current[value];
        setRenderTrigger((prev) => !prev);
        setChanged(true);
    };

    const handleLanguageChange = (value) => {
        languageRef.current = value;
        setRenderTrigger((prev) => !prev);
    };

    const handlePageAdd = (index) => {
        const newPages = [...templateRef.current.pages];
        newPages.splice(index, 0, newPage());
        templateRef.current.pages = newPages;
        setRenderTrigger((prev) => !prev);
        setChanged(true);
    };

    const handleBlockAdd = (blockType, pageIndex, blockIndex) => {
        addBlockToPage(blockType, pageIndex, blockIndex);
        setRenderTrigger((prev) => !prev);
        setChanged(true);
    }

    const handleSettingChange = (key, newValue) => {
        updatedSettingsRef.current[key] = newValue;

        // To prevent double rerender
        if (changed) {
            setRenderTrigger((prev) => !prev);
        } else {
            setChanged(true);
        }

        // setRenderTrigger((prev) => !prev);
        // setChanged(true);
    };

    const handleComponentItemClick = (blockType) => {
        const destinationPageIndex = 0;
        addBlockToPage(blockType, destinationPageIndex, 0);
        setRenderTrigger((prev) => !prev);
        setChanged(true);
        editorPanelRef.current.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleFormAccessCodeChange = (value) => {
        console.log("handleFormAccessCodeChange, value: %o", value);
        formAccessCodeRef.current = value;
        setChanged(true);
    }

    if (!Boolean(templateRef.current) || !Boolean(languagePacksRef.current)) {
        return <Loader />;
    }

    return (
        <WorkspacePage className={classes.root} disableScroll>
            <FormEditorToolbar
                workspaceKey={workspaceKey}
                workspaceName={workspace.name}
                workspacePicture={workspace.picture}
                formKey={formKey}
                formName={form.name}
                saveEnabled={changed}
                mode={mode}
                onSaveClick={handleSaveChanges}
                onModeChange={setMode}
            />
            <Divider className={classes.divider} />
            <div className={classes.content}>
                {mode === "preview" ? (
                    <FormPreviewPanel
                        template={templateRef.current}
                        languagePacks={languagePacksRef.current}
                        defaultLanguage={languageRef.current}
                        workspace={workspace}
                    />
                ) : (
                    <div className={clsx(classes.editModeContainer)}>
                        <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                            <FormComponentsPanel onItemClick={handleComponentItemClick} />
                            {/*<Divider className={classes.divider} orientation="vertical"/>*/}
                            <NestedDragDropContext.Provider value={nestedDragDropContextValue}>
                                <FormEditorPanel
                                    ref={editorPanelRef}
                                    template={templateRef.current}
                                    languagePack={languagePacksRef.current[languageRef.current]}
                                    language={languageRef.current}
                                    workspace={workspace}
                                    //showConfirmationPageOverlay={showConfirmationPageOverlay}
                                    onTextChange={handleBlockTextChange}
                                    onTextDelete={handleBlockTextDelete}
                                    onTemplateChange={handleBlockTemplateChange}
                                    onBlockDelete={handleDeleteBlock}
                                    onBlockDuplicate={handleDuplicateBlock}
                                    onPageAdd={handlePageAdd}
                                    onPageDelete={handlePageDelete}
                                    onBlockAdd={handleBlockAdd}
                                />
                            </NestedDragDropContext.Provider>
                            {/*<Divider className={classes.divider} orientation="vertical"/>*/}
                            <FormSettingsPanel
                                form={form}
                                activeLanguage={languageRef.current}
                                languagePacks={languagePacksRef.current}
                                defaultSettings={defaultFormSettings}
                                onSettingChange={handleSettingChange}
                                onLanguageChange={handleLanguageChange}
                                onLanguagesAdded={handleLanguagesAdded}
                                onLanguageDeleted={handleLanguageDeleted}
                                onFormAccessCodeChange={handleFormAccessCodeChange}
                            />
                        </DragDropContext>
                    </div>
                )}
            </div>
        </WorkspacePage>
    );
};

export default FormEditView;
