import React from "react";
import { FormControl, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { DEFAULT_FORM_SIDE_PADDING_SPACING } from "../../constants";
import TextFormBlock from "./TextFormBlock";
import TextFieldFormBlock from "./TextFieldFormBlock";
import DropdownFormBlock from "./DropdownFormBlock";
import CheckboxListFormBlock from "./CheckboxListFormBlock";
import AttachmentsFormBlock from "./AttachmentsFormBlock";
import DateFormBlock from "./DateFormBlock";
import ReportIdFormBlock from "./ReportIdFormBlock";
import ReportEmailFormBlock from "./ReportEmailFormBlock";

const useStyles = makeStyles((theme) => ({
    formBlock: {
        display: "block"
    },
}));

export const renderBlock = (
    blockTemplate,
    languagePack,
    language,
    editable,
    errorText,
    onTextChange,
    onTemplateChange,
    onTextDelete,
    onValueChange
) => {
    switch (blockTemplate.type) {
        case "text": {
            return (
                <TextFormBlock
                    blockTemplate={blockTemplate}
                    languagePack={languagePack}
                    editable={editable}
                    onTextChange={onTextChange}
                />
            );
        }
        case "textField": {
            return (
                <TextFieldFormBlock
                    blockTemplate={blockTemplate}
                    languagePack={languagePack}
                    language={language}
                    editable={editable}
                    errorText={errorText}
                    onTextChange={onTextChange}
                    onTemplateChange={onTemplateChange}
                    onValueChange={onValueChange}
                />
            );
        }
        case "multilineTextField": {
            return (
                <TextFieldFormBlock
                    multiline
                    blockTemplate={blockTemplate}
                    languagePack={languagePack}
                    language={language}
                    editable={editable}
                    errorText={errorText}
                    onTextChange={onTextChange}
                    onTemplateChange={onTemplateChange}
                    onValueChange={onValueChange}
                />
            );
        }
        case "dropdown": {
            return (
                <DropdownFormBlock
                    blockTemplate={blockTemplate}
                    languagePack={languagePack}
                    language={language}
                    editable={editable}
                    errorText={errorText}
                    onTextChange={onTextChange}
                    onTemplateChange={onTemplateChange}
                    onTextDelete={onTextDelete}
                    onValueChange={onValueChange}
                />
            );
        }
        case "checkboxList": {
            return (
                <CheckboxListFormBlock
                    blockTemplate={blockTemplate}
                    languagePack={languagePack}
                    language={language}
                    editable={editable}
                    errorText={errorText}
                    onTextChange={onTextChange}
                    onTemplateChange={onTemplateChange}
                    onTextDelete={onTextDelete}
                    onValueChange={onValueChange}
                />
            );
        }
        case "attachments": {
            return (
                <AttachmentsFormBlock
                    blockTemplate={blockTemplate}
                    languagePack={languagePack}
                    language={language}
                    editable={editable}
                    errorText={errorText}
                    onTextChange={onTextChange}
                    onTemplateChange={onTemplateChange}
                    onValueChange={onValueChange}
                />
            );
        }
        case "dateField": {
            return (
                <DateFormBlock
                    blockTemplate={blockTemplate}
                    languagePack={languagePack}
                    language={language}
                    editable={editable}
                    errorText={errorText}
                    onTextChange={onTextChange}
                    onTemplateChange={onTemplateChange}
                    onValueChange={onValueChange}
                />
            );
        }
        case "reportIdField": {
            return <ReportIdFormBlock blockTemplate={blockTemplate} language={language} editable={editable} />;
        }
        case "reportEmailField": {
            return (
                <ReportEmailFormBlock
                    language={language}
                    editable={editable}
                    errorText={errorText}
                    onValueChange={onValueChange}
                />
            );
        }
        default: {
            return <></>;
        }
    }
};

const FormBlock = ({
    className,
    blockTemplate,
    languagePack,
    language,
    editable,
    errorText,
    onTextChange,
    onTemplateChange,
    onTextDelete,
    onValueChange,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.formBlock, className)} {...rest}>
            {renderBlock(
                blockTemplate,
                languagePack,
                language,
                editable,
                errorText,
                onTextChange,
                onTemplateChange,
                onTextDelete,
                onValueChange
            )}
        </div>
    );
};

FormBlock.propTypes = {
    className: PropTypes.string,
    blockTemplate: PropTypes.object.isRequired,
    languagePack: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    errorText: PropTypes.string,
    onTextChange: PropTypes.func,
    onTemplateChange: PropTypes.func,
    onTextDelete: PropTypes.func,
    onValueChange: PropTypes.func,
};

FormBlock.defaultProps = {
    onTextChange: () => {},
    onTemplateChange: () => {},
    onTextDelete: () => {},
    onValueChange: () => {},
};

export default FormBlock;
