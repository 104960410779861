import React, {useContext, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles,
    CssBaseline,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    TablePagination,
    List,
    ListItem,
    ListItemAvatar,
    useTheme,
    ListItemText,
    Divider,
    ListItemSecondaryAction,
    ListItemIcon,
    ButtonBase,
    Dialog,
    useMediaQuery, DialogTitle, DialogContent, DialogActions, Tabs, Tab, ButtonGroup, TextField
} from '@material-ui/core';
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import {ApiContext} from "../../contexts/ApiContext";
import {changePassword} from "../../api/AuthApi";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        "&>div:not(:last-child)": {
            marginBottom: theme.spacing(3)
        },
    },
    dialogActions: {
        padding: theme.spacing(3)
    }
}));


const ChangePasswordPanel = ({className, onClose}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState(null);
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState(null);
    const [repeatedPassword, setRepeatedPassword] = useState("");
    const [repeatedPasswordError, setRepeatedPasswordError] = useState(null);
    const [saveEnabled, setSaveEnabled] = useState(false);

    const handleCurrentPasswordChange = (event) => {
        const value = event.target.value;
        setCurrentPassword(value);

        if (!value) {
            setCurrentPasswordError(t("profileSettingsDialog.currentPasswordRequiredError"));
        } else {
            setCurrentPasswordError(null);
        }
    }

    const handleNewPasswordChange = (event) => {
        const value = event.target.value;
        setNewPassword(value);
        if (!value) {
            setNewPasswordError(t("profileSettingsDialog.newPasswordRequiredError"));
        } else if (value.length < 8) {
            setNewPasswordError(t("profileSettingsDialog.newPasswordHelperText"));
        } else {
            setNewPasswordError(null);
        }
    }

    const handleRepeatPasswordChange = (event) => {
        const value = event.target.value;
        setRepeatedPassword(value);
        if (value !== newPassword) {
            setRepeatedPasswordError(t("profileSettingsDialog.confirmPasswordMatchError"));
        } else {
            setRepeatedPasswordError(null);
        }
    }

    const handleSaveChanges = async () => {
        if (repeatedPassword !== newPassword) {
            setRepeatedPasswordError(t("profileSettingsDialog.confirmPasswordMatchError"));
            return;
        }

        try {
            await changePassword(currentPassword, newPassword);
            onClose();
        } catch {
            setCurrentPasswordError(t("profileSettingsDialog.currentPasswordInvalidError"))
        }
    }

    return (
        <>
            <DialogContent id="change-password-panel" className={clsx(classes.root, className)}>
                <TextField
                    required
                    fullWidth
                    id="current-password"
                    label={t("profileSettingsDialog.currentPassword")}
                    variant="outlined"
                    type="password"
                    autoComplete="current-password"
                    value={currentPassword}
                    onChange={handleCurrentPasswordChange}
                    error={Boolean(currentPasswordError)}
                    helperText={currentPasswordError}
                />
                <TextField
                    required
                    fullWidth
                    id="new-password"
                    label={t("profileSettingsDialog.newPassword")}
                    variant="outlined"
                    type="password"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    error={Boolean(newPasswordError)}
                    helperText={newPasswordError ?? t("profileSettingsDialog.newPasswordHelperText")}
                />
                <TextField
                    required
                    fullWidth
                    id="confirm-password"
                    label={t("profileSettingsDialog.confirmPassword")}
                    variant="outlined"
                    type="password"
                    value={repeatedPassword}
                    onChange={handleRepeatPasswordChange}
                    error={Boolean(repeatedPasswordError)}
                    helperText={repeatedPasswordError}
                />
            </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button autoFocus color="primary" variant="outlined" onClick={onClose}>
                        {t("Cancel")}
                    </Button>
                    <Button autoFocus color="primary" variant="contained" onClick={handleSaveChanges}>
                        {t("Save changes")}
                    </Button>
                </DialogActions>
        </>
    )
}

ChangePasswordPanel.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func
}

ChangePasswordPanel.defaultProps = {
    onClose: () => {}
};

export default ChangePasswordPanel;