import * as types from "./actionTypes";

export const loadInvitations = (workspaceKey, invitations) => async (dispatch, getState) => {
    dispatch({ type: types.INVITATIONS_LOADED, payload: { workspaceKey: workspaceKey, invitations: invitations } });
}

export const addInvitation = (workspaceKey, invitation) => async (dispatch, getState) => {
    const exists = getState().invitations[workspaceKey]?.some(t => t.id === invitation.id);
    if (exists) {
        console.log("Invitation with ID %o already exists", invitation.id);
        return;
    }

    dispatch({ type: types.INVITATIONS_ADDED, payload: { workspaceKey: workspaceKey, invitations: [invitation] } });
}

export const deleteInvitation = (workspaceKey, invitationId) => async (dispatch, getState) => {
    dispatch({ type: types.INVITATION_DELETED, payload: { workspaceKey, invitationId } });
}

export const deleteAllInvitation = (workspaceKey) => async (dispatch, getState) => {
    dispatch({ type: types.INVITATIONS_DELETED, payload: { workspaceKey } });
}