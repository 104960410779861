import React, { useContext } from "react";
import { Box, Container, Grid, Link, makeStyles, Typography, useTheme } from "@material-ui/core";
import Page from "../../components/Page/Page";
import Card from "../../components/Card/Card";
import { useSelector } from "react-redux";
import { workspaceReports } from "../../state/reports/selectors";
import ReportTable from "./ReportTable";
import { currentUserSelector } from "../../state/currentUser/selectors";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { isFormKey, toId, toWorkspaceKey } from "../../utils/apiUtils";
import { formatWorkspaceId } from "../../utils/textFormatter";
import { elapsedDays, isClosed, isOpen, isOverdue, remainingDays } from "../../utils/reportUtils";
import { useParams } from "react-router-dom";
import DashboardCard from "../../components/DashboardCard";
import { Pie } from "@reactchartjs/react-chart.js";
import { allFormsSelector, formSelector } from "../../state/forms/selectors";
import stc from "string-to-color";
import {workspaceSelector} from "../../state/workspaces/selectors";
import WorkspacePage from "../../components/WorkspacePage/WorkspacePage";

const useStyles = makeStyles((theme) => ({
    dashboardView: {
        padding: theme.spacing(3),
    },
    largeCard: {
        height: theme.spacing(51),
    },
    emptyReportTableCard: {
        alignItems: "flex-start",
        marginTop: theme.spacing(1.5),
    },
    reportTable: {
        marginTop: theme.spacing(1),
        height: '100%',
        overflow: "auto"
    },
    reportTableCardContent: {
        alignItems: 'flex-start'
    }
}));

const getChartDataset = (reports, forms, t) => {
    if (!reports || reports.length === 0) {
        return {};
    }

    const formTags = [];
    const formNames = [];
    const backgroundColors = [];
    const tagCounts = {};
    let unclassifiedReportsCount = 0;
    for (const report of reports) {
        const formTag = Boolean(report.tags) && report.tags.find((tag) => isFormKey(tag));
        if (formTag) {
            if (!formTags.includes(formTag)) {
                formTags.push(formTag);
                const formName = forms.find((form) => form.pk === formTag)?.name;
                if (!formName) {
                    continue;
                }
                formNames.push(formName);
                backgroundColors.push(stc(formName));
            }
            tagCounts[formTag] = (tagCounts[formTag] ?? 0) + 1;
        } else {
            unclassifiedReportsCount++;
        }
    }

    return {
        labels: [t("unclassified"), ...formNames],
        datasets: [
            {
                data: [unclassifiedReportsCount, ...formTags.map((tag) => tagCounts[tag])],
                backgroundColor: ["#bdbdbd", ...backgroundColors],
            },
        ],
    };
};

// TODO: Rename
const DashboardView = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { workspaceId } = useParams();
    const workspaceKey = toWorkspaceKey(workspaceId);
    // TODO: Use Skeleton if reports undefined
    const reports = useSelector(workspaceReports(workspaceKey)) ?? [];
    const forms = useSelector(allFormsSelector(workspaceKey)) ?? [];
    const reportsDueSoon = reports.filter((report) => isOpen(report) && report.dueDate && remainingDays(report) < 10);
    const chartData = getChartDataset(reports, forms, t);

    return (
        <WorkspacePage>
            <Container maxWidth="xl" className={classes.dashboardView}>
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                        <DashboardCard label={t("openReports")}>
                            <Typography variant="h3">{reports.filter(isOpen).length}</Typography>
                        </DashboardCard>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <DashboardCard label={t("solvedReports")}>
                            <Typography variant="h3">{reports.filter(isClosed).length}</Typography>
                        </DashboardCard>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <DashboardCard label={t("overdueReports")}>
                            <Typography variant="h3">{reports.filter(isOverdue).length}</Typography>
                        </DashboardCard>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <DashboardCard
                            className={classes.largeCard}
                            classes={{content: reports.length === 0 ? classes.emptyReportTableCard : classes.reportTableCardContent}}
                            label={t("reportsNearingDeadline")}
                        >
                            {reportsDueSoon.length > 0 ? (
                                <ReportTable className={classes.reportTable} reports={reportsDueSoon} />
                            ) : (
                                <Typography variant="body2" color="secondary">
                                    {t("No reports due soon")}
                                </Typography>
                            )}
                        </DashboardCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DashboardCard className={classes.largeCard} label={t("reportTypes")}>
                            <Pie data={chartData} />
                        </DashboardCard>
                    </Grid>
                </Grid>
            </Container>
        </WorkspacePage>
    );
};

export default DashboardView;
