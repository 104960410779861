import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    alpha,
    ButtonBase, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider, IconButton, InputAdornment, ListItemIcon, ListItemText,
    makeStyles, TextField, Tooltip, Typography, useTheme
} from "@material-ui/core";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {MoreVert} from "@material-ui/icons";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from "@material-ui/core/MenuItem";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Menu from "@material-ui/core/Menu";
import {useTranslation} from "react-i18next";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import {StyledMenu, StyledMenuItem} from "../StyledMenu";

const borderRadius = 12;
const useStyles = makeStyles((theme) => ({
    tileButtonContainer: {
        position: "relative",
        borderRadius: borderRadius,
        transition: theme.transitions.create(['background-color', 'box-shadow', 'transform'/*, 'border'*/], {
            duration: theme.transitions.duration.short,
        }),
        //color: theme.palette.text.primary,
        //backgroundColor: theme.palette.grey[300],
        //...CARD_SHADOW3,
        //...CardShadow,
        //boxShadow: theme.shadows[7],
        //boxShadow: "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
        // '&:hover': {
        //     transform: "translateY(-1px)",
        //     backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
        //     // Reset on touch devices, it doesn't add specificity
        //     '@media (hover: none)': {
        //         backgroundColor: 'transparent',
        //     },
        //     '&$disabled': {
        //         backgroundColor: 'transparent',
        //     },
        //     //border: `1px solid ${theme.palette.primary.main}`,
        //     //border: `1px solid ${theme.palette.grey[500]}`,
        //     //color: theme.palette.primary.main,
        //     //boxShadow: "0 10px 15px -5px rgb(48 54 60 / 15%), 0 1px 2px 0 rgb(48 54 60 / 15%)",
        //     //border: `1px solid black`,
        //     //boxShadow: "rgb(32 40 45 / 8%) 0px 2px 14px 0px",
        //     //transform: "scale3d(1.02, 1.02, 1)",
        //     //textDecoration: 'underline',
        //     //boxShadow: theme.shadows[4],
        //     //boxShadow: "rgb(145 158 171 / 16%) 0px 16px 32px -4px",
        //     //...CardShadow2,
        //     // Reset on touch devices, it doesn't add specificity
        // },
        '&$disabled': {
            color: theme.palette.action.disabled,
        }
    },
    tileButtonContainerHover: {
        transform: "translateY(-1px)",
        backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
            backgroundColor: 'transparent',
        },
        '&$disabled': {
            backgroundColor: 'transparent',
        },
    },
    tileButton: {
        borderRadius: borderRadius,
        flexDirection: "column",
        padding: theme.spacing(2),
        // '&$focusVisible': {
        //     boxShadow: theme.shadows[6],
        // },
        // '&:active': {
        //     boxShadow: theme.shadows[8],
        // },
        // '&$disabled': {
        //     color: theme.palette.action.disabled,
        //     boxShadow: theme.shadows[0],
        //     backgroundColor: theme.palette.action.disabledBackground,
        // },
    },
    content: {
        width: theme.spacing(20),
        height: theme.spacing(15),
        borderRadius: borderRadius,
        border: `1px solid ${theme.palette.grey[300]}`,
        //backgroundColor: theme.palette.grey[100],
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    divider: {
        width: "100%",
        color: theme.palette.grey[300]
    },
    label: {
        //maxHeight: `${theme.typography.body1.lineHeight * 2}em`,
        //marginTop: theme.spacing(1),
        visibility: "hidden",
        margin: theme.spacing(1),
        textAlign: "center",
        //margin: "auto",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    moreButton: {
        position: "absolute",
        top: theme.spacing(3),
        right: theme.spacing(3),
        visibility: "hidden",
        // backgroundColor: theme.palette.common.white,
        // border: `1px solid ${theme.palette.grey[300]}`,
        // '&:hover': {
        //     backgroundColor: theme.palette.grey[300]
        // }
        // transition: theme.transitions.create(['visibility'], {
        //     duration: theme.transitions.duration.short,
        // }),
    },
    visible: {
        visibility: "visible",
    },
    menuItem: {
        paddingRight: theme.spacing(3)
    },
    menuItemIcon: {
        minWidth: theme.spacing(5),
    },
    labelTextFieldContainer: {
        position: "absolute",
        top: theme.spacing(15),
        bottom: 0,
        left: 0,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    labelTextFieldInput: {
        ...theme.typography.subtitle2
    }
}));

const TileButton = ({className, classes: propClasses, defaultLabel, children, showContextMenu, editModeActive, onRename, onClick, onDelete, ...rest}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const moreButtonRef = useRef();
    const textFieldInputRef = useRef();
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [hover, setHover] = useState(false);
    const [label, setLabel] = useState(defaultLabel);
    const [editMode, setEditMode] = useState(editModeActive);

    useEffect(() => {
        if (editModeActive) {
            focusLabelTextField();
        }
    }, [editModeActive]);

    const focusLabelTextField = () => {
        setTimeout(() => textFieldInputRef.current.focus(), 1);
    }

    const handleRenameClick = () => {
        setContextMenuOpen(false);
        setEditMode(true);
        focusLabelTextField();
    }

    const handleTextFieldKeyDown = (event) => {
        if(event.keyCode == 13){
            triggerLabelUpdate();
        }
    }

    const triggerLabelUpdate = () => {
        setEditMode(false);
        if (label !== defaultLabel) {
            onRename(label);
        }
    }

    return (
        <div
            className={clsx(classes.tileButtonContainer, {[classes.tileButtonContainerHover]: hover || editMode || contextMenuOpen})}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <ButtonBase
                component="div"
                className={clsx(classes.tileButton, className)}
                onClick={onClick}
                {...rest}
            >
                <div className={clsx(classes.content, propClasses.content)}>
                    {children}
                </div>
                <Typography
                    className={clsx(classes.label, {[classes.visible]: !editMode})}
                    variant="subtitle2"
                    color="inherit"
                    align="center"
                >
                    {label}
                </Typography>
            </ButtonBase>
            {editMode && (
                <ClickAwayListener onClickAway={triggerLabelUpdate}>
                    <div className={classes.labelTextFieldContainer}>
                        <TextField
                            required
                            fullWidth
                            hiddenLabel
                            inputRef={textFieldInputRef}
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                            onFocus={(e) => e.target.select()}
                            onKeyDown={handleTextFieldKeyDown}
                            inputProps={{min: 1, style: { textAlign: 'center', height: "auto" }}}
                            InputProps={{
                                disableUnderline: true,
                                classes: {
                                    root: classes.labelTextFieldInput
                                }
                            }}
                        />
                    </div>
                </ClickAwayListener>
            )}
            <Tooltip title={t("more")}>
                <IconButton
                    className={clsx(classes.moreButton, {[classes.visible]: showContextMenu && hover || contextMenuOpen})}
                    ref={moreButtonRef}
                    size="small"
                    onClick={() => setContextMenuOpen(true)}
                >
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>
            <StyledMenu
                keepMounted
                anchorEl={moreButtonRef.current}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                transformOrigin={{vertical: "top", horizontal: "right" }}
                open={contextMenuOpen}
                onClose={() => setContextMenuOpen(false)}
            >
                <StyledMenuItem label={t("rename")} Icon={EditOutlinedIcon} onClick={handleRenameClick}/>
                <StyledMenuItem label={t("delete")} Icon={DeleteOutlineOutlinedIcon} onClick={onDelete}/>
            </StyledMenu>
        </div>
    );
};

TileButton.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    defaultLabel: PropTypes.string,
    showContextMenu: PropTypes.bool,
    editModeActive: PropTypes.bool,
    onClick: PropTypes.func,
    onRename: PropTypes.func,
    onDelete: PropTypes.func
};

TileButton.defaultProps = {
    classes: {},
    showContextMenu: true,
    onClick: () => {},
    onRename: () => {},
    onDelete: () => {}
};

export default TileButton;