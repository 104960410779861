import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    Chip, Hidden, ListItemIcon, ListItemText,
    makeStyles, useMediaQuery,
    useTheme
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SaveAltOutlinedIcon from "@material-ui/icons/SaveAltOutlined";
import ExportToPdfDialog from "../../components/ReportPDF/ExportToPdfDialog";
import NotInterestedOutlinedIcon from "@material-ui/icons/NotInterestedOutlined";
import {isOpen, isUnfounded} from "../../utils/reportUtils";
import {useSelector} from "react-redux";
import {currentUserRoleSelector} from "../../state/members/selectors";
import {isApprover} from "../../utils/user";

const useStyles = makeStyles((theme) => ({
    menuItemIcon: {
        //marginLeft: -theme.spacing(0.5),
        marginLeft: -theme.spacing(0.25),
        //marginRight: theme.spacing(1),
        //minWidth: 0
        minWidth: theme.spacing(5)
    },
}));

const ToolbarContextMenu = ({report, anchorEl, open, onClose}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [exportPdfDialogOpen, setExportPdfDialogOpen] = useState(false);

    return (
        <>
            <Menu
                id="report-toolbar-more-menu"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                getContentAnchorEl={null}
                keepMounted
                transformOrigin={{vertical: "top", horizontal: "center" }}
                onClose={onClose}
            >
                <MenuItem onClick={() => setExportPdfDialogOpen(true)}>
                    <ListItemIcon className={classes.menuItemIcon}>
                        <SaveAltOutlinedIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Export to PDF")} />
                </MenuItem>
            </Menu>
            <ExportToPdfDialog
                report={report}
                open={exportPdfDialogOpen}
                onClose={() => setExportPdfDialogOpen(false)}
                onCompleted={() => setExportPdfDialogOpen(false)}
            />
        </>
    )
};

ToolbarContextMenu.propTypes = {
    anchorEl: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func
}

ToolbarContextMenu.defaultProps = {
    onClose: () => {}
};


export default ToolbarContextMenu;