import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    CardHeader,
    Grid,
    TextField,
    CardActionArea,
    Container,
    Paper,
    FormControlLabel,
    Checkbox,
    alpha,
    InputAdornment,
    Tooltip,
    FormControl,
    Input,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import BorderlessTextField from "../BorderlessTextField";
import { moveCursorToEnd } from "../../utils/ui";
import { CardShadow } from "../../constants";

const bottomLineColor = "rgba(0, 0, 0, 0.42)";

const useStyles = makeStyles((theme) => ({
    editableTextField: (props) => ({
        position: "relative",
        cursor: "text",
        minWidth: 0,
        overflow: "hidden",
        display: "inline-flex",
    }),
    underline: {
        "&:after": {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
            left: 0,
            bottom: 0,
            // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
            content: '""',
            position: "absolute",
            right: 0,
            transform: "scaleX(0)",
            transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shorter,
                easing: theme.transitions.easing.easeOut,
            }),
            pointerEvents: "none", // Transparent to the hover style.
        },
        "&$focused:after": {
            transform: "scaleX(1)",
        },
        "&:before": {
            borderBottom: `1px solid ${bottomLineColor}`,
            left: 0,
            bottom: 0,
            // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
            content: '"\\00a0"',
            position: "absolute",
            right: 0,
            transition: theme.transitions.create("border-bottom-color", {
                duration: theme.transitions.duration.shorter,
            }),
            pointerEvents: "none", // Transparent to the hover style.
        },
        "&:hover:before": {
            borderBottom: `2px solid ${theme.palette.text.primary}`,
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                borderBottom: `1px solid ${bottomLineColor}`,
            },
        },
    },
    focused: {},
    fullWidth: {
        display: "block",
        width: "100%",
    },
    centerAligned: {
        paddingRight: theme.spacing(3.5),
        paddingLeft: theme.spacing(3.5),
    },
    textField: (props) => ({
        maxWidth: "100%",
        "& .MuiInputBase-root": {
            color: "inherit",
            paddingLeft: "4px", //6px
            paddingRight: "4px",
            //backgroundColor: theme.palette.background.paper,
            //backgroundColor: theme.palette.grey[50]
        },
        "& input": {
            //backgroundColor: theme.palette.background.paper,
            // borderTopLeftRadius: theme.shape.borderRadius,
            // borderTopRightRadius: theme.shape.borderRadius,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            textAlign: props.alignment === "center" ? "center" : "inherit",
            // paddingTop: props.size === "small" ? "6px" : "12px",
            // paddingBottom: props.size === "small" ? "7px" : "12px",
            ...theme.typography[props.textVariant],
        },
        "&::after": {
            content: "attr(data-value)",
            whiteSpace: "pre-wrap",
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
            //paddingRight: theme.spacing(5), // to accommodate end adornment
            visibility: "hidden",
            ...theme.typography[props.textVariant],
            lineHeight: 0, // to collapse vertical space taken by :after, must be placed after theme.typography.h5 to override system lineHeight
        },
    }),
    editIcon: {
        color: theme.palette.text.hint,
        position: "absolute",
        top: "50%",
        left: (props) => props.iconPosX,
        transform: "translate(0, -50%)",
        marginLeft: (props) => (props.alignment === "center" ? theme.spacing(4) : theme.spacing(1)),
        pointerEvents: "none",
    },
    hidden: {
        display: "none",
    },
}));

const FormTextField = ({
    className,
    classes: propClasses,
    templateKey,
    languagePack,
    defaultValue,
    placeholder,
    size,
    fullWidth,
    disableUnderline,
    alignment,
    textVariant,
    onTextChange,
}) => {
    const inputId = `input-${templateKey}`;
    const fieldRef = useRef(null);
    const inputRef = useRef(null);
    const [value, setValue] = useState(defaultValue);
    const [focused, setFocused] = React.useState(false);
    const [iconPosX, setIconPosX] = React.useState(0);
    const classes = useStyles({ alignment, textVariant, size, iconPosX });

    useEffect(() => {
        adjustTextFieldWidth(
            Boolean(languagePack[templateKey]) ? languagePack[templateKey] : defaultValue,
            placeholder
        );
        setIconPosition();
        setValue(languagePack[templateKey] ?? defaultValue);
    }, [languagePack, defaultValue]);

    const handleChange = (event) => {
        adjustTextFieldWidth(event.target.value, placeholder);
        //inputRef.current.size = value ? `"${Math.max(value.length, 1)}"` : `"${Math.min(value.length, 20)}"`;
        setValue(event.target.value);
        onTextChange({ key: templateKey, value: event.target.value });
    };

    const handleClick = () => {
        inputRef.current.focus();
        moveCursorToEnd(inputRef.current);
    };

    const handleTextFieldClick = (event) => {
        // Prevent parent div from responding to the event
        event.stopPropagation();
        event.preventDefault();
    };

    const handleTextFieldFocus = () => {
        setFocused(true);
    };

    const handleTextFieldBlur = () => {
        setFocused(false);
        setIconPosition();
    };

    const setIconPosition = () => {
        const inputWidth = document.getElementById(inputId).clientWidth;
        setIconPosX(inputWidth);
    };

    const adjustTextFieldWidth = (newValue, placeholder) => {
        let datasetValue = Boolean(newValue) ? newValue : placeholder;
        fieldRef.current.dataset.value = datasetValue;
    };

    return (
        <div
            className={clsx(classes.editableTextField, className, {
                [classes.underline]: !disableUnderline,
                [classes.fullWidth]: fullWidth,
                [classes.centerAligned]: alignment === "center",
                [classes.focused]: focused,
                [propClasses.focused]: focused,
            })}
            onClick={handleClick}
        >
            <TextField
                className={classes.textField}
                ref={fieldRef}
                inputRef={inputRef}
                variant="standard"
                hiddenLabel
                size={size}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                onClick={handleTextFieldClick}
                onFocus={handleTextFieldFocus}
                onBlur={handleTextFieldBlur}
                inputProps={{
                    size: "1",
                    id: inputId,
                }}
                InputProps={{
                    disableUnderline: true,
                    // endAdornment: (
                    //     <InputAdornment position="end" onClick={handleClick}>
                    //         <EditOutlinedIcon fontSize="small" className={clsx(classes.editIcon, {[classes.hidden]: focused})}/>
                    //     </InputAdornment>
                    // ),
                }}
            />
            <EditOutlinedIcon fontSize="small" className={clsx(classes.editIcon, { [classes.hidden]: focused })} />
        </div>
    );
};

FormTextField.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.shape({
        focused: PropTypes.string
    }),
    templateKey: PropTypes.string.isRequired,
    languagePack: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium"]),
    alignment: PropTypes.oneOf(["center", "left"]),
    textVariant: PropTypes.oneOf([
        "h6",
        "h5",
        "h4",
        "h3",
        "h2",
        "h1",
        "body1",
        "body2",
        "subtitle1",
        "subtitle2",
        "caption",
        "buttonSmall",
        "buttonMedium",
        "buttonLarge",
    ]),
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    onTextChange: PropTypes.func,
};

FormTextField.defaultProps = {
    classes: {},
    defaultValue: "",
    size: "medium",
    textVariant: "body2",
    alignment: "left",
    onTextChange: () => {},
};

export default FormTextField;
