import React, { useContext, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { makeStyles, Divider, Hidden, useTheme, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { WorkspaceKeyContext } from "../../contexts/WorkspaceKeyContext";
import { activeFormsSelector, allFormsSelector } from "../../state/forms/selectors";
import TileButton2 from "../../components/TileButton2";
import TileButton from "../../components/TileButton/TileButton";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import AddTileButton from "../../components/AddTileButton";
import RichTextEditor2 from "../../components/RichTextEditor2/RichTextEditor2";
import { emptyEditorState, serializeEditorState } from "../../components/RichTextEditor2/editorState";
import Button from "@material-ui/core/Button";
import { deminifyDraftEditorState, minifyDraftEditorState } from "../../utils/draftJsUtils";
import {
    getDefaultFormLanguage,
    getFormActionsVariant,
    getFormBlockId,
    hydrateLanguagePacks,
    loadFormLanguagePacks,
    loadFormTemplate,
    newForm,
} from "../../utils/formUtils";
import { ApiContext } from "../../contexts/ApiContext";
import { addForm, deleteForm, updateForm } from "../../state/forms/actions";
import { useNavigate } from "react-router";
import { toId, toUserKey } from "../../utils/apiUtils";
import { normalizeId } from "../../utils/textFormatter";
import { currentUserSelector } from "../../state/currentUser/selectors";
import FormPreviewPanel from "../FormEditView/FormPreviewPanel";
import { workspaceSelector } from "../../state/workspaces/selectors";
import FormPage from "../../components/FormPage/FormPage";
import FormPageActions from "../../components/FormPageActions";
import { memberSelector } from "../../state/members/selectors";
import AddFormDialog from "./AddFormDialog";
import Page from "../../components/Page/Page";
import WorkspacePage from "../../components/WorkspacePage/WorkspacePage";
import FormBlockContainer from "../../components/FormBlockContainer/FormBlockContainer";
import FormBlock from "../../components/formBlocks";
import DeleteFormConfirmationDialog from "./DeleteFormConfirmationDialog";

const useStyles = makeStyles((theme) => ({
    formsOverviewRoot: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        alignContent: "flex-start", // removes space between wrapped rows in flex layout
        padding: theme.spacing(8),
        "&>div:not(:last-child) ": {
            marginRight: theme.spacing(2),
        },
    },
    editor: {
        height: "100%",
        width: "100%",
        margin: "auto",
        maxWidth: "640px",
        ...theme.typography.body2,
        fontFamily: "Inter, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, Sans-Serif",
        //fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        lineHeight: 1.57143,
        "& div[data-contents]": {
            "&>h3:not(:first-child)": {
                marginTop: theme.spacing(1),
            },
            "&>h4:not(:first-child)": {
                marginTop: theme.spacing(1),
            },
            "&>h5:not(:first-child)": {
                marginTop: theme.spacing(1),
            },
        },
    },
    tileButtonContent: {
        backgroundColor: theme.palette.grey[50],
        display: "block",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    formPage: {
        transformOrigin: "0 0",
        transform: "scale(0.228)",
        width: "439%",
        pointerEvents: "none",
    },
}));

const FormsView = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const api = useContext(ApiContext);
    const workspaceKey = useContext(WorkspaceKeyContext);
    const workspace = useSelector(workspaceSelector(workspaceKey));
    const forms = useSelector(activeFormsSelector(workspaceKey)) ?? [];
    const [addFormDialogOpen, setAddFormDialogOpen] = useState(false);
    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
    const [selectedFormKey, setSelectedFormKey] = useState();

    const handleFormButtonClick = (formKey) => {
        navigate(`./${toId(formKey)}`);
    };

    const handleFormRename = (formKey, newName) => {
        api.mutation.updateResource(formKey, workspaceKey, { name: newName });
        dispatch(updateForm(workspaceKey, formKey, {name: newName}));
    };

    const handleAddFormDialogConfirm = async (form) => {
        //dispatch(addForm(workspaceKey, form));
        navigate(`./${toId(form.pk)}`);
    };

    const handleFormDeleteClick = (formKey) => {
        setSelectedFormKey(formKey);
        setDeleteConfirmationDialogOpen(true);
    }

    const handleDeleteFormDialogConfirm = async () => {
        api.mutation.updateResource(selectedFormKey, workspaceKey, { disabled: true });
        dispatch(updateForm(workspaceKey, selectedFormKey, {disabled: true}));
        setDeleteConfirmationDialogOpen(false);
    };

    return (
        <WorkspacePage className={classes.formsOverviewRoot} disableScroll>
            <AddTileButton onClick={() => setAddFormDialogOpen(true)} />
            {forms &&
                forms.map((form) => {
                    const language = getDefaultFormLanguage(form.languagePacks);
                    return (
                        <TileButton
                            key={form.pk}
                            defaultLabel={form.name}
                            classes={{ content: classes.tileButtonContent }}
                            onClick={() => handleFormButtonClick(form.pk)}
                            onRename={(newName) => handleFormRename(form.pk, newName)}
                            onDelete={() => handleFormDeleteClick(form.pk)}
                        >
                            {/*<FormPreviewPanel*/}
                            {/*    className={classes.tileButtonPreview}*/}
                            {/*    template={form.template}*/}
                            {/*    languagePacks={form.languagePacks}*/}
                            {/*    workspace={workspace}*/}
                            {/*    showToolbar={false}*/}
                            {/*/>*/}
                            <FormPage
                                className={classes.formPage}
                                workspace={workspace}
                            >
                                {form.template.pages[0].blocks.map((block) => (
                                    <FormBlockContainer key={getFormBlockId(block)}>
                                        <FormBlock
                                            blockTemplate={block}
                                            languagePack={form.languagePacks[language]}
                                            language={language}
                                        />
                                    </FormBlockContainer>
                                ))}
                                <FormPageActions
                                    template={form.template.pages[0].actions}
                                    languagePack={form.languagePacks[language]}
                                    language={language}
                                    pageIndex={0}
                                    pagesCount={form.template.pages.length}
                                />
                            </FormPage>
                        </TileButton>
                    );
                })}
            <AddFormDialog
                open={addFormDialogOpen}
                formsCount={forms.length}
                onConfirm={handleAddFormDialogConfirm}
                onClose={() => setAddFormDialogOpen(false)}
            />
            <DeleteFormConfirmationDialog
                open={deleteConfirmationDialogOpen}
                formKey={selectedFormKey}
                workspaceKey={workspaceKey}
                onConfirm={handleDeleteFormDialogConfirm}
                onClose={() => setDeleteConfirmationDialogOpen(false)}
            />

        </WorkspacePage>
    );
};

export default FormsView;
