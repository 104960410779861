import {applyMiddleware, createStore} from "redux";
import thunkMiddleware from 'redux-thunk'
import rootReducer from "./reducer";
import {composeWithDevTools} from "redux-devtools-extension";

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))
const configureStore = (state) => {
    return createStore(rootReducer, state, composedEnhancer)
}

export default configureStore;