import React, {lazy} from "react"
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import SignIn from "../views/SignIn";
import InvitationView from "../views/InvitationView/InvitationView";
import SubmitView from "../views/dialogs/SubmitView";
import CompanyLoginView from "../views/dialogs/CompanyLoginView";
import FollowupView from "../views/dialogs/FollowupView";
import NotFoundView from "../views/errors/NotFoundView";
import DashboardView from "../views/DashboardView";
import DocumentsView from "../views/DocumentsView";
import PortalLayout from "../layouts/PortalLayout";
import {Onboarding} from "../views/Onboarding/Onboarding";
import PortalSettings from "../views/PortalSettings/PortalSettings";
import AuthorizedRoutes from "./AuthorizedRoutes";
import GuestRoutes from "./GuestRoutes";
import PrivateRoute from "./PrivateRoute";
import PrivateWorkspaceRoute from "./PrivateWorkspaceRoute";
import AttachmentView from "../views/AttachmentView/AttachmentView";
import HomeView from "../views/HomeView/HomeView";
import SubmitLayout from "../layouts/SubmitLayout";
import LandingPageView from "../views/LandingPageView/LandingPageView";
import PhoneSubmitView from "../views/PhoneSubmitView/PhoneSubmitView";
import EmailSubmitView from "../views/EmailSubmitView/EmailSubmitView";
import FormSubmitView from "../views/FormSubmitView/FormSubmitView";
import FormPresentationView from "../views/FormPresentationView";
import ReportsView from "../views/ReportsView/ReportsView";
import FormEditView from "../views/FormEditView/FormEditView";
import FormsView from "../views/FormsView/FormsView";

// TODO: Fix warning
// TODO: Rename
// TODO: Paths to constants
// TODO: Use lazy loading for components
// TODO: Use Suspense for data fetching
// TODO: Move portal redirection to first team to routes
export default function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <AuthorizedRoutes>
                    <PrivateRoute path="/" element={<Navigate to="/portal/home" />}/>
                    <PrivateRoute path="portal/" element={<PortalLayout />}>
                        <Route path="home" element={<HomeView/>} />
                        <Route path="" element={<Navigate to="./home" />} />
                        <PrivateWorkspaceRoute path=":workspaceId">
                            <Route path="overview" element={<DashboardView />} />
                            <Route path="reports" element={<ReportsView />} />
                            <Route path="reports/:reportId" element={<ReportsView />} />
                            <Route path="documents" element={<DocumentsView />} />
                            <Route path="settings" element={<PortalSettings />} />
                            <Route path="forms" element={<FormsView />} />
                            <Route path="forms/:formId" element={<FormEditView />} />
                            <Route path="/" element={<Navigate to="./reports" />} />
                        </PrivateWorkspaceRoute>
                    </PrivateRoute>
                    <Route path="portal/" element={<MainLayout />}>
                        <Route path="signin" element={<SignIn/>} />
                        <PrivateRoute path="onboarding" element={<Onboarding/>} />
                        <PrivateRoute path="invitation/:invitationId" element={<InvitationView />} />
                    </Route>
                </AuthorizedRoutes>
                <GuestRoutes>
                    <Route path="f" element={<Navigate to="/submit" />}/>
                    <Route path="f/:formId" element={<FormPresentationView />}/>
                    <Route path="test/:workspaceId" element={<SubmitLayout />}>
                        <Route path="" element={<LandingPageView />} />
                    </Route>
                    <Route path="/" element={<MainLayout />}>
                        <Route path="submit" element={<CompanyLoginView />} />
                        <Route path="submit/:workspaceId" element={<SubmitView />} />
                        <Route path="followup" element={<FollowupView />} />
                        <Route path="404" element={<NotFoundView />} />
                        <Route path="attachment/*" element={<AttachmentView />} />
                        {/*<Route path="rich" element={<RteContainer />} />*/}
                        {/*<Route path='*' element={<NotFoundView />} />*/}
                    </Route>
                    <Route path=":workspaceId" element={<SubmitLayout />}>
                        <Route path="" element={<LandingPageView />} />
                        <Route path="phone" element={<PhoneSubmitView />} />
                        <Route path="email" element={<EmailSubmitView />} />
                        <Route path="form" element={<FormSubmitView />} />
                    </Route>
                </GuestRoutes>
            </Routes>
        </BrowserRouter>
    )
}