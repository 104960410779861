import React from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, Typography,
    useTheme
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    statusIndicator: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    icon: {
        width: theme.spacing(1),
        height: theme.spacing(1),
        color: props => props.color,
    },
    label: {
        color: theme.palette.text.hint,
        lineHeight: "135%",
        marginLeft: theme.spacing(0.5)
    },
}));

const getIndicatorColor = (status, theme) => {
    switch (status) {
        case 'solved': {
            return '#4CAF50';
        }
        case 'junk': {
            return '#9E9E9E';
        }
        default:
            return theme.palette.primary.main;
    }
}

const StatusIndicator = ({className, classes: propClasses, report, TypographyProps}) => {
    const theme = useTheme();
    const classes = useStyles({color: getIndicatorColor(report.state, theme)});
    const { t } = useTranslation();

    // TODO: Move svg into Typography
    return (
        <div className={clsx(classes.statusIndicator, className)}>
            <svg xmlns="http://www.w3.org/2000/svg" className={classes.icon}>
                <circle cx="50%" cy="50%" r="50%" fill="currentColor" />
            </svg>
            <Typography className={clsx(classes.label, propClasses.label)} variant="caption" {...TypographyProps}>
                {t(report.state)}
            </Typography>
        </div>

    )
};

StatusIndicator.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    TypographyProps: PropTypes.object,
    report: PropTypes.shape({
        state: PropTypes.oneOf(["open", "solved", "junk"])
    })
}

StatusIndicator.defaultProps = {
    classes: {},
    TypographyProps: {}
};


export default StatusIndicator;