import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    CardHeader,
    Grid,
    TextField,
    CardActionArea,
    Container,
    Paper,
    FormControlLabel,
    Checkbox,
    alpha,
    FormControl,
} from "@material-ui/core";
import BlockHeader from "./BlockHeader";
import FormBlock from "./index";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    formTextField: {
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            // borderColor: alpha(theme.palette.text.primary, 0.12)
        },
    },
    multilineInput: {
        height: "14.275rem",
        //height: "100%",
        alignItems: "initial",
    },
    helperText: {
        position: "absolute",
        top: "100%",
    },
}));

// TODO: Rename to FormTextFieldBlock
const TextFieldFormBlock = ({
    blockTemplate,
    languagePack,
    editable,
    multiline,
    errorText,
    onTextChange,
    onTemplateChange,
    onValueChange,
}) => {
    const classes = useStyles();

    const handleValueChange = (event) => {
        onValueChange(event.target.value);
    };

    return (
        <FormControl fullWidth className={classes.formTextField} required={blockTemplate.required} error={Boolean(errorText)}>
            <BlockHeader
                editable={editable}
                blockTemplate={blockTemplate}
                languagePack={languagePack}
                onTemplateChange={onTemplateChange}
                onTextChange={onTextChange}
            />
            <TextField
                fullWidth
                disabled={editable}
                multiline={multiline}
                variant="outlined"
                maxRows={multiline ? 10 : 1}
                name="answer"
                hiddenLabel
                defaultValue={blockTemplate.value ?? ""}
                onChange={handleValueChange}
                error={Boolean(errorText)}
                helperText={Boolean(errorText) ? errorText : null}
                FormHelperTextProps={{ classes: { root: classes.helperText } }}
                InputProps={{
                    classes: {
                        root: multiline ? classes.multilineInput : undefined,
                    },
                }}
            />
        </FormControl>
    );
};

TextFieldFormBlock.propTypes = {
    blockTemplate: PropTypes.object.isRequired,
    languagePack: PropTypes.object.isRequired,
    multiline: PropTypes.bool,
    editable: PropTypes.bool,
    errorText: PropTypes.string,
    onTextChange: PropTypes.func,
    onTemplateChange: PropTypes.func,
    onValueChange: PropTypes.func,
};

TextFieldFormBlock.defaultProps = {
    onTextChange: () => {},
    onTemplateChange: () => {},
    onValueChange: () => {},
};

export default TextFieldFormBlock;
