import React, {useContext, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles,
    CssBaseline,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    TablePagination,
    List,
    ListItem,
    ListItemAvatar,
    useTheme,
    ListItemText,
    Divider,
    ListItemSecondaryAction,
    ListItemIcon,
    ButtonBase,
    Dialog,
    useMediaQuery, DialogTitle, DialogContent, DialogActions, Tabs, Tab, Button, Tooltip, DialogContentText
} from '@material-ui/core';
import {useTranslation} from "react-i18next";

// TODO: Extract common dialog styles to dialog component
const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingBottom: 0
    },
    dialogActions: {
        padding: theme.spacing(3)
    }
}));

// TODO: Add Exit icon to the top of the dialog
const RoleConfirmationDialog = ({className, open, onConfirm, onClose, title, text}) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    
    return (
        <Dialog
            id="role-confirmation-dialog"
            className={className}
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
            aria-labelledby="role-confirmation-dialog-title"
        >
            <DialogTitle id="role-confirmation-dialog-title" className={classes.dialogTitle}>
                {title}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <DialogContentText variant="body2" color="textSecondary">
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button autoFocus color="primary" variant="outlined" onClick={onClose}>
                    {t("Cancel")}
                </Button>
                <Button autoFocus color="primary" variant="contained" onClick={onConfirm}>
                    {t("confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

RoleConfirmationDialog.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

RoleConfirmationDialog.defaultProps = {
    onConfirm: () => {},
    onClose: () => {}
};

export default RoleConfirmationDialog;