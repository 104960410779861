import React, {useEffect, useState} from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    makeStyles, MenuItem,
    Select,
    Step,
    StepLabel,
    Stepper,
    TextField, Tooltip,
    Typography
} from "@material-ui/core"
import PropTypes from "prop-types";
import GeneralProfileSettings from "../ProfileButton/GeneralProfileSettings";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import IconButton from "@material-ui/core/IconButton";
import UserRoleSelect from "../UserRoleSelect";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    invitationContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        '&>div:not(:last-child) ': {
            marginRight: theme.spacing(2)
        }
    },
    inviteButton: {
        color: theme.palette.text.secondary,
    },
    emailField: {
        flex: "1 1 auto"
    },
    select: {
        flex: "0 0 auto",
        minWidth: theme.spacing(13)
    }
}));


// TODO: Rename
// TODO: Don't return invitations with ID
const WorkspaceInvitationPanel = ({className, invitations, onInvitationsChanged}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleAddInvitation = () => {
        if (invitations.length > 0 && invitations[invitations.length - 1].email === "") {
            document.getElementById(`${invitations[invitations.length - 1].id}-email-field`).focus();
            return;
        }
        const updated = [...invitations, {id: invitations.length, email: "", role: "solver"}];
        onInvitationsChanged(updated);
    }

    const handleRemoveInvitation = (index) => {
        const updated = [...invitations];
        updated.splice(index, 1);
        onInvitationsChanged(updated);
    }

    const handleUpdateInvitationEmail = (index, event) => {
        const email = event.target.value;
        const updated = [...invitations];
        updated[index].email = email;
        onInvitationsChanged(updated);
    };

    const handleUpdateInvitationRole = (index, role) => {
        const updated = [...invitations];
        updated[index].role = role;
        onInvitationsChanged(updated);
    };

    return (
        <Box mt={3} width="100%" className={className}>
            { invitations.map((invitation, index) => (
                <div key={invitation.id} className={classes.invitationContainer}>
                    <TextField
                        className={classes.emailField}
                        required
                        id={`${invitation.id}-email-field`}
                        label={t("common.email")}
                        variant="outlined"
                        value={invitation.email}
                        onChange={(event) => handleUpdateInvitationEmail(index, event)}
                    />
                    <UserRoleSelect
                        className={classes.select}
                        value={invitation.role}
                        variant="outlined"
                        onChange={(role) => handleUpdateInvitationRole(index, role)}
                    />
                    <Tooltip title={t("Remove invitation")}>
                        <IconButton size="medium" edge="end" onClick={() => handleRemoveInvitation(index)}>
                            <ClearOutlinedIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
            ))}
            <Button className={classes.inviteButton} size="small" startIcon={<AddIcon/>} onClick={handleAddInvitation}>
                {t("Add new member")}
            </Button>
        </Box>
    )
}

WorkspaceInvitationPanel.propTypes = {
    className: PropTypes.string,
    invitations: PropTypes.arrayOf(PropTypes.shape({
        email: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired
    })).isRequired,
    onInvitationsChanged: PropTypes.func
}

WorkspaceInvitationPanel.defaultProps = {
    onInvitationsChanged: () => {}
};

export default WorkspaceInvitationPanel;