import React, {useContext, useEffect, useState} from 'react';
import {
    alpha, AppBar,
    Avatar,
    Box, Button, ButtonBase, CircularProgress,
    Container, Divider, Drawer,
    Grid, Link,
    makeStyles, Typography,
    useTheme
} from '@material-ui/core';
import {useNavigate, useParams} from "react-router-dom";
import {ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {WorkspaceKeyContext} from "../../contexts/WorkspaceKeyContext";
import {workspaceSelector} from "../../state/workspaces/selectors";
import {ActiveLanguageContext} from "../../contexts/ActiveLanguageContext";
import Page from "../../components/Page/Page";
import {useTranslation} from "react-i18next";
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindowsOutlined';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import {
    BUTTON_BORDER_RADIUS,
    CardShadow,
    CardShadow2,
    PhoneNumbers,
    WHISPERO_PRIVACY_POLICY_URL
} from "../../constants";
import {formatPhoneNumber, formatWorkspaceId} from "../../utils/textFormatter";
import {toId} from "../../utils/apiUtils";
import clsx from "clsx";
import UnderlinedLink from "../../components/UnderlinedLink";

const useStyles = makeStyles((theme) => ({
    phoneSubmitViewRoot: {
    },
    body: {
        maxWidth: theme.spacing(63),
        height: '100%',
        margin: "auto",
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        //alignItems: "center",
        justifyContent: "center",
        padding: [theme.spacing(2), 0],
    },
    topMargin: {
        marginTop: theme.spacing(4)
    },
    button: {
        borderRadius: BUTTON_BORDER_RADIUS,
        marginBottom: theme.spacing(2),
        '&:hover': {
            color: theme.palette.primary.contrastText
        }
    },
    legal: {
        color: theme.palette.text.hint
    }
}));

const PhoneSubmitView = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const language = useContext(ActiveLanguageContext);
    const workspaceKey = useContext(WorkspaceKeyContext);
    const workspace = useSelector(workspaceSelector(workspaceKey));
    const phoneNumber = formatPhoneNumber(workspace.phone ?? PhoneNumbers.cs)

    return (
        <Page
            title={workspace.name}
            className={classes.phoneSubmitViewRoot}
            disableScroll
        >
            <div className={classes.body}>
                <Typography color="textPrimary" variant="h3" paragraph>
                    {t("submitReportByPhoneHeader", {lng: language})}
                </Typography>
                <Typography color="textSecondary">
                    {t("submitReportByPhoneText", {lng: language, phone: phoneNumber})}
                </Typography>
                <Typography className={classes.topMargin} variant="h5" color="textSecondary" align="center">
                    {`${t("companyId", {lng: language})}: `}
                    <Typography variant="h5" color="textPrimary" component="span">
                        {formatWorkspaceId(toId(workspaceKey))}
                    </Typography>
                </Typography>
                <Button
                    className={clsx(classes.button, classes.topMargin)}
                    component={Link}
                    href={`callto:${phoneNumber}`}
                    underline="none"
                    size="large"
                    color="primary"
                    variant="contained"
                    fullWidth
                >
                    {t("makeCall", {lng: language})}
                </Button>
                <Typography className={classes.legal} variant="caption" color="textSecondary" align="center">
                    {`${t("bySubmittingReportYouAgreeWith", {lng: language})} `}
                    <UnderlinedLink href={WHISPERO_PRIVACY_POLICY_URL}>
                        {t("withPrivacyPolicy", {lng: language})}
                    </UnderlinedLink>
                </Typography>
            </div>
        </Page>
    );
};

export default PhoneSubmitView;