import {AtomicBlockUtils, convertToRaw, EditorState, Modifier} from "draft-js";
import {addText} from "./modifierUtils";
import AvatarMenuItem from "../AvatarMenuItem";
import {STATIC_DOCUMENTS} from "../../constants/documents";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import React from "react";

export const insertAutocompleteSuggestionAsAtomicBlock = (editorState, selection, blockName, data) => {
    // Remove selected text
    const removedSelectionContentState = Modifier.removeRange(editorState.getCurrentContent(), selection,"forward");
    const removedSelectionState = EditorState.push(editorState, removedSelectionContentState, "remove-range");
    // Create entity
    // TODO: May need to call getCurrentContent() to get content state from the editor state returned from above method instead of reusing removedSelectionContentState
    const createdEntityContentState = removedSelectionContentState.createEntity(blockName.toUpperCase(), 'IMMUTABLE', data);
    const entityKey = createdEntityContentState.getLastCreatedEntityKey();
    const createdEntityEditorStateRaw = EditorState.set(editorState, {
        currentContent: createdEntityContentState,
        selection: removedSelectionState.getCurrentContent().getSelectionAfter()
    });
    // Insert atomic block
    const withAtomicBlockEditorState = AtomicBlockUtils.insertAtomicBlock(createdEntityEditorStateRaw, entityKey, ' ');
    return withAtomicBlockEditorState;
   // return insertSpaceAfter ? insertSpace(withAtomicBlockEditorState) : withAtomicBlockEditorState;
}

export const insertAutocompleteSuggestionAsText = (editorState, selection, value, data, insertSpaceAfter = true) => {
    const currentContentState = editorState.getCurrentContent();
    const entityKey = currentContentState.createEntity("MENTION", 'IMMUTABLE', data).getLastCreatedEntityKey();
    const contentState = Modifier.replaceText(
        currentContentState,
        selection,
        value,
        editorState.getCurrentInlineStyle(),
        entityKey);
    const newEditorState = EditorState.push(editorState, contentState, "insert-characters");
    return insertSpaceAfter ? addText(newEditorState, " ") : newEditorState;
}

export const filterAutocompleteItems = (allItems, searchTerm) => {
    if (!searchTerm) {
        return allItems;
    }
    const lowercaseSearchTerm = searchTerm.toLowerCase();
    return allItems.filter(item => item.key.toLowerCase().includes(lowercaseSearchTerm));
}

export const getAtMentionStrategy = (members, t) => {
    let items = members.map(user => ({
        key: user.name,
        //value: {name: user.name},
        value: user.name,
        data: {url: "#", key: user.pk},
        group: t("Users"),
        content: <AvatarMenuItem title={user.name ?? t("Anonymous")} subtitle={user.role ? t(user.role) : undefined} imageKey={user.picture}/>
    })).concat(STATIC_DOCUMENTS.map(document => ({
        key: document.name,
        value: document.name,
        data: {url: document.url},
        group: t("documents"),
        content: <AvatarMenuItem title={document.name} icon={<DescriptionOutlinedIcon />}/>
    })));

    return {
        items: items,
        triggerChar: "@",
        insertSpaceAfter: true
    }
}