import React, {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    makeStyles,
    TextField, Typography,
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import RichTextEditor2 from "../RichTextEditor2/RichTextEditor2";
import {ToolbarFiller} from "../RichTextEditor2/ToolbarFiller";
import {deserializeEditorState, emptyEditorState, serializeEditorState} from "../RichTextEditor2/editorState";
import {useParams} from "react-router";
import {toWorkspaceKey} from "../../utils/apiUtils";

const useStyles = makeStyles((theme) => ({
    settingsField: {
        //display: "flex"
    },
    label: {
        //flex: "0 0 auto",
        //marginBottom: theme.spacing(0.5)
    },
    field: {
        //display: "block",
        marginTop: theme.spacing(0.5),
        '& fieldset': {
            borderRadius: "6px"
        }
    },
    editorToolbarVisible: {
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    editorToolbarHidden: {
        opacity: 0,
    }
}));

// TODO: Delete
// TODO: Stringify value in mutationApi class
const SettingsField = ({className, value, label, placeholder, canBeEmpty, richMode, readOnly, TextFieldProps, LabelProps, onChange, onSave}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {workspaceId} = useParams();
    const workspaceKey = toWorkspaceKey(workspaceId);
    const [pendingText, setPendingText] = useState("");
    const [pendingEditorState, setPendingEditorState] = useState(() => emptyEditorState());
    const [focused, setFocused] = useState();

    useEffect(() => {
        if (richMode) {
            setPendingEditorState(deserializeEditorState(value));
        } else {
            setPendingText(value ?? "");
        }
    }, [value]);

    const handleRichEditorChange = (editorState) => {
        setPendingEditorState(editorState);
        if (onChange) {
            onChange(serializeEditorState(editorState));
        }
    }

    const handleTextFieldChange = (event) => {
        const value = event.target.value;
        setPendingText(value);
        if (onChange && (canBeEmpty || value !== "")) {
            onChange(value);
        }
    }

    const handleRichEditorBlur = () => {
        setFocused(false);
        onSave(serializeEditorState(pendingEditorState));
    }

    const handleTextFieldBlur = () => {
        onSave(pendingText);
    }

    return (
        <div className={clsx(classes.settingsField, className)}>
            <Typography className={classes.label} variant="subtitle2" color="textSecondary" {...LabelProps}>
                {label}
            </Typography>
            {richMode ? (
                <RichTextEditor2
                    variant={readOnly ? "readOnly" : "outlined"}
                    padding='dense'
                    highlightOnFocus
                    highlightOnHover
                    editorState={pendingEditorState}
                    placeholder={placeholder}
                    options={["bold", "italic", "underline", "divider", "unordered-list-item", "ordered-list-item"]}
                    classes={{
                        toolbar: clsx(classes.editorToolbarVisible, {[classes.editorToolbarHidden]: !focused})
                    }}
                    onChange={handleRichEditorChange}
                    onFocus={() => setFocused(true)}
                    onBlur={handleRichEditorBlur}
                />
            ) : (
                readOnly ? (
                    <Typography variant="body2" color="textPrimary">
                        {value}
                    </Typography>
                ) : (
                    <TextField
                        className={classes.field}
                        hiddenLabel
                        fullWidth
                        multiline
                        variant="outlined"
                        margin="dense"
                        value={pendingText}
                        placeholder={placeholder}
                        error={!canBeEmpty && pendingText ===""}
                        helperText={!canBeEmpty && pendingText ==="" ? t("{name} is required", {name: label}) : null}
                        onChange={handleTextFieldChange}
                        onBlur={handleTextFieldBlur}
                        {...TextFieldProps}
                    />
                )
            )}
        </div>
    );
}

SettingsField.propTypes = {
    value: PropTypes.any,
    label: PropTypes.string,
    className: PropTypes.string,
    canBeEmpty: PropTypes.bool,
    TextFieldProps: PropTypes.object,
    LabelProps: PropTypes.object,
    richMode: PropTypes.bool,
    onChange: PropTypes.func,
    onSave: PropTypes.func
}

SettingsField.defaultProps = {
    value: "",
    LabelProps: {},
    canBeEmpty: true,
    TextFieldProps: {},
    onSave: () => {}
};

export default SettingsField;