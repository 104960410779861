import gql from "graphql-tag";
import * as queries from "../graphql/queries";
import * as customQueries from "../graphql/customQueries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import { AUTH_TYPE } from "aws-appsync";
import { parseResourceFields, toWorkspaceKey } from "../utils/apiUtils";

// TODO: USE scoped down api types in schema querys, not the whole resource object
// TODO: Convert UpdatedAt and CreatedAt to Date, currently everywhere at each render a new Date is created for these values
// TODO: use network-only??
export default class QueryApi {
    constructor(apiClient, guestMode = false) {
        this.client = apiClient;
        this.guestMode = guestMode;
    }

    async translateLanguagePacks(languagePack, sourceLanguage, targetLanguages) {
        const query = await this.client.query({
            query: gql(queries.translateLanguagePack),
            variables: {
                languagePack: JSON.stringify(languagePack),
                sourceLanguage: sourceLanguage,
                targetLanguages: targetLanguages,
            },
            fetchPolicy: "network-only",
        });

        return JSON.parse(query.data?.translateLanguagePack ?? "{}");
    }

    // TODO: Remove, use getResourceKey
    async getAccessKey(accessId) {
        const query = await this.client.query({
            query: gql(queries.getResourceAccessKey),
            variables: { id: accessId },
            fetchPolicy: "network-only",
        });

        return query.data.getResourceAccessKey;
    }

    async listCurrentUserResources() {
        let resources = [];
        let nextToken;

        do {
            const query = await this.client.query({
                query: gql(queries.listCurrentUserResources),
                variables: { nextToken },
                fetchPolicy: "network-only",
            });

            const responseData = query.data.listCurrentUserResources;
            nextToken = responseData?.nextToken;
            const responseResources = responseData?.items.map((item) =>
                parseResourceFields(item)
            );
            resources = resources.concat(responseResources);
        } while (nextToken);

        return resources;
    }

    async listWorkspaceResources(workspaceKey) {
        let resources = [];
        let nextToken;

        do {
            const query = await this.client.query({
                query: gql(queries.resourcesByWorkspace),
                variables: { workspace: workspaceKey, nextToken },
                fetchPolicy: "network-only",
            });

            const responseData = query.data.resourcesByWorkspace;
            nextToken = responseData?.nextToken;
            const responseResources = responseData?.items.map((item) => parseResourceFields(item));
            resources = resources.concat(responseResources);
        } while (nextToken);

        return resources;
    }

    async listResources(variables) {
        let resources = [];
        let nextToken;

        do {
            const query = await this.client.query({
                query: gql(queries.listResources),
                variables: { ...variables, nextToken },
                fetchPolicy: "network-only",
            });

            const responseData = query.data.listResources;
            nextToken = responseData?.nextToken;
            const responseResources = responseData?.items.map((item) => parseResourceFields(item));
            resources = resources.concat(responseResources);
        } while (nextToken);

        return resources;
    }

    // TODO: Refactor parameter, use pk and sk instead of variables
    async getResource(variables) {
        const query = await this.client.query({
            query: gql(queries.getResource),
            variables: variables,
            fetchPolicy: "network-only",
        });

        return parseResourceFields(query.data.getResource);
    }

    async getWorkspace(key) {
        return this.getResource({ pk: key, sk: key });
    }

    async listReportResources(reportKey) {
        return this.listResources({ pk: reportKey });
    }

    // TODO: Use cache?
    async listReportMessages(reportKey) {
        let resources = [];
        let nextToken;

        do {
            const query = await this.client.query({
                query: gql(queries.listReportMessages),
                variables: { pk: reportKey, nextToken },
                fetchPolicy: "network-only",
            });

            const responseData = query.data.listReportMessages;
            nextToken = responseData?.nextToken;
            const responseResources = responseData?.items.map((item) => parseResourceFields(item));
            resources = resources.concat(responseResources);
        } while (nextToken);

        return resources;
    }

    async getUserProfile(key) {
        const query = await this.client.query({
            query: gql(queries.getUserProfile),
            variables: { pk: key },
            fetchPolicy: "network-only",
        });

        return parseResourceFields(query.data.getUserProfile);
    }

    async listWorkspaceInvitations(workspaceKey) {
        let resources = [];
        let nextToken;

        do {
            const query = await this.client.query({
                query: gql(queries.invitationsByWorkspace),
                variables: { workspace: workspaceKey, nextToken },
                fetchPolicy: "network-only",
            });

            const responseData = query.data.invitationsByWorkspace;
            nextToken = responseData?.nextToken;
            const responseResources = responseData?.items;
            resources = resources.concat(responseResources);
        } while (nextToken);

        return resources;
    }

    async getInvitation(invitationId) {
        const query = await this.client.query({
            query: gql(queries.getInvitation),
            variables: { id: invitationId },
            //fetchPolicy: 'network-only'
        });

        return query.data.getInvitation;
    }

    async deleteInvitation(invitationId) {}

    async getInvitation(invitationId) {
        const query = await this.client.query({
            query: gql(queries.getInvitation),
            variables: { id: invitationId },
            //fetchPolicy: 'network-only'
        });

        return query.data.getInvitation;
    }

    async getResourceAccessCredentials(resourceKey) {
        const query = await this.client.query({
            query: gql(queries.getResourceAccessCredentials),
            variables: { resourceKey: resourceKey },
            fetchPolicy: "network-only",
        });

        return query.data.getResourceAccessCredentials;
    }
}
