import * as types from "./actionTypes";
import {stableSort} from "../../utils/arrayUtils";
import {toId} from "../../utils/apiUtils";

const getMembersWithId = (members) => {
    return members.map(member => ({...member, id: toId(member.pk)}))
}

const getMemberWithId = (member) => {
    return {...member, id: toId(member.pk)}
}

export const loadMembers = (workspaceKey, members) => async (dispatch, getState) => {
    if (!members || members.length === 0) {
        return;
    }

    dispatch({ type: types.MEMBERS_LOADED, payload: {workspaceKey: workspaceKey, members: getMembersWithId(members)} });
}

export const addMembers = (workspaceKey, members) => async (dispatch, getState) => {
    if (!members || members.length === 0) {
        return;
    }

    const existingMembers = getState().members[workspaceKey] ?? [];
    const uniqueMembers = [...existingMembers, ...getMembersWithId(members)];
    dispatch({ type: types.MEMBERS_LOADED, payload: {workspaceKey: workspaceKey, members: uniqueMembers} });
}

export const addMember = (workspaceKey, member) => async (dispatch, getState) => {
    const existingMembers = getState().members[workspaceKey];

    if (existingMembers && existingMembers.find(t => t.pk === member.pk)) {
        console.log("WARNING. Member already exists");
        return;
    }

    dispatch({ type: types.MEMBER_ADDED, payload: {workspaceKey: workspaceKey, member: getMemberWithId(member)} });
}

export const updateMember = (workspaceKey, memberKey, data) => async (dispatch, getState) => {
    const member = getState().members[workspaceKey]?.find(t => t.pk === memberKey);

    if (!member) {
        console.error("Cann update member %o as it doesn't exist", memberKey);
    } else {
        const updated = {...member, ...data};
        dispatch({ type: types.MEMBER_UPDATED, payload: {workspaceKey, member: updated} });
    }
}

export const deleteMember = (workspaceKey, memberKey) => async (dispatch, getState) => {
    dispatch({ type: types.MEMBER_DELETED, payload: { workspaceKey, memberKey } });
}

export const deleteAllMembers = (workspaceKey) => async (dispatch, getState) => {
    dispatch({ type: types.MEMBERS_DELETED, payload: { workspaceKey }});
}