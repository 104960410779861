import React from 'react';
import PropTypes from 'prop-types';
import {
    useScrollTrigger
} from '@material-ui/core'

const ElevationScroll = ({ children }) => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });

    return React.cloneElement(children, {
        elevation: trigger ? 2 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired
};

export default ElevationScroll;