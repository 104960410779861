import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import ErrorDialog from "../ErrorDialog";
import { useTranslation } from "react-i18next";
import { numericId } from "../../utils/idGenerator";
import FormBlock from "../formBlocks";
import AttachmentsFormBlock from "../formBlocks/AttachmentsFormBlock";
import {MAX_ATTACHMENT_SIZE} from "../../constants";

const AddAttachmentButton = ({
    className,
    language,
    variant,
    size,
    edge,
    component,
    children,
    onFileAdded,
    ...rest
}) => {
    const { t, i18n } = useTranslation();
    const activeLanguage = language ?? i18n.language;
    const [invalidFile, setInvalidFile] = useState(false);
    const inputId = useMemo(() => `file-upload-button-${numericId()}`, []);

    const handleFileInputChange = (event) => {
        const {
            target: { value, files },
        } = event;
        for (const file of files) {
            if (file.size > MAX_ATTACHMENT_SIZE) {
                setInvalidFile(file.name);
            } else {
                onFileAdded(file);
            }
        }

        // setFileKeys(newFiles.map(file => getFileKey(file)));
        event.target.value = "";
    };

    const renderButton = () =>
        variant === "icon" ? (
            <Tooltip title={t("Attach file", { lng: activeLanguage })}>
                <label htmlFor={inputId} style={{ marginBottom: 0 }}>
                    <IconButton
                        className={className}
                        aria-label="attach-file"
                        size={size}
                        component={component}
                        edge={edge}
                        {...rest}
                    >
                        {children}
                    </IconButton>
                </label>
            </Tooltip>
        ) : (
            <label htmlFor={inputId} style={{ marginBottom: 0 }}>
                <Button
                    className={className}
                    variant="contained"
                    aria-label="attach-file"
                    size={size}
                    component={component}
                    edge={edge}
                    {...rest}
                >
                    {children}
                </Button>
            </label>
        );

    return (
        <>
            {renderButton()}
            <input id={inputId} type="file" style={{ display: "none" }} onChange={handleFileInputChange} multiple />
            <ErrorDialog
                open={Boolean(invalidFile)}
                title={t("cannotUploadAttachment", { name: invalidFile, lng: activeLanguage })}
                text={t("attachmentSizeError", { lng: activeLanguage })}
                onClose={() => setInvalidFile(null)}
            />
        </>
    );
};

AddAttachmentButton.propTypes = {
    className: PropTypes.string,
    language: PropTypes.string,
    variant: PropTypes.oneOf(["icon", "standard"]),
    size: PropTypes.oneOf(["small", "medium", "large"]),
    edge: PropTypes.oneOf(["end", "start"]),
    component: PropTypes.string,
    onFileAdded: PropTypes.func,
};

AddAttachmentButton.defaultProps = {
    variant: "standard",
    size: "medium",
    onFileAdded: () => {}
};

export default AddAttachmentButton;
