import React, {useContext, useState, useEffect, useCallback, useRef} from 'react';
import {ApiContext} from "../contexts/ApiContext";
import {ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {loadMessages} from "../state/messages/actions";
import {isComment, isMessage, isReport, toReportKey} from "../utils/apiUtils";
import {loadComments} from "../state/comments/actions";
import {addOrUpdateReport} from "../state/reports/actions";

// TODO: Rename useReportAsMember
export const useReport = (reportKey, workspaceKey) => {
    const api = useContext(ApiContext);
    const dispatch = useDispatch();
    const { store } = useContext(ReactReduxContext);

    const fetchReportResources = async () => {
        const resources = await api.query.listReportResources(reportKey);
        // resources can be empty if no resource with matching key exists
        if (resources && resources.length > 0) {
            const report = resources.find(r => isReport(r));
            dispatch(addOrUpdateReport(report.workspace, reportKey, report));

            const messages = resources.filter(r => isMessage(r));
            dispatch(loadMessages(reportKey, messages));

            const comments = resources.filter(r => isComment(r));
            dispatch(loadComments(reportKey, comments));
        }
    }

    useEffect(() => {
        if (reportKey && workspaceKey) {
            // User can fetch messages of only those reports that he is assigned to. Keys of all available reports
            // are loaded into redux before this hook gets called.
            const isAuthorized = store.getState().reports[workspaceKey]?.some(item => item.pk === reportKey);
            const messages = store.getState().messages[reportKey];

            if (isAuthorized && (!messages || messages.length === 0)) {
                fetchReportResources();
            }
        }
    }, [reportKey]);
}

