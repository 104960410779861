import React, {useContext} from 'react';
import {Route, Navigate, useParams} from 'react-router-dom';
import Loader from "../components/Loader";
import {AuthContext} from "../contexts/AuthContext";
import {Auth} from "aws-amplify";
import {useDispatch, useSelector} from "react-redux";
import { activeWorkspacesSelector, allWorkspacesSelector } from "../state/workspaces/selectors";
import {toWorkspaceKey} from "../utils/apiUtils";
import {useWorkspace} from "../hooks/useWorkspace";
import {WorkspaceKeyContext} from "../contexts/WorkspaceKeyContext";

// TODO: Use suspense
const PrivateWorkspaceRoute = (props) => {
    const { isAuthenticated } = useContext(AuthContext);
    const workspaces = useSelector(activeWorkspacesSelector());
    const {workspaceId} = useParams();
    const workspaceKey = workspaceId ? toWorkspaceKey(workspaceId) : null;
    const isAuthorized = !workspaceKey || Boolean(workspaces?.find(w => w.pk === workspaceKey))
    const isLoaded = useWorkspace(workspaceKey);

    if (!isAuthenticated) {
        console.log("WorkspaceRoute, unauthenticated, redirecting to /signin");
        const state = isAuthorized ? { from: window.location.pathname } : null;
        return (
            <Navigate to="/portal/signin" state={state} />
        )
    }

    if (!isAuthorized) {
        console.log("WorkspaceRoute, unauthorized, redirecting to /portal")
        return (
            <Navigate to="/portal" />
        )
    }

    if (!isLoaded) {
        return (
            <Loader/>
        )
    }

    return (
        <WorkspaceKeyContext.Provider value={workspaceKey}>
            <Route {...props}/>
        </WorkspaceKeyContext.Provider>
    )
}

export default PrivateWorkspaceRoute;