import React, {useEffect, useState} from 'react';
import {
    makeStyles,
    Divider,
    alpha,
    AppBar,
    Toolbar,
    Typography,
    Box,
    IconButton,
    Select,
    Button,
    Link,
    Hidden,
    Drawer,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@material-ui/core"
import {NavLink, Outlet, useParams} from 'react-router-dom';
import clsx from "clsx";
import UserAvatar from "../../components/UserAvatar";
import ProfileSettingsDialog from "../../components/ProfileSettingsDialog";
import MenuItem from "@material-ui/core/MenuItem";
import {toWorkspaceKey} from "../../utils/apiUtils";
import {useFetchWorkspaces} from "../../hooks/useFetchWorkspaces";
import {useSelector} from "react-redux";
import {workspaceSelector} from "../../state/workspaces/selectors";
import Loader from "../../components/Loader";
import {useTranslation} from "react-i18next";
import {LANGUAGES} from "../../constants/languages";
import {WorkspaceKeyContext} from "../../contexts/WorkspaceKeyContext";
import {ActiveLanguageContext} from "../../contexts/ActiveLanguageContext";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ElevationScroll from "../../components/ElevationScroll";
import {BUTTON_BORDER_RADIUS} from "../../constants";

const useStyles = makeStyles((theme) => ({
    submitLayoutRoot: {
        //overflow: "hidden",
        height: '100%',
        display: "flex",
        flexDirection: "column",
        //overflow: "auto"
    },
    appBar: {
        flex: 0,
        backgroundColor: theme.palette.background.paper
    },
    toolbar: {
        justifyContent: "space-between",
        width: "100%",
        maxWidth: theme.spacing(144), // 1152px
        margin: "auto",
    },
    logo: {
        marginRight: theme.spacing(1),
        display: "inline-flex" // TODO: Move to UserAvatar root style
    },
    languageSelect: {
        marginRight: theme.spacing(3)
    },
    drawerPaper: {
        height: "auto",
        width: "100%",
        padding: theme.spacing(3),
        '&>*:not(:first-child)': {
            marginTop: theme.spacing(3),
        }
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1)
    },
    accordion: {
        boxShadow: "none",
        '&::before': {
            display: "none"
        }
    },
    accordionExpanded: {
        marginBottom: "0!important"
    },
    accordionHeader: {
        minHeight: "0!important",
        padding: 0,
        ...theme.typography.button,
        fontSize: "0.9375rem",
        '& .MuiIconButton-root': {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    menuList: {
        width: "100%"
    },
    menuListItem: {
        borderRadius: 8
    },
    noGutters: {
        margin: "0!important",
        padding: 0
    },
    button: {
        //borderRadius: BUTTON_BORDER_RADIUS,
        '&:hover': {
            color: theme.palette.primary.contrastText
        }
    },
    toolbarHeader: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center"
    }
}));

// TODO: Delete
const SubmitLayout = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const {workspaceId} = useParams();
    const workspaceKey = toWorkspaceKey(workspaceId);
    const workspaceLoaded = useFetchWorkspaces([workspaceKey]);
    const workspace = useSelector(workspaceSelector(workspaceKey));
    const [availableLanguages, setAvailableLanguages] = useState();
    const [language, setLanguage] = useState({});
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    useEffect(() => {
        const enabled = LANGUAGES.filter(lang => !workspace?.disabledSubmitLanguages?.includes(lang.code));
        setAvailableLanguages(enabled);
        const activeLanguage = (enabled.find(lang => lang.code === i18n.language) ?? enabled[0]).code;
        setLanguage(activeLanguage);
    }, [workspaceLoaded])

    const WorkspaceLogo = () => (
        <div className={classes.toolbarHeader}>
            <UserAvatar className={classes.logo} name={workspace.name} imageKey={workspace.picture} component="span" variant={"rounded"}/>
            <Typography variant="h5" color="textPrimary" component="span">{workspace.name}</Typography>
        </div>
    )

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleDrawerLanguageChanged = (value) => {
        setLanguage(value);
        setDrawerOpen(false);
    }

    if (!workspaceLoaded || !availableLanguages) {
        return <Loader/>
    }

    // TODO: Move toolbar to stanalone function
    return (
        <div className={classes.submitLayoutRoot}>
            <ElevationScroll>
                <AppBar className={clsx(classes.appBar)} position="sticky" color="transparent" elevation={0}>
                    <Toolbar className={classes.toolbar}>
                        <WorkspaceLogo/>
                        <Hidden smUp>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleDrawerOpen}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                        <Hidden xsDown>
                            <Select
                                className={classes.languageSelect}
                                disableUnderline
                                color="secondary"
                                value={language}
                                onChange={(event) => setLanguage(event.target.value)}
                            >
                                {availableLanguages.map((lang) => (
                                    <MenuItem key={lang.code} value={lang.code}>
                                        {lang.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Button
                                className={classes.button}
                                component={NavLink}
                                to="/followup"
                                underline="none"
                                size="medium"
                                color="primary"
                                variant="contained"
                            >
                                {t("loginToReport", {lng: language})}
                            </Button>
                        </Hidden>
                    </Toolbar>
                    <Divider/>
                </AppBar>
            </ElevationScroll>
            <Box flexGrow="1">
                <WorkspaceKeyContext.Provider value={workspaceKey}>
                    <ActiveLanguageContext.Provider value={language}>
                        <Outlet/>
                    </ActiveLanguageContext.Provider>
                </WorkspaceKeyContext.Provider>
            </Box>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleDrawerClose}
                classes={{
                    paper: classes.drawerPaper
                }}
            >
                <div className={classes.drawerHeader}>
                    <WorkspaceLogo/>
                    <IconButton
                        color="inherit"
                        aria-label="close drawer"
                        edge="end"
                        onClick={handleDrawerClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <Accordion className={classes.accordion} classes={{expanded: classes.accordionExpanded}}>
                    <AccordionSummary
                        className={classes.accordionHeader}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="language-panel-content"
                        id="language-panel-header"
                        classes={{
                            content: classes.noGutters
                        }}
                    >
                        <Typography variant="inherit" color="inherit">{t("Language", {lng: language})}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.noGutters}>
                        <List className={classes.menuList} component="div" disablePadding>
                            {availableLanguages.map((lang) => (
                                <ListItem
                                    button
                                    key={lang.code}
                                    className={classes.menuListItem}
                                    selected={language === lang.code}
                                    onClick={() => handleDrawerLanguageChanged(lang.code)}
                                >
                                    <ListItemText primary={lang.label} />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Button
                    className={classes.button}
                    component={NavLink}
                    to="/followup"
                    underline="none"
                    size="large"
                    color="primary"
                    variant="contained"
                >
                    {t("loginToReport", {lng: language})}
                </Button>
            </Drawer>
        </div>
    )
}

export default SubmitLayout