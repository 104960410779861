import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { currentUserSelector } from "../../state/currentUser/selectors";
import { useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';
import { Button, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import TodayIcon from '@material-ui/icons/Today';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    button: {
        color: theme.palette.text.primary,
        textTransform: "none",
        flexDirection: 'column'
    },
    icon: {
        color: theme.palette.text.secondary,
        borderRadius: "50%",
        borderThickness: "1px",
        borderColor: theme.palette.grey[400],
        borderStyle: "dashed",
        padding: theme.spacing(0.5),
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    clearButton: {
        color: theme.palette.text.secondary,
    }
}));

// TODO: Use dynamic import emailTemplate
const DueDatePicker = ({ value, disabled, onChange, ...rest }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const handleDateChange = (newValue) => {
        onChange(newValue);
    };

    return (
        <div className={classes.root} {...rest}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DatePicker
                    autoOk
                    disableToolbar
                    disablePast
                    readOnly={disabled}
                    disabled={disabled}
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="none"
                    value={value}
                    placeholder={t("noDueDate")}
                    onChange={handleDateChange}
                    helperText={null}
                />
            </MuiPickersUtilsProvider>
            {value && !disabled && (
                <IconButton className={classes.clearButton} size="small" onClick={() => onChange(null)}>
                    <ClearIcon fontSize="inherit" colo='inherit'/>
                </IconButton>
            )}
        </div>
    );
};

DueDatePicker.propTypes = {
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired,
};

DueDatePicker.defaultProps = {
    value: null,
    onChange: () => {},
};

export default DueDatePicker;
