import * as types from "./actionTypes";

export const loadReports = (workspaceKey, reports) => async (dispatch, getState) => {
    const loaded = Boolean(getState().reports[workspaceKey]);
    if (loaded) {
        console.warn(`Reports for workspace %o already loaded`, workspaceKey);
    }

    dispatch({ type: types.REPORTS_LOADED, payload: {workspaceKey: workspaceKey, reports: reports} });
}

export const addOrUpdateReport = (workspaceKey, reportKey, report) => async (dispatch, getState) => {
    const reports = getState().reports[workspaceKey];
    console.log("addOrUpdateReport called");
    if (!reports) {
        console.log("addOrUpdateReport - no reports loaded, loading report into workspace")
        dispatch({ type: types.REPORTS_LOADED, payload: {workspaceKey: workspaceKey, reports: [report]} });
        return;
    }

    const existing = reports.find(t => t.pk === reportKey);
    if (existing) {
        console.log("addOrUpdateReport - found marching report, updating existing report")
        const updated = {...existing, ...report};
        dispatch({ type: types.REPORT_UPDATED, payload: {workspaceKey: workspaceKey, report: updated} });
    } else {
        console.log("addOrUpdateReport - no marching report, adding report into workspace")
        dispatch({ type: types.REPORT_ADDED, payload: {workspaceKey: workspaceKey, report: report} });
    }
}

export const deleteAllReports = (workspaceKey) => async (dispatch, getState) => {
    dispatch({ type: types.REPORTS_DELETED, payload: { workspaceKey } });
}