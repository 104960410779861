export const allFormsSelector = (workspaceKey) => state => {
    if (!Boolean(workspaceKey)) {
        return null;
    }

    return state.forms[workspaceKey];
};
export const activeFormsSelector = (workspaceKey) => state => {
    if (!Boolean(workspaceKey)) {
        return null;
    }

    return state.forms[workspaceKey].filter(form => !form.disabled);
};

export const formSelector = (workspaceKey, formKey) => state => {
    if (!Boolean(workspaceKey) || !Boolean(formKey)) {
        return null;
    }

    return state.forms[workspaceKey]?.find(t => t.pk === formKey)
}