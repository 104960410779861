import React, {useContext} from 'react';
import {Route, Navigate} from 'react-router-dom';
import Loader from "../components/Loader";
import {AuthContext} from "../contexts/AuthContext";
import {Auth} from "aws-amplify";

// TODO: Use suspense
const PrivateRoute = (props) => {
    const { isAuthenticated } = useContext(AuthContext);

    //Auth.signOut();

    if (!isAuthenticated) {
        console.log("Private route, redirecting to /signin")
    }

    return isAuthenticated ? (
        <Route {...props}/>
    ) : (
        <Navigate to="/portal/signin" state={{ from: window.location.pathname }} />
    );
}

export default PrivateRoute;