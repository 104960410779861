import React, {useContext, useEffect, useState} from 'react';
import {
    Box,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    alpha,
    Grid, ListItemIcon, ListItemText,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import {deleteWorkspace, updateWorkspace} from "../../../state/workspaces/actions";
import {currentUserSelector} from "../../../state/currentUser/selectors";
import {workspaceSelector} from "../../../state/workspaces/selectors";
import {ApiContext} from "../../../contexts/ApiContext";
import IconButton from "@material-ui/core/IconButton";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import AddIcon from '@material-ui/icons/Add';
import {activeMembersSelector} from "../../../state/members/selectors";
import UserRoleSelect from "../../../components/UserRoleSelect";
import { deleteMember, updateMember } from "../../../state/members/actions";
import MenuItem from "@material-ui/core/MenuItem";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Menu from "@material-ui/core/Menu";
import OwnerChip from "./OwnerChip";
import PropTypes from "prop-types";
import RoleConfirmationDialog from "./RoleConfirmationDialog";
import DeleteMemberConfirmationDialog from "./DeleteMemberConfirmationDialog";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import UserCard from "../../../components/UserCard";
import {toId, toWorkspaceKey} from "../../../utils/apiUtils";
import {workspaceReports} from "../../../state/reports/selectors";

const useStyles = makeStyles((theme) => ({
    roleSelect: {
        minWidth: theme.spacing(15),
    }
}));

const getRoleConfirmationDialogTitle = (t, newRole, userName= "", workspaceName = "") => {
    const firstName = userName.split(' ')[0];

    switch (newRole) {
        case "owner": {
            return t("Give {user} ownership of {workspace}", {user: firstName, workspace: workspaceName});
        }
        case "admin": {
            return t("Give {user} administrative access to {workspace}", {user: firstName, workspace: workspaceName});
        }
        case "solver": {
            return t("Give {user} limited access to {workspace}", {user: firstName, workspace: workspaceName});
        }
        default: {
            return "";
        }
    }
}

const getRoleConfirmationDialogText = (t, newRole, userName) => {
    switch (newRole) {
        case "owner": {
            return t("As owner {user} will be able to access billing settings of the workspace, as well as all other workspace settings and reports", {user: userName});
        }
        case "admin": {
            return t("Administrative access will allow {user} to see and alter all reports and access workspace settings", {user: userName});
        }
        case "solver": {
            return t("If you make {user} a solver, {user} will only be able to see reports that are explicitly assigned to them", {user: userName});
        }
        default: {
            return "";
        }
    }
}

// TODO: Refactor, don't show conrifmation dialog when changing roles
const MembersSection = ({className, classes: propClasses}) => {
    const classes = useStyles();
    const api = useContext(ApiContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {workspaceId} = useParams();
    const workspaceKey = toWorkspaceKey(workspaceId);
    const workspace = useSelector(workspaceSelector(workspaceKey));
    const members = useSelector(activeMembersSelector(workspaceKey));
    const currentUser = useSelector(currentUserSelector());
    const reports = useSelector(workspaceReports(workspaceKey));
    const [roleConfirmationDialogOpen, setRoleConfirmationDialogOpen] = useState(false);
    const [deleteMemberDialogOpen, setDeleteMemberDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState();
    const [newRole, setNewRole] = useState();
    const [contextMenuAnchorEl, setContextMenuAnchorEl] = React.useState(null);

    const openContextMenu = (event, user) => {
        setSelectedUser(user);
        setContextMenuAnchorEl(event.currentTarget);
    }

    const closeContextMenu = () => {
        setContextMenuAnchorEl(null);
    }

    const handleRoleSelectChanged = (member, role) => {
        //setSelectedUser(user);
        //setNewRole(role);
        if (member) {
            api.mutation.updateMember(member.pk, workspaceKey, {role: role});
            dispatch(updateMember(workspaceKey, member.pk, {role: role}))
        }
        //setRoleConfirmationDialogOpen(true);
    }

    const handleSetAsOwnerClicked = () => {
        // selectedUserKey was already set when role context menu was opened
        setNewRole("owner");
        closeContextMenu();
        setRoleConfirmationDialogOpen(true);
    }

    const deleteSelectedMember = () => {
        closeContextMenu();
        setDeleteMemberDialogOpen(true);
    }

    const handleRoleConfirmationDialogConfirmed = () => {
        //if (newRole === "owner") {
            const data = {owners: [toId(selectedUser.pk)], reportSettings: JSON.stringify({autoAssign: [toId(selectedUser.pk)]})};
            // TODO: Move reportSettings update to resolver??
            // This just makes sure the owner and report settings are correctly updated in redux. Sometimes a user may
            // not get a workspace update subscription, for example if he looses connection.
            dispatch(updateWorkspace(workspaceKey, data));
            api.mutation.updateWorkspace(workspaceKey, data).then(() => {
                api.mutation.updateMember(selectedUser.pk, workspaceKey, {role: "admin"});
            });
        // } else {
        //     api.mutation.updateMember(selectedUser.pk, workspaceKey, {role: newRole});
        // }

        setRoleConfirmationDialogOpen(false);
    }

    const handleDeleteConfirmationDialogConfirmed = () => {
        api.mutation.deleteMember(selectedUser.pk, workspaceKey, reports);
        dispatch(deleteMember(workspaceKey, selectedUser.pk)); // TODO: May not be necessary
        setDeleteMemberDialogOpen(false);

        if (selectedUser.pk === currentUser.pk) {
            dispatch(deleteWorkspace(workspaceKey));
        }
    }

    const renderContextMenu = () => (
        currentUser.id === workspace.owners[0] ? (
                <Menu
                    anchorEl={contextMenuAnchorEl}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    getContentAnchorEl={null}
                    id="membership-menu"
                    keepMounted
                    transformOrigin={{vertical: "top", horizontal: "center" }}
                    open={Boolean(contextMenuAnchorEl)}
                    onClose={closeContextMenu}
                >
                    <MenuItem onClick={handleSetAsOwnerClicked}>{t("Set as workspace owner")}</MenuItem>
                    <Divider/>
                    <MenuItem onClick={deleteSelectedMember} disabled={members.length <= 1}>{t("delete")}</MenuItem>
                </Menu>
            ) : (
            <Menu
                anchorEl={contextMenuAnchorEl}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                getContentAnchorEl={null}
                id="membership-menu"
                keepMounted
                transformOrigin={{vertical: "top", horizontal: "center" }}
                open={Boolean(contextMenuAnchorEl)}
                onClose={closeContextMenu}
            >
                <MenuItem onClick={deleteSelectedMember} disabled={members.length <= 1}>{t("delete")}</MenuItem>
            </Menu>
        )
    )

    return (
        <div className={className}>
            <Typography variant="h5" gutterBottom>
                {t("Members")}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {t("Members have access to workspace reports and conversations")}
            </Typography>
            <div className={propClasses.content}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={propClasses.tableHeader}>
                                    {t("common.name")}
                                </TableCell>
                                <TableCell className={propClasses.tableHeader}>
                                </TableCell>
                                <TableCell className={propClasses.tableHeader}>
                                    {t("Role")}
                                </TableCell>
                                <TableCell className={propClasses.tableHeader}>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { members.map((member) => {
                                const memberId = toId(member.pk);
                                return (
                                    <TableRow key={member.pk} >
                                        <TableCell component="th" scope="row">
                                            {<UserCard size="large" name={member.name} subtitle={member.email} imageKey={member.picture}/>}
                                        </TableCell>
                                        <TableCell>
                                            {member.id === workspace.owners[0] && (
                                                <OwnerChip/>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {(member.id !== workspace.owners[0] || member.pk === currentUser.pk) && (
                                                <UserRoleSelect
                                                    className={classes.roleSelect}
                                                    hiddenLabel
                                                    SelectProps={{
                                                        disableUnderline: true
                                                    }}
                                                    size="medium"
                                                    value={member.role}
                                                    onChange={(role) => handleRoleSelectChanged(member, role)}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            { !workspace.owners.includes(memberId) && (
                                                <IconButton size="small" edge="end" onClick={(event => openContextMenu(event, member))} >
                                                    <MoreVertOutlinedIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )})
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {renderContextMenu()}
            <RoleConfirmationDialog
                open={roleConfirmationDialogOpen}
                title={getRoleConfirmationDialogTitle(t, newRole, selectedUser?.name, workspace.name)}
                text={getRoleConfirmationDialogText(t, newRole, selectedUser?.name)}
                onClose={() => setRoleConfirmationDialogOpen(false)}
                onConfirm={handleRoleConfirmationDialogConfirmed}
            />
            <DeleteMemberConfirmationDialog
                open={deleteMemberDialogOpen}
                userName={selectedUser?.name ?? ""}
                workspaceName={workspace.name}
                onClose={() => setDeleteMemberDialogOpen(false)}
                onConfirm={handleDeleteConfirmationDialogConfirmed}
            />
        </div>
    );
};

export default MembersSection;

MembersSection.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.shape({
        content: PropTypes.string,
        tableHeader: PropTypes.string
    })
}

MembersSection.defaultProps = {
    classes: {}
};