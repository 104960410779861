import React from 'react';
import PropTypes from 'prop-types';
import {
    Chip, Grid,
    makeStyles, Typography,
    useTheme
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {isApproved, isPendingApproval, isRejected} from "../../utils/reportUtils";
import Tag from "../Tag";
import Card from "../Card";
import {CARD_SHADOW3, CardShadow, CardShadow2, CardShadow3} from "../../constants";

const useStyles = makeStyles((theme) => ({
    dashboardCard: {
        padding: theme.spacing(3),
        border: `1px solid ${theme.palette.grey[300]}`,
        ...CardShadow
    },
    content: {
        height: "100%",
        display: "flex",
        alignItems: "center",
    }
}));

const DashboardCard = ({className, classes: propClasses, label, children}) => {
    const classes = useStyles();

    return (
        <Card className={clsx(classes.dashboardCard, className)}>
            <Typography variant="body2" color="textSecondary">
                {label}
            </Typography>
            <div className={clsx(classes.content, propClasses.content)}>
                {children}
            </div>
        </Card>
    )
};

DashboardCard.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    label: PropTypes.string,
}

DashboardCard.defaultProps = {
    classes: {}
};


export default DashboardCard;