import * as types from "./actionTypes";

const initialState = {};

export default function membersReducer(state = initialState, action) {
    switch (action.type) {
        case types.MEMBERS_LOADED: {
            const newState = {...state};
            newState[action.payload.workspaceKey] = action.payload.members;
            return newState;
        }
        case types.MEMBER_ADDED: {
            const newState = {...state};
            newState[action.payload.workspaceKey] = [...(state[action.payload.workspaceKey] ?? []), action.payload.member];
            return newState;
        }
        case types.MEMBER_UPDATED: {
            const members = state[action.payload.workspaceKey].map(member => {
                if (member.pk !== action.payload.member.pk) {
                    return member
                }

                return action.payload.member;
            });

            const newState = {...state};
            newState[action.payload.workspaceKey] = members
            return newState;
        }
        case types.MEMBER_DELETED: {
            const newState = {...state};
            newState[action.payload.workspaceKey] = state[action.payload.workspaceKey].filter(t => t.pk !== action.payload.memberKey);
            return newState;
        }
        case types.MEMBERS_DELETED: {
            const newState = {...state};
            delete newState[action.payload.workspaceKey];
            return newState;
        }
        default:
            return state
    }
}
