import React, {useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    CardHeader,
    Grid,
    TextField,
    CardActionArea,
    Container,
    Paper,
    FormControlLabel,
    Checkbox,
    alpha,
    InputAdornment, Tooltip
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import FormBlock from "./index";
import {isValidEmail} from "../../utils/textUtils";

const useStyles = makeStyles((theme) => ({
    reportEmailFormBlock: {
    },
}));

const ReportEmailFormBlock = ({ language, editable, errorText, onValueChange}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState("");

    const handleChange = (event) => {
        setValue(event.target.value);
        onValueChange(event.target.value);
    }

    return (
        <TextField
            fullWidth
            hiddenLabel
            className={classes.reportEmailFormBlock}
            disabled={editable}
            name="Email"
            variant="outlined"
            placeholder={t("emailAddress", {lng: language})}
            error={Boolean(errorText)}
            helperText={Boolean(errorText) ? errorText : null}
            value={value}
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment disablePointerEvents position="start">
                        <EmailOutlinedIcon color="action"/>
                    </InputAdornment>
                )
            }}
        />
    )
};

ReportEmailFormBlock.propTypes = {
    language: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    errorText: PropTypes.string,
    onValueChange: PropTypes.func
};

ReportEmailFormBlock.defaultProps = {
    onValueChange: () => {}
};

export default ReportEmailFormBlock;
