import React, {useContext, useState} from 'react';
import {
    Chip,
    Container, Dialog,
    Grid,
    makeStyles, Typography,
    useTheme
} from '@material-ui/core';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    chip: {
        color: theme.palette.secondary.light
    },
}));

const OwnerChip = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Chip className={classes.chip} label={t("Workspace owner")} variant="outlined"/>
    );
}

export default OwnerChip;