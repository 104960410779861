import React, {useContext} from 'react';
import {
    makeStyles,
    useTheme,
    MenuList,
    MenuItem,
    Avatar,
    Box,
    Tooltip,
    IconButton,
    Popover,
    Popper,
    alpha,
    InputBase,
    Typography,
    ButtonBase,
    Badge, withStyles, ListItemIcon, ListItemText, List, ListItem, ListItemAvatar
} from '@material-ui/core';
import PropTypes from "prop-types";
import {Autocomplete} from "@material-ui/lab";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import UserCard from "./UserCard";
import Menu from "@material-ui/core/Menu";
import {isOpen} from "../utils/reportUtils";
import {isApprover} from "../utils/user";
import NotInterestedOutlinedIcon from "@material-ui/icons/NotInterestedOutlined";
import SaveAltOutlinedIcon from "@material-ui/icons/SaveAltOutlined";

const useStyles = makeStyles((theme) => ({
    userMenuRoot: {
        minWidth: theme.spacing(80),
    },
    paper: {
        boxShadow: 'none',
        margin: 0
    },
    popperDisablePortal: {
        position: 'relative',
        width: "initial!important"
    },
    inputBase: {
        padding: 10,
        width: '100%',
        borderBottom: '1px solid #dfe2e5',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 8,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    readOnlyItem: {
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
    }
}));

class ImageIcon extends React.Component<{}> {
    render() {
        return null;
    }
}

const UserMenu = ({className, open, anchorEl, users, readOnly, onClose, onUserSelected, ...rest}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleClose = (event, reason) => {
        if (reason === 'toggleInput') {
            return;
        }
        if (anchorEl) {
            anchorEl.focus();
        }
        onClose();
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            className={clsx(classes.userMenuRoot, className)}
            onClose={handleClose}
            {...rest}
        >
            <List>
                {users.map(user => (
                    <ListItem
                        key={`${user.name}_${user.role}`}
                        className={classes.readOnlyItem}
                        button={!readOnly}
                        onClick={() => !readOnly && onUserSelected(user)}
                    >
                        <UserCard
                            name={user.name}
                            subtitle={user.email}
                            imageKey={user.picture}
                            size="medium"
                        />
                    </ListItem>
                ))}
            </List>
            {/*{readOnly ? (*/}
            {/*    <List>*/}
            {/*        {users.map(user => (*/}
            {/*            <ListItem key={`${user.name}_${user.role}`} className={classes.readOnlyItem}>*/}
            {/*                <UserCard*/}
            {/*                    name={user.name}*/}
            {/*                    subtitle={user.role ? t(user.role) : undefined}*/}
            {/*                    imageKey={user.picture}*/}
            {/*                    size="medium"*/}
            {/*                />*/}
            {/*            </ListItem>*/}
            {/*        ))}*/}
            {/*    </List>*/}
            {/*) : (*/}
            {/*    <Autocomplete*/}
            {/*        open*/}
            {/*        //onClose={handleClose}*/}
            {/*        //value={selectedMember}*/}
            {/*        classes={{*/}
            {/*            paper: classes.paper,*/}
            {/*            popperDisablePortal: classes.popperDisablePortal,*/}
            {/*        }}*/}
            {/*        onChange={(event, newValue) => onUserSelected(newValue)}*/}
            {/*        disablePortal*/}
            {/*        renderTags={() => null}*/}
            {/*        noOptionsText="No user"*/}
            {/*        renderOption={(option, { selected }) => (*/}
            {/*            <React.Fragment>*/}
            {/*                <UserCard*/}
            {/*                    name={option.name}*/}
            {/*                    subtitle={option.role ? t(option.role) : undefined}*/}
            {/*                    imageKey={option.picture}*/}
            {/*                    size="medium"*/}
            {/*                />*/}
            {/*            </React.Fragment>*/}
            {/*        )}*/}
            {/*        options={users}*/}
            {/*        getOptionLabel={(option) => option.name}*/}
            {/*        renderInput={(params) => (*/}
            {/*            <InputBase*/}
            {/*                ref={params.InputProps.ref}*/}
            {/*                inputProps={params.inputProps}*/}
            {/*                autoFocus*/}
            {/*                className={classes.inputBase}*/}
            {/*            />*/}
            {/*        )}*/}
            {/*    />*/}
            {/*)}*/}
        </Popover>
    )
};

UserMenu.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    users: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        picture: PropTypes.string,
        role: PropTypes.string
    })),
    readOnly: PropTypes.bool,
    onClose: PropTypes.func,
    onUserSelected: PropTypes.func,
}

UserMenu.defaultProps = {
    onClose: () => {},
    onUserSelected: () => {}
};

export default UserMenu;