import * as types from "./actionTypes";

export const loadForms = (workspaceKey, forms) => async (dispatch, getState) => {
    dispatch({ type: types.FORMS_LOADED, payload: {workspaceKey, forms} });
}

export const addForm = (workspaceKey, form) => async (dispatch, getState) => {
    const forms = getState().forms[workspaceKey];
    // Early exit if form already exists
    if (forms && forms.find(t => t.pk === form.pk)) {
        return;
    }

    dispatch({ type: types.FORM_ADDED, payload: {workspaceKey, form} });
}

export const updateForm = (workspaceKey, formKey, data) => async (dispatch, getState) => {
    dispatch({ type: types.FORM_UPDATED, payload: {workspaceKey, formKey, data} });
}
export const deleteForm = (workspaceKey, formKey) => async (dispatch, getState) => {
    dispatch({ type: types.FORM_DELETED, payload: {workspaceKey, formKey} });
}