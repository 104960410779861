import {API} from "@aws-amplify/api";
import gql from 'graphql-tag';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import awsconfig from '../aws-exports';
import QueryApi from "./QueryApi";
import MutationApi from "./MutationApi";
import SubscriptionApi from "./SubscriptionApi";
import StorageApi from "./StorageApi";
import React from "react";
import * as GuestAuth from "./GuestAuth";

const publicClient = new AWSAppSyncClient({
    url: awsconfig.aws_appsync_graphqlEndpoint,
    region: awsconfig.aws_appsync_region,
    auth: {
        type: AUTH_TYPE.AWS_IAM,
        credentials: () => GuestAuth.getCredentials(),
    },
    offlineConfig: {
        keyPrefix: 'public'
    }
});

export default class GuestAppSyncApi {
    constructor(appSyncClient = null) {

        // TODO: Rename api constructor arguments
        //this.query = new QueryApi(client);
        //this.mutation = new MutationApi(client);
        //this.subscriptions = new SubscriptionApi(client);
        this.storage = new StorageApi();
    }

    get query() {
        Object .defineProperty (
            this,
            "query",
            { value: new QueryApi(publicClient), writable: false}
        )
        return this.query;
    }

    get mutation() {
        Object .defineProperty (
            this,
            "mutation",
            { value: new MutationApi(publicClient), writable: false}
        )
        return this.mutation;
    }

    get subscriptions() {
        Object .defineProperty (
            this,
            "subscriptions",
            { value: new SubscriptionApi(publicClient), writable: false}
        )
        return this.subscriptions;
    }

    // get client() {
    //     console.log("GuestApi.client getter, credentials: %o", AWS.config.credentials);
    //     const value = new AWSAppSyncClient({
    //         url: awsconfig.aws_appsync_graphqlEndpoint,
    //         region: awsconfig.aws_appsync_region,
    //         auth: {
    //             type: AUTH_TYPE.AWS_IAM,
    //             credentials: () => Auth.currentCredentials(),
    //         }
    //     });
    //
    //     Object .defineProperty (
    //         this,
    //         "client",
    //         { value: value, writable: false}
    //     )
    //     return this.client;
    // }
}