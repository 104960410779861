// TODO: Uncomment if there will be any unexplained errors from MUI components
// import { createTheme, colors } from "@material-ui/core";
// Used to suppress React warning that findDOMNode is deprecated in StrictMode
import {
  unstable_createMuiStrictModeTheme as createTheme,
  colors,
} from "@material-ui/core";

const theme = createTheme({
  palette: {
    /*primary: {
           main: colors.deepPurple[400]
       },*/
    primary: {
      main: "#007DFF",
      light: "#3397FF",
      dark: "#0057B2",
    },
    secondary: {
      main: colors.deepOrange[400],
      contrastText: "#FFFFFF",
    },
    // secondary: {
    //     main: "#FF9800",
    //     light: "#FFC947",
    //     dark: "#C66900",
    // },
    background: {
      default: colors.common.white,
      //default: colors.grey[50]
    },
    divider: colors.grey[300],
    border: {
      default: "rgba(0, 0, 0, 0.23)",
      hover: "rgba(0, 0, 0, 0.87)",
    },
    success: {
      main: "#4CAF50",
      dark: "#3B873E",
      light: "#7BC67E",
      textDark:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #4CAF50",
      lightBg:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4CAF50",
      border: "rgba(76, 175, 80, 0.5)",
    },
    warning: {
      main: "#FF9800",
      dark: "#C77700",
      light: "#FFB547",
      textDark:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #FF9800",
      lightBg:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FF9800",
      border: "rgba(255, 152, 0, 0.5)",
    },
    error: {
      main: "#F44336",
      dark: "#E31B0C",
      light: "#F88078",
      textDark:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #F44336",
      lightBg:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336",
      hoverBg:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #F44336",
      border: "rgba(244, 67, 54, 0.5)",
    },
    text: {
      primary: "rgb(33, 43, 54)"
    }
  },
  typography: {
    //fontFamily: "Inter, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, Sans-Serif",
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    h1: {
      fontSize: "4rem",
      //lineHeight: 1.25,
      lineHeight: 1.5,
      letterSpacing: "2px",
      //fontWeight: 500
    },
    h2: {
      fontSize: "3rem",
      //lineHeight: 1.33333,
      lineHeight: 1.5,
      letterSpacing: "0rem",
      //fontWeight: 500
    },
    h3: {
      fontSize: "2rem",
      lineHeight: 1.5,
      letterSpacing: "0rem",
      //fontWeight: 500
    },
    h4: {
      fontSize: "1.5rem",
      lineHeight: 1.5,
      letterSpacing: "0rem",
      fontWeight: 500
    },
    h5: {
      fontSize: "1.25rem",
      lineHeight: 1.5,
      letterSpacing: "0rem",
      fontWeight: 500,
    },
    h6: {
      fontSize: "1.125rem",
      lineHeight: 1.55556,
      //lineHeight: 1.5,
      letterSpacing: "0rem",
      fontWeight: 500,
    },
    body2: {
      lineHeight: 1.57143,
      letterSpacing: "normal"
    },
    subtitle2: {
      lineHeight: 1.57143,
      letterSpacing: "normal"
    },
    buttonSmall: {
      fontSize: "0.8125rem",
      fontWeight: 500,
      lineHeight: 1.75,
      textTransform: "uppercase",
    },
    buttonMedium: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.75,
      textTransform: "uppercase",
    },
    buttonLarge: {
      fontSize: "0.9375rem",
      fontWeight: 500,
      lineHeight: 1.75,
      textTransform: "uppercase",
    }
    //    subtitle1: {
    //        fontWeight: 500
    //    }
  },
  shape: {
    borderRadius: 6,
  },
});

export default theme;
