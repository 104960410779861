import PropTypes from "prop-types";

export const DEFAULT_SUBMIT_SETTINGS = {
    title: "Help us help you",
    text: "Your feedback is important for us. Please tell us what happened. Your report will be confidential and anonymous."
}

export const SUBMIT_PROP_TYPES = PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
});

export const DEFAULT_CONFIRMATION_SETTINGS = {
    title: "Thank you",
    text: "We have received your feedback and we will address it as soon as possible",
    idFieldLabel: "This is your code, that you can use to login into the report",
    emailSectionEnabled: true,
    emailSectionLabel: "Want to be notified",
    emailSectionText: "Enter your email address if you wish to be notified about any updates to your report. Your email will be known only to Whispero and won’t be shared with any third-party."
}

export const CONFIRMATION_PROP_TYPES = PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    idFieldLabel: PropTypes.string,
    emailSectionEnabled: PropTypes.bool,
    emailSectionLabel: PropTypes.string,
    emailSectionText: PropTypes.string,
});