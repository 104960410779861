import React, {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles,
    CssBaseline,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    TablePagination,
    List,
    ListItem,
    ListItemAvatar,
    useTheme,
    ListItemText,
    Divider,
    ListItemSecondaryAction,
    ListItemIcon,
    ButtonBase,
    Dialog,
    useMediaQuery,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tabs,
    Tab,
    ButtonGroup,
    TextField,
    Backdrop,
    Tooltip,
    LinearProgress, FormHelperText
} from '@material-ui/core';
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import {ApiContext} from "../../contexts/ApiContext";
import {numericId} from "../../utils/idGenerator";
import {useTranslation} from "react-i18next";
import {getAvatarColor, getInitials} from "../../utils/avatarUtils";

const useStyles = makeStyles((theme) => ({
    avatarPicker: {
        textAlign: "center"
    },
    avatarContainer: {
        padding: theme.spacing(0.5),
        border: `1px dashed ${theme.palette.grey[400]}`,
        borderRadius: props => props.variant === "circular" ? "50%" : 4,
        display: "block",
        margin: "auto",
        position: "relative",
        zIndex: 0,
        width: "138px"
    },
    avatar: {
        height: theme.spacing(16),
        width: theme.spacing(16),
        fontSize: '3.0rem',
        backgroundColor: props => props.backgroundColor
    },
    backdrop: {
        position: "absolute",
        zIndex: theme.zIndex.drawer + 1,
        borderRadius: props => props.variant === "circular" ? "50%" : 4,
        margin: theme.spacing(0.5),
    },
    backdropButton: {
        color: theme.palette.grey[300],
        height: "100%",
        width: "100%"
    },
    avatarActions: {
        marginTop: theme.spacing(1),
    },
    errorLabel: {
        textAlign: "center"
    },
    progress: {
        flexGrow: "0.6",
        height: theme.spacing(0.75),
        borderRadius: 6,
        backgroundColor: theme.palette.grey[300]
    },
    barColor : {
        backgroundColor: theme.palette.grey[500]
    }
}));

const AvatarPicker = ({className, name, imageUrl, variant, onAvatarSelected, onAvatarCleared, isUploading, uploadProgress, classes: propClasses, ...rest}) => {
    const background = imageUrl ? null : getAvatarColor(name);
    const classes = useStyles({variant, backgroundColor: background});
    const { t } = useTranslation();
    const [avatarHover, setAvatarHover] = React.useState(false);
    const [avatarError, setAvatarError] = useState(false);
    const [inputId] = useState(`image-input-${numericId()}`)

    const handleUploadAvatarClick = (event) => {
        const { target: { value, files } } = event;
        const file = files[0];
        if (files[0].size > 5242880) {
            setAvatarError(true);
            return;
        }

        setAvatarError(false);
        onAvatarSelected(file);
        // Reset input value, otherwise the input's onChange event would not fire if user would pick same file as before
        document.getElementById(inputId).value='';
    }

    const handleRemoveButtonClick = () => {
        onAvatarCleared();
    }

    // TODO: Sizing doesnt work, remove fixed size in avatarContainer
    return (
        <div className={clsx(classes.avatarPicker, className)} {...rest}>
            <span
                className={classes.avatarContainer}
                onMouseEnter={() => setAvatarHover(true)}
                onMouseLeave={() => setAvatarHover(false)}
            >
                <Avatar className={clsx(classes.avatar, className)} component="span" src={imageUrl} variant={variant}>
                    {getInitials(name)}
                </Avatar>
                <Backdrop open={avatarHover || isUploading} className={classes.backdrop}>
                    { isUploading ? (
                        <LinearProgress
                            className={classes.progress}
                            value={uploadProgress}
                            variant="determinate"
                            classes={{
                                barColorPrimary: classes.barColor
                            }}
                        />
                    ) : (
                        <>
                            <Tooltip title={t("Upload photo")}>
                                <label className={classes.backdropButton} htmlFor={inputId} style={{marginBottom: 0}}>
                                    <IconButton disableRipple className={classes.backdropButton} component="span" aria-label="upload-image">
                                        <AddAPhotoIcon />
                                    </IconButton>
                                </label>
                            </Tooltip>
                        </>
                    )}
                </Backdrop>
            </span>
            { avatarError && (
                <FormHelperText error className={classes.errorLabel}>
                    {t("The image must be smaller than 5 MB")}
                </FormHelperText>
            )}
            <ButtonGroup disableRipple className={classes.avatarActions} variant="text" color="primary" aria-label="avatar actions" size="small">
                <Button onClick={() => {document.getElementById(inputId).click()}}>{t("Upload")}</Button>
                { imageUrl && (
                    <Button onClick={handleRemoveButtonClick}>{t("Remove")}</Button>
                )}
            </ButtonGroup>
            <input
                type="file"
                style={{ display: 'none' }}
                id={inputId}
                onChange={handleUploadAvatarClick}
            />
        </div>
    )
}

AvatarPicker.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    imageUrl: PropTypes.string,
    variant: PropTypes.oneOf(["rounded", "circular"]),
    onAvatarSelected: PropTypes.func.isRequired,
    onAvatarCleared: PropTypes.func,
    isUploading: PropTypes.bool,
    uploadProgress: PropTypes.number,
    classes: PropTypes.object
}

AvatarPicker.defaultProps = {
    variant: "circular",
    isUploading: false,
    classes: {},
    onAvatarSelected: () => {},
    onAvatarCleared: () => {}
};

export default AvatarPicker;