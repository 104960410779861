import React, { useContext, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
    Typography,
    makeStyles, Tooltip
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { ApiContext } from "../../contexts/ApiContext";
import IconButton from "@material-ui/core/IconButton";
import { getReportName } from "../../utils/textUtils";
import { useTranslation } from "react-i18next";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import { addOrUpdateReport } from "../../state/reports/actions";
import { newAwsDateTime } from "../../utils/time";
import { currentUserSelector } from "../../state/currentUser/selectors";
import ClosureDialog from "./ClosureDialog";
import {
    AcceptedApproval, isClosed,
    isOpen,
    isOverdue,
    isPendingApproval,
    isSystemMessage, remainingDays
} from "../../utils/reportUtils";
import { useParams } from "react-router-dom";
import { toId, toWorkspaceKey } from "../../utils/apiUtils";
import { messagesSelector } from "../../state/messages/selectors";
import { allMembersSelector, currentUserRoleSelector } from "../../state/members/selectors";
import ReportStatus from "../../components/ReportStatus/ReportStatus";
import { Skeleton } from "@material-ui/lab";
import ToolbarButton from "./ToolbarButton";
import { isAdmin, isApprover, isInvestigator } from "../../utils/user";
import ToolbarContextMenu from "./ToolbarContextMenu";
import ToolbarSplitButton from "./ToolbarSplitButton";
import ReportTags from "../../components/ReportTags";

const useStyles = makeStyles((theme) => ({
    reportToolbar: {
        flex: "0 0 auto",
        overflowX: 'auto',
        [theme.breakpoints.down("xl")]: {
            padding: [theme.spacing(2.5), theme.spacing(2)],
        },
        [theme.breakpoints.up("xl")]: {
            padding: [theme.spacing(2.5), theme.spacing(4)],
        },
    },
    header: {
        display: "flex",
        alignItems: "flex-start",
    },
    title: {
        flex: "1 1 auto",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
    },
    actions: {
        flex: "0 0 auto",
        [theme.breakpoints.down("xl")]: {
            "&>*:not(:last-child) ": {
                marginRight: theme.spacing(1),
            },
        },
        [theme.breakpoints.up("xl")]: {
            "&>*:not(:last-child) ": {
                marginRight: theme.spacing(2),
            },
        },
    },
    solveButton: {
        whiteSpace: "nowrap",
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.success.main,
        borderColor: `${theme.palette.common.white}!important`,
        "&:hover": {
            backgroundColor: theme.palette.success.dark,
        },
    },
    divider: {
        height: theme.spacing(5),
    },
    statusProgress: {
        maxWidth: "33%",
        width: theme.spacing(30),
        height: "6px",
    },
    statusLabel: {
        color: theme.palette.text.secondary,
        //...theme.typography.caption
    },
    openReportStatus: {
        flexDirection: "row-reverse",
        justifyContent: "flex-end",
        "&>*:not(:first-child) ": {
            marginRight: theme.spacing(0.5),
        },
    },
    tags: {
        marginTop: theme.spacing(0.25),
    },
    approveButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: "#27AE60",
        "&:hover": {
            backgroundColor: "#219653",
        },
    },
    rejectButton: {
        color: theme.palette.error.main,
        background: theme.palette.error.lightBg,
        "&:hover": {
            //color: theme.palette.error.textDark,
            background: theme.palette.error.hoverBg,
        },
    },
}));

// TODO: Check if needed!!!!
const getStatusLabel = (report, messages, users, t) => {
    if (isOpen(report)) {
        return isOverdue(report)
            ? t("days overdue", { count: Math.abs(remainingDays(report)) })
            : t("days remaining", { count: remainingDays(report) });
    } else {
        // TODO: Don't filter messages on each render
        const systemMessages = messages?.filter((msg) => isSystemMessage(msg));
        // Sometimes report update mutation arrives before system message creation mutation. Reports whose
        // state was not changed before will have no system messages.
        if (!systemMessages || systemMessages.length === 0) {
            return null;
        }

        // Find the last closure message
        for (let i = messages.length - 1; i >= 0; i--) {
            if (messages[i].state === "solved") {
                const senderName = users.find((user) => user.pk === messages[i].sender)?.name ?? "";
                return t("solvedBy", { name: senderName, date: new Date(messages[i].createdAt) });
            } else if (messages[i].state === "junk") {
                const senderName = users.find((user) => user.pk === messages[i].sender)?.name ?? "";
                return t("closedAsUnfoundedBy", { name: senderName, date: new Date(messages[i].createdAt) });
            }
        }

        return null;
    }
};

const getAdminMenuCloseItems = (t, workspaceMembers) => {
    const items = [{ tooltip: t("solveReport"), value: t("solve") }];
    if (!workspaceMembers.some((member) => isApprover(member.role))) {
        return items;
    }

    return [
        ...items,
        {
            tooltip: t("requestSolutionApproval"),
            value: t("requestApproval"),
        },
    ];
};

const ReportToolbar = ({ className, report, onGoBack }) => {
    const classes = useStyles();
    const api = useContext(ApiContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { workspaceId } = useParams();
    const workspaceKey = toWorkspaceKey(workspaceId);
    const messages = useSelector(messagesSelector(report.pk));
    const members = useSelector(allMembersSelector(workspaceKey));
    const currentUser = useSelector(currentUserSelector());
    const currentUserRole = useSelector(currentUserRoleSelector(workspaceKey));
    const [closureDialogOpen, setClosureDialogOpen] = useState(false);
    const [closureDialogVariant, setClosureDialogVariant] = useState();
    const [contextMenuAnchorEl, setContextMenuAnchorEl] = React.useState(null);
    const statusLabel = getStatusLabel(report, messages, members, t);

    const handleUnfoundedButtonClick = async () => {
        dispatch(addOrUpdateReport(workspaceKey, report.pk, { state: "junk" }));
        await api.mutation.updateReportState(report.pk, report.sk, currentUser.pk, "junk");
        await api.mutation.createSystemMessage(report.pk, report.sk, currentUser.pk, { state: "junk" });
    };

    const handleOpenButtonClick = async () => {
        // TODO: Remove redux dispatch??
        dispatch(addOrUpdateReport(workspaceKey, report.pk, { state: "open", stateUpdatedAt: newAwsDateTime() }));
        await api.mutation.updateReportState(report.pk, report.sk, currentUser.pk, "open", { approval: null });
        await api.mutation.createSystemMessage(report.pk, report.sk, currentUser.pk, { state: "open" });
    };

    const handleApproverAcceptButtonClick = async () => {
        dispatch(addOrUpdateReport(workspaceKey, report.pk, { approval: AcceptedApproval }));
        await api.mutation.updateResource(report.pk, report.sk, { approval: AcceptedApproval });
        if (Boolean(report.solution)) {
            await api.mutation.sendReportSolution(report.pk, report.workspace, report.solution);
        }
    };

    const handleApproverRejectButtonClick = () => {
        setClosureDialogVariant("rejectedApproval");
        setClosureDialogOpen(true);
    };

    const handleSolverCloseButtonClick = () => {
        setClosureDialogVariant("requestedApproval");
        setClosureDialogOpen(true);
    };

    const handleAdminCloseButtonClick = (index) => {
        if (index === 0) {
            setClosureDialogVariant("reportSolved");
        } else {
            setClosureDialogVariant("requestedApproval");
        }

        setClosureDialogOpen(true);
    };

    const renderToolbarButtons = () => {
        if (isPendingApproval(report) && (isApprover(currentUserRole) || isAdmin(currentUserRole))) {
            return (
                <>
                    <ToolbarButton
                        className={classes.approveButton}
                        tooltip={t("acceptSolution")}
                        variant="contained"
                        value={t("accept")}
                        onClick={handleApproverAcceptButtonClick}
                    />
                    <ToolbarButton
                        className={classes.rejectButton}
                        tooltip={t("rejectSolution")}
                        value={t("reject")}
                        onClick={handleApproverRejectButtonClick}
                    />
                </>
            );
        } else if (isClosed(report) && !isApprover(currentUserRole)) {
            return <ToolbarButton tooltip={t("openReport")} value={t("open")} onClick={handleOpenButtonClick} />;
        } else if (isAdmin(currentUserRole)) {
            return (
                <>
                    <ToolbarSplitButton
                        stateId={`whispero.${toId(currentUser.pk)}.closeButton`}
                        classes={{
                            button: classes.solveButton,
                        }}
                        options={getAdminMenuCloseItems(t, members)}
                        onClick={handleAdminCloseButtonClick}
                    />
                    <ToolbarButton
                        tooltip={t("closeAsUnfounded")}
                        value={t("unfounded")}
                        onClick={handleUnfoundedButtonClick}
                    />
                </>
            );
        } else if (isInvestigator(currentUserRole)) {
            return (
                <>
                    <ToolbarButton
                        className={classes.solveButton}
                        tooltip={t("requestSolutionApproval")}
                        variant="contained"
                        value={t("requestApproval")}
                        onClick={handleSolverCloseButtonClick}
                    />
                    <ToolbarButton
                        tooltip={t("closeAsUnfounded")}
                        value={t("unfounded")}
                        onClick={handleUnfoundedButtonClick}
                    />
                </>
            );
        } else {
            return <div />;
        }
    };

    return (
        <div className={clsx(classes.reportToolbar, className)}>
            {/*<Hidden mdUp>*/}
            {/*    <Tooltip title= {t("Back")}>*/}
            {/*        <IconButton aria-label="export" onClick={onGoBack}>*/}
            {/*            <ArrowBackIcon fontSize="small" />*/}
            {/*        </IconButton>*/}
            {/*    </Tooltip>*/}
            {/*</Hidden>*/}
            <div className={classes.header}>
                <Typography className={classes.title} variant="h5">
                    {getReportName(report, t)}
                </Typography>
                <div className={classes.actions}>
                    {renderToolbarButtons()}
                    <Tooltip title={t("More")}>
                        <IconButton
                            size="small"
                            edge="end"
                            onClick={(event) => setContextMenuAnchorEl(event.currentTarget)}
                        >
                            <MoreVertOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <Typography variant="caption" color="textSecondary" component="p">
                {t("createdAt", { date: new Date(report.createdAt) })}
            </Typography>
            {messages && messages.length > 0 && members && statusLabel ? (
                <ReportStatus
                    className={clsx({ [classes.openReportStatus]: isOpen(report) })}
                    report={report}
                    label={statusLabel}
                    classes={{
                        label: classes.statusLabel,
                        progressBar: classes.statusProgress,
                    }}
                />
            ) : (
                <Skeleton />
            )}
            {(report.approval || report.tags) && <ReportTags className={classes.tags} report={report} />}
            <ToolbarContextMenu
                open={Boolean(contextMenuAnchorEl)}
                anchorEl={contextMenuAnchorEl}
                report={report}
                onClose={() => setContextMenuAnchorEl(null)}
            />
            <ClosureDialog
                open={closureDialogOpen}
                report={report}
                variant={closureDialogVariant}
                onClose={() => setClosureDialogOpen(false)}
            ></ClosureDialog>
        </div>
    );
};

ReportToolbar.propTypes = {
    className: PropTypes.string,
};

export default ReportToolbar;
