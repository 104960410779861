export const allMembersSelector = (workspaceKey) => state => state.members[workspaceKey];
export const activeMembersSelector = (workspaceKey) => state => state.members[workspaceKey].filter(member => Boolean(member.role));

export const memberSelector = (workspaceKey, memberKey) => state => {
    if (!Boolean(workspaceKey) || !Boolean(memberKey)) {
        return null;
    }

    return state.members[workspaceKey]?.find(t => t.pk === memberKey)
};

export const membersSelector = (workspaceKey, memberKeys) => state => {
    if (!Boolean(workspaceKey) || !Boolean(memberKeys) || memberKeys.length === 0) {
        return null;
    }

    return state.members[workspaceKey]?.find(t => memberKeys.includes(t.pk));
};

export const adminMembersSelector = (workspaceKey) => state => state.members[workspaceKey]?.filter(t => t.role === "admin");
export const solverMembersSelector = (workspaceKey) => state => state.members[workspaceKey]?.filter(t => t.role === "solver");
export const approverMembersSelector = (workspaceKey) => state => state.members[workspaceKey]?.filter(t => t.role === "approver");

export const currentUserRoleSelector = (workspaceKey) => state => {
    if (!state.currentUser || !state.members[workspaceKey]) {
        return null;
    }
    return state.members[workspaceKey].find(t => t.pk === state.currentUser.pk).role;
};

export const currentUserMemberships = () => state => {
    if (!state.currentUser || !state.members) {
        return null;
    }

    return Object.keys(state.members);
}