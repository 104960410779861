import React, {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles,
    CssBaseline,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    TablePagination,
    List,
    ListItem,
    ListItemAvatar, useTheme, ListItemText, Divider, ListItemSecondaryAction, ListItemIcon, ButtonBase, Container
} from '@material-ui/core';
import ConfirmationDialog from "../../../components/reportDialogs/ConfirmationDialog";
import SubmitDialog from "../../../components/reportDialogs/SubmitDialog";
import {Navigate, useLocation} from "react-router";
import {useNavigate, useParams} from "react-router-dom";
import {ApiContext} from "../../../contexts/ApiContext";
import Page from "../../../components/Page/Page";
import {Skeleton} from "@material-ui/lab";
import DisclaimerDialog from "../../../components/reportDialogs/DisclaimerDialog";
import SubmitToolbar from "./SubmitToolbar";
import Loader from "../../../components/Loader";
import {useTranslation} from "react-i18next";
import {CardShadow2, SubmitToolbarHeight, TopBarHeight} from "../../../constants";
import {LANGUAGES} from "../../../constants/languages";
import {toUserKey, toWorkspaceKey} from "../../../utils/apiUtils";
import {useFetchWorkspaces} from "../../../hooks/useFetchWorkspaces";
import {ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {workspaceSelector} from "../../../state/workspaces/selectors";
import {memberSelector, activeMembersSelector} from "../../../state/members/selectors";
import {addMember} from "../../../state/members/actions";
import {useFetchUsers} from "../../../hooks/useFetchUsers";
import NotFoundView from "../../errors/NotFoundView";
import {newAttachmentsSection, newTextSection} from "../../../utils/formBlockUtils";

const useStyles = makeStyles((theme) => ({
    toolbarVisible: {
        height: `calc(100% - ${SubmitToolbarHeight + 1}px)`, // + 1 is to include divider under top bar,
        //maxHeight: "100%",
        //height: "100%"
    },
    dialog: {
        ...CardShadow2,
        borderRadius: 16,
        border: `1px solid ${theme.palette.grey[50]}`
    }
}));

// TODO: Delete component
const SubmitView = () => {
    const classes = useStyles();
    const api = useContext(ApiContext);
    const { t, i18n } = useTranslation();
    const {workspaceId} = useParams();
    const workspaceKey = toWorkspaceKey(workspaceId);
    const workspace = useSelector(workspaceSelector(workspaceKey));
    const owner = useSelector(memberSelector(workspaceKey, workspace?.owners?.length > 0 ? toUserKey(workspace.owners[0]) : null));
    // State can passed to the submit view by the login dialog
    const workspaceLoaded = useFetchWorkspaces([workspaceKey]);
    const usersLoaded = useFetchUsers(workspaceKey, workspace?.owners?.map(id => toUserKey(id)));
    const [accessId, setAccessId] = useState(null);
    const [disclaimerDisplayed, setDisclaimerDisplayed] = useState(false);
    const [availableLanguages, setAvailableLanguages] = useState();
    const [language, setLanguage] = useState();
    const submitLayout = workspace?.submitLayout ?? {};
    const toolbarVisible = availableLanguages && availableLanguages.length > 1;

    useEffect(() => {
        const enabled = LANGUAGES.filter(lang => !workspace?.disabledSubmitLanguages?.includes(lang.code));
        setAvailableLanguages(enabled);
        const activeLanguage = (enabled.find(lang => lang.code === i18n.language) ?? enabled[0]).code;
        setLanguage(activeLanguage);
    }, [workspaceLoaded])

    const handleOnSubmit = async ({text, formData, attachments}) => {
        const content = [];
        if (Boolean(text)) {
            content.push(newTextSection("", text));
        }
        if (Boolean(attachments) && attachments.length > 0) {
            content.push(newAttachmentsSection("", text));
        }
        const report = await api.mutation.createReport(workspace.pk, content, "cs");
        setAccessId(report.accessId);
    }

    return (
        <Navigate to={`/f/${workspaceId}`}/>
    )

    const renderDialogs = () => {
        if (!disclaimerDisplayed) {
            return (
                <DisclaimerDialog
                    workspace={workspace}
                    owner={owner}
                    language={language}
                    layout={submitLayout[language]?.disclaimer}
                    classes={{dialog: classes.dialog}}
                    onContinue={() => setDisclaimerDisplayed(true)}
                />
            )
        } else if (!accessId) {
            return (
                <SubmitDialog
                    workspace={workspace}
                    language={language}
                    layout={submitLayout[language]?.submit}
                    classes={{dialog: classes.dialog}}
                    onSubmit={handleOnSubmit}
                />
            );
        } else {
            return (
                <ConfirmationDialog
                    accessId={accessId}
                    workspace={workspace}
                    language={language}
                    layout={submitLayout[language]?.confirmation}
                    classes={{dialog: classes.dialog}}
                />
            );
        }
    };

    const renderToolbar = () => {
        if (!toolbarVisible) {
            return null;
        }

        return  (
            <SubmitToolbar workspace={workspace} language={language} availableLanguages={availableLanguages} onLanguageChange={setLanguage}/>
        )
    }

    // TODO: Not working with suburls, e.g. 111111111/form
    if (workspaceLoaded && !workspace) {
        return <NotFoundView/>
    }

    if (!workspaceLoaded || !usersLoaded || !availableLanguages) {
        return <Loader/>;
    }

    return (
        <Page
            title={t("Submit report", {lng: language})}
            className={toolbarVisible ? classes.toolbarVisible : undefined}
            disableScroll
        >
            {renderToolbar()}
            {renderDialogs()}
        </Page>
    );
}

export default SubmitView;