import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    alpha,
    ListItem, ListItemIcon, ListItemText,
    makeStyles, Tooltip
} from '@material-ui/core';
import AvatarPicker from "../../../components/AvatarPicker";
import {Tool} from "react-feather";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    itemDefault: {
        borderRadius: 6,
        color: theme.palette.text.secondary,
        padding: [theme.spacing(1.5), theme.spacing(2.5)],
        //color: theme.palette.grey[700],
        '&:hover': {
            //color: theme.palette.grey[700],
            color: theme.palette.text.primary,
            //backgroundColor: alpha(theme.palette.primary.main, 0.10),
            //backgroundColor: theme.palette.common.white,
            backgroundColor: theme.palette.grey[50],
        },
    },
    itemActive: {
        //color: theme.palette.grey[200],
        color: theme.palette.text.primary,
        //backgroundColor: alpha(theme.palette.primary.main, 0.90),
        backgroundColor: theme.palette.grey[300],
        fontWeight: theme.typography.fontWeightMedium,
        '&:hover': {
            //color: theme.palette.grey[200],
            //backgroundColor: theme.palette.primary.main,
            backgroundColor: theme.palette.grey[400],
        }
    },
    iconContainer: {
        color: "inherit",
        minWidth: 0,
        marginRight: theme.spacing(1.5)
    },

    defaultPadding: {
        padding: [theme.spacing(1.25), theme.spacing(2)]
    },
    collapsedPadding: {
        padding: [theme.spacing(1.5), theme.spacing(2.5)]
    },
    label: {
        fontWeight: "inherit",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
}));

const NavItem = ({className, href, icon, title, size}) => {
    const classes = useStyles();

    return (
        <Tooltip title={size === "collapsed" ? title : ""}>
            <ListItem
                className={clsx(className, classes.itemDefault)}
                key={title}
                button
                // classes={{gutters: size === "default" ? classes.defaultPadding : classes.collapsedPadding}}
                component={NavLink}
                to={href}
                activeClassName={classes.itemActive}
            >
                <ListItemIcon className={classes.iconContainer}>
                    {icon}
                </ListItemIcon>
                { size === "default" && (
                    <ListItemText
                        primary={title}
                        primaryTypographyProps={{className: classes.label, variant: "body2"/*, color: "textSecondary"*/}}
                    />
                )}
            </ListItem>
        </Tooltip>
    )
}

NavItem.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.object.isRequired,
    title: PropTypes.string,
    size: PropTypes.oneOf(["default", "collapsed"])
}

NavItem.defaultProps = {
    size: "default"
};

export default NavItem;