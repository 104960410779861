import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles, Divider, Hidden, Typography, TextField, Button, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { WorkspaceKeyContext } from "../../contexts/WorkspaceKeyContext";
import { allFormsSelector, formSelector } from "../../state/forms/selectors";
import TileButton2 from "../../components/TileButton2";
import TileButton from "../../components/TileButton/TileButton";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import AddTileButton from "../../components/AddTileButton";
import { Navigate, useParams } from "react-router-dom";
import { toFormKey, toId, toUserKey } from "../../utils/apiUtils";
import { formatFormId } from "../../utils/textFormatter";
import FormEditorToolbar from "./FormEditorToolbar";
import FormPage from "../../components/FormPage/FormPage";
import {
    deleteBlock,
    deminifyLanguagePacks,
    duplicateBlock,
    getFormActionsVariant,
    getFormBlockId,
    hydrateFormTemplate,
    hydrateLanguagePacks,
    isConfirmationPage,
    minifyLanguagePacks,
} from "../../utils/formUtils";
import { ApiContext } from "../../contexts/ApiContext";
import { workspaceSelector } from "../../state/workspaces/selectors";
import { memberSelector } from "../../state/members/selectors";
import Loader from "../../components/Loader";
import { s8 } from "../../utils/idGenerator";
import cloneDeep from "lodash/cloneDeep";
import ElevationScroll from "../../components/ElevationScroll";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { reorder } from "../../utils/arrayUtils";
import PropTypes from "prop-types";
import FormPageActions from "../../components/FormPageActions";
import EditableFormBlockContainer from "./EditableFormBlockContainer";
import { DEFAULT_FORM_TOP_PADDING_SPACING } from "../../constants";
import ContentSeparator from "./ContentSeparator";
import AddIcon from "@material-ui/icons/Add";
import EditableFormPage from "./EditableFormPage";
import EditableConfirmationPage from "./EditableConfirmationPage";

const useStyles = makeStyles((theme) => ({
    formEditorPanel: {
        overflow: "auto",
        flex: "1 1 auto",
        backgroundColor: theme.palette.grey[50],
        paddingTop: theme.spacing(DEFAULT_FORM_TOP_PADDING_SPACING),
        // '&>*:not(:last-child) ': {
        //     marginBottom: theme.spacing(4)
        // }
    },
}));

const FormEditorPanel = React.forwardRef(
    (
        {
            template,
            languagePack,
            language,
            workspace,
            showConfirmationPageOverlay,
            onTemplateChange,
            onTextChange,
            onTextDelete,
            onBlockDelete,
            onBlockDuplicate,
            onPageAdd,
            onPageDelete,
            onBlockAdd,
        },
        ref
    ) => {
        const classes = useStyles();
        const { t } = useTranslation();

        const addPageSeparator = (index) => (
            <ContentSeparator>
                <Button
                    variant="text"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => onPageAdd(index)}
                >
                    {t("addNewPage")}
                </Button>
            </ContentSeparator>
        );

        return (
            <div className={classes.formEditorPanel} ref={ref}>
                {addPageSeparator(0)}
                {template.pages.map((page, pageIndex) =>
                    !isConfirmationPage(pageIndex, template.pages.length) ? (
                        <React.Fragment key={page.key}>
                            <EditableFormPage
                                pageTemplate={page}
                                pageIndex={pageIndex}
                                pagesCount={template.pages.length}
                                languagePack={languagePack}
                                language={language}
                                workspace={workspace}
                                onBlockDuplicate={onBlockDuplicate}
                                onBlockDelete={onBlockDelete}
                                onTemplateChange={onTemplateChange}
                                onTextChange={onTextChange}
                                onTextDelete={onTextDelete}
                                onDelete={onPageDelete}
                                onBlockAdd={(type, blockIndex) => onBlockAdd(type, pageIndex, blockIndex)}
                            />
                            {addPageSeparator(pageIndex + 1)}
                        </React.Fragment>
                    ) : (
                        <EditableConfirmationPage
                            key={page.key}
                            pageTemplate={page}
                            pageIndex={pageIndex}
                            pagesCount={template.pages.length}
                            languagePack={languagePack}
                            language={language}
                            workspace={workspace}
                            showDragOverlay={showConfirmationPageOverlay}
                            onBlockDuplicate={onBlockDuplicate}
                            onBlockDelete={onBlockDelete}
                            onTemplateChange={onTemplateChange}
                            onTextChange={onTextChange}
                            onTextDelete={onTextDelete}
                        />
                    )
                )}
            </div>
        );
    }
);

FormEditorPanel.propTypes = {
    template: PropTypes.object.isRequired,
    languagePack: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    workspace: PropTypes.object.isRequired,
    showConfirmationPageOverlay: PropTypes.bool,
    onTemplateChange: PropTypes.func,
    onTextChange: PropTypes.func,
    onTextDelete: PropTypes.func,
    onBlockDelete: PropTypes.func,
    onBlockDuplicate: PropTypes.func,
    onPageAdd: PropTypes.func,
    onPageDelete: PropTypes.func,
};

FormEditorPanel.defaultProps = {
    classes: {},
    onTemplateChange: () => {},
    onTextChange: () => {},
    onTextDelete: () => {},
    onBlockDelete: () => {},
    onBlockDuplicate: () => {},
    onPageAdd: () => {},
    onPageDelete: () => {},
};

export default FormEditorPanel;
