import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import clsx from "clsx";
import {
    makeStyles,
    Divider,
    Hidden,
    Typography,
    TextField,
    Button,
    Paper,
    IconButton,
    Tooltip, ListItemText, Toolbar, ListItemIcon,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { WorkspaceKeyContext } from "../../contexts/WorkspaceKeyContext";
import { allFormsSelector, formSelector } from "../../state/forms/selectors";
import TileButton2 from "../../components/TileButton2";
import TileButton from "../../components/TileButton/TileButton";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import AddTileButton from "../../components/AddTileButton";
import { Navigate, useParams } from "react-router-dom";
import { toFormKey, toId, toUserKey } from "../../utils/apiUtils";
import { formatFormId } from "../../utils/textFormatter";
import FormEditorToolbar from "./FormEditorToolbar";
import FormPage from "../../components/FormPage/FormPage";
import {
    deleteBlock,
    deminifyLanguagePacks,
    duplicateBlock,
    getFormActionsVariant,
    getFormBlockId,
} from "../../utils/formUtils";
import { ApiContext } from "../../contexts/ApiContext";
import { workspaceSelector } from "../../state/workspaces/selectors";
import { memberSelector } from "../../state/members/selectors";
import Loader from "../../components/Loader";
import { s8 } from "../../utils/idGenerator";
import cloneDeep from "lodash/cloneDeep";
import ElevationScroll from "../../components/ElevationScroll";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { reorder } from "../../utils/arrayUtils";
import PropTypes from "prop-types";
import FormPageActions from "../../components/FormPageActions";
import EditableFormBlockContainer from "./EditableFormBlockContainer";
import { StyledMenu, StyledMenuItem } from "../../components/StyledMenu";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import FormBlock from "../../components/formBlocks";
import MemoizedEditableFormBlock from "./MemoizedEditableFormBlock";
import ContentSeparator from "./ContentSeparator";
import AddIcon from "@material-ui/icons/Add";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {LANGUAGE_LABELS} from "../../constants/languages";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import ShortTextOutlinedIcon from "@material-ui/icons/ShortTextOutlined";
import SubjectIcon from "@material-ui/icons/Subject";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";

const useStyles = makeStyles((theme) => ({
    formPage: {
        // "&:hover": {
        //     "& $moreButton": {
        //         visibility: "visible",
        //     },
        // },
    },
    pagePaper: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    blockPlaceholder: {
        height: theme.spacing(12),
    },
    moreButton: {
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(3),
        //visibility: "hidden",
        // transition: theme.transitions.create(['visibility'], {
        //     duration: theme.transitions.duration.short,
        // }),
    },
    visible: {
        visibility: "visible",
    },
}));

const components = [
    {
        type: "text",
        icon: <TextFieldsIcon/>,
    },
    {
        type: "textField",
        icon: <ShortTextOutlinedIcon/>,
    },
    {
        type: "multilineTextField",
        icon: <SubjectIcon/>,
    },
    {
        type: "dateField",
        icon: <EventOutlinedIcon/>,
    },
    {
        type: "dropdown",
        icon: <ArrowDropDownCircleOutlinedIcon/>,
    },
    {
        type: "checkboxList",
        icon: <CheckBoxOutlinedIcon/>,
    },
    {
        type: "attachments",
        icon: <AttachFileOutlinedIcon/>,
    }
];

const EditableFormPage = React.forwardRef(({
    pageTemplate,
    languagePack,
    language,
    workspace,
    pageIndex,
    pagesCount,
    onTemplateChange,
    onTextChange,
    onTextDelete,
    onBlockDelete,
    onBlockDuplicate,
    onDelete,
                                               onBlockAdd
}, ref) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const moreButtonRef = useRef();
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [blockMenuAnchorEl, setBlockMenuAnchorEl] = useState();
    const [selectedBlockIndex, setSelectedBlockIndex] = useState();

    const handleDelete = () => {
        setContextMenuOpen(false);
        onDelete(pageIndex);
    };

    const addBlockSeparator = (index) => (
        <ContentSeparator>
            <Button
                variant="text"
                size="small"
                color="primary"
                startIcon={<AddIcon />}
                onClick={(e) => handleAddBlockClick(e, index)}
            >
                {t("addNewBlock")}
            </Button>
        </ContentSeparator>
    );

    const handleAddBlockClick = (event, index) => {
        setSelectedBlockIndex(index);
        setBlockMenuAnchorEl(event.currentTarget);
    }

    const handleAddComponent = (type) => {
        console.log("adding block of type %o at index %o", type, selectedBlockIndex);
        onBlockAdd(type, selectedBlockIndex);
        setBlockMenuAnchorEl(null);
    }

    return (
        <FormPage className={classes.formPage} classes={{ paper: classes.pagePaper }} workspace={workspace}>
            <Droppable droppableId={`page-${pageTemplate.key}#${pageIndex}`}>
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        <>
                            {/*{pageTemplate.blocks.length === 0 && <div className={classes.blockPlaceholder} />}*/}
                            {pageTemplate.blocks.length === 0 && addBlockSeparator(0)}
                            {pageTemplate.blocks.map((block, blockIndex) => (
                                <React.Fragment key={getFormBlockId(block)}>
                                    <Draggable
                                        draggableId={`block-${block.key}`}
                                        index={blockIndex}
                                    >
                                        {(provided, snapshot) => (
                                            <EditableFormBlockContainer
                                                ref={provided.innerRef}
                                                blockType={block.type}
                                                isDragging={snapshot.isDragging}
                                                onDuplicate={() => onBlockDuplicate(pageIndex, blockIndex)}
                                                onDelete={() => onBlockDelete(pageIndex, blockIndex)}
                                                onAddBlockBeforeClick={(e) => handleAddBlockClick(e, blockIndex)}
                                                onAddBlockAfterClick={(e) => handleAddBlockClick(e, blockIndex + 1)}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <MemoizedEditableFormBlock
                                                    blockTemplate={block}
                                                    languagePack={languagePack}
                                                    language={language}
                                                    pageIndex={pageIndex}
                                                    blockIndex={blockIndex}
                                                    onTextChange={onTextChange}
                                                    onTemplateChange={onTemplateChange}
                                                    onTextDelete={onTextDelete}
                                                />
                                            </EditableFormBlockContainer>
                                        )}
                                    </Draggable>
                                </React.Fragment>
                            ))}
                            {provided.placeholder}
                        </>
                    </div>
                )}
            </Droppable>
            <FormPageActions
                editable
                template={pageTemplate.actions}
                languagePack={languagePack}
                language={language}
                pageIndex={pageIndex}
                pagesCount={pagesCount}
                onTextChange={onTextChange}
            />
            <Tooltip title={t("more")}>
                <IconButton
                    //className={clsx(classes.moreButton, { [classes.visible]: contextMenuOpen })}
                    className={classes.moreButton}
                    ref={moreButtonRef}
                    size="small"
                    edge="end"
                    onClick={() => setContextMenuOpen(true)}
                >
                    <MoreHorizOutlinedIcon />
                </IconButton>
            </Tooltip>
            <StyledMenu
                keepMounted
                anchorEl={moreButtonRef.current}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={contextMenuOpen}
                onClose={() => setContextMenuOpen(false)}
            >
                <StyledMenuItem label={t("delete")} Icon={DeleteOutlineOutlinedIcon} onClick={handleDelete} />
            </StyledMenu>
            <Menu
                anchorEl={blockMenuAnchorEl}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                getContentAnchorEl={null}
                keepMounted
                transformOrigin={{vertical: "top", horizontal: "center" }}
                open={Boolean(blockMenuAnchorEl)}
                onClose={() => setBlockMenuAnchorEl(null)}
            >
                {components.map((component) => (
                    <MenuItem
                        key={`menu-item-${component.type}`}
                        className={classes.menuItem}
                        onClick={() => handleAddComponent(component.type)}
                    >
                        <ListItemIcon>{component.icon}</ListItemIcon>
                        <ListItemText primary={t(component.type)} primaryTypographyProps={{variant: "body2"}}/>
                    </MenuItem>
                ))}
            </Menu>
        </FormPage>
    );
});

EditableFormPage.propTypes = {
    pageTemplate: PropTypes.object.isRequired,
    languagePack: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    workspace: PropTypes.object.isRequired,
    pageIndex: PropTypes.number.isRequired,
    pagesCount: PropTypes.number.isRequired,
    onTemplateChange: PropTypes.func,
    onTextChange: PropTypes.func,
    onTextDelete: PropTypes.func,
    onBlockDelete: PropTypes.func,
    onBlockDuplicate: PropTypes.func,
    onDelete: PropTypes.func,
};

EditableFormPage.defaultProps = {
    classes: {},
    onTemplateChange: () => {},
    onTextChange: () => {},
    onTextDelete: () => {},
    onBlockDelete: () => {},
    onBlockDuplicate: () => {},
    onDelete: () => {},
};

export default EditableFormPage;
