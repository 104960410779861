import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles, CardHeader, Grid, TextField, CardActionArea, Container
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        //height: "100%",
        display: "flex",
        alignItems: "center",
        maxWidth: theme.spacing(78),
        [theme.breakpoints.only('xs')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3)
        }
    },
}));

const DialogContainer = ({ className, children }) => {
    const classes = useStyles();

    return (
        <Container className={clsx(classes.dialogContainer, className)}>
            {children}
        </Container>
    );
};

DialogContainer.propTypes = {
};

DialogContainer.defaultProps = {
};

export default DialogContainer;
