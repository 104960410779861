import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
    Box, LinearProgress,
    makeStyles,
} from '@material-ui/core';
import PropTypes from "prop-types";
import {isAudio, isImage} from "../../utils/fileUtils";
import {Skeleton} from "@material-ui/lab";
import AttachmentPreview from "./AttachmentPreview";
import {downloadFile} from "../../utils/storageUtils";
import {ThumbnailMaxWidth, ThumbnailHeight} from "../../constants";
import clsx from "clsx";
import DescriptionIcon from "@material-ui/icons/Description";
import {ApiContext} from "../../contexts/ApiContext";

const useStyles = makeStyles((theme) => ({
    imageAttachment: {
        maxWidth: theme.spacing(40),
        height: theme.spacing(8),
    },
    localAttachmentUploading: {
        width: theme.spacing(20),
        height: theme.spacing(8),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 8,
        borderWidth: "thin",
        borderStyle: "solid",
        borderColor: theme.palette.divider,
        padding: theme.spacing(1),
        paddingRight: theme.spacing()
    },
    icon: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        color: theme.palette.grey[400],
        marginRight: theme.spacing(1)
    },
    progress: {
        height: theme.spacing(0.75),
        flex: "1 1 auto",
        borderRadius: 6,
        backgroundColor: theme.palette.grey[300]
    },
    barColor : {
        backgroundColor: theme.palette.grey[500]
    }
}));

// TODO: Rename
const LocalAttachment = ({className, language, file, onUploaded, onDeleted}) => {
    const classes = useStyles();
    const api = useContext(ApiContext);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [blobUrl, setBlobUrl] = useState();
    const [uploaded, setUploaded] = useState();
    const s3KeyRef = useRef();

    useEffect(() => {
        setBlobUrl(URL.createObjectURL(file));
        uploadAttachment();

        return () => {
            URL.revokeObjectURL(blobUrl);
            // TODO: Delete unused attachments, bellow is commented
            //  out because it deleted attachments event if message was successfully sent
            // if (s3KeyRef.current) {
            //     StorageApi.deleteAttachment(s3KeyRef.current);
            // }
        }
    }, [])

    const uploadAttachment = async () => {
        const key = await api.storage.uploadAttachment(file, setUploadProgress);
        s3KeyRef.current = key;
        setUploaded(true);
        onUploaded(key);
    }

    const handleDelete = () => {
        URL.revokeObjectURL(blobUrl);
        api.storage.deleteAttachment(s3KeyRef.current);
        s3KeyRef.current = null;    // set to null to prevent duplicit deletion in useEffect cleanup
        onDeleted();
    };

    return uploaded ? (
        <AttachmentPreview
            className={className}
            classes={{image: classes.imageAttachment}}
            name={file.name}
            url={blobUrl}
            language={language}
            canDelete
            onDelete={handleDelete}
        />
    ) : (
        <div className={clsx(classes.localAttachmentUploading, className)}>
            <DescriptionIcon className={classes.icon} />
            <LinearProgress
                className={classes.progress}
                value={uploadProgress}
                variant="determinate"
                classes={{
                    barColorPrimary: classes.barColor
                }}
            />
        </div>
    )
}

LocalAttachment.propTypes = {
    className: PropTypes.string,
    file: PropTypes.object.isRequired,
    onUploaded: PropTypes.func,
    onDeleted: PropTypes.func,
    language: PropTypes.string
}

LocalAttachment.defaultProps = {
    onUploaded: () => {},
    onDeleted: () => {}
};

export default LocalAttachment;