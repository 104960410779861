export const daysToMilliseconds = (days) => days * 24 * 60 * 60 * 1000;
export const millisecondsToDays = (milliseconds) => milliseconds / 1000 / 60 / 60 / 24;

export const newAwsDateTime = () => new Date(Date.now()).toISOString();

export const addDays = (date, days) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export const daysDiff = (date1, date2) => {
    const diff = date2.getTime() - date1.getTime();
    return Math.round(diff / (1000 * 3600 * 24));
}