import React, {useEffect, useState} from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    makeStyles, MenuItem,
    Select,
    Step,
    StepLabel,
    Stepper,
    TextField, Tooltip,
    Typography
} from "@material-ui/core"
import PropTypes from "prop-types";
import GeneralProfileSettings from "../ProfileButton/GeneralProfileSettings";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import WorkspaceInvitationPanel from "../WorkspaceInvitationPanel";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    invitationContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        '&>div:not(:last-child) ': {
            marginRight: theme.spacing(2)
        }
    },
    inviteButton: {
        color: theme.palette.text.secondary,
    },
    emailField: {
        flex: "1 1 auto"
    },
    select: {
        flex: "0 0 auto",
        minWidth: theme.spacing(13)
    }
}));


// TODO: Remame
const WorkspaceInvitationPage = ({className, invitations, onInvitationsChanged}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div id="invitations-page" className={clsx(classes.root, className)}>
            <Typography variant="h4" align="center" gutterBottom>
                {t("Invite people to your workspace")}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                {t("Your teammates will get an email that gives them access to your workspace")}
            </Typography>
            <WorkspaceInvitationPanel invitations={invitations} onInvitationsChanged={onInvitationsChanged}/>
        </div>
    )
}

WorkspaceInvitationPage.propTypes = {
    className: PropTypes.string,
    onInvitationsChanged: PropTypes.func
}

WorkspaceInvitationPage.defaultProps = {
    onInvitationsChanged: () => {}
};

export default WorkspaceInvitationPage;