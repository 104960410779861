import React, { useContext, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
    makeStyles,
    useTheme,
    Divider,
    Dialog,
    useMediaQuery,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Snackbar,
    ListItemAvatar,
    Avatar,
    Link, IconButton,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { LANGUAGES } from "../../constants/languages";
import { SpinnerButton } from "../../components/ConfirmationButton/SpinnerButton";
import { ApiContext } from "../../contexts/ApiContext";
import { deminifyLanguagePacks, hydrateLanguagePacks, minifyLanguagePacks } from "../../utils/formUtils";
import { Alert } from "@material-ui/lab";
import { Provider, useSelector } from "react-redux";
import { formTemplateNames, getFormTemplateIcon, loadDefaultForm } from "./formTemplates";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Loader from "../../components/Loader";
import {toFormKey, toId, toUserKey} from "../../utils/apiUtils";
import { WorkspaceKeyContext } from "../../contexts/WorkspaceKeyContext";
import { workspaceSelector } from "../../state/workspaces/selectors";
import { memberSelector } from "../../state/members/selectors";
import CloseIcon from "@material-ui/icons/Close";
import {s16} from "../../utils/idGenerator";

// TODO: Move dialog component styles to separate components
const useStyles = makeStyles((theme) => ({
    paper: {
        maxHeight: theme.spacing(60),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(75),
        },
        position: "relative",
    },
    loader: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: theme.zIndex.drawer,
    },
    footer: {
        backgroundColor: theme.palette.grey[100],
        padding: [theme.spacing(3), theme.spacing(4), theme.spacing(3), theme.spacing(4)],
        justifyContent: "flex-start",
    },
    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        backgroundColor: "red",
        marginRight: theme.spacing(2),
    },
    list: {
        padding: [theme.spacing(2), theme.spacing(0)],
    },
    listItem: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    header: {
        position: "relative",
        padding: [theme.spacing(3), theme.spacing(4), theme.spacing(3), theme.spacing(4)],
        '&>*:not(:last-child) ': {
            marginBottom: theme.spacing(2),
        }
    },
    closeButton: {
        position: "absolute",
        top: theme.spacing(1.75),
        right: theme.spacing(4),
    }
}));

const AddFormDialog = ({ open, formsCount, onClose, onConfirm }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const api = useContext(ApiContext);
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const workspaceKey = useContext(WorkspaceKeyContext);
    const workspace = useSelector(workspaceSelector(workspaceKey));
    const owner = useSelector(memberSelector(workspace?.pk, workspace ? toUserKey(workspace.owners[0]) : null));
    const [isBusy, setIsBusy] = useState(false);

    const handleDialogClose = (event, reason) => {
        // Don't close the dialog if it is waiting for a translation to complete
        if (!isBusy) {
            onClose();
        }
    };

    const handleTemplateItemClick = async (templateName) => {
        setIsBusy(true);

        const formKey = toFormKey(s16());
        const variables = {
            contactPersons: owner.name,
            workspaceId: toId(workspaceKey),
            workspaceName: workspace.name,
            phone: workspace.phone ?? "+420 239 050 505",
            address: workspace.address,
            formId: toId(formKey)
        };
        const { template, languagePacks, name, ...rest } = await loadDefaultForm(templateName, t, formsCount, variables);
        const form = await api.mutation.createForm(formKey, workspaceKey, name, template, languagePacks, rest);

        setIsBusy(false);
        onConfirm(form);
    };

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullScreen={fullScreen}
            open={open}
            onClose={handleDialogClose}
        >
            {isBusy && <Loader className={classes.loader} />}
            <div className={classes.header}>
                <Typography className={classes.title} variant="h6">
                    {t("addFormDialogTitle")}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {t("addFormDialogText")}
                </Typography>
                <IconButton
                    className={classes.closeButton}
                    aria-label="close-dialog"
                    edge="end"
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <Divider />
            <List className={classes.list}>
                {formTemplateNames.map((name) => (
                    <ListItem
                        key={name}
                        className={classes.listItem}
                        button
                        onClick={() => handleTemplateItemClick(name)}
                    >
                        <Avatar className={classes.avatar} variant="rounded">
                            {getFormTemplateIcon(name)}
                        </Avatar>
                        <ListItemText
                            primary={
                                <>
                                    {t(`${name}FormName`)} <ArrowForwardIcon fontSize="small" />
                                </>
                            }
                            secondary={t(`${name}FormDescription`)}
                            primaryTypographyProps={{ variant: "subtitle2" }}
                            secondaryTypographyProps={{ variant: "body2" }}
                        />
                    </ListItem>
                ))}
            </List>
            <DialogActions className={classes.footer}>
                <Button variant="text" size="small" color="primary" onClick={() => handleTemplateItemClick("blank")}>
                    <>
                        {t("createEmptyForm")} <ArrowForwardIcon fontSize="small" />
                    </>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddFormDialog.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    formsCount: PropTypes.number,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
};

AddFormDialog.defaultProps = {
    formsCount: 0,
    onClose: () => {},
    onConfirm: () => {},
};

export default AddFormDialog;
