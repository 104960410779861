import React, {useContext, useEffect, useState} from 'react';
import {
    alpha, AppBar,
    Avatar,
    Box, Button, ButtonBase, CircularProgress,
    Container, Divider, Drawer,
    Grid, Link,
    makeStyles, Typography,
    useTheme
} from '@material-ui/core';
import {useNavigate, useParams} from "react-router-dom";
import {ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {WorkspaceKeyContext} from "../../contexts/WorkspaceKeyContext";
import {workspaceSelector} from "../../state/workspaces/selectors";
import {ActiveLanguageContext} from "../../contexts/ActiveLanguageContext";
import Page from "../../components/Page/Page";
import {useTranslation} from "react-i18next";
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindowsOutlined';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import {
    BUTTON_BORDER_RADIUS,
    CardShadow,
    CardShadow2,
    PhoneNumbers,
    WHISPERO_PRIVACY_POLICY_URL
} from "../../constants";
import {formatPhoneNumber, formatWorkspaceId} from "../../utils/textFormatter";
import {toId} from "../../utils/apiUtils";
import clsx from "clsx";
import UnderlinedLink from "../../components/UnderlinedLink";
import DisclaimerDialog from "../../components/reportDialogs/DisclaimerDialog";
import SubmitDialog from "../../components/reportDialogs/SubmitDialog";
import ConfirmationDialog from "../../components/reportDialogs/ConfirmationDialog";
import {ApiContext} from "../../contexts/ApiContext";
import {newAttachmentsSection, newTextSection} from "../../utils/formBlockUtils";

const useStyles = makeStyles((theme) => ({
    formSubmitViewRoot: {
    },
    body: {
        height: '100%',
        overflow: "auto",
        margin: "auto",
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        //alignItems: "center",
        justifyContent: "center",
        padding: [theme.spacing(2), 0],
    },
    button: {
        marginTop: theme.spacing(4),
        borderRadius: BUTTON_BORDER_RADIUS,
        marginBottom: theme.spacing(2),
        '&:hover': {
            color: theme.palette.primary.contrastText
        }
    },
    legal: {
        color: theme.palette.text.hint
    },
    dialog: {
        ...CardShadow2,
        borderRadius: 16,
        border: `1px solid ${theme.palette.grey[50]}`
    }
}));

// TODO: Delete
const FormSubmitView = () => {
    const classes = useStyles();
    const api = useContext(ApiContext);
    const language = useContext(ActiveLanguageContext);
    const workspaceKey = useContext(WorkspaceKeyContext);
    const workspace = useSelector(workspaceSelector(workspaceKey));
    const submitData = workspace?.submitLayout ?? {};
    const [accessId, setAccessId] = useState();

    const handleOnSubmit = async ({text, formData, attachments}) => {
        const content = [];
        if (Boolean(text)) {
            content.push(newTextSection("", text));
        }
        if (Boolean(attachments) && attachments.length > 0) {
            content.push(newAttachmentsSection("", text));
        }
        const report = await api.mutation.createReport(workspace.pk, content, "cs");
        setAccessId(report.accessId);
    }

    const renderDialogs = () => {
        if (!accessId) {
            return (
                <SubmitDialog
                    classes={{dialog: classes.dialog}}
                    showLogo={false}
                    workspace={workspace}
                    language={language}
                    layout={submitData[language]?.submit}
                    onSubmit={handleOnSubmit}
                />
            )
        } else {
            return (
                <ConfirmationDialog
                    classes={{dialog: classes.dialog}}
                    showLogo={false}
                    accessId={accessId}
                    workspace={workspace}
                    language={language}
                    layout={submitData[language]?.confirmation}
                />
            )
        }
    };

    return (
        <Page
            title={workspace.name}
            className={classes.formSubmitViewRoot}
            disableScroll
        >
            <div className={classes.body}>
                {renderDialogs()}
            </div>
        </Page>
    );
};

export default FormSubmitView;