import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    makeStyles,
    useTheme,
    Divider,
    Dialog,
    useMediaQuery, DialogTitle
} from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import GeneralProfileSettings from "./GeneralProfileSettings";
import ChangePasswordPanel from "./ChangePasswordPanel";
import {ApiContext} from "../../contexts/ApiContext";
import StyledTabs from "../StyledTabs/StyledTabs";
import StyledTab from "../StyledTabs/StyledTab";
import TabPanel from "../StyledTabs/TabPanel";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    dialogTitle: {
        paddingBottom: theme.spacing(1)
    },
    tabs: {
        paddingLeft: theme.spacing(3)
    },
    dialogContent: {
        paddingBottom: 0
    },
    dialogActions: {
        padding: theme.spacing(3)
    },
    tabPanel: {
        paddingTop: theme.spacing(1.5)
    }
}));

function tabProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const ProfileSettingsDialog = ({className, open, onClose}) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    const [tabValue, setTabValue] = React.useState(0);

    return (
        <Dialog
            className={className}
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
            aria-labelledby="profile-dialog-title"
        >
            <DialogTitle id="profile-dialog-title" className={classes.dialogTitle}>
                {t("Profile Settings")}
            </DialogTitle>
            <StyledTabs
                className={classes.tabs}
                value={tabValue}
                onChange={setTabValue}
                aria-label="setting-categories"
            >
                <StyledTab label={t("General")} icon={<AccountBoxIcon />} {...tabProps(0)} />
                <StyledTab label={t("Change password")} icon={<VpnKeyIcon />} {...tabProps(1)}/>
            </StyledTabs>
            <Divider/>
            {/*<DialogContentText>*/}
            {/*    Let Google help apps determine location. This means sending anonymous location data to*/}
            {/*    Google, even when no apps are running.*/}
            {/*</DialogContentText>*/}
            <TabPanel className={classes.tabPanel} value={tabValue} index={0}>
                <GeneralProfileSettings onClose={onClose}/>
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={tabValue} index={1}>
                <ChangePasswordPanel onClose={onClose}/>
            </TabPanel>
        </Dialog>
    )
}

ProfileSettingsDialog.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func
}

ProfileSettingsDialog.defaultProps = {
    onClose: () => {}
};

export default ProfileSettingsDialog;