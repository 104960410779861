import React, {useContext, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    makeStyles,Button, Paper
} from '@material-ui/core';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.default
    },
    image: {
        width: "25%",
        maxWidth: theme.spacing(40)
    }
}));

const ChatPlaceholder = ({className}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Paper id="chat-placeholder" className={clsx(classes.root, className)}>
            <img className={classes.image} alt="Empty Inbox" src="/static/images/no_reports.jpg"/>
            <Box width="50%" pt={1}>
                <Typography align="center" paragraph>
                    {t("No report selected. Be sure to publish the report dialog on your website")}
                </Typography>
            </Box>
            {/*<Button variant="contained" color="primary">*/}
            {/*    {t("Publish")}*/}
            {/*</Button>*/}
        </Paper>
    );
}

ChatPlaceholder.propTypes = {
    className: PropTypes.string
}

export default ChatPlaceholder;