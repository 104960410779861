import * as types from "./actionTypes";

const initialState = [];

export default function workspacesReducer(state = initialState, action) {
    switch (action.type) {
        case types.WORKSPACES_LOADED: {
            return [...state, ...action.payload];
        }
        case types.WORKSPACE_ADDED: {
            return [...state, action.payload];
        }
        case types.WORKSPACE_UPDATED: {
            return state.map(t => {
                if (t.pk !== action.payload.pk) {
                    return t;
                }

                return action.payload;
            });
        }
        case types.WORKSPACE_DELETED: {
            return state.filter(t => t.pk !== action.payload.pk);
        }
        default:
            return state
    }
}
