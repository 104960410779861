import React, {useContext, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    makeStyles,Button, Paper
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    attachmentsContainer: {
        maxWidth: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        flexWrap: "wrap",
        '&>*': {
            margin: [theme.spacing(0.5), theme.spacing(1), theme.spacing(0.5), 0],
        },
    },
    attachmentsContainerRtl:{
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "flex-start",
        flexWrap: "wrap",
        '&>*': {
            margin: [theme.spacing(0.5), 0, theme.spacing(0.5), theme.spacing(1)],
        },
    }
}));

const AttachmentsContainer = ({className, direction, children}) => {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.attachmentsContainer, className, {
                [classes.attachmentsContainer]: !direction || direction === "leftToRight",
                [classes.attachmentsContainerRtl]: direction === "rightToLeft"
            })}
        >
            {children}
        </div>
    );
}

AttachmentsContainer.propTypes = {
    className: PropTypes.string,
    direction: PropTypes.oneOf(["leftToRight", "rightToLeft"])
}

AttachmentsContainer.defaultProps = {
    direction: "leftToRight"
};

export default AttachmentsContainer;