import React, {useContext} from 'react';
import {
    Box,
    Container, Divider,
    Grid,
    makeStyles, Typography,
    useTheme
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import UserAvatar from "../../components/UserAvatar";
import {toId} from "../../utils/apiUtils";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {WorkspaceAvatarBorderRadius} from "../../constants";
import {LargePictureSuffix, SmallPictureSuffix, ThumbnailPictureSuffix} from "../../constants/storage";

const useStyles = makeStyles((theme) => ({
    workspaceTile: {
        width: theme.spacing(14),
        minWidth: theme.spacing(14),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // padding: [theme.spacing(3), 0]
        '&:hover': {
            backgroundColor: theme.palette.grey[100]
        },
        borderRadius: 6,
        padding: theme.spacing(2),
    },
    link: {
        textDecoration: "none!important"
    },
    avatarContainer: {
        // padding: theme.spacing(0.75),
        borderRadius: 4,
        border: "2px solid transparent",
        // '&:hover': {
        //     borderColor: theme.palette.grey[300]
        // }
    },
    avatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        borderRadius: "12px"
    },
    tileHeader: {
        //maxHeight: `${theme.typography.body1.lineHeight * 2}em`,
        //marginTop: theme.spacing(1),
        width: "100%",
        height: theme.spacing(5),
        marginTop: theme.spacing(1),
        padding: [0, theme.spacing(1)],
        textAlign: "center",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
}));

const WorkspaceTile = ({workspace, ...rest}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const href = `/portal/${toId(workspace.pk)}/overview`;
    const imageKey = workspace.picture?.replace(SmallPictureSuffix, LargePictureSuffix);

    return (
        <Link className={classes.link} to={href}>
            <div className={classes.workspaceTile} {...rest}>
                    <div className={classes.avatarContainer}>
                        <UserAvatar
                            className={classes.avatar}
                            name={workspace.name}
                            imageKey={imageKey}
                            component="span"
                            size="large"
                            variant="rounded"
                        />
                    </div>
                    <Typography className={classes.tileHeader} variant="body2" color="textPrimary" align="center">
                        {workspace.name}
                    </Typography>
            </div>
        </Link>
    );
};

export default WorkspaceTile;