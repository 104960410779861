import React from 'react';
import clsx from 'clsx';
import {
    Dialog,
    makeStyles, Tab, Tabs,
} from '@material-ui/core';
import PropTypes from "prop-types";
import ProfileSettingsDialog from "../ProfileSettingsDialog";
import DialogMenu from "../../views/PortalSettings/DialogsSettings/DialogMenu";

const useStyles = makeStyles((theme) => ({
    tabs: {
        minHeight: 0
    },
}));

const StyledTabs = ({ className, children, value, onChange, ...other }) => {
    const classes = useStyles();

    return (
        <Tabs
            className={clsx(classes.tabs, className)}
            value={value}
            onChange={(_, newValue) => onChange(newValue)}
            indicatorColor="primary"
            textColor="primary"
            {...other}
        >
            {children}
        </Tabs>
    );
}

export default StyledTabs;

StyledTabs.propTypes = {
    className: PropTypes.string,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func
}

StyledTabs.defaultProps = {
    onChange: () => {}
};