import gql from "graphql-tag";
import * as mutations from "../graphql/mutations";
import { getReportExcerpt, systemMessage } from "../utils/reportUtils";
import { newAwsDateTime } from "../utils/time";
import * as id from "../utils/idGenerator";
import * as customQueries from "../graphql/customQueries";
import * as queries from "../graphql/queries";
import {
    toAdminsGroup,
    toCommentKey,
    toFormKey,
    toId,
    toMessageKey,
    toReportKey,
    toWorkspaceKey,
} from "../utils/apiUtils";
import { newUuid, s16 } from "../utils/idGenerator";

const RetryCount = 3;

export default class MutationApi {
    constructor(appSyncClient) {
        this.client = appSyncClient;
    }

    // TODO: Rename table and method
    async updateResourceAccessKey(id, data) {
        const result = await this.client.mutate({
            mutation: gql(mutations.updateResourceAccessKey),
            variables: { input: { id: id, ...data } },
        });

        return result.data.updateResourceAccessKey;
    }

    async createResource(pk, sk, data) {
        const result = await this.client.mutate({
            mutation: gql(mutations.createResource),
            variables: { input: { pk: pk, sk: sk, ...data } },
        });

        return result.data.createResource;
    }

    async updateResource(pk, sk, data) {
        const result = await this.client.mutate({
            mutation: gql(mutations.updateResource),
            variables: { input: { pk: pk, sk: sk, ...data } },
        });

        return result.data.updateResource;
    }

    async deleteResource(pk, sk) {
        const result = await this.client.mutate({
            mutation: gql(mutations.deleteResource),
            variables: { input: { pk: pk, sk: sk } },
        });

        return result.data.deleteResource;
    }

    // TODO: Check groups??
    async createForm(formKey, workspaceKey, name, template, languagePacks, fields = {}) {
        const data = {
            name: name,
            template: JSON.stringify(template),
            languagePacks: JSON.stringify(languagePacks),
            workspace: workspaceKey,
            subscription: workspaceKey,
            group: toId(workspaceKey),
            readOnlyGroup: toAdminsGroup(toId(workspaceKey)),
            ...fields,
        };
        const pk = formKey ?? toFormKey(s16());
        return await this.createResource(pk, workspaceKey, data);
    }

    async updateForm(pk, sk, template = undefined, languagePacks = undefined, settings = {}) {
        const params = {
            ...settings,
        };
        if (Boolean(template)) {
            params.template = JSON.stringify(template);
        }
        if (Boolean(languagePacks)) {
            params.languagePacks = JSON.stringify(languagePacks);
        }

        return await this.updateResource(pk, sk, params);
    }

    // TODO: Check why so many workspaceID begins with 3
    async createWorkspace(workspaceName, workspacePlan, ownerName, ownerPicture) {
        //for (let i = 0; i < RetryCount; i += 1) {
        // TODO: Move id generation to lambda resolver
        const workspaceId = id.n9();
        const pk = toWorkspaceKey(workspaceId);

        try {
            const result = await this.client.mutate({
                mutation: gql(mutations.createWorkspace),
                variables: {
                    pk: pk,
                    name: workspaceName,
                    plan: workspacePlan,
                    userName: ownerName,
                    userPicture: ownerPicture,
                },
            });

            return result.data.createWorkspace;
        } catch {
            console.error("Failed to create workspace with key: %o", pk);
        }
        //}

        //throw new Error(`Failed to create workspace ${workspaceName} for ${RetryCount} times. All generated IDs were already used."`);
    }

    async updateWorkspace(key, data) {
        return await this.updateResource(key, key, data);
    }

    async createReport(
        workspaceKey,
        content,
        language,
        assignTo = undefined,
        tags = undefined,
        termLength = undefined
    ) {
        const input = {
            workspaceKey: workspaceKey,
            language: language,
            content: JSON.stringify(content),
            termLength: termLength ?? 30,
            tags: tags,
        };
        if (assignTo) {
            input.owners = assignTo;
        }
        const result = await this.client.mutate({
            mutation: gql(mutations.createReport),
            variables: { input: input },
        });

        return result.data.createReport;
    }

    async addReportAssignee(report, assignedUserId, currentUserKey) {
        const ownersNew = report.owners ? [...report.owners, assignedUserId] : [assignedUserId];
        const data = {
            owners: ownersNew,
            signature: currentUserKey,
        };
        return await this.updateResource(report.pk, report.sk, data);
    }

    async removeReportAssignee(report, assignedUserId, currentUserKey) {
        const ownersNew = report.owners.filter((item) => item !== assignedUserId);
        const data = {
            owners: ownersNew.length > 0 ? ownersNew : null, //  null because we want to reset owners field
            signature: currentUserKey,
        };
        return await this.updateResource(report.pk, report.sk, data);
    }

    // TODO: Delete
    async updateReportDescription(reportKey, workspaceKey, description) {
        const serialized = typeof description === "object" ? JSON.stringify(description) : description;
        return await this.updateResource(reportKey, workspaceKey, { description: serialized });
    }

    async updateReportSolution(reportKey, workspaceKey, solution) {
        const serialized = typeof solution === "object" ? JSON.stringify(solution) : solution;
        return await this.updateResource(reportKey, workspaceKey, { solution: serialized });
    }

    async updateReportState(reportKey, workspaceKey, currentUserKey, newState, additionalData = {}) {
        const data = {
            state: newState,
            stateUpdatedAt: newAwsDateTime(), // TODO: Check if stateUpdatedAt is neccessary
            signature: currentUserKey,
            ...additionalData,
        };
        return await this.updateResource(reportKey, workspaceKey, data);
    }

    async createComment(workspaceKey, reportKey, sender, content = undefined, attachments = undefined) {
        const comment = {
            content: JSON.stringify(content),
            attachments: attachments,
            sender: sender,
            subscription: workspaceKey,
            readOnlyGroup: toId(workspaceKey),
        };
        const sk = toCommentKey(newUuid());
        return await this.createResource(reportKey, sk, comment);
    }

    // TODO: Use batch resolver
    // TODO: Rearrange args
    async createMessage(workspaceKey, reportKey, text, currentUserKey, attachments = null) {
        const message = {
            text: text,
            attachments: attachments,
            sender: currentUserKey,
            subscription: workspaceKey,
            readOnlyGroup: toId(workspaceKey),
        };
        const sk = toMessageKey(newUuid());
        const messageMutation = this.createResource(reportKey, sk, message);

        const report = {
            excerpt: getReportExcerpt(text, attachments),
            excerptUpdatedAt: new Date(Date.now()).toISOString(),
            sender: currentUserKey,
            signature: currentUserKey,
        };
        const _ = await this.updateResource(reportKey, workspaceKey, report);

        return await messageMutation;
    }

    // TODO: Rearrange args
    async createGuestMessage(workspaceKey, accessId, reportKey, text, attachments) {
        const input = {
            workspaceKey: workspaceKey,
            accessId: accessId,
            reportKey: reportKey,
            text: text,
            attachments: attachments,
        };
        const result = await this.client.mutate({
            mutation: gql(mutations.createGuestMessage),
            variables: { input: input },
        });

        return result.data.createGuestMessage;
    }

    async createSystemMessage(reportKey, workspaceKey, sender, data) {
        const message = {
            ...data,
            text: systemMessage,
            sender: sender,
            subscription: workspaceKey,
            readOnlyGroup: toId(workspaceKey),
        };
        const sk = toMessageKey(newUuid());
        return await this.createResource(reportKey, sk, message);
    }

    // TODO: Merge with updateReportSolution
    async sendReportSolution(reportKey, workspaceKey, solution) {
        const serialized = typeof solution === "object" ? JSON.stringify(solution) : solution;
        await this.createSystemMessage(reportKey, workspaceKey, "user#bot", { solution: serialized });
    }

    async acceptInvitation(invitationId, userName, userPicture) {
        const result = await this.client.mutate({
            mutation: gql(mutations.acceptInvitation),
            variables: { id: invitationId, name: userName, picture: userPicture },
        });

        return result.data.acceptInvitation;
    }

    async updateMember(userKey, workspaceKey, data) {
        return await this.updateResource(userKey, workspaceKey, data);
    }

    // TODO: Create lambda resolver
    async deleteMember(userKey, workspaceKey, reports) {
        const userId = toId(userKey);
        for (const report of reports) {
            if (report.owners?.includes(userId)) {
                await this.removeReportAssignee(report, userKey);
            }
        }

        const result = await this.client.mutate({
            mutation: gql(mutations.deleteWorkspaceMember),
            variables: { workspaceKey: workspaceKey, userKey: userKey },
        });
        return result.data.deleteWorkspaceMember;
    }

    // TODO: Rename
    async updateUser(userKey, workspaceKeys, data) {
        // Update memberships
        const memberData = {};
        if (data.name !== undefined) {
            memberData.name = data.name;
        }
        if (data.picture !== undefined) {
            memberData.picture = data.picture;
        }
        if (Object.keys(memberData).length > 0) {
            console.log("updateUser, updating membership, memberData: %o", memberData);
            for (const workspaceKey of workspaceKeys) {
                await this.updateResource(userKey, workspaceKey, memberData);
            }
        }

        // Update user settings
        return await this.updateResource(userKey, userKey, data);
    }

    async createInvitation(email, workspaceKey, role, senderName, workspaceName, locale = "en") {
        const input = {
            email: email.trim(),
            workspace: workspaceKey,
            role,
            senderName,
            workspaceName,
            locale,
            group: toAdminsGroup(toId(workspaceKey)),
        };
        const result = await this.client.mutate({
            mutation: gql(mutations.createInvitation),
            variables: { input: input },
        });

        return result.data.createInvitation;
    }

    async deleteInvitation(id) {
        const result = await this.client.mutate({
            mutation: gql(mutations.deleteInvitation),
            variables: { input: { id } },
        });

        return result.data.deleteInvitation;
    }

    async updateLastRead(workspaceKey, reportKey) {
        const result = await this.client.mutate({
            mutation: gql(mutations.updateLastRead),
            variables: { workspaceKey: workspaceKey, reportKey: reportKey },
        });

        return result.data.updateLastRead;
    }

    async createResourceAccessCredentials(workspaceKey, resourceKey, accessCode) {
        const input = {
            resourceKey: resourceKey,
            accessCode: accessCode,
            group: toAdminsGroup(toId(workspaceKey)),
        };
        const result = await this.client.mutate({
            mutation: gql(mutations.createResourceAccessCredentials),
            variables: { input: input },
        });
        return result.data.createResourceAccessCredentials;
    }

    async updateResourceAccessCredentials(resourceKey, accessCode) {
        const input = {
            resourceKey: resourceKey,
            accessCode: accessCode
        };
        const result = await this.client.mutate({
            mutation: gql(mutations.updateResourceAccessCredentials),
            variables: { input: input },
        });
        return result.data.updateResourceAccessCredentials;
    }

    async deleteResourceAccessCredentials(resourceKey) {
        const input = {
            resourceKey: resourceKey
        };
        const result = await this.client.mutate({
            mutation: gql(mutations.deleteResourceAccessCredentials),
            variables: { input: input },
        });
        return result.data.deleteResourceAccessCredentials;
    }
}
