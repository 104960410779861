import React from 'react';
import PropTypes from 'prop-types';
import {
    List,
} from '@material-ui/core';
import DialogMenuItem from "./DialogMenuItem";
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import {useTranslation} from "react-i18next";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

const items = [
    {
        icon: DescriptionOutlinedIcon,
        title: 'Disclaimer dialog'
    },
    {
        icon: PostAddOutlinedIcon,
        title: 'Submit dialog'
    },
    {
        icon: ConfirmationNumberOutlinedIcon,
        title: 'Confirmation dialog'
    }
];

const DialogMenu = ({className, selectedIndex, onSelectedChanged}) => {
    const { t } = useTranslation();

    return (
        <List className={className}>
            {items.map((item, index) => (
                <DialogMenuItem
                    key={item.title}
                    selected={selectedIndex === index}
                    title={t(item.title)}
                    icon={item.icon}
                    onClick={() => onSelectedChanged(index)}
                />
            ))}
        </List>
    );
}

DialogMenu.propTypes = {
    className: PropTypes.string,
};

DialogMenu.defaultProps = {
};

export default DialogMenu