import React, {useEffect, useMemo, useState} from "react";
import {ApiContext} from "../contexts/ApiContext";
import GuestAppSyncApi from "../api/GuestAppSyncApi";
import {Routes} from "react-router-dom";

const GuestRoutes = ({children, ...rest}) => {
    console.log("GuestAccess render");

    const api = useMemo(() => {
        return new GuestAppSyncApi();
    }, []);

    return  (
        <ApiContext.Provider value={api}>
            <Routes {...rest}>
                {children}
            </Routes>
        </ApiContext.Provider>
    )
}

export default GuestRoutes;