import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    staticDialogActions: {
        flex: "0 0 auto",
        padding: [theme.spacing(2), theme.spacing(2), theme.spacing(2), theme.spacing(2)],
        display: "flex",
        alignItems: "center",
        '&>button:not(:last-child) ': {
            marginRight: theme.spacing(3),
        }
    }
}));

const StaticDialogActions = ({ className, children }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.staticDialogActions, className)}>
            {children}
        </div>
    );
};

StaticDialogActions.propTypes = {
    className: PropTypes.string
};

StaticDialogActions.defaultProps = {
};

export default StaticDialogActions;
