import React, {useContext, useState, useEffect, useRef} from 'react';
import {ApiContext} from "../contexts/ApiContext";
import {ReactReduxContext, useDispatch} from "react-redux";
import {addMember} from "../state/members/actions";
import {toUserKey} from "../utils/apiUtils";

// TODO: Delete, replace with useFetchUsers
// TODO: Extract missing user function to utils
export const useFetchMissingSenders = (workspaceKey, messages) => {
    const api = useContext(ApiContext);
    const dispatch = useDispatch();
    const { store } = useContext(ReactReduxContext);
    const messagesLength = messages?.length ?? 0;
    const messageKey = messagesLength > 0 ? messages[0].pk : "";

    const fetchMissingUsers = async () => {
        const senders = messages.filter(msg => Boolean(msg.sender)).map(msg => msg.sender);
        const uniqueUsers = [...new Set(senders)];
        const existingUsers = store.getState().members[workspaceKey];
        const missingUsers = existingUsers
            ? uniqueUsers.flatMap(key =>!existingUsers.find(user => user.pk === key)  ? [key] : [])
            : uniqueUsers;
        if (missingUsers.length === 0) {
            return;
        }

        for (const key of missingUsers) {
            console.log("fetchMissingMember: %o", key);
            const user = await api.query.getUserProfile(key);
            dispatch(addMember(workspaceKey, user));
        }
    };

    useEffect(() => {
        if (!messages || messages.length === 0) {
            return;
        }

        fetchMissingUsers();
    }, [workspaceKey, messagesLength, messageKey]);
}