import {hydrateLanguagePacks, loadFormLanguagePacks, loadFormTemplate} from "../../utils/formUtils";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import React from "react";

export const formTemplateNames = ["whistleblowing"];

export function getFormTemplateIcon(templateName) {
    switch (templateName){
        case "whistleblowing": {
            return <HomeOutlinedIcon/>
        }
        default: {
            return <></>
        }
    }
}

export async function loadDefaultForm(templateName = "blank", t, formsCount, variables) {
    const template = await loadFormTemplate(templateName);
    // const csLanguagePack = await loadFormLanguagePack("whistleblowing", "cs");
    const languagePacks = await loadFormLanguagePacks(templateName, ["cs", "en"]);
    const hydratedLanguagePacks = hydrateLanguagePacks(languagePacks, variables)
    const form = {template: template, languagePacks: hydratedLanguagePacks};

    switch (templateName){
        case "whistleblowing": {
            form.name = t("whistleblowingFormName");
            form.termLength = 30;
            break;
        }
        default: {
            form.name = t("blankFormName", {index: formsCount + 1});
            break;
        }
    }

    return form;
}