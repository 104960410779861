import {cyan, green, grey, indigo, lightBlue, lime, purple, red} from "@material-ui/core/colors";

export const FONT_SIZES = {
    xSmall: '0.775rem',
    small: '0.875rem',
    medium: '1.1rem', // 1.25rem
    large: '1.25rem'
}

// TODO: Replace
export const AVATAR_SIZES = {
    xSmall: 3.5,
    small: 4,
    medium: 4.5,
    large: 5
}

const baseColors = ([
    grey[400],
    purple[400],
    indigo[700], // 700
    lightBlue[700], // 900
    cyan[800],
    green[800],
    lime[900],
    red[700],
])

export const getInitials = (name = '') => {
    if (!Boolean(name)) {
        return "";
    }

    const words = name
        .replace(/\s+/, ' ')
        .split(' ')
        .filter(word => word.length > 0);

    if (words.length > 1) {
        return words.slice(0, 2).map((word) => word[0].toUpperCase()).join('');
    } else {
        return words[0].slice(0, Math.min(2, words[0].length));
    }
}

export const getAvatarColor = (name) => {
    if (!Boolean(name)) {
        return baseColors[0];
    }

    const pos = name ? Math.min(Math.max(Math.floor((name.toLowerCase().charCodeAt(0) - 97) / 3.25), 0), 7) : 0;
    return baseColors[pos];
}

