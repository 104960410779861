import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { format as formatDate, isDate, formatRelative, formatDistance } from "date-fns";
import { cs } from "date-fns/locale";
import translationEn from "./locales/en/translation.json";
import dialogEn from "./locales/en/dialogs.json";
import translationCs from "./locales/cs/translation.json";
import dialogCs from "./locales/cs/dialogs.json";
import translationDe from "./locales/de/translation.json";
import translationSk from "./locales/sk/translation.json";
import translationPl from "./locales/pl/translation.json";
import translationHu from "./locales/hu/translation.json";
import translationFr from "./locales/fr/translation.json";
import translationEs from "./locales/es/translation.json";
import translationPt from "./locales/pt/translation.json";
import translationIt from "./locales/it/translation.json";
import translationNl from "./locales/nl/translation.json";
import translationDa from "./locales/da/translation.json";
import translationNo from "./locales/no/translation.json";
import translationFi from "./locales/fi/translation.json";
import translationSv from "./locales/sv/translation.json";
import translationRo from "./locales/ro/translation.json";
import translationSl from "./locales/sl/translation.json";
import translationHr from "./locales/hr/translation.json";
import translationLv from "./locales/lv/translation.json";
import translationEt from "./locales/et/translation.json";
import translationRu from "./locales/ru/translation.json";
import translationUk from "./locales/uk/translation.json";
import translationBg from "./locales/bg/translation.json";
import translationEl from "./locales/el/translation.json";

import { I18n } from "aws-amplify";
import { authScreenCs } from "./locales/cs/authScreen";

const locales = { en: undefined, cs };

const DETECTION_OPTIONS = {
    order: ["localStorage", "navigator"],
    caches: ["localStorage"],
};

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: translationEn,
        dialogs: dialogEn,
    },
    cs: {
        translation: translationCs,
        dialogs: dialogCs,
    },
    de: { translation: translationDe },
    sk: { translation: translationSk },
    pl: { translation: translationPl },
    hu: { translation: translationHu },
    fr: { translation: translationFr },
    es: { translation: translationEs },
    pt: { translation: translationPt },
    it: { translation: translationIt },
    nl: { translation: translationNl },
    da: { translation: translationDa },
    no: { translation: translationNo },
    fi: { translation: translationFi },
    sv: { translation: translationSv },
    ro: { translation: translationRo },
    sl: { translation: translationSl },
    hr: { translation: translationHr },
    lv: { translation: translationLv },
    et: { translation: translationEt },
    ru: { translation: translationRu },
    uk: { translation: translationUk },
    bg: { translation: translationBg },
    el: { translation: translationEl }
};

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        detection: DETECTION_OPTIONS,
        fallbackLng: "en",
        debug: true,
        resources,
        interpolation: {
            escapeValue: false, // react already saves from xss
            formatSeparator: ",",
            format: (value, format, lng) => {
                if (isDate(value)) {
                    const locale = locales[lng];

                    if (format === "short") return formatDate(value, "PPP", { locale });
                    if (format === "long") return formatDate(value, "PPPpp", { locale });
                    if (format === "relative") return formatRelative(value, new Date(), { locale });
                    if (format === "ago")
                        return formatDistance(value, new Date(), {
                            locale,
                            addSuffix: true,
                        });

                    return formatDate(value, format, { locale });
                }

                return value;
            },
        },
        // ns: ["translations"],
        // defaultNS: "translations"
    });

const authScreenResources = {
    cs: authScreenCs,
};

// Amplify uses a different i18n instance
I18n.putVocabularies(authScreenResources);
I18n.setLanguage(i18n.language);

export default i18n;
