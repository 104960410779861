import React, {useContext, useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles, CardHeader, Grid, TextField, CardActionArea, Container
} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import {ApiContext} from "../../contexts/ApiContext";
import {useNavigate} from "react-router-dom";
import Page from "../../components/Page/Page";
import {addOrUpdateWorkspace} from "../../state/workspaces/actions";
import {useDispatch} from "react-redux";
import {toWorkspaceKey} from "../../utils/apiUtils";
import {formatWorkspaceId, normalizeId} from "../../utils/textFormatter";
import {useTranslation} from "react-i18next";

const user = {
    avatar: '/static/images/avatars/avatar_6.png',
    city: 'Los Angeles',
    country: 'USA',
    jobTitle: 'Senior Developer',
    name: 'Katarina Smith',
    timezone: 'GTM-7'
};

// TODO: Move styles to MainLayout component
const useStyles = makeStyles((theme) => ({
    loginPage: {
        display: "flex",
        alignItems: "center"
    },
    cardRoot: {
        padding: [theme.spacing(1), theme.spacing(3)]
    },
    avatar: {
        height: 100,
        width: 100
    },
    headerIcon: {
        maxWidth: theme.spacing(6),
        marginBottom: theme.spacing(2)
    },
    header: {
        flexDirection: "column",
        justifyContent: "center"
    },
    actionButton: {
    }
}));

const noErrorText = " ";

// TODO: rename
const CompanyLoginView = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const api = useContext(ApiContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [id, setId] = useState('');
    const [errorText, setErrorText] = useState(noErrorText);

    const handleSubmit = async (event) => {
        event.persist()
        event.preventDefault();
        if (!Boolean(id)) {
            return;
        }

        const key = toWorkspaceKey(id);
        try {
            const workspace = await api.query.getWorkspace(key);
            if (!workspace) {
                setErrorText(t("companyNotFound"));
            } else {
                setErrorText("");
                dispatch(addOrUpdateWorkspace(workspace));
                navigate(`/submit/${id}`);
            }
        } catch (err) {
            setErrorText(`${err}`);
        }
    };

    const handleTextChange = (event) => {
        setId(normalizeId(event.target.value));
        if (errorText.length != 1) {
            setErrorText(noErrorText);
        }
    }

    return (
        <Page title="Submit a report" className={classes.loginPage}>
            <Container maxWidth="xs">
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <Card className={classes.cardRoot}>
                        <CardHeader
                            className={classes.header}
                            title={t("submitReport")}
                            titleTypographyProps={{variant: "h4"}}
                            avatar={
                                <img className={classes.headerIcon} alt="Whispero" src="/static/images/whispero.png"/>
                            }
                        />
                        <CardContent>
                            <TextField
                                required
                                fullWidth
                                label={t("Workspace ID")}
                                name="team"
                                error={errorText.length !== 1}
                                helperText={errorText}
                                value={formatWorkspaceId(id)}
                                onChange={handleTextChange}
                                variant="outlined"
                            />
                        </CardContent>
                        <Box
                            display="flex"
                            justifyContent="center"
                            px={2}
                            pb={2}
                            pt={0}
                        >
                            <Button
                                fullWidth
                                type="submit"
                                size="large"
                                color="primary"
                                variant="contained"
                            >
                                {t("continue")}
                            </Button>
                        </Box>
                    </Card>
                </form>
            </Container>
        </Page>
    );
};

export default CompanyLoginView;