import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Link,
    makeStyles
} from '@material-ui/core';
import {useParams} from "react-router-dom";
import {toWorkspaceKey} from "../../utils/apiUtils";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    clickableLink: {
        cursor: "pointer"
    }
}));


const MentionLink = ({className, children, contentState, entityKey, ...rest}) => {
    const classes = useStyles();
    const {url} = contentState.getEntity(entityKey).getData();
    // const {workspaceId} = useParams();
    // const workspaceKey = toWorkspaceKey(workspaceId);

    const handleClick = (e) => {
        e.preventDefault();
        if (url !== "#") {
            window.open(url, "_blank");
        }
    };

    return (
        <Link
            className={clsx(className, {[classes.clickableLink]: url !== "#"})}
            href={url}
            rel="noreferrer"
            aria-label={url}
            onClick={handleClick}
        >
            {children}
        </Link>
    )
};

MentionLink.propTypes = {
}

export default MentionLink;