import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {CssBaseline, Slide, Snackbar, ThemeProvider} from '@material-ui/core';
import {Alert} from "@material-ui/lab";
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

function SlideTransition(props) {
    return <Slide {...props} direction="right" />;
}

const ClipboardNotification = ({open, onClose}) => {
    const { t } = useTranslation();

    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //     return;
        // }
        onClose();
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
        >
            <Alert
                onClose={handleClose}
                elevation={6}
                variant="filled"
                severity="success"
            >
                {t("copied")}
            </Alert>
        </Snackbar>
    );
}

ClipboardNotification.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

ClipboardNotification.defaultProps = {
    onClose: () => {}
};

export default ClipboardNotification;
