import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    ButtonGroup,
    Chip, ClickAwayListener, Grow, ListItemIcon, ListItemText,
    makeStyles, MenuList, Paper, Popper, Tooltip,
    useTheme
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SaveAltOutlinedIcon from "@material-ui/icons/SaveAltOutlined";
import ExportToPdfDialog from "../../components/ReportPDF/ExportToPdfDialog";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles((theme) => ({
    toolbarSplitButton: {
        verticalAlign: "bottom"
    },
    menuItemIcon: {
        //marginLeft: -theme.spacing(0.5),
        marginLeft: -theme.spacing(0.25),
        //marginRight: theme.spacing(1),
        //minWidth: 0
        minWidth: theme.spacing(5),
    },
    button: {
        // color: "inherit",
        // backgroundColor: "inherit",
        // borderColor: "inherit",
        // '&:hover': {
        //     color: "initial",
        //     backgroundColor: "initial",
        //     borderColor: "initial",
        // }
    },
    expandButton: {
        padding: [0, theme.spacing(0.5)],
        minWidth: 0
    }
}));

export const getStoredState = (id) => {
    const value = window.localStorage.getItem(id);
    return Boolean(value) ? parseInt(value) : 0;
}

export const storeState = (id, value) => {
    window.localStorage.setItem(id, value);
}

const ToolbarSplitButton = ({stateId, className, classes: propClasses, options, onClick, ...rest}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(getStoredState(stateId));
    const anchorRef = React.useRef(null);

    useEffect(() => {
        if (selectedIndex >= options.length) {
            setSelectedIndex(0);
        }
    }, [options?.length])

    console.log("ToolbarSplitButton, options: %o, selectedIndex: %o", options, selectedIndex)

    const handleClick = () => {
        onClick(selectedIndex);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        storeState(stateId, index);
        setOpen(false);
        onClick(index);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <ButtonGroup className={classes.toolbarSplitButton} variant="contained" color="primary" size="small" ref={anchorRef} {...rest}>
                {options[selectedIndex] && (
                    <Tooltip title={options[selectedIndex].tooltip}>
                        <Button
                            className={clsx(classes.button, propClasses.button)}
                            size="small"
                            startIcon={options[selectedIndex].icon}
                            onClick={handleClick}
                        >
                            {options[selectedIndex].value}
                        </Button>
                    </Tooltip>
                )}
                <Button
                    className={clsx(classes.expandButton, propClasses.button)}
                    color="primary"
                    size="small"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 1}}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option.value}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option.icon && (
                                                <ListItemIcon className={classes.menuItemIcon}>
                                                    {option.icon}
                                                </ListItemIcon>
                                            )}
                                            <ListItemText primary={option.value} />
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
};

ToolbarSplitButton.propTypes = {
    stateId: PropTypes.string.isRequired,
    className: PropTypes.string,
    classes: PropTypes.exact({
        button: PropTypes.string
    }),
    options: PropTypes.arrayOf(PropTypes.shape({
        tooltip: PropTypes.string,
        icon: PropTypes.element,
        value: PropTypes.string.isRequired,
    })).isRequired,
    onClick: PropTypes.func
}

ToolbarSplitButton.defaultProps = {
    classes: {},
    onClick: () => {}
};


export default ToolbarSplitButton;