import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    alpha,
    Button,
    ButtonBase,
    Checkbox,
    createTheme,
    makeStyles,
    useTheme,
    ThemeProvider,
    Paper,
} from "@material-ui/core";
import { SpinnerButton } from "../ConfirmationButton/SpinnerButton";
import FormTextField from "../FormTextField/FormTextField";
import { useTranslation } from "react-i18next";
import FormBlock from "../formBlocks";
import { isConfirmationPage } from "../../utils/formUtils";
import FormBlockContainer from "../FormBlockContainer/FormBlockContainer";

const useStyles = makeStyles((theme) => ({
    formPageActions: {
        //flex: "0 0 auto",
        //padding: [theme.spacing(2), 0],
        //paddingTop: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingTop: theme.spacing(5)
    },
    secondaryButton: {
        marginRight: theme.spacing(3),
    },
    disabledContainedButton: {
        width: "100%",
        borderRadius: theme.shape.borderRadius,
        //backgroundColor: theme.palette.action.disabledBackground,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[2],
        padding: "6px 22px",
    },
    disabledOutlinedButton: {
        width: "100%",
        borderRadius: theme.shape.borderRadius,
        //border: `1px solid ${theme.palette.action.disabledBackground}`,
        border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
        color: theme.palette.primary.main,
        padding: "5px 21px",
    },
    editorTextField: {
        pointerEvents: "all",
        color: "inherit",
    },
}));

const showSecondaryButton = (variant) => variant === "other" || variant === "lastPage";

const getPrimaryDefaultText = (t, language, variant) =>
    variant === "lastPage" ? t("submit", { lng: language }) : t("continue", { lng: language });

const getSecondaryDefaultText = (t, language) => t("back", { lng: language });

export const getFormActionsVariant = (pageIndex, pagesCount) => {
    if (pageIndex === 0) {
        return "firstPage";
    } else if (pageIndex === pagesCount - 2) {
        return "lastPage";
    } else if (isConfirmationPage(pageIndex, pagesCount)) {
        return "confirmationPage";
    } else {
        return "other";
    }
};

const PresentationalFormActionButtons = ({
    className,
    template,
    languagePack,
    language,
    pageIndex,
    pagesCount,
    isBusy,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const variant = getFormActionsVariant(pageIndex, pagesCount);

    return (
        <FormBlockContainer className={clsx(classes.formPageActions, className)}>
            {showSecondaryButton(variant) && (
                <Button
                    className={classes.secondaryButton}
                    fullWidth
                    size="large"
                    color="primary"
                    variant="outlined"
                    disabled={isBusy}
                    onClick={onSecondaryButtonClick}
                >
                    {languagePack[template.secondary] ?? getSecondaryDefaultText(t, language)}
                </Button>
            )}
            <SpinnerButton
                fullWidth
                showSpinner={isBusy}
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                onClick={onPrimaryButtonClick}
            >
                {languagePack[template.primary] ?? getPrimaryDefaultText(t, language, variant)}
            </SpinnerButton>
        </FormBlockContainer>
    );
};

const EditableFormPageActions = ({
    className,
    template,
    languagePack,
    language,
    pageIndex,
    pagesCount,
    onTextChange,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const variant = getFormActionsVariant(pageIndex, pagesCount);

    return (
        <FormBlockContainer className={clsx(classes.formPageActions, className)}>
            {showSecondaryButton(variant) && (
                <ButtonBase
                    className={clsx(classes.disabledOutlinedButton, classes.secondaryButton)}
                    disabled
                    component="div"
                >
                    <FormTextField
                        className={classes.editorTextField}
                        templateKey={template.secondary}
                        languagePack={languagePack}
                        defaultValue={getSecondaryDefaultText(t, language)}
                        placeholder={t("buttonText")}
                        alignment="center"
                        textVariant="buttonLarge"
                        disableUnderline
                        onTextChange={onTextChange}
                    />
                </ButtonBase>
            )}
            <ThemeProvider theme={(theme) => createTheme({ palette: { type: "dark" }, typography: theme.typography })}>
                <ButtonBase className={classes.disabledContainedButton} disabled component="div">
                    <FormTextField
                        className={classes.editorTextField}
                        templateKey={template.primary}
                        languagePack={languagePack}
                        defaultValue={getPrimaryDefaultText(t, language, variant)}
                        placeholder={t("buttonText")}
                        alignment="center"
                        textVariant="buttonLarge"
                        disableUnderline
                        onTextChange={onTextChange}
                    />
                </ButtonBase>
            </ThemeProvider>
        </FormBlockContainer>
    );
};

const FormPageActions = ({
    className,
    template,
    languagePack,
    language,
    editable,
    pageIndex,
    pagesCount,
    isBusy,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    onTextChange,
}) => {
    return editable ? (
        <EditableFormPageActions
            className={className}
            template={template}
            languagePack={languagePack}
            language={language}
            pageIndex={pageIndex}
            pagesCount={pagesCount}
            onTextChange={onTextChange}
        />
    ) : (
        <PresentationalFormActionButtons
            className={className}
            template={template}
            languagePack={languagePack}
            language={language}
            pageIndex={pageIndex}
            pagesCount={pagesCount}
            isBusy={isBusy}
            onPrimaryButtonClick={onPrimaryButtonClick}
            onSecondaryButtonClick={onSecondaryButtonClick}
        />
    );
};

FormPageActions.propTypes = {
    className: PropTypes.string,
    template: PropTypes.object.isRequired,
    languagePack: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    pageIndex: PropTypes.number,
    pagesCount: PropTypes.number.isRequired,
    isBusy: PropTypes.bool,
    onPrimaryButtonClick: PropTypes.func,
    onSecondaryButtonClick: PropTypes.func,
    onTextChange: PropTypes.func,
};

FormPageActions.defaultProps = {
    onPrimaryButtonClick: () => {},
    onSecondaryButtonClick: () => {},
    onTextChange: () => {},
};

export default FormPageActions;
