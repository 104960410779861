import React, {forwardRef, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles, CardHeader, Grid, TextField, CardActionArea, Container, Paper
} from '@material-ui/core';
import {
    BUTTON_BORDER_RADIUS, CARD_SHADOW3, CardShadow,
    CardShadow2, CardShadow3, DEFAULT_FORM_SIDE_PADDING_SPACING,
    FORM_PAGE_BORDER_RADIUS, FORM_PAGE_WIDTH
} from "../../constants";
import UserAvatar from "../UserAvatar";
import {LargePictureSuffix, SmallPictureSuffix} from "../../constants/storage";

const useStyles = makeStyles((theme) => ({
    formPage: {
        //overflow: "hidden",
        //maxWidth: FORM_PAGE_WIDTH,
        [theme.breakpoints.only('xs')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3)
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5)
        }
    },
    paper: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        ...CardShadow2,
        borderRadius: FORM_PAGE_BORDER_RADIUS,
        //padding: [theme.spacing(9), 0, theme.spacing(4), 0],
        padding: [theme.spacing(7), 0, theme.spacing(4), 0],
        // '&>*:not(:last-child) ': {
        //     marginBottom: theme.spacing(2)
        // }
        //border: `1px solid ${theme.palette.grey[50]}`,
    },
    logo: {
        position: "absolute",
        top: 0,
        left: "50%",
        transform: "translate(-50%, -33%)",
        width: theme.spacing(10),
        height: theme.spacing(10),
        ...theme.typography.h3,
        boxShadow: theme.shadows[3]
    },
    imageLogo: {
        backgroundColor: theme.palette.background.paper
    }
}));

const FormPage = forwardRef((props, ref) => {
    const {
        className,
        classes: propClasses,
        children,
        workspace,
        ...rest
    } = props;
    const classes = useStyles();

    return (
        <Container className={clsx(classes.formPage, className)} ref={ref} maxWidth="sm" {...rest}>
            <Paper className={clsx(classes.paper, propClasses.paper)}>
                <UserAvatar
                    className={clsx(classes.logo, {[classes.imageLogo]: Boolean(workspace.picture)})}
                    name={workspace.name}
                    imageKey={workspace.picture?.replace(SmallPictureSuffix, LargePictureSuffix)}
                    component="span"
                    variant={"circular"}
                />
                {children}
            </Paper>
        </Container>
    );
});

FormPage.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.shape({
        paper: PropTypes.string
    }),
    workspace: PropTypes.object.isRequired,
};

FormPage.defaultProps = {
    classes: {},
};

export default FormPage;
