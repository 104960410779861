import React, { useContext, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, TablePagination, List, Divider } from "@material-ui/core";
import ReportCard from "./ReportCard";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import ListToolbar from "./ListToolbar";
import { isApproved, isOpen, isPendingApproval, isRejected, isSolved, isUnfounded } from "../../utils/reportUtils";
import { useSelector } from "react-redux";
import { currentUserRoleSelector } from "../../state/members/selectors";
import { useParams } from "react-router-dom";
import { toWorkspaceKey } from "../../utils/apiUtils";
import { isApprover } from "../../utils/user";

const useStyles = makeStyles((theme) => ({
    reportList: {
        minHeight: 0,
        display: "flex",
        flexDirection: "column",
    },
    list: {
        overflow: "auto",
        flex: "1 1 auto",
        padding: 0, // needed to reset default padding of the list
    },
    pagination: {
        flex: "0 0 auto",
    },
    divider: {
        margin: [0, theme.spacing(4)],
    },
}));

const filterByStatus = (report, filter) => {
    return (
        filter === "all" ||
        (filter === "open" && isOpen(report)) ||
        (filter === "solved" && isSolved(report)) ||
        (filter === "junk" && isUnfounded(report)) ||
        (filter === "pending" && isPendingApproval(report)) ||
        (filter === "approved" && isApproved(report)) ||
        (filter === "rejected" && isRejected(report))
    );
};

const getSortingFunction = (currentUserRole) => {
    return isApprover(currentUserRole)
        ? (a, b) => {
              if (!a.updatedAt && b.updatedAt) {
                  return 1;
              }
              if (a.updatedAt && !b.updatedAt) {
                  return -1;
              }
              if (a.updatedAt < b.updatedAt) {
                  return 1;
              }
              if (a.updatedAt > b.updatedAt) {
                  return -1;
              }
              return 0;
          }
        : (a, b) => {
              if (!a.excerptUpdatedAt && b.excerptUpdatedAt) {
                  return 1;
              }
              if (a.excerptUpdatedAt && !b.excerptUpdatedAt) {
                  return -1;
              }
              if (a.excerptUpdatedAt < b.excerptUpdatedAt) {
                  return 1;
              }
              if (a.excerptUpdatedAt > b.excerptUpdatedAt) {
                  return -1;
              }
              return 0;
          };
};

const skeleton = (
    <div>
        <Skeleton />
        <Skeleton />
        <Skeleton />
    </div>
);

// TODO: Move reports to parent component
const ReportList = ({ className, reports, selectedKey, onReportSelected }) => {
    const classes = useStyles();
    const { workspaceId } = useParams();
    const workspaceKey = toWorkspaceKey(workspaceId);
    const currentUserRole = useSelector(currentUserRoleSelector(workspaceKey));
    const [statusFilter, setStatusFilter] = useState("all");
    const filteredReports = (reports ?? []).filter((report) => filterByStatus(report, statusFilter)).sort(getSortingFunction(currentUserRole));

    const handleListItemClick = (reportKey) => {
        onReportSelected(reportKey);
    };

    return !reports ? (
        skeleton
    ) : (
        <div className={clsx(classes.reportList, className)}>
            <ListToolbar state={statusFilter} currentUserRole={currentUserRole} onStatusSelected={setStatusFilter} />
            <List component="div" className={classes.list}>
                {filteredReports &&
                    filteredReports.map((report) => (
                        <React.Fragment key={report.pk}>
                            <ReportCard
                                selected={report.pk === selectedKey}
                                onClick={() => handleListItemClick(report.pk)}
                                report={report}
                            />
                            {/*<Divider className={classes.divider}/>*/}
                        </React.Fragment>
                    ))}
            </List>
        </div>
    );
};

ReportList.propTypes = {
    className: PropTypes.string,
    reports: PropTypes.arrayOf(
        PropTypes.shape({
            pk: PropTypes.string,
            excerptUpdatedAt: PropTypes.string,
        })
    ).isRequired,
    selectedKey: PropTypes.string,
    statusFilter: PropTypes.oneOf(["all", "open", "solved", "junk"]),
    onReportSelected: PropTypes.func,
};

ReportList.defaultProps = {
    statusFilter: "all",
    onSave: () => {},
};

export default ReportList;
