import React, {useContext, useState, useEffect, useCallback, useRef} from 'react';
import {ApiContext} from "../contexts/ApiContext";
import {ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {addOrUpdateReport, deleteAllReports, loadReports} from "../state/reports/actions";
import {deleteWorkspace, updateWorkspace} from "../state/workspaces/actions";
import {addMessage, deleteAllMessages} from "../state/messages/actions";
import {addComment, deleteAllComments} from "../state/comments/actions";
import {isComment, isForm, isMembership, isMessage, isReport, isWorkspace, toId} from "../utils/apiUtils";
import {
    addMember,
    addMembers,
    deleteAllMembers,
    deleteMember,
    loadMembers,
    updateMember
} from "../state/members/actions";
import {addInvitation, deleteAllInvitation, deleteInvitation, loadInvitations} from "../state/invitations/actions";
import {AuthContext} from "../contexts/AuthContext";
import {currentUserRoleSelector} from "../state/members/selectors";
import {useNavigate} from "react-router-dom";
import {getReportExcerpt} from "../utils/reportUtils";
import {newAwsDateTime} from "../utils/time";
import {getMessageSender} from "../utils/redux";
import {addForm, deleteForm, loadForms, updateForm} from "../state/forms/actions";

export const useWorkspace = (workspaceKey) => {
    const api = useContext(ApiContext);
    const dispatch = useDispatch();
    const subscriptions = useRef({});
    const { store } = useContext(ReactReduxContext);
    const { refreshToken } = useContext(AuthContext);
    const [isLoaded, setIsLoaded] = useState(false);

    const fetchWorkspaceResources = async () => {
        const resources = await api.query.listWorkspaceResources(workspaceKey);
        if (resources.length > 0) {
            const workspace = resources.find(res => isWorkspace(res));
            dispatch(updateWorkspace(workspaceKey, workspace));

            const reports = resources.filter(res => isReport(res));
            dispatch(loadReports(workspaceKey, reports));

            const members = resources.filter(res => isMembership(res));
            dispatch(loadMembers(workspaceKey, members));

            const forms = resources.filter(res => isForm(res));
            dispatch(loadForms(workspaceKey, forms));
        }

        setIsLoaded(true);
    }

    const subscribeToWorkspaceChanges = () => {
        if (subscriptions.current[workspaceKey]?.assetCreatedSubscription) {
            return;
        }

        subscriptions.current[workspaceKey] = {};
        subscriptions.current[workspaceKey].assetCreatedSubscription = api.subscriptions.onResourceCreated(workspaceKey, handleResourceCreated);
        subscriptions.current[workspaceKey].assetUpdatedSubscription = api.subscriptions.onResourceUpdated(workspaceKey, handleResourceUpdated);
        subscriptions.current[workspaceKey].assetDeletedSubscription = api.subscriptions.onResourceDeleted(workspaceKey, handleResourceDeleted);
    }

    const handleResourceCreated = async (resource) => {
        if (isReport(resource)) {
            dispatch(addOrUpdateReport(workspaceKey, resource.pk, resource));
        } else if (isMembership(resource)) {
            dispatch(addMember(workspaceKey, resource));
        } else if (isMessage(resource)) {
            // TODO: Find more efficient way than to retrieve whole store object
            const reportLoaded = Boolean(store.getState().messages[resource.pk]);
            // Add message to report only if the report is loaded
            if (reportLoaded) {
                dispatch(addMessage(resource.pk, resource));
            }
        } else if (isComment(resource)) {
            dispatch(addComment(resource.pk, resource));
        } else if (isForm(resource)) {
            dispatch(addForm(workspaceKey, resource));
        }
    }

    const handleResourceUpdated = async (resource) => {
        if (isWorkspace(resource)) {
            dispatch(updateWorkspace(workspaceKey, resource));
        } else if (isReport(resource)) {
            dispatch(addOrUpdateReport(workspaceKey, resource.pk, resource));
        } else if (isMembership(resource)) {
            if (resource.pk === store.getState().currentUser.pk) {
                const currentUserRole = currentUserRoleSelector(workspaceKey)(store.getState());
                if (resource.role !== currentUserRole) {
                    // Refresh JWT token if the current user's role has changed
                    await refreshToken();
                    await fetchWorkspaceResources();
                }
            }
            dispatch(updateMember(workspaceKey, resource.pk, resource));
        } else if (isForm(resource)) {
            dispatch(updateForm(workspaceKey, resource.pk, resource));
        }
    }

    const handleResourceDeleted = async (resource) => {
        if (isWorkspace(resource)) {
            dispatch(deleteWorkspace(resource.pk));
        } else if (isForm(resource)) {
            dispatch(deleteForm(workspaceKey, resource.sk));
        } else if (isMembership(resource)) {
            if (resource.pk === store.getState().currentUser.pk) {
                dispatch(deleteWorkspace(workspaceKey));
                await refreshToken();

                subscriptions.current[workspaceKey].assetCreatedSubscription.unsubscribe();
                subscriptions.current[workspaceKey].assetUpdatedSubscription.unsubscribe();
                subscriptions.current[workspaceKey].assetDeletedSubscription.unsubscribe();
                subscriptions.current[workspaceKey].invitationCreatedSubscription.unsubscribe();
                subscriptions.current[workspaceKey].invitationDeletedSubscription.unsubscribe();

                // TODO: delete all in one call
                dispatch(deleteAllMembers(workspaceKey));
                dispatch(deleteAllInvitation(workspaceKey));
                dispatch(deleteAllReports(workspaceKey));
                dispatch(deleteAllMessages(workspaceKey));
                dispatch(deleteAllComments(workspaceKey));
            } else {
                dispatch(deleteMember(workspaceKey, resource.pk));
            }
        }
    }

    const fetchInvitations = async () => {
        const invitations = await api.query.listWorkspaceInvitations(workspaceKey);
        dispatch(loadInvitations(workspaceKey, invitations));
    }

    const subscribeToInvitationChanges = () => {
        if (subscriptions.current[workspaceKey]?.invitationCreatedSubscription) {
            return;
        }

        subscriptions.current[workspaceKey].invitationCreatedSubscription = api.subscriptions.onInvitationCreated(workspaceKey, (resource) => {
            dispatch(addInvitation(workspaceKey, resource));
        });
        subscriptions.current[workspaceKey].invitationDeletedSubscription = api.subscriptions.onInvitationDeleted(workspaceKey, (resource) => {
            dispatch(deleteInvitation(workspaceKey, resource.id));
        });
    }

    useEffect(() => {
        if (workspaceKey) {
            const workspace = store.getState().workspaces[workspaceKey];
            if (!workspace || !workspace.plan) {
                setIsLoaded(false);
                fetchWorkspaceResources();
                subscribeToWorkspaceChanges();
                fetchInvitations();
                subscribeToInvitationChanges();
            } else {
                setIsLoaded(true);
            }
        }
    }, [workspaceKey]);

    return isLoaded;
}

