import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Typography,
    makeStyles,
    Hidden,
    Button,
    Tooltip,
    TableCell,
    Divider,
    ListItemIcon,
    ListItemText,
    TextField,
    InputAdornment,
    ListItem, useTheme
} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import {ApiContext} from "../contexts/ApiContext";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ReportProgress from "./ReportProgress";
import ExportReportButton from "./ExportReportButton";
import {getReportName} from "../utils/textUtils";
import {useTranslation} from "react-i18next";
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import ExportToPdfDialog from "./ReportPDF/ExportToPdfDialog";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import UserPicker from "./UserPicker";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import DueDatePicker from "./Pickers";

const useStyles = makeStyles((theme) => ({
    borderlessTextField: props => ({
        display: "inline-grid",
        marginTop: 0,
        marginBottom: 0,
        '&::after': {
            content: "attr(data-value)",
            whiteSpace: "pre-wrap",
            visibility: "hidden",
            paddingLeft: props.dense ? theme.spacing(1.5) : theme.spacing(1.75),
            //paddingRight: theme.spacing(3.5),
            paddingRight: props.dense ? theme.spacing(7.25) : theme.spacing(5.75),  //7.25
            ...theme.typography[props.variant],
            lineHeight: 0   // to collapse vertical space taken by :after, must be placed after theme.typography.h5 to override system lineHeight
        },
        '& input': {
            ...theme.typography[props.variant],
            padding: props.dense ? [theme.spacing(0.5), theme.spacing(1.5), theme.spacing(0.5), theme.spacing(1.5)] : "intial",
        },
        '& fieldset': {
            borderStyle: props.borderVisible ? "solid" : "none"
        }
    })
}));

const BorderlessTextField = ({className, defaultValue, variant, dense, onSave, ...rest}) => {
    const fieldRef = useRef(null);
    const [value, setValue] = useState("");
    const [active, setActive] = useState(false);
    const [borderVisible, setBorderVisible] = useState(false);
    const classes = useStyles({borderVisible: borderVisible, variant: variant, dense: dense});

    useEffect(() => {
        if (defaultValue !== value) {
            fieldRef.current.dataset.value = defaultValue ?? "";
            setValue(defaultValue);
        }
    }, [defaultValue]);

    const handleOnChange = (event) => {
        setValue(event.target.value);
        fieldRef.current.dataset.value = event.target.value;    // needed to make field input grow as text is typed in
    }

    const handleOnMouseEnter = () => {
        if (!active) {
            setBorderVisible(true);
        }
    }

    const handleOnMouseLeave = () => {
        if (!active) {
            setBorderVisible(false);
        }
    }

    const handleOnBlur = () => {
        setActive(false);
        setBorderVisible(false);
        onSave(value);
    }

    const handleOnClick= () => {
        setActive(true);
        setBorderVisible(true);
    }

    return (
        <TextField
            {...rest}
            ref={fieldRef}
            className={clsx(classes.borderlessTextField, className)}
            hiddenLabel
            variant="outlined"
            margin="dense"
            value={value}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleOnClick}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <EditOutlinedIcon color="primary" style={{pointerEvents: "none"}}/>
                    </InputAdornment>
                ),
            }}
        />
    );
}

BorderlessTextField.propTypes = {
    className: PropTypes.string,
    defaultValue: PropTypes.string,
    variant: PropTypes.oneOf(["body1", "body2", "h1", "h2", "h3", "h4", "h5", "h6"]),
    dense: PropTypes.bool,
    onSave: PropTypes.func
}

BorderlessTextField.defaultProps = {
    variant: "body1",
    onSave: () => {}
};

export default BorderlessTextField;