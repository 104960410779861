import config from '../aws-exports';
import {deminifyLanguagePacks} from "./formUtils";

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

export const parseResourceFields = (resource) => {
    const parsed = {...resource};
    if (parsed.content) {
        parsed.content = JSON.parse(parsed.content);
    }
    if (parsed.submitLayout) {
        parsed.submitLayout = JSON.parse(parsed.submitLayout);
    }
    if (parsed.reportSettings) {
        parsed.reportSettings = JSON.parse(parsed.reportSettings);
    }
    if (parsed.pushSubscription) {
        parsed.pushSubscription = JSON.parse(parsed.pushSubscription);
    }
    if (parsed.lastReads) {
        parsed.lastReads = JSON.parse(parsed.lastReads);
    }
    // TODO: Refactor solution&description
    if (parsed.solution && parsed.solution.startsWith('{') && parsed.solution.endsWith('}')) {
        parsed.solution = JSON.parse(parsed.solution);
    }
    if (parsed.description && parsed.description.startsWith('{') && parsed.description.endsWith('}')) {
        parsed.description = JSON.parse(parsed.description);
    }
    if (parsed.template) {
        parsed.template = JSON.parse(parsed.template);
    }
    if (parsed.languagePacks) {
        parsed.languagePacks = deminifyLanguagePacks(JSON.parse(parsed.languagePacks));
    }

    return parsed;
}

// TODO: Remove
export const getSignedUrl = (key) => {
    return key ? `https://${bucket}.s3.${region}.amazonaws.com/public/${key}` : null;
}

export const toAdminsGroup = (workspaceId) => {
    return `admin#${workspaceId}`;
}

export const toWorkspaceKey = (id) => {
    return `team#${id}`;
}

export const toReportKey = (id) => {
    return `report#${id}`;
}

export const toMessageKey = (id) => {
    return `message#${id}`;
}

export const toCommentKey = (id) => {
    return `comment#${id}`;
}

export const toUserKey = (id) => {
    return `user#${id}`;
}

export const toFormKey = (id) => {
    return `form#${id}`;
}

export const toId = (key) => {
    return key.split('#')[1];
}

export const isUser = (resource) => {
    return resource.pk.startsWith("user#") && resource.pk === resource.sk;
}

export const isWorkspace = (resource) => {
    return resource.pk.startsWith("team#") && resource.pk === resource.sk;
}

export const isMembership = (resource) => {
    return resource.pk.startsWith("user#") && resource.sk.startsWith("team#");
}

export const isReport = (resource) => {
    return resource.pk.startsWith("report#") && resource.sk.startsWith("team#");
}

export const isMessage = (resource) => {
    return resource.sk.startsWith("message#");
}

export const isComment = (resource) => {
    return resource.sk.startsWith("comment#");
}

export const isForm = (resource) => {
    return isFormKey(resource.pk);
}

export const isFormKey = (key) => key.startsWith("form#");