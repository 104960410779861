import React, {useContext, useEffect, useRef, useState} from 'react';
import {makeStyles, Divider, ThemeProvider, Hidden, Drawer, CircularProgress, Typography, Link} from "@material-ui/core"
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp, AmplifySignOut, AmplifyGreetings, AmplifyForgotPassword } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import {Navigate, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router";
import {AuthContext} from "../../contexts/AuthContext";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    legal: {
        textAlign: "center"
    }
}));

// TODO: Wrap in Page
// TODO: Use Suspense
export const SignIn = (props) => {
    const classes = useStyles();
    const {isAuthenticated, signIn} = useContext(AuthContext);
    const { t } = useTranslation();
    const { state } = useLocation();
    const navigate = useNavigate();
    const authStateRef = useRef();

    useEffect(() => {
        console.log("SignIn useEffect");
        return onAuthUIStateChange((nextAuthState, authData) => {
            console.log("SignIn onAuthUIStateChange, current auth state: %o, next: %o",authStateRef.current, nextAuthState);
            // TODO: Remove
            if (authStateRef.current === AuthState.ConfirmSignUp && nextAuthState === AuthState.SignedIn) {
                console.log("SignIn, creating new user after signUp");
                // dispatch(loadOrUpdateCurrentUser({
                //     name: authData.attributes.name,
                //     email: authData.attributes.email,
                //     phone: authData.attributes.phone_number,
                //     teams: []
                // }));
            } else if (nextAuthState === AuthState.SignedIn) {
                console.log("SignIn - user signed in");
                // Reset previously loaded user data in redux after sing in, since they can belong to other user
                //dispatch(resetCurrentUser());
                signIn();
                navigate(state?.from || '/portal');
            }

            authStateRef.current = nextAuthState;
            //setAuthState(nextAuthState);
        });
    }, []);

    return (
        <AmplifyAuthenticator usernameAlias="email">
            <div slot="sign-up">
                <AmplifySignUp
                    usernameAlias="email"
                    formFields={[
                        {
                            type: "name",
                            label: t("common.name"),
                            placeholder: t("namePlaceholder"),
                            inputProps: { required: true },
                        },
                        {
                            type: "email",
                            inputProps: { required: true, autocomplete: "username" },
                        },
                        {
                            type: "password",
                            inputProps: { required: true, autocomplete: "new-password" },
                        }
                    ]}
                />
                <Typography className={classes.legal} component="p" variant="caption" color="textSecondary">
                    {`${t("singUpLegal")} `}
                    <Link href="https://www.whispero.cz/obchodni-podminky" color="inherit" underline="always" target="_blank">
                        {t("termsOfService")}
                    </Link>
                    {` ${t("and")} `}
                    <Link href="https://www.whispero.cz/ochrana-osobn-ch-daj-a-cookies" color="inherit" underline="always" target="_blank">
                        {t("privacyPolicy")}
                    </Link>
                </Typography>
            </div>
            <div slot="secondary-footer-content">ss</div>
        </AmplifyAuthenticator>
    );
}

