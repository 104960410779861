export const NavBarWidth = 240;
export const TopBarHeight = 72;
export const SubmitToolbarHeight = 64;

export const WorkspaceAvatarBorderRadius = 8;

export const ThumbnailMaxWidth = "320px";
export const ThumbnailHeight = "160px";

export const CardShadow = {boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)"};
export const CardShadow2 = {boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.10)"};
export const CardShadow3 = {boxShadow: "rgb(145 158 171 / 16%) 0px 16px 32px -4px"};
//export const CardShadow = {boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)"};
export const ThumbnailPictureDimensions = {height: 320};    // width: 320
export const AvatarPictureSizes = {
    small: 80,
    large: 512
}

export const PhoneNumbers = {
    cs: "+420239050505"
}

export const TEXT_FIELD_BORDER_RADIUS = "6px";
export const BUTTON_BORDER_RADIUS = 8;
export const FORM_PAGE_BORDER_RADIUS = 16;

export const WHISPERO_PRIVACY_POLICY_URL = "https://www.whispero.cz/zasady-oznamovani";
export const CARD_SHADOW3 = {boxShadow: "0px -4px 24px rgba(145, 158, 171, 0.12), 0px 0px 2px rgba(145, 158, 171, 0.2)"};

export const FORM_PAGE_WIDTH = "624px";
export const DEFAULT_FORM_SIDE_PADDING_SPACING = 4;
export const DEFAULT_FORM_TOP_PADDING_SPACING = 3;

export const MAX_ATTACHMENT_SIZE = 5242880;

