import {Box, Divider, makeStyles} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    toolbarDivider: {
        height: theme.spacing(2),
        margin: [0, theme.spacing(0.5)]
    }
}));

export const ToolbarDivider = () => {
    const classes = useStyles();

    return (<Divider className={classes.toolbarDivider} orientation="vertical"/>);
}