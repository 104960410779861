import * as types from "./actionTypes";

const initialState = {};

//  Messages state is a collection of messages grouped by report ID
export default function messagesReducer(state = initialState, action) {
    switch (action.type) {
        case types.MESSAGES_LOADED: {
            const newState = {...state};
            newState[action.payload.reportKey] = action.payload.messages;
            return newState;
        }
        case types.MESSAGE_ADDED: {
            const newState = {...state};
            newState[action.payload.reportKey] = [...(state[action.payload.reportKey] ?? []), action.payload.message];
            return newState;
        }
        case types.MESSAGES_DELETED: {
            const newState = {...state};
            console.log("MESSAGES_DELETED reducer, state: %o", newState[action.payload.workspaceKey]);
            newState[action.payload.workspaceKey] = undefined;   // TODO: This will not work, use reportKey instead
            return newState;
        }
        default:
            return state
    }
}
