import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {ApiContext} from "../../contexts/ApiContext";
import {currentUserSelector} from "../../state/currentUser/selectors";
import ChatMessage from "../../components/chat/ChatMessage";
import ChatSkeleton from "../../components/chat/ChatSkeleton";
import {messagesSelector} from "../../state/messages/selectors";
import {makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import {useTranslation} from "react-i18next";
import {activeMembersSelector, allMembersSelector} from "../../state/members/selectors";
import {hasUnreadMessages, isClosed, isReportClosed, isSystemMessage} from "../../utils/reportUtils";
import SystemMessage from "../../components/chat/SystemMessage";
import {Chat} from "../../components/chat";
import {useFetchMissingSenders} from "../../hooks/useFetchMissingSenders";
import ChatMessageSkeleton from "../../components/chat/ChatMessageSkeleton";
import {newAwsDateTime} from "../../utils/time";
import {loadOrUpdateCurrentUser} from "../../state/currentUser/actions";
import {toUserKey} from "../../utils/apiUtils";

const useStyles = makeStyles((theme) => ({
    reportViewChatRoot: {
        height: "100%",
        position: "relative"
    },
    chat: {
        height: "100%"
    }
}));

const ReportChat = ({className, report}) => {
    const classes = useStyles();
    const api = useContext(ApiContext);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const messages = useSelector(messagesSelector(report.pk));
    const currentUser = useSelector(currentUserSelector());
    const reportClosed = isReportClosed(messages);

    useEffect(() => {
        if (!messages || messages.length === 0) {
            return;
        }

        if (hasUnreadMessages(report, currentUser)) {
            const lastReads = {...currentUser.lastReads};
            const lastWorkspaceReads = {...lastReads[report.workspace]};
            lastWorkspaceReads[report.pk] = newAwsDateTime();
            lastReads[report.workspace] = lastWorkspaceReads;
            dispatch(loadOrUpdateCurrentUser({lastReads: lastReads}));
            api.mutation.updateLastRead(report.workspace, report.pk)
        }
    }, [report.pk, messages?.length])

    const handleChatSubmit = async ({content, attachments}) => {
        // TODO: Add message to redux??
        await api.mutation.createMessage(report.workspace, report.pk, content, currentUser.pk, attachments);
    };

    return (
        <div className={clsx(classes.reportViewChatRoot, className)}>
            {messages ? (
                <Chat
                    className={clsx(classes.chat)}
                    messages={messages}
                    workspaceKey={report.workspace}
                    onSubmit={handleChatSubmit}
                    inputDisabled={reportClosed}
                    inputPlaceholder={reportClosed ? t("disabledChatInputPlaceholder") : t("reportChatInputPlaceholder")}
                />
            ) : (
                <ChatSkeleton/>
            )}
        </div>
    )
}

ReportChat.propTypes = {
    className: PropTypes.string,
    report: PropTypes.shape({
        workspace: PropTypes.string.isRequired,
        pk: PropTypes.string.isRequired
    }).isRequired
}

export default ReportChat;