/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateLastRead = /* GraphQL */ `
  mutation UpdateLastRead($workspaceKey: ID!, $reportKey: ID!) {
    updateLastRead(workspaceKey: $workspaceKey, reportKey: $reportKey) {
      pk
      sk
      workspace
      owners
      group
      readOnlyGroup
      subscription
      createdAt
      updatedAt
      name
      picture
      email
      plan
      address
      phone
      cin
      country
      locale
      role
      description
      solution
      state
      stateUpdatedAt
      excerpt
      excerptUpdatedAt
      sender
      text
      content
      attachments
      submitLayout
      disabledSubmitLanguages
      reportSettings
      lastReads
      pushSubscription
      signature
      approval
      assigned
      followers
      dueDate
      tags
      color
      termLength
      active
      assignTo
      language
      template
      languagePacks
      passwordProtected
      disabled
    }
  }
`;
export const createWorkspace = /* GraphQL */ `
  mutation CreateWorkspace(
    $pk: ID!
    $name: String!
    $plan: String!
    $userName: String!
    $userPicture: String
  ) {
    createWorkspace(
      pk: $pk
      name: $name
      plan: $plan
      userName: $userName
      userPicture: $userPicture
    ) {
      pk
      sk
      workspace
      owners
      group
      readOnlyGroup
      subscription
      createdAt
      updatedAt
      name
      picture
      email
      plan
      address
      phone
      cin
      country
      locale
      role
      description
      solution
      state
      stateUpdatedAt
      excerpt
      excerptUpdatedAt
      sender
      text
      content
      attachments
      submitLayout
      disabledSubmitLanguages
      reportSettings
      lastReads
      pushSubscription
      signature
      approval
      assigned
      followers
      dueDate
      tags
      color
      termLength
      active
      assignTo
      language
      template
      languagePacks
      passwordProtected
      disabled
    }
  }
`;
export const acceptInvitation = /* GraphQL */ `
  mutation AcceptInvitation(
    $id: ID!
    $name: String!
    $picture: String
    $lastReads: AWSJSON
  ) {
    acceptInvitation(
      id: $id
      name: $name
      picture: $picture
      lastReads: $lastReads
    ) {
      pk
      sk
      workspace
      owners
      group
      readOnlyGroup
      subscription
      createdAt
      updatedAt
      name
      picture
      email
      plan
      address
      phone
      cin
      country
      locale
      role
      description
      solution
      state
      stateUpdatedAt
      excerpt
      excerptUpdatedAt
      sender
      text
      content
      attachments
      submitLayout
      disabledSubmitLanguages
      reportSettings
      lastReads
      pushSubscription
      signature
      approval
      assigned
      followers
      dueDate
      tags
      color
      termLength
      active
      assignTo
      language
      template
      languagePacks
      passwordProtected
      disabled
    }
  }
`;
export const deleteWorkspaceMember = /* GraphQL */ `
  mutation DeleteWorkspaceMember($workspaceKey: ID!, $userKey: ID!) {
    deleteWorkspaceMember(workspaceKey: $workspaceKey, userKey: $userKey) {
      pk
      sk
      workspace
      owners
      group
      readOnlyGroup
      subscription
      createdAt
      updatedAt
      name
      picture
      email
      plan
      address
      phone
      cin
      country
      locale
      role
      description
      solution
      state
      stateUpdatedAt
      excerpt
      excerptUpdatedAt
      sender
      text
      content
      attachments
      submitLayout
      disabledSubmitLanguages
      reportSettings
      lastReads
      pushSubscription
      signature
      approval
      assigned
      followers
      dueDate
      tags
      color
      termLength
      active
      assignTo
      language
      template
      languagePacks
      passwordProtected
      disabled
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport($input: CreateReportInput!) {
    createReport(input: $input) {
      pk
      accessId
    }
  }
`;
export const createGuestMessage = /* GraphQL */ `
  mutation CreateGuestMessage($input: CreateGuestMessageInput!) {
    createGuestMessage(input: $input) {
      pk
      sk
      workspace
      owners
      group
      readOnlyGroup
      subscription
      createdAt
      updatedAt
      name
      picture
      email
      plan
      address
      phone
      cin
      country
      locale
      role
      description
      solution
      state
      stateUpdatedAt
      excerpt
      excerptUpdatedAt
      sender
      text
      content
      attachments
      submitLayout
      disabledSubmitLanguages
      reportSettings
      lastReads
      pushSubscription
      signature
      approval
      assigned
      followers
      dueDate
      tags
      color
      termLength
      active
      assignTo
      language
      template
      languagePacks
      passwordProtected
      disabled
    }
  }
`;
export const createResource = /* GraphQL */ `
  mutation CreateResource(
    $input: CreateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    createResource(input: $input, condition: $condition) {
      pk
      sk
      workspace
      owners
      group
      readOnlyGroup
      subscription
      createdAt
      updatedAt
      name
      picture
      email
      plan
      address
      phone
      cin
      country
      locale
      role
      description
      solution
      state
      stateUpdatedAt
      excerpt
      excerptUpdatedAt
      sender
      text
      content
      attachments
      submitLayout
      disabledSubmitLanguages
      reportSettings
      lastReads
      pushSubscription
      signature
      approval
      assigned
      followers
      dueDate
      tags
      color
      termLength
      active
      assignTo
      language
      template
      languagePacks
      passwordProtected
      disabled
    }
  }
`;
export const updateResource = /* GraphQL */ `
  mutation UpdateResource(
    $input: UpdateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    updateResource(input: $input, condition: $condition) {
      pk
      sk
      workspace
      owners
      group
      readOnlyGroup
      subscription
      createdAt
      updatedAt
      name
      picture
      email
      plan
      address
      phone
      cin
      country
      locale
      role
      description
      solution
      state
      stateUpdatedAt
      excerpt
      excerptUpdatedAt
      sender
      text
      content
      attachments
      submitLayout
      disabledSubmitLanguages
      reportSettings
      lastReads
      pushSubscription
      signature
      approval
      assigned
      followers
      dueDate
      tags
      color
      termLength
      active
      assignTo
      language
      template
      languagePacks
      passwordProtected
      disabled
    }
  }
`;
export const deleteResource = /* GraphQL */ `
  mutation DeleteResource(
    $input: DeleteResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    deleteResource(input: $input, condition: $condition) {
      pk
      sk
      workspace
      owners
      group
      readOnlyGroup
      subscription
      createdAt
      updatedAt
      name
      picture
      email
      plan
      address
      phone
      cin
      country
      locale
      role
      description
      solution
      state
      stateUpdatedAt
      excerpt
      excerptUpdatedAt
      sender
      text
      content
      attachments
      submitLayout
      disabledSubmitLanguages
      reportSettings
      lastReads
      pushSubscription
      signature
      approval
      assigned
      followers
      dueDate
      tags
      color
      termLength
      active
      assignTo
      language
      template
      languagePacks
      passwordProtected
      disabled
    }
  }
`;
export const createResourceAccessCredentials = /* GraphQL */ `
  mutation CreateResourceAccessCredentials(
    $input: CreateResourceAccessCredentialsInput!
    $condition: ModelResourceAccessCredentialsConditionInput
  ) {
    createResourceAccessCredentials(input: $input, condition: $condition) {
      resourceKey
      accessCode
      group
      createdAt
      updatedAt
    }
  }
`;
export const updateResourceAccessCredentials = /* GraphQL */ `
  mutation UpdateResourceAccessCredentials(
    $input: UpdateResourceAccessCredentialsInput!
    $condition: ModelResourceAccessCredentialsConditionInput
  ) {
    updateResourceAccessCredentials(input: $input, condition: $condition) {
      resourceKey
      accessCode
      group
      createdAt
      updatedAt
    }
  }
`;
export const deleteResourceAccessCredentials = /* GraphQL */ `
  mutation DeleteResourceAccessCredentials(
    $input: DeleteResourceAccessCredentialsInput!
    $condition: ModelResourceAccessCredentialsConditionInput
  ) {
    deleteResourceAccessCredentials(input: $input, condition: $condition) {
      resourceKey
      accessCode
      group
      createdAt
      updatedAt
    }
  }
`;
export const createResourceAccessKey = /* GraphQL */ `
  mutation CreateResourceAccessKey(
    $input: CreateResourceAccessKeyInput!
    $condition: ModelResourceAccessKeyConditionInput
  ) {
    createResourceAccessKey(input: $input, condition: $condition) {
      id
      resourceKey
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateResourceAccessKey = /* GraphQL */ `
  mutation UpdateResourceAccessKey(
    $input: UpdateResourceAccessKeyInput!
    $condition: ModelResourceAccessKeyConditionInput
  ) {
    updateResourceAccessKey(input: $input, condition: $condition) {
      id
      resourceKey
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteResourceAccessKey = /* GraphQL */ `
  mutation DeleteResourceAccessKey(
    $input: DeleteResourceAccessKeyInput!
    $condition: ModelResourceAccessKeyConditionInput
  ) {
    deleteResourceAccessKey(input: $input, condition: $condition) {
      id
      resourceKey
      email
      createdAt
      updatedAt
    }
  }
`;
export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation(
    $input: CreateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    createInvitation(input: $input, condition: $condition) {
      id
      email
      workspace
      role
      workspaceName
      senderName
      locale
      group
      createdAt
      updatedAt
    }
  }
`;
export const updateInvitation = /* GraphQL */ `
  mutation UpdateInvitation(
    $input: UpdateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    updateInvitation(input: $input, condition: $condition) {
      id
      email
      workspace
      role
      workspaceName
      senderName
      locale
      group
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvitation = /* GraphQL */ `
  mutation DeleteInvitation(
    $input: DeleteInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    deleteInvitation(input: $input, condition: $condition) {
      id
      email
      workspace
      role
      workspaceName
      senderName
      locale
      group
      createdAt
      updatedAt
    }
  }
`;
export const createVoicemail = /* GraphQL */ `
  mutation CreateVoicemail(
    $input: CreateVoicemailInput!
    $condition: ModelVoicemailConditionInput
  ) {
    createVoicemail(input: $input, condition: $condition) {
      contactId
      workspaceId
      assetId
      createdAt
      updatedAt
    }
  }
`;
export const updateVoicemail = /* GraphQL */ `
  mutation UpdateVoicemail(
    $input: UpdateVoicemailInput!
    $condition: ModelVoicemailConditionInput
  ) {
    updateVoicemail(input: $input, condition: $condition) {
      contactId
      workspaceId
      assetId
      createdAt
      updatedAt
    }
  }
`;
export const deleteVoicemail = /* GraphQL */ `
  mutation DeleteVoicemail(
    $input: DeleteVoicemailInput!
    $condition: ModelVoicemailConditionInput
  ) {
    deleteVoicemail(input: $input, condition: $condition) {
      contactId
      workspaceId
      assetId
      createdAt
      updatedAt
    }
  }
`;
