import * as types from "./actionTypes";
import {groupBy, stableSort} from "../../utils/arrayUtils";

export const loadComments = (reportKey, comments) => async (dispatch, getState) => {
    const sorted = stableSort(comments, 'asc', "createdAt");
    dispatch({ type: types.COMMENTS_LOADED, payload: {reportKey: reportKey, comments: sorted} });
}

export const addComment = (reportKey, comment) => async (dispatch, getState) => {
    const exists = getState().comments[reportKey]?.some(t => t.sk === comment.sk);
    if (exists) {
        console.warn(`Comment with id ${comment.sk} already exists`);
        return;
    }

    dispatch({ type: types.COMMENT_ADDED, payload: {reportKey: reportKey, comment: comment} });
}

export const deleteAllComments = (workspaceKey) => async (dispatch, getState) => {
    dispatch({ type: types.COMMENTS_DELETED, payload: { workspaceKey } });
}