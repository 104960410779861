import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles, CardHeader, Grid, TextField, CardActionArea, Container, withStyles
} from '@material-ui/core';
import UserAvatar from "../UserAvatar";

const useStyles = makeStyles((theme) => ({
    staticDialogHeader: {
        flex: "0 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        '&>div': {
            flex: "initial"
        }
    },
    logo: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        fontSize: "1.5rem",
        marginRight: theme.spacing(2)
    },
}));

// TODO: Rename to submitHeader
const StaticDialogHeader = ({className, workspace}) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.staticDialogHeader, className)}>
            <UserAvatar className={classes.logo} name={workspace.name} imageKey={workspace.picture} component="span" variant={"rounded"}/>
            <Typography variant="h2" color="textPrimary">{workspace.name}</Typography>
        </div>
    );
};

StaticDialogHeader.propTypes = {
    className: PropTypes.string,
    workspace: PropTypes.object.isRequired
};

StaticDialogHeader.defaultProps = {
};

export default StaticDialogHeader;
