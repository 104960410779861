// TODO: Delete
export const LANGUAGES = [
    {label: "English", code: "en"},
    {label: "Čeština", code: "cs"},
    {label: "Deutsch", code: "de"},
    {label: "Slovenčina", code: "sk"},
    {label: "Polski", code: "pl"},
    {label: "Magyar", code: "hu"},
    {label: "Français", code: "fr"},
    {label: "Español", code: "es"},
    {label: "Português", code: "pt"},
    {label: "Italiano", code: "it"},
    {label: "Nederlands ", code: "nl"},
    {label: "Dansk ", code: "da"},
    {label: "Norsk ", code: "no"},
    {label: "Suomi ", code: "fi"},
    {label: "Svenska ", code: "sv"},
    {label: "Română ", code: "ro"},
    {label: "Slovenščina ", code: "sl"},
    {label: "Hrvatski ", code: "hr"},
    {label: "Latviešu ", code: "lv"},
    {label: "Eesti ", code: "et"},
    {label: "Pусский ", code: "ru"},
    {label: "Українська ", code: "uk"},
    {label: "български език", code: "bg"},
    {label: "ελληνικά", code: "el"}
];

export const LANGUAGE_LABELS = {
    en: "English",
    cs: "Čeština",
    de: "Deutsch",
    sk: "Slovenčina",
    pl: "Polski",
    hu: "Magyar",
    fr: "Français",
    es: "Español",
    pt: "Português",
    it: "Italiano",
    nl: "Nederlands",
    da: "Dansk",
    no: "Norsk",
    fi: "Suomi",
    sv: "Svenska",
    ro: "Română",
    sl: "Slovenščina",
    hr: "Hrvatski",
    lv: "Latviešu",
    et: "Eesti",
    ru: "Pусский",
    uk: "Українська",
    bg: "български език",
    el: "ελληνικά"
};

export const PORTAL_LANGUAGES = [LANGUAGES[0], LANGUAGES[1]];