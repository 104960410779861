import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Avatar,
    Button, Grow, InputAdornment,
    List,
    ListItem,
    ListItemAvatar, ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Paper, Snackbar, TextField, Tooltip,
    Typography
} from "@material-ui/core";
import TextFieldsIcon from '@material-ui/icons/TextFields';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import FormEditorPanel from "./FormEditorPanel";
import AddIcon from "@material-ui/icons/Add";
import {StyledMenu, StyledMenuItem} from "../../components/StyledMenu";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import LanguageIcon from '@material-ui/icons/Language';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import AddFormLanguageDialog from "./AddFormLanguageDialog";
import {Alert} from "@material-ui/lab";
import {deminifyLanguagePacks} from "../../utils/formUtils";
import AvatarPicker from "../../components/AvatarPicker";
import UserPicker from "../../components/UserPicker";
import {deleteElementByValue} from "../../utils/arrayUtils";
import SettingsControl from "../../components/SettingsControl";
import PropertySectionLabel from "../PortalSettings/DialogsSettings/PropertySectionLabel";
import clsx from "clsx";
import {toFormAccessId} from "../../utils/apiUtils";
import {useFetchAccessCode} from "../../hooks/useFetchAccessCode";
import {ApiContext} from "../../contexts/ApiContext";
import {useDispatch} from "react-redux";
import {loadOrUpdateCurrentUser} from "../../state/currentUser/actions";

const getLanguageListSize = (languageCount, theme) => {
    // Number of rows can be between 3-5
    const rows = Math.max(Math.min(languageCount, 5), 3);
    return `calc((${theme.typography.body2.fontSize} * ${theme.typography.body2.lineHeight} + 16px) * ${rows} + 16px + 2px)`
}

const useStyles = makeStyles((theme) => ({
    settingsPanel: {
        height: "100%",
        width: theme.spacing(40),
        padding: [theme.spacing(3), theme.spacing(3)],
        overflow: "auto"
    },
    controls: {
        '&>*:not(:last-child)': {
            marginBottom: theme.spacing(3)
        }
    },
    languageList: {
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.border.default}`,
        height: props => getLanguageListSize(props.languageCount, theme),
        // height: `calc((${theme.typography.body2.fontSize} * ${theme.typography.body2.lineHeight} + 16px) * 3 + 16px + 2px)`,
        overflow: "auto"
    },
    listItem: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        }
    },
    componentAvatar: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.text.secondary
    },
    clonedItem: {
        '& ~ *': {
            transform: "none!important"
        }
    },
    label: {
    },
    defaultHint: {
        marginLeft: theme.spacing(0.5),
        color: theme.palette.text.hint
    },
    formPasswordField: {
        marginTop: theme.spacing(1)
    },
    collapsed: {
        display: "none"
    }
}));

// TODO: Refactor parameters
const FormSettingsPanel = ({form, activeLanguage, languagePacks, defaultSettings, onLanguageChange, onLanguagesAdded, onLanguageDeleted, onSettingChange, onFormAccessCodeChange}) => {
    const languages = Object.keys(languagePacks);
    const classes = useStyles({languageCount: languages.length});
    const { t } = useTranslation();
    const api = useContext(ApiContext);
    const [clickedLanguage, setClickedLanguage] = useState();
    const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState(false);
    const [languageDialogOpen, setLanguageDialogOpen] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [translationFailed, setTranslationFailed] = useState(false);
    const [assignTo, setAssignTo] = useState(defaultSettings?.assignTo ?? []);
    const [passwordProtected, setPasswordProtected] = useState(defaultSettings?.passwordProtected ?? false);
    const [accessCode, setAccessCode] = useFetchAccessCode(form);

    const changeSelectedLanguage = (newLanguage) => {
        if (newLanguage !== activeLanguage) {
            onLanguageChange(newLanguage);
        }
    }

    const handleAddLanguageButtonClick = () => {
        setTranslationFailed(true);
        setLanguageDialogOpen(true);
    }

    const handleLanguageItemMoreButtonClick = (event, language) => {
        setLanguageMenuAnchorEl(event.currentTarget);
        setClickedLanguage(language);
    }

    const handleDeleteLanguageButtonClick = () => {
        if (clickedLanguage === activeLanguage) {
            // If the active language is deleted switch active language to the first language
            onLanguageChange(languages.find(lang => lang !== clickedLanguage));
        }

        onLanguageDeleted(clickedLanguage);
        setLanguageMenuAnchorEl(null);
    }

    const handleLanguageDialogConfirm = (translatedLanguagePacks) => {
        setLanguageDialogOpen(false);

        // Show error snackbar if the translation has completely failed, e.g. because of timeout
        if (!translatedLanguagePacks) {
            setTranslationFailed(true);
            setShowSnackbar(true);
            return;
        }
        const translatedLanguages = Object.keys(translatedLanguagePacks);
        // translatedLanguagePacks can be an empty object if the translation has failed for all languages
        if (translatedLanguages.length === 0) {
            setShowSnackbar(true);
            return;
        }

        const nonNullLanguagePacks = {};
        let success = true;
        for (const language of translatedLanguages) {
            // Not translated languages will have null value, e.g: {cs: null, sk: {....}}
            if (Boolean(translatedLanguagePacks[language])) {
                nonNullLanguagePacks[language] = deminifyLanguagePacks([translatedLanguagePacks[language]])[0];
            } else {
                success = false;
            }
        }

        // Show error snackbar if any of the translations have failed
        if (!success) {
            setShowSnackbar(true);
        }

        onLanguagesAdded(nonNullLanguagePacks);
        changeSelectedLanguage(Object.keys(nonNullLanguagePacks)[0]);
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSnackbar(false);
    };

    const handleUserAdded = (userId) => {
        const newAssignTo = [...assignTo, userId];
        setAssignTo(newAssignTo);
        onSettingChange('assignTo', newAssignTo);
    }

    const handleUserRemoved = (userId) => {
        const newAssignTo = deleteElementByValue(assignTo, userId);
        setAssignTo(newAssignTo);
        onSettingChange('assignTo', newAssignTo);
    }

    const handleTermDaysChange = (event) => {
        const value = event.target.value;
        // TODO: Don't use string setting names
        onSettingChange('termLength', value !== "" ? parseInt(value) : null);
    }

    const handleFormPasswordSwitchChange = (value) => {
        setPasswordProtected(value);
        onFormAccessCodeChange(accessCode);

        // TODO: REFACTOR!!!!
        if (value) {
            api.mutation.createResourceAccessCredentials(form.workspace, form.pk, accessCode ?? " ");

        }
        onSettingChange('passwordProtected', value);
    }

    const handleAccessCodeChanged = (event) => {
        setAccessCode(event.target.value);
        onFormAccessCodeChange(event.target.value);
    }

    return (
        <div className={classes.settingsPanel}>
            <div className={classes.controls}>
                <SettingsControl
                    labelKey={"languages"}
                    action={(
                        <Button
                            variant="text"
                            size="small"
                            color="primary"
                            startIcon={<AddIcon/>}
                            onClick={handleAddLanguageButtonClick}
                        >
                            {t("addNew")}
                        </Button>
                    )}
                >
                    <List className={classes.languageList} dense>
                        {languages.map(language => (
                            <ListItem
                                key={language}
                                button
                                selected={language === activeLanguage}
                                onClick={() => changeSelectedLanguage(language)}
                            >
                                <ListItemText
                                    primary={t(language)}
                                    primaryTypographyProps={{variant: "body2", display: "inline"}}
                                    secondaryTypographyProps={{variant: "caption", display: "inline", className: classes.defaultHint}}
                                />
                                <ListItemSecondaryAction>
                                    <Tooltip title={t("more")}>
                                        <IconButton
                                            size="small"
                                            edge="end"
                                            onClick={(event) => handleLanguageItemMoreButtonClick(event, language)}
                                        >
                                            <MoreVertOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                        <StyledMenu
                            keepMounted
                            anchorEl={languageMenuAnchorEl}
                            open={Boolean(languageMenuAnchorEl)}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            onClose={() => setLanguageMenuAnchorEl(null)}
                        >
                            <StyledMenuItem
                                disabled={languages.length === 1}
                                label={t("delete")}
                                Icon={DeleteOutlinedIcon}
                                onClick={handleDeleteLanguageButtonClick}
                            />
                        </StyledMenu>
                    </List>
                </SettingsControl>
                <SettingsControl labelKey={"assignReportsTo"}>
                    <UserPicker userIDs={assignTo} onAdded={handleUserAdded} onRemoved={handleUserRemoved}/>
                </SettingsControl>
                <SettingsControl labelKey={"deadlineForSolvingReports"}>
                    <TextField
                        variant="outlined"
                        size="small"
                        defaultValue={defaultSettings.termLength ?? ""}
                        onChange={handleTermDaysChange}
                        InputProps={{
                            type: "number",
                            endAdornment : <InputAdornment position="end">{t("days")}</InputAdornment>
                        }}
                    />
                </SettingsControl>
                <div>
                    <PropertySectionLabel
                        title={t("passwordProtectedFormSwitchTitle")}
                        subtitle={t("passwordProtectedFormSwitchSubtitle")}
                        showSwitch
                        switchChecked={passwordProtected}
                        onSwitchChange={handleFormPasswordSwitchChange}
                    />
                    <TextField
                        className={clsx(classes.formPasswordField, {[classes.collapsed]: !passwordProtected})}
                        variant="outlined"
                        size="small"
                        label={t("password")}
                        value={(!accessCode || accessCode === " ") ? "" : accessCode}
                        onChange={handleAccessCodeChanged}
                    />
                </div>
            </div>
            <AddFormLanguageDialog
                open={languageDialogOpen}
                languagePacks={languagePacks}
                onClose={() => setLanguageDialogOpen(false)}
                onConfirm={handleLanguageDialogConfirm}
            />
            <Snackbar
                open={showSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={translationFailed ? "error" : "warning"} variant="filled">
                    {translationFailed ? t("translationFailed") : t("notAllLanguagesTranslated")}
                </Alert>
            </Snackbar>
        </div>
    );
};

FormSettingsPanel.propTypes = {
    activeLanguage: PropTypes.string.isRequired,
    languagePacks: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object,
    onSettingChange: PropTypes.func,
    onLanguageChange: PropTypes.func,
    onLanguagesAdded: PropTypes.func,
    onLanguageDeleted: PropTypes.func,
};

FormSettingsPanel.defaultProps = {
    defaultSettings: {},
    onSettingChange: () => {},
    onLanguageChange: () => {},
    onLanguagesAdded: () => {},
    onLanguageDeleted: () => {},
    onFormAccessCodeChange: () => {},
};

export default FormSettingsPanel;