import React, {useState} from 'react';
import {
    makeStyles,
    Hidden,
    useMediaQuery, useTheme, Typography, IconButton
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import {Outlet} from 'react-router-dom';
import NavBar from "./NavBar";
import {useSelector} from "react-redux";
import {useFetchWorkspaces} from "../../hooks/useFetchWorkspaces";
import Loader from "../../components/Loader";
import {useTranslation} from "react-i18next";
import {TopBarHeight} from "../../constants";
import {currentUserMemberships} from "../../state/members/selectors";
import PortalTopBar from "./PortalTopBar";

const useStylesOld = makeStyles((theme) => ({
    layoutRoot: {
        overflow: "hidden",
        height: '100%',
        display: "flex"
    },
    errorContainer: {
        height: '100%',
        display: "flex",
        flexDirection: "column",
        alignIteenus: "center",
        justifyContent: "center"
    },
    container: {
        height: "100%",
        flex: "1 1 auto"
    },
    main: {
        height: `calc(100% - ${TopBarHeight + 1}px)`, // + 1 is to include divider under top bar,
    }
}));

const PortalLayoutOld = () => {
    const classes = useStylesOld();
    const { t } = useTranslation();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const workspaceKeys = useSelector(currentUserMemberships()) ?? [];
    const workspacesLoaded = useFetchWorkspaces(workspaceKeys);

    if (smallScreen) {
        return (
            <div className={classes.errorContainer}>
                <Typography variant="h5" color="textSecondary">
                    {t("screenSizeNotSupported")}
                </Typography>
            </div>
        )
    }

    return (
        <div className={classes.layoutRoot}>
            <NavBar showSkeletons={!workspacesLoaded} skeletonCount={workspaceKeys.length}/>
            {workspacesLoaded ? (
                <div className={classes.container}>
                    <PortalTopBar/>
                    <main className={classes.main}>
                        <Outlet/>
                    </main>
                </div>
            ) : (
                <Loader/>
            )}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    layoutRoot: {
        overflow: "hidden",
        height: '100%',
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    container: {
        height: "100%",
        flex: "1 1 auto",
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            minHeight: 0
        }
    },
    main: {
        height: `calc(100% - ${TopBarHeight + 1}px)`, // + 1 is to include divider under top bar
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            flex: 1,
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch' // For smooth scrolling on iOS
        }
    },
    mobileNav: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(1),
            borderBottom: `1px solid ${theme.palette.divider}`
        }
    },
    navBarMobile: {
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            zIndex: theme.zIndex.drawer,
            transform: props => props.isNavOpen ? 'translateX(0)' : 'translateX(-100%)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.standard
            })
        }
    },
    overlay: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: theme.zIndex.drawer - 1
        }
    }
}));

const PortalLayout = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const classes = useStyles({ isNavOpen });
    // const { t } = useTranslation();
    // const theme = useTheme(); // historically used for smallScreen check, not needed now
    const workspaceKeys = useSelector(currentUserMemberships()) ?? [];
    const workspacesLoaded = useFetchWorkspaces(workspaceKeys);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <div className={classes.layoutRoot}>
            {/* Mobile Navigation Toggle */}
            <Hidden mdUp>
                <div className={classes.mobileNav}>
                    <IconButton onClick={toggleNav}>
                        <MenuIcon />
                    </IconButton>
                </div>
            </Hidden>

            {/* Navigation Sidebar */}
            <div className={classes.navBarMobile}>
                <NavBar
                    showSkeletons={!workspacesLoaded}
                    skeletonCount={workspaceKeys.length}
                />
            </div>

            {/* Mobile Overlay */}
            {isNavOpen && (
                <Hidden mdUp>
                    <div
                        className={classes.overlay}
                        onClick={() => setIsNavOpen(false)}
                    />
                </Hidden>
            )}

            {/* Main Content */}
            {workspacesLoaded ? (
                <div className={classes.container}>
                    <PortalTopBar />
                    <main className={classes.main}>
                        <Outlet />
                    </main>
                </div>
            ):(
                <Loader />
            )}
        </div>
    );
};


export default PortalLayout;