import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles, CardHeader, Grid, TextField, CardActionArea, Container, Paper
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    staticDialog: {
        flex: "1 1 auto",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
        //padding: [theme.spacing(2), theme.spacing(3)],
        padding: theme.spacing(4),
        // [theme.breakpoints.up('xl')]: {
        //     maxHeight: theme.spacing(120),
        // },
    },
}));

const StaticDialog = ({ className, children}) => {
    const classes = useStyles();

    return (
        <Paper className={clsx(classes.staticDialog, className)}>
            {children}
        </Paper>
    );
};

StaticDialog.propTypes = {
    className: PropTypes.string
};

StaticDialog.defaultProps = {
};

export default StaticDialog;
