import React, {useEffect, useState} from 'react';
import {makeStyles, Step, StepLabel, Stepper, TextField, Typography} from "@material-ui/core"
import PropTypes from "prop-types";
import GeneralProfileSettings from "../ProfileButton/GeneralProfileSettings";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    field: {
        marginTop: theme.spacing(4)
    }
}));

const WorkspaceDetailsPage = ({className, name, error, onNameChanged}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleNameChange = (event) => {
        const text = event.target.value;
        onNameChanged(text);
    }

    return (
        <div id="workspace-details" className={clsx(classes.root, className)}>
            <Typography variant="h4" align="center" gutterBottom>
                {t("What is your company's name")}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                {t("It will be used as the name of your workspace")}
            </Typography>
            <TextField
                required
                fullWidth
                className={classes.field}
                label={t("Company name")}
                variant="outlined"
                error={error}
                helperText={error ? t("{name} is required", {name: t("common.name")}) : null}
                value={name}
                onChange={handleNameChange}
            />
        </div>
    )
}

WorkspaceDetailsPage.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.bool,
    onNameChanged: PropTypes.func
}

WorkspaceDetailsPage.defaultProps = {
    onNameChanged: () => {},
    error: false
};

export default WorkspaceDetailsPage;