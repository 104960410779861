import React, {useContext, useEffect, useState} from 'react';
import {
    Box,
    makeStyles,
    Avatar,
    Typography, useTheme, alpha
} from '@material-ui/core';
import PropTypes from "prop-types";
import PersonIcon from '@material-ui/icons/Person';
import DueDatePicker from "./Pickers/DueDatePicker";
import {
    cyan,
    green,
    grey,
    indigo,
    lightBlue,
    lime,
    purple,
    red
} from '@material-ui/core/colors';
import clsx from "clsx";
import {ApiContext} from "../contexts/ApiContext";
import {AVATAR_SIZES, FONT_SIZES, getAvatarColor, getInitials} from "../utils/avatarUtils";
import UserAvatar from "./UserAvatar";

const useStyles = makeStyles((theme) => ({
    avatarMenuItem: {
        display: "flex",
        alignItems: "center",
        maxWidth: "100%"
    },
    textContainer: {
        flex: "1 1 auto",
        minWidth: 0,
        marginLeft: theme.spacing(1),
        lineHeight: 0
    },
    subtitle: {
        color: theme.palette.text.hint,
        lineHeight: 1.33
        //marginLeft: theme.spacing(1)
    },
    iconAvatar: props => ({
        height: theme.spacing(AVATAR_SIZES[props.size]),
        width: theme.spacing(AVATAR_SIZES[props.size]),
        fontSize: `${FONT_SIZES[props.size]}`,
        backgroundColor: theme.palette.primary.main
    }),
}));

const AvatarMenuItem = ({className, title, subtitle, imageKey, icon, size, AvatarProps, ...rest}) => {
    const classes = useStyles({size});

    return (
        <Box
            className={clsx(classes.avatarMenuItem, className)}
            {...rest}
        >
            {icon ? (
                <Avatar className={classes.iconAvatar} variant="circular" {...AvatarProps}>
                    {icon}
                </Avatar>
            ) : (
                <UserAvatar name={title} imageKey={imageKey} size={size} {...AvatarProps}/>
            )}
            <div className={classes.textContainer}>
                <Typography color="textPrimary" variant="body2" noWrap>
                    {/*{name ?? t("Anonymous")}*/}
                    {title}
                </Typography>
                { subtitle && (
                    <Typography className={classes.subtitle} variant="caption" noWrap>
                        {subtitle}
                    </Typography>
                )}
            </div>
        </Box>
    );
};

AvatarMenuItem.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    imageKey: PropTypes.string,
    icon: PropTypes.object,
    AvatarProps: PropTypes.object,
    size: PropTypes.oneOf(["small", "medium", "large", "xSmall"])
}

AvatarMenuItem.defaultProps = {
    size: 'medium'
};

export default AvatarMenuItem;