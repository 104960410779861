import {
    Divider,
    FormControl,
    InputLabel,
    ListItem,
    ListItemAvatar,
    ListItemText, makeStyles,
    MenuItem,
    Select,
    Typography
} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    menuItem: {
        whiteSpace: "normal",
        maxWidth: theme.spacing(75)
    },
}));

const UserRoleSelect = ({className, value, onChange, hiddenLabel, SelectProps, ...rest}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleChange = (value) => {
        ;
    }

    return (
        <FormControl className={className} {...rest}>
            { !hiddenLabel && <InputLabel id="role-select-label">Role</InputLabel> }
            <Select
                labelId="role-select-label"
                value={value}
                renderValue={(val) => t(val)}
                onChange={(event) => onChange(event.target.value)}
                label={t("Role")}
                {...SelectProps}
            >
                <MenuItem className={classes.menuItem} value="approver">
                    <ListItemText
                        primary={t("approver")}
                        secondary={t("approverHint")}
                    />
                </MenuItem>
                <Divider/>
                <MenuItem className={classes.menuItem} value="solver">
                    <ListItemText
                        primary={t("solver")}
                        secondary={t("solverHint")}
                    />
                </MenuItem>
                <Divider/>
                <MenuItem className={classes.menuItem} value="admin">
                    <ListItemText
                        primary={t("admin")}
                        secondary={t("adminHint")}
                    />
                </MenuItem>
            </Select>
        </FormControl>
    )
}

UserRoleSelect.propTypes = {
    className: PropTypes.string,
    value: PropTypes.oneOf(["admin", "solver", "approver"]).isRequired,
    onChange: PropTypes.func,
    variant: PropTypes.string,
    hiddenLabel: PropTypes.bool,
    disableUnderline: PropTypes.bool
}

UserRoleSelect.defaultProps = {
    onChange: () => {}
};

export default UserRoleSelect;