import { combineReducers } from 'redux'
import reportsReducer from "./state/reports/reducer";
import currentUserReducer from "./state/currentUser/reducer";
import workspacesReducer from "./state/workspaces/reducer";
import messagesReducer from "./state/messages/reducer";
import commentsReducer from "./state/comments/reducer";
import membersReducer from "./state/members/reducer";
import invitationsReducer from "./state/invitations/reducer";
import formsReducer from "./state/forms/reducer";
import accessCredentialsReducer from "./state/accessCredentials/reducer";
import { CLEAR_REDUX_STATE } from "./state/rootActions";

/*const combineReducersWithRoot = (rootReducer, reducers) => {
    return (state, action) => {
        // Ensure the root state object is a new object; otherwise
        // React may not re-render.
        let newState = {...rootReducer(state, action)};
        Object.keys(reducers).forEach(domain => {
            let obj = state ? state[domain] : undefined;
            newState[domain] = reducers[domain](obj, action);
        });
        return newState;
    };
}

function rootReducer(state = {loading: false, loaded: false}, action) {
    switch(action.type) {
        case STARTED_LOADING:
            return {...state, loading: true, loaded: false};
        case FINISHED_LOADING:
            return {...state, loading: false, loaded: true};
        default:
            return state;
    }
}

export default combineReducersWithRoot(rootReducer, {data: dataReducer});*/

const appReducer = combineReducers({
    currentUser: currentUserReducer,
    workspaces: workspacesReducer,
    reports: reportsReducer,
    invitations: invitationsReducer,
    members: membersReducer,
    messages: messagesReducer,
    comments: commentsReducer,
    forms: formsReducer,
    accessCredentials: accessCredentialsReducer,
    api: (state = {}) => state,
})

const rootReducer = (state, action) => {
    if (action.type === CLEAR_REDUX_STATE) {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

export default rootReducer