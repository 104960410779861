import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Typography,
    makeStyles,
} from '@material-ui/core';
import UserAvatar from "../UserAvatar";
import { isImage } from "../../utils/fileUtils";
import AttachmentsContainer from "../AttachmentsContainer";
import { useTranslation } from "react-i18next";
import S3Attachment from "../attachments/S3Attachment";
import RichTextEditor2 from "../RichTextEditor2/RichTextEditor2";
import { deserializeEditorState } from "../RichTextEditor2/editorState";
import { isAttachmentSection, isDateSection } from "../../utils/formBlockUtils";

const useStyles = makeStyles((theme) => ({
    chatMessage: props => ({
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: props.position === "right" ? "row": "row-reverse"
    }),
    messageContainer: {
        flex: "1 1 auto",
        minWidth: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: props => props.position === "right" ? "flex-end" : "flex-start"
    },
    message: {
        [theme.breakpoints.up('xs')]: {
            maxWidth: "100%",
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: "40ch",
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: "50ch",
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: "60ch",
        },
        wordWrap: "break-word",
        padding: theme.spacing(2),
        borderRadius: 12,
        border: `1px solid ${theme.palette.grey[300]}`, //300
        //boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)"
        //...CardShadow,
        "&>*:not(:last-child)": {
            marginBottom: "1rem"
        }
    },
    rightPositioned: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    leftPositioned: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[200], //200
    },
    avatar: {
        marginTop: "0.15rem",
        marginRight: theme.spacing(1),
    },
    attachmentsContainer: {
        marginTop: theme.spacing(0.5)
    },
    senderLabel: {
        lineHeight: 0,
        '&>span:not(:last-child)': {
            marginRight: theme.spacing(1)
        }
    },
    sectionLabelDark: {
        color: theme.palette.common.white
    },
    sectionLabelLight: {
        color: theme.palette.text.secondary
    }
}));

const renderContent = (content, messagePosition, classes, i18n) => {
    const renderAnswer = (section) => {
        if (Array.isArray(section.value)) {
            return section.value.map(item => (<React.Fragment key={item}>{item}<br/></React.Fragment>));
        } else if (isDateSection(section)) {
            return new Date(section.value).toLocaleString(i18n.language, {
                dateStyle: "long"
            });
        } else {
            return section.value;
        }
    }

    if (!Array.isArray(content)) {
        // Render older rich text messages.
        return (
            <RichTextEditor2
                variant="readOnly"
                padding="none"
                editorState={content}
            />
        )
    }

    return (
        content.filter(section => !isAttachmentSection(section)).map((section, index) => (
            <div key={`${section.type}-${section.label}-${index}`}>
                <Typography className={messagePosition === 'right' ? classes.sectionLabelDark : classes.sectionLabelLight} variant="caption">
                    {section.label}
                </Typography>
                <Typography variant="body2">
                    {renderAnswer(section)}
                </Typography>
            </div>
        ))
    )
}

// TODO: Simplify component
const ChatMessage = ({
    className,
    classes: propClasses,
    message,
    sender,
    position,
    labelPlacement,
    showSenderName,
    attachmentSize,
    LabelProps,
    AvatarProps,
    onRendered,
    header
}) => {
    const classes = useStyles({position});
    const { t, i18n } = useTranslation();
    const [renderedCount, setRenderedCount] = useState(0);

    const content = useMemo(() => {
        if (!message.content) {
            return null;
        }

        if (Array.isArray(message.content)) {
            return message.content;
        }

        return deserializeEditorState(message.content);
    }, [message.content]);

    const [imageAttachments, fileAttachments] = useMemo(() => {
        let attachments = message.attachments ?? [];
        if (message.content && Array.isArray(message.content)) {
            const sectionAttachments = message.content.filter(isAttachmentSection).flatMap(section => section.value);
            attachments = attachments.concat(sectionAttachments);
        }
        const images = attachments.filter(attachment => isImage(attachment));
        const files = attachments.filter(attachment => !isImage(attachment));
        return [images, files]
    });

    useEffect(() => {
        if (renderedCount === imageAttachments.length) {
            onRendered();
        }
    }, [renderedCount])

    const handleAttachmentRendered = () => {
        setRenderedCount(prev => prev + 1)
    }

    const label = () => (
        <div className={classes.senderLabel}>
            { showSenderName &&
                <Typography variant="subtitle2" component="span" {...LabelProps}>
                    {sender.name}
                </Typography>
            }
            <Typography variant="body2" color="textSecondary" align={position} component="span" {...LabelProps}>
                {t("{timeAgo}", {value: new Date(message.createdAt)})}
            </Typography>
        </div>
    );

    // TODO: Refactor
    return (
        <div className={clsx(classes.chatMessage, className)}>
            <div className={clsx(classes.messageContainer, propClasses.container)}>
                {labelPlacement === "top" && label()}
                { ((message.text && !message.text.startsWith("__", 0)) || header || message.content) && (
                    <div
                        className={clsx(classes.message, propClasses.message, {
                            [classes.leftPositioned]: position === "left",
                            [classes.rightPositioned]: position === "right"
                        })}
                    >
                        {header && (
                            <Typography variant="body2">
                                {header}
                            </Typography>
                        )}
                        { message.text && !message.text.startsWith("__", 0) && (
                            <Typography variant="body2">
                                {message.text}
                            </Typography>
                        )}
                        { content && renderContent(content, position, classes, i18n)}
                    </div>
                )}
                { imageAttachments.length > 0 && (
                    <AttachmentsContainer className={classes.attachmentsContainer} direction={position === "right" ? "rightToLeft" : "leftToRight"}>
                        { imageAttachments.map(attachmentKey => (
                            <S3Attachment
                                key={attachmentKey}
                                s3Key={attachmentKey}
                                size={attachmentSize}
                                onRendered={handleAttachmentRendered}
                            />
                        ))}
                    </AttachmentsContainer>
                )}
                { fileAttachments.length > 0 && (
                    <AttachmentsContainer className={classes.attachmentsContainer} direction={position === "right" ? "rightToLeft" : "leftToRight"}>
                        { fileAttachments.map(attachmentKey => (
                            <S3Attachment key={attachmentKey} s3Key={attachmentKey}/>
                        ))}
                    </AttachmentsContainer>
                )}
                {labelPlacement === "bottom" && label()}
            </div>
            { position === "left" && (
                <UserAvatar className={classes.avatar} name={sender?.name} imageKey={sender?.picture} {...AvatarProps}/>
            )}
        </div>
    )
}

ChatMessage.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    message: PropTypes.shape({
        text: PropTypes.string,
        createdAt: PropTypes.string.isRequired,
        subscription: PropTypes.string.isRequired,
        attachments: PropTypes.arrayOf(PropTypes.string)
    }).isRequired,
    sender: PropTypes.shape({
        pk: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        picture: PropTypes.string
    }),
    position: PropTypes.oneOf(["left", "right"]),
    showAvatar: PropTypes.bool,
    labelPlacement: PropTypes.oneOf(["top", "bottom"]),
    showSenderName: PropTypes.bool,
    LabelProps: PropTypes.object,
    onRendered: PropTypes.func,
    header: PropTypes.string
}

ChatMessage.defaultProps = {
    classes: {},
    position: "left",
    showAvatar: false,
    labelPlacement: "bottom",
    onRendered: () => {}
};

export default ChatMessage;