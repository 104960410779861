import React, {useContext, useState, useEffect, useCallback, useRef} from 'react';
import {ApiContext} from "../contexts/ApiContext";
import {ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {addOrUpdateWorkspace} from "../state/workspaces/actions";
import {toId, toUserKey} from "../utils/apiUtils";
import {addMember, addMembers} from "../state/members/actions";

export const useFetchUsers = (workspaceKey, keys) => {
    const api = useContext(ApiContext);
    const { store } = useContext(ReactReduxContext);
    const dispatch = useDispatch();
    const loadedRef = useRef(false);
    const [_, setTriggerRendering] = useState(false);

    const fetchUsers = async () => {
        const members = [];
        for (const key of keys) {
            const loaded = store.getState().members[workspaceKey]?.some(member => member.pk === key);
            if (!loaded) {
                const user = await api.query.getUserProfile(key);
                members.push(user);
            }
        }

        // Must be set before the redux store is updated
        loadedRef.current = true;

        if (members.length === 0) {
            // If all users were already loaded in redux, addMembers action would not trigger a state update on
            // the parent control. The parent control then wouldn't know that loadedRef.current was set to true.
            setTriggerRendering(prev => !prev);
        } else {
            dispatch(addMembers(workspaceKey, members));
        }
    }

    useEffect(() => {
        if (!keys) {
            return;
        }
        if (keys.length === 0) {
            loadedRef.current = true;
            setTriggerRendering(prev => !prev);
            return;
        }

        fetchUsers();
    }, [JSON.stringify(keys)]);

    return loadedRef.current;
}

