import React, {useContext, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles,
    CssBaseline,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    TablePagination,
    List,
    ListItem,
    ListItemAvatar,
    useTheme,
    ListItemText,
    Divider,
    ListItemSecondaryAction,
    ListItemIcon,
    ButtonBase,
    Dialog,
    useMediaQuery, DialogTitle, DialogContent, DialogActions, Tabs, Tab, Button, Tooltip
} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import ExportToPdfDialog from "../ReportPDF/ExportToPdfDialog";
import {useTranslation} from "react-i18next";

// TODO: Remove, not used
const ExportReportButton = ({className, report}) => {
    const { t } = useTranslation();
    const [exportPdfDialogOpen, setExportPdfDialogOpen] = useState(false);

    const handleOnClick = () => {
        setExportPdfDialogOpen(true);
    }

    const handleExportCompleted = (blob) => {
        setExportPdfDialogOpen(false);
    }

    return (
        <>
            <Tooltip title={t("Export to PDF")}>
                <IconButton className={className} aria-label="export-report" onClick={handleOnClick}>
                    <CloudDownloadOutlinedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <ExportToPdfDialog
                report={report}
                open={exportPdfDialogOpen}
                onClose={() => setExportPdfDialogOpen(false)}
                onCompleted={handleExportCompleted}
            />
        </>
    )
}

ExportReportButton.propTypes = {
    className: PropTypes.string,
    report: PropTypes.object.isRequired,
}

ExportReportButton.defaultProps = {
};

export default ExportReportButton;