import React, {forwardRef, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles, CardHeader, Grid, TextField, CardActionArea, Container, Paper
} from '@material-ui/core';
import {CardShadow2, TEXT_FIELD_BORDER_RADIUS} from "../../constants";
import {normalizeLocalizationKey} from "../../utils/formUtils";

const useStyles = makeStyles((theme) => ({
    editorContainer: props => ({
        position: 'relative',
        cursor: "text",
        ...theme.typography[props.defaultTextSize]
    }),
    normalPadding: {
        '& .public-DraftEditor-content': {
            padding: [theme.spacing(2.3125), theme.spacing(1.75)]
        },
        '& $placeholder': {
            top: theme.spacing(2.3125),
            left: theme.spacing(1.75),
        }
    },
    densePadding: {
        '& .public-DraftEditor-content': {
            padding: [theme.spacing(1.3125), theme.spacing(1.75)]
        },
        '& $placeholder': {
            top: theme.spacing(1.3125),
            left: theme.spacing(1.75),
        }
    },
    placeholder: {
        position: "absolute",
        pointerEvents: "none"
    },
    standardVariant: {
        position: 'relative',
        '&:after': {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
            left: 0,
            bottom: 0,
            // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
            content: '""',
            position: 'absolute',
            right: 0,
            transform: 'scaleX(0)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shorter,
                easing: theme.transitions.easing.easeOut,
            }),
            pointerEvents: 'none', // Transparent to the hover style.
        },
        '&:before': {
            borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
            left: 0,
            bottom: 0,
            // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
            content: '"\\00a0"',
            position: 'absolute',
            right: 0,
            transition: theme.transitions.create('border-bottom-color', {
                duration: theme.transitions.duration.shorter,
            }),
            pointerEvents: 'none', // Transparent to the hover style.
        },
        '&:hover:before': {
            borderBottom: `2px solid ${theme.palette.text.primary}`,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
            },
        }
    },
    standardVariantFocused: {
        '&:after': {
            transform: 'scaleX(1)',
        }
    },
    outlinedDecorator: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        pointerEvents: "none",
        borderRadius: TEXT_FIELD_BORDER_RADIUS,
        border: `1px solid ${theme.palette.border.default}`,
        zIndex: theme.zIndex.drawer
        //border: `1px solid rgba(0, 0, 0, 0.42)`
    },
    outlinedDecoratorHovered: {
        borderColor: theme.palette.text.primary,
        //border: `1px solid ${theme.palette.border.hover}`,
    },
    outlinedDecoratorFocused: {
        border: `2px solid ${theme.palette.primary.main}`
    },
}));

const EditorContainer = forwardRef((props, ref) => {
    const {className, children, variant, defaultTextSize, padding, placeholder, highlightOnHover, highlightOnFocus, onFocus, onBlur, ...rest} = props;
    const classes = useStyles({defaultTextSize});
    const [focused, setFocused] = useState(false);
    const [hovered, setHovered] = useState(false);

    const handleFocus = () => {
        if (highlightOnFocus) {
            setFocused(true);
        }
        onFocus();
    }

    const handleBlur = () => {
        setFocused(false);
        onBlur();
    }

    return (
        <div
            ref={ref}
            className={clsx(classes.editorContainer, className, {
                [classes.normalPadding]: padding === 'normal' && variant !== 'readOnly',
                [classes.densePadding]: padding === 'dense' && variant !== 'readOnly',
                [classes.standardVariant]: variant === 'standard',
                [classes.standardVariantFocused]: variant === 'standard' && focused,
            })}
            {...rest}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onMouseEnter={() => highlightOnHover && setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {variant === 'outlined' && (
                <span
                    className={clsx(classes.outlinedDecorator, {
                        [classes.outlinedDecoratorHovered]: hovered,
                        [classes.outlinedDecoratorFocused]: focused
                    })}
                />
            )}
            {placeholder && (
                <Typography
                    className={classes.placeholder}
                    color="textSecondary"
                    variant="inherit"
                >
                    {placeholder}
                </Typography>
            )}
            {children}
        </div>
    )
});

EditorContainer.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    highlightOnHover: PropTypes.bool,
    highlightOnFocus: PropTypes.bool,
    variant: PropTypes.oneOf(["standard", "outlined", "readOnly"]),
    defaultTextSize: PropTypes.oneOf(["body1", "body2"]),
    padding: PropTypes.oneOf(["dense", "normal", "none"]),
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
};

EditorContainer.defaultProps = {
    variant: "standard",
    defaultTextSize: "body2",
    padding: "normal",
    highlightOnHover: true,
    highlightOnFocus: true,
    onFocus: () => {},
    onBlur: () => {}
};

export default EditorContainer;
