import {createStyles, makeStyles} from "@material-ui/core";
import theme from "./theme";

const useStyles = makeStyles(() => createStyles({
    '@global': {
        '*': {
            scrollbarWidth: "thin",
            // https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-color
            scrollbarColor: "#d2d2d2 #eeeeee"
            // boxSizing: 'border-box',
            // margin: 0,
            // padding: 0,
        },
        // Chrome/Edge/Safari
        "*::-webkit-scrollbar": {
            width: "10px",
            height: "10px"
        },
        "*::-webkit-scrollbar-thumb": {
            backgroundColor: "#d2d2d2"
        },
        "*::-webkit-scrollbar-track": {
            background: "#eeeeee"
        },
        "*::-webkit-scrollbar-corner": {
            background: "#eeeeee"
        },
        html: {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            height: '100%',
            width: '100%'
        },
        body: {
            backgroundColor: theme.palette.background.default,
            height: '100%',
            width: '100%',
            // fontSize: "14px",
            // lineHeight: 1
            //overflow: "hidden",
        },
        div: {
            // lineHeight: 'initial',
            // fontFamily: 'initial',
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
        },
        '#root': {
            height: '100%',
            width: '100%',
            maxHeight: "100%",
            "--amplify-primary-color": "#1F81F7",
            "--amplify-primary-tint": "#3397FF",
            "--amplify-primary-shade": "#0057B2"
        },
        '.ps > .ps__rail-y': {
            opacity: 0.6
        },
        '.MuiButton-root': {
            borderRadius: 6
        },
        '.MuiButtonGroup-root': {
            borderRadius: 6
        }
    }
}));

const GlobalStyles = () => {
    useStyles();

    return null;
}

export default GlobalStyles;