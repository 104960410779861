import * as types from "./actionTypes";

const initialState = {};

//  Reports state is a collection of reports grouped by team ID, e.g. {
//     miton: [{id:"report1"},{id:"report2"}],
//     cola: [{id:"report5"}]
// }
export default function reportsReducer(state = initialState, action) {
    switch (action.type) {
        case types.REPORTS_LOADED: {
            const newState = {...state};
            newState[action.payload.workspaceKey] = action.payload.reports;
            return newState;
        }
        case types.REPORTS_ADDED: {
            const newState = {...state};
            newState[action.payload.workspaceKey] = [...state[action.payload.workspaceKey], ...action.payload.reports];
            return newState;
        }
        case types.REPORT_ADDED: {
            const newState = {...state};
            newState[action.payload.workspaceKey] = [...state[action.payload.workspaceKey], action.payload.report];
            return newState;
        }
        case types.REPORT_UPDATED: {
            const updatedReports = state[action.payload.workspaceKey].map(t => {
                if (t.pk !== action.payload.report.pk) {
                    return t
                }

                return action.payload.report;
            });

            const newState = {...state};
            newState[action.payload.workspaceKey] = updatedReports
            return newState;
        }
        case types.REPORTS_DELETED: {
            const newState = {...state};
            newState[action.payload.workspaceKey] = undefined;
            return newState;
        }
        default:
            return state
    }
}
