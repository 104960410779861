import React, {useEffect, useState} from 'react';
import {
    Box,
    makeStyles,
    Avatar,
    Typography, Backdrop, CircularProgress, Tooltip, Toolbar, Link, useTheme, LinearProgress
} from '@material-ui/core';
import PropTypes from "prop-types";
import {
    cyan,
    green,
    grey,
    indigo,
    lightBlue,
    lime,
    purple,
    red
} from '@material-ui/core/colors';
import clsx from "clsx";
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles((theme) => ({
    placeholderRoot: {
        width: theme.spacing(20),
        height: theme.spacing(8),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 8,
        borderWidth: "thin",
        borderStyle: "solid",
        borderColor: theme.palette.divider,
        padding: theme.spacing(1),
        paddingRight: theme.spacing()
    },
    icon: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        color: theme.palette.grey[400],
        marginRight: theme.spacing(1)
    },
    progress: {
        backgroundColor: theme.palette.grey[300]
    },
    linear: {
        height: theme.spacing(0.75),
        flex: "1 1 auto",
        borderRadius: 6,
        backgroundColor: theme.palette.grey[300]
    },
    circular: {
        color: theme.palette.grey[500],
        margin: "auto"
    },
    barColor : {
        backgroundColor: theme.palette.grey[500]
    }
}));

const AttachmentProgress = ({className, progress, type}) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.placeholderRoot, className)}>
            {/*<DescriptionOutlinedIcon className={classes.icon} stroke={theme.palette.background.paper}/>*/}
            <DescriptionIcon className={classes.icon} />
            { type === "linear" && (
                <LinearProgress
                    className={classes.linear}
                    value={progress}
                    variant="determinate"
                    classes={{
                        barColorPrimary: classes.barColor
                    }}
                />
            )}
            { type === "circular" && (
                <CircularProgress className={classes.circular}/>
            )}
        </div>
    )
};

AttachmentProgress.propTypes = {
    className: PropTypes.string,
    progress: PropTypes.number,
    type: PropTypes.oneOf(['circular', 'linear'])
}

AttachmentProgress.defaultProps = {
    type: 'linear'
};

export default AttachmentProgress;