import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles, Divider, Hidden, Typography, TextField, Button, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { WorkspaceKeyContext } from "../../contexts/WorkspaceKeyContext";
import { allFormsSelector, formSelector } from "../../state/forms/selectors";
import TileButton2 from "../../components/TileButton2";
import TileButton from "../../components/TileButton/TileButton";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import AddTileButton from "../../components/AddTileButton";
import { Navigate, useParams } from "react-router-dom";
import { toFormKey, toId, toUserKey } from "../../utils/apiUtils";
import { formatFormId } from "../../utils/textFormatter";
import FormEditorToolbar from "./FormEditorToolbar";
import FormPage from "../../components/FormPage/FormPage";
import {
    deleteBlock,
    deminifyLanguagePacks,
    duplicateBlock,
    getFormActionsVariant,
    getFormBlockId,
    hydrateFormTemplate,
    hydrateLanguagePacks,
    isConfirmationPage,
    minifyLanguagePacks,
} from "../../utils/formUtils";
import PropTypes from "prop-types";
import MemoizedFormPage from "./EditableFormPage";
import FormPageActions from "../../components/FormPageActions";
import FormPresentationToolbar from "../../components/FormPresentationToolbar";
import { DEFAULT_FORM_TOP_PADDING_SPACING } from "../../constants";
import { s4 } from "../../utils/idGenerator";
import { ApiContext } from "../../contexts/ApiContext";
import { isDateBlock, isReportEmailBlock } from "../../utils/formBlockUtils";
import { isValidDate, isValidEmail } from "../../utils/textUtils";
import { scrollToElement } from "../../utils/ui";
import FormBlock from "../../components/formBlocks";
import FormBlockContainer from "../../components/FormBlockContainer/FormBlockContainer";

const useStyles = makeStyles((theme) => ({
    formPreview: {
        width: "100%",
        //backgroundColor: theme.palette.grey[50],
        backgroundColor: theme.palette.background.paper,
        position: "relative",
    },
    pagesContainer: {
        paddingTop: theme.spacing(DEFAULT_FORM_TOP_PADDING_SPACING), // to accommodate appbar,
        overflow: "auto",
        maxHeight: "100%",
    },
    page: {
        // pointerEvents: "none",
    },
}));

// TODO: Delete any attachments that were uploaded in preview mode
const FormPreviewPanel = ({ className, template, languagePacks, defaultLanguage, workspace, showToolbar }) => {
    const classes = useStyles();
    const api = useContext(ApiContext);
    const languages = Object.keys(languagePacks);
    const [language, setLanguage] = useState(defaultLanguage ?? languages[0]);
    const [pageIndex, setPageIndex] = useState(0);
    const rootRef = useRef();
    // Dummy api prevents file uploads to S3 when attaching files in preview mode
    // downloadObject needs to be real because S3 avatars may need to download logo images from S3
    const dummyApi = useMemo(
        () => ({
            storage: {
                uploadAttachment: () => s4(),
                deleteAttachment: () => {},
                downloadObject: api.storage.downloadObject,
            },
        }),
        []
    );

    useEffect(() => {
        rootRef.current?.scrollTo({ top: 0 });
    }, [pageIndex]);

    useEffect(() => {
        setLanguage(defaultLanguage);
    }, [defaultLanguage]);

    const handlePrimaryButtonClick = () => {
        if (pageIndex === template.pages.length - 1) {
            //confirmReportSubmission();
        } else {
            setPageIndex((prev) => prev + 1);
        }
    };

    const handleSecondaryButtonClick = () => {
        setPageIndex((prev) => prev - 1);
    };

    return (
        <div className={clsx(classes.formPreview, className)}>
            {showToolbar && (
                <FormPresentationToolbar
                    position="absolute"
                    selectedLanguage={language}
                    languages={languages}
                    workspace={workspace}
                    onLanguageChange={setLanguage}
                />
            )}
            <div ref={rootRef} className={classes.pagesContainer}>
                <ApiContext.Provider value={dummyApi}>
                    <FormPage
                        className={classes.page}
                        key={template.pages[pageIndex].key}
                        workspace={workspace}
                    >
                        {template.pages[pageIndex].blocks.map((block) => (
                            <FormBlockContainer key={getFormBlockId(block)}>
                                <FormBlock
                                    blockTemplate={block}
                                    languagePack={languagePacks[language]}
                                    language={language}
                                />
                            </FormBlockContainer>
                        ))}
                        <FormPageActions
                            template={template.pages[pageIndex].actions}
                            languagePack={languagePacks[language]}
                            language={language}
                            pageIndex={pageIndex}
                            pagesCount={template.pages.length}
                            onPrimaryButtonClick={handlePrimaryButtonClick}
                            onSecondaryButtonClick={handleSecondaryButtonClick}
                        />
                    </FormPage>
                </ApiContext.Provider>
            </div>
        </div>
    );
};

FormPreviewPanel.propTypes = {
    className: PropTypes.string,
    template: PropTypes.object.isRequired,
    languagePacks: PropTypes.object.isRequired,
    defaultLanguage: PropTypes.string,
    workspace: PropTypes.object.isRequired,
    showToolbar: PropTypes.bool,
};

FormPreviewPanel.defaultProps = {
    showToolbar: true,
};

export default FormPreviewPanel;
