import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Chip,
    makeStyles, Tooltip,
    useTheme
} from '@material-ui/core';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    toolbarButton: {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.grey[50],
        '&:hover': {
            backgroundColor: theme.palette.grey[100],
        }
    },
}));

const ToolbarButton = ({className, tooltip, startIcon, onClick, value, ...rest}) => {
    const classes = useStyles();

    return (
        <Tooltip title={tooltip}>
            <Button
                className={clsx(className, classes.toolbarButton)}
                variant="outlined"
                size="small"
                startIcon={startIcon}
                onClick={onClick}
                {...rest}
            >
                {value}
            </Button>
        </Tooltip>
    )
};

ToolbarButton.propTypes = {
    className: PropTypes.string,
    tooltip: PropTypes.string,
    startIcon: PropTypes.element,
    onClick: PropTypes.func,
    value: PropTypes.string.isRequired
}

ToolbarButton.defaultProps = {
};


export default ToolbarButton;