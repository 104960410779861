import React, {useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Typography,
    makeStyles,
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {getSystemMessageContent} from "../../utils/reportUtils";
import ChatMessage from "./ChatMessage";

const useStyles = makeStyles((theme) => ({
    systemMessage: {
        textAlign: "center"
    },
}));

// TODO: Simplify
const SystemMessage = ({className, message, sender, onRendered, ...rest}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    // Sender can occasionally be null due to bugs in create system message mutations, for now we just log in
    // instances when sender is null, after bug is fixed this can be removed
    if (!sender) {
        console.error("message sender cannot be null, message key: %o", message?.pk);
    }

    useEffect(() => {
        if (!Boolean(message.solution)) {
            onRendered();
        }
    }, [message.createdAt])

    if (message.solution) {
        return (
            <ChatMessage
                header={t("solvedReportMessageHeader")}
                message={{
                    content: message.solution,
                    createdAt: message.createdAt,
                    subscription: message.subscription
                }}
                sender={sender}
                onRendered={onRendered}
            />
        )
    }

    return (
        <div className={clsx(classes.systemMessage, className)} {...rest}>
            <span>
                <Typography component="span" variant="body2" color="textSecondary">
                    {`${getSystemMessageContent(message, sender?.name, t)} `}
                </Typography>
                <Typography component="span" variant="body2" color="textSecondary">
                    {t("{dateLong}", {date: new Date(message.createdAt)})}
                </Typography>
            </span>
        </div>
    );
}

SystemMessage.propTypes = {
    className: PropTypes.string,
    onRendered: PropTypes.func
}

SystemMessage.defaultProps = {
    onRendered: () => {}
};

export default SystemMessage;