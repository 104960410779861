import React, {useContext, useEffect, useMemo, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Typography,
    makeStyles,
    ListItem, useTheme, Avatar,
} from '@material-ui/core';
import {getReportName} from "../../utils/textUtils";
import {formatReportExcerpt, getReportExcerpt, hasUnreadMessages, isClosed, isOpen} from "../../utils/reportUtils";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {currentUserSelector} from "../../state/currentUser/selectors";
import {activeMembersSelector, currentUserRoleSelector} from "../../state/members/selectors";
import ReportStatus from "../../components/ReportStatus/ReportStatus";
import StatusIndicator from "../../components/ReportStatus/StatusIndicator";
import ApprovalTag from "../../components/ReportTags/ApprovalTag";
import {isApprover} from "../../utils/user";
import Tag from "../../components/Tag";
import {isFormKey} from "../../utils/apiUtils";
import ReportTags from "../../components/ReportTags";
import UnreadIndicator from "../../components/UnreadIndicator";

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        transition: `opacity 75ms ease-in-out`,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        //  border: `1px solid ${theme.palette.divider}`,
        //  borderRadius: "4px",
        //  marginBottom: theme.spacing(1),
        //  marginLeft: theme.spacing(1),
        //  marginRight: theme.spacing(1),
        // ...CardShadow
    },
    listItem: {
        alignItems: "flex-start",
        padding: [theme.spacing(2), theme.spacing(4), theme.spacing(2), theme.spacing(4)]
    },
    selected: {
        background: theme.palette.background.paper
    },
    leftGutter: {
        flex: "0 0 auto",
        //paddingTop: theme.spacing(0.25),
        width: theme.spacing(4),
        position: "relative",
        '& > *': {
            margin: 0,
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, 0)"
        }
    },
    cardContent: {
        flex: "1 1 auto",
        minWidth: 0
    },
    title: {
        //paddingRight: theme.spacing(5)
        flex: "1 1 auto",
        fontWeight: theme.typography.fontWeightMedium,
    },
    progress: {
        maxWidth: "66%",
        //marginBottom: "0.5em"
    },
    progressBar: {
        height: "4px"
    },
    message: {
        flex: "1 1 auto",
        overflow: "hidden",
        minWidth: 0,
    },
    timestamp: {
        //color: theme.palette.text.hint,
        flex: "0 0 auto"
    },
    cardSection: {
        display: "flex",
        alignItems: "center",
        '&>*:not(:last-child) ': {
            marginRight: "0.75em"
        }
    },
    denseMargin: {
        marginTop: theme.spacing(0.5),
    },
    footer: {
        marginTop: theme.spacing(1),
    },
    progressLabel: {
        color: theme.palette.text.hint,
        lineHeight: "135%"
    }
}));

const filterTags = (workspace, report) => {
    if (workspace.tags && report.tags) {
        return report.tags.flatMap(id => {
            const tag = workspace.tags.find(w => w.id === id);
            return tag ? tag : [];
        });
    }

    return [];
};

const ReportCard = ({report, selected, onClick, onDelete, ...rest}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const members = useSelector(activeMembersSelector(report.workspace));
    const currentUser = useSelector(currentUserSelector());
    const currentUserRole = useSelector(currentUserRoleSelector(report.workspace));
    const [unread, setUnread] = useState();
    // TODO: Delete
    const collaborators = useMemo(() => (
        report.assigned?.map(userKey => members.find(member => member.pk === userKey)).filter(user => Boolean(user))
    ), [JSON.stringify(report.assigned ?? [])]);

    useEffect(() => {
        if (selected) {
            setUnread(false);
        }
    }, [selected])

    useEffect(() => {
        if (!selected) {
            setUnread(hasUnreadMessages(report, currentUser));
        }
    }, [report.excerptUpdatedAt])

    const handleOnClick = (event) => {
        onClick();
    }

    return (
        <div className={classes.cardRoot}>
            <ListItem
                button
                className={classes.listItem}
                onClick={handleOnClick}
                classes={{selected: classes.selected}}
                selected={selected}
                {...rest}
            >
                <div className={clsx(classes.cardContent)}>
                    <ReportStatus report={report}/>
                    <div className={classes.cardSection}>
                        <Typography variant="subtitle2" className={classes.title}>
                            {getReportName(report, t)}
                        </Typography>
                        { !isApprover(currentUserRole) && isOpen(report) && unread && (
                            <UnreadIndicator/>
                        )}
                    </div>
                    {!isApprover(currentUserRole) && (
                        <div className={clsx(classes.cardSection, classes.denseMargin)}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                className={classes.message}
                                noWrap
                            >
                                {formatReportExcerpt(report.excerpt, t)}
                            </Typography>
                            <Typography
                                component="span"
                                variant="body2"
                                color="textSecondary"
                                className={classes.timestamp}
                            >
                                {t("{timeAgo}", {value: new Date(report.excerptUpdatedAt)})}
                            </Typography>
                        </div>
                    )}
                    <div className={clsx(classes.cardSection, classes.footer)}>
                        <ReportTags report={report}/>
                        {/*<AvatarGroup max={5}>*/}
                        {/*    {collaborators.map(user => (*/}
                        {/*        <UserAvatar key={`cardAvatar-${user.pk}`} name={user.name} imageKey={user.picture} component="span" alt={user.name} size="xSmall" />*/}
                        {/*    ))}*/}
                        {/*</AvatarGroup>*/}
                        {/*{ filterTags(workspace, report).map(tag => (*/}
                        {/*    <Tag key={tag.id} label={tag.name} color={tag.color} size="small" onClick={() => {}}/>*/}
                        {/*))}*/}
                    </div>
                </div>
            </ListItem>
        </div>
    );
}

ReportCard.propTypes = {
    report: PropTypes.object.isRequired
}

export default ReportCard;