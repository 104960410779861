import {Translations} from "@aws-amplify/ui-components";

export const authScreenCs = {
  "An account with the given email already exists.": "Účet s daným e-mailem již existuje.",
  "Incorrect username or password.": "Nesprávné uživatelské jméno nebo heslo.",
  "User does not exist.": "Uživatel neexistuje.",
  "Username/client id combination not found.": "Kombinace uživatelské jméno/ID klienta nebyla nalezena.",
  "Invalid verification code provided, please try again.": "Byl zadán neplatný ověřovací kód, zkuste to prosím znovu.",
  "User is not confirmed.": "Uživatel není potvrzen.",
  [Translations.DEFAULT_MSG]: "Chyba ověření",
  [Translations.EMPTY_USERNAME]: "Uživatelské jméno nemůže být prázdné",
  [Translations.EMPTY_EMAIL]: "Email nemůže být prázdný",
  [Translations.EMPTY_PASSWORD]: "Heslo nemůže být prázdné",
  [Translations.EMPTY_CODE]: "Potvrzovací kód nesmí být prázdný",
  [Translations.SIGN_UP_ERROR]: "Při vytváření účtu došlo k chybě",
  [Translations.BACK_TO_SIGN_IN]: "Zpět k přihlášení",
  [Translations.CODE_LABEL]: "Ověřovací kód",
  [Translations.CODE_PLACEHOLDER]: "Zadejte kód",
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: "Potvrzovací kód",
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: "Vložte potvrzovací kód",
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "Potvrďte registraci",
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]: "Nedoručený kód?",
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: "Znovu odeslat",
  [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: "Potvrdit",
  [Translations.CONFIRM]: "Potvrdit",
  [Translations.CREATE_ACCOUNT_TEXT]: "Vytvořit účet",
  [Translations.EMAIL_LABEL]: "Emailová adresa *",
  [Translations.EMAIL_PLACEHOLDER]: "Zadejte svou e-mailovou adresu",
  [Translations.FORGOT_PASSWORD_TEXT]: "Zapomněli jste heslo?",
  [Translations.NEW_PASSWORD_LABEL]: "Nové heslo",
  [Translations.NEW_PASSWORD_PLACEHOLDER]: "Zadejte své nové heslo",
  [Translations.NO_ACCOUNT_TEXT]: "Žádný účet?",
  [Translations.PASSWORD_LABEL]: "Heslo",
  [Translations.PASSWORD_PLACEHOLDER]: "Zadejte heslo",
  [Translations.PHONE_LABEL]: "Telefonní číslo",
  [Translations.RESET_PASSWORD_TEXT]: "Obnovit heslo",
  [Translations.RESET_YOUR_PASSWORD]: "Obnovte své heslo",
  [Translations.SEND_CODE]: "Poslat kód",
  [Translations.SUBMIT]: "Odeslat",
  [Translations.SIGN_IN_ACTION]: "Přihlaste se",
  [Translations.SIGN_IN_HEADER_TEXT]: "Přihlaste se ke svému účtu",
  [Translations.SIGN_IN_TEXT]: "Přihlaste se",
  [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: "Email",
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: "Máte účet?",
  [Translations.SIGN_UP_HEADER_TEXT]: "Vytvořit nový účet",
  [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: "Heslo",
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: "Vytvořit účet",
  [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: "Přihlaste se",
  [Translations.USERNAME_LABEL]: "Uživatelské jméno",
  [Translations.USERNAME_PLACEHOLDER]: "Zadejte své uživatelské jméno"
}