import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    alpha,
    ButtonBase,
    Divider, IconButton, ListItemIcon, ListItemText,
    makeStyles, Typography
} from '@material-ui/core';
import PropTypes from "prop-types";
import clsx from "clsx";
import Menu from "@material-ui/core/Menu";

const useStyles = makeStyles((theme) => ({
    styledMenuPaper: {
        borderRadius: 8,
        minWidth: theme.spacing(20)
    },
}));

const StyledMenu = ({classes: propClasses, children, ...rest}) => {
    const classes = useStyles();

    return (
        <Menu
            classes={{paper: clsx(classes.styledMenuPaper, propClasses.paper), list: classes.list}}
            {...rest}
        >
            {children}
        </Menu>
    );
};

StyledMenu.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    ...Menu.propTypes
};

StyledMenu.defaultProps = {
    classes: {}
};

export default StyledMenu;