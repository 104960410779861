import React from "react";
import clsx from "clsx";
import { Dialog, makeStyles, Tab, Tabs } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    tab: {
        //textTransform: 'none',
        marginRight: theme.spacing(4),
        minHeight: 0,
        minWidth: theme.spacing(6),
        paddingLeft: 0,
        paddingRight: 0,
        //paddingTop: theme.spacing(0.75),
        //paddingBottom: theme.spacing(0.75),
        ...theme.typography.subtitle2,
        textTransform: "none",
    },
    tabContent: {
        alignItems: "initial",
        flexDirection: "row",
        "&>*:first-child": {
            marginRight: theme.spacing(0.75),
        },
    },
}));

const StyledTab = ({ className, ...other }) => {
    const classes = useStyles();

    return (
        <Tab
            disableRipple
            className={clsx(classes.tab, className)}
            classes={{ wrapper: classes.tabContent }}
            {...other}
        />
    );
};

export default StyledTab;
