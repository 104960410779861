import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles, CssBaseline, ListItemIcon, LinearProgress, TableCell, useTheme, Chip, alpha, darken
} from '@material-ui/core';
import {isFormKey} from "../../utils/apiUtils";
import FormPresentationView from "../../views/FormPresentationView";
import {cyan, green, grey, indigo, lightBlue, lime, purple, red} from "@material-ui/core/colors";
import stc from "string-to-color";

// const pallete = {
//     gray6: "#F2F2F2",
//     gray4: "#BDBDBD",
//     gray3: "#828282"
// }

const useStyles = makeStyles((theme) => ({
}));

const backgrounds = ([
    grey[200],
    purple[200],
    indigo[200], // 700
    lime[200],
    cyan[200],
    green[200],
    lightBlue[200], // 900
    red[200],
])

export const getTagBackground = (label) => {
    const pos = label ? Math.min(Math.max(Math.floor((label.toLowerCase().charCodeAt(0) - 97) / 3.25), 0), 7) : 0;
    return backgrounds[pos];
}

const Tag = ({className, label, backgroundColor, textColor, onClick, onDelete, ...rest}) => {
    //const classes = useStyles({background: getTagBackground(label)});
    const background = backgroundColor ? backgroundColor : alpha(stc(label), 0.15);
    const color = textColor ? textColor : darken(stc(label), 0.4);

    return (
        <Chip
            className={className}
            label={label}
            size="small"
            onClick={onClick}
            onDelete={onDelete}
            style={{background: background, color: color}}
            {...rest}
        />
    )
};

Tag.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
}

Tag.defaultProps = {
};

export default Tag;