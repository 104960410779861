import moment from "moment";

export function capitalizeFirstLetter(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function getReportName(report, t) {
    if (report.name) {
        return report.name;
    }

    return t("Report from {date}", {date: new Date(report.createdAt)});
}

export function isValidEmail(email) {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
}

export function isValidDate(date) {
    return moment(date).isValid();
}

export function urlB64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export function insertAtCaret(textArea, text) {
    text = text || '';
    if (document.selection) {
        // IE
        textArea.focus();
        var sel = document.selection.createRange();
        sel.text = text;
    } else if (textArea.selectionStart || textArea.selectionStart === 0) {
        // Others
        var startPos = textArea.selectionStart;
        var endPos = textArea.selectionEnd;
        textArea.value = textArea.value.substring(0, startPos) +
            text +
            textArea.value.substring(endPos, textArea.value.length);
        textArea.selectionStart = startPos + text.length;
        textArea.selectionEnd = startPos + text.length;
    } else {
        textArea.value += text;
    }
};