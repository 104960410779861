import React, { forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import { AppBar, Box, Divider, makeStyles, Toolbar, Typography } from "@material-ui/core";
import clsx from "clsx";
import { NavBarWidth, TopBarHeight } from "../../constants";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Page from "../Page/Page";
import {toWorkspaceKey} from "../../utils/apiUtils";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {workspaceSelector} from "../../state/workspaces/selectors";
import {useTranslation} from "react-i18next";

const WorkspacePage = ({ children, className, disableScroll}) => {
    const { t } = useTranslation();
    const { workspaceId } = useParams();
    const workspaceKey = workspaceId ? toWorkspaceKey(workspaceId) : null;
    const workspace = useSelector(workspaceSelector(workspaceKey));

    return (
        <Page
            title={t("workspaceRouteTitle", { workspaceName: workspace?.name ?? "" })}
            className={className}
            disableScroll={disableScroll}
        >
            {children}
        </Page>
    );
};

WorkspacePage.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    disableScroll: PropTypes.bool,
};

WorkspacePage.defaultProps = {};

export default WorkspacePage;
