import React, { useContext } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Container,
    Divider,
    Grid,
    Link,
    makeStyles,
    Typography,
    useTheme,
} from "@material-ui/core";
import Page from "../../components/Page/Page";
import Card from "../../components/Card";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";
import { activeWorkspacesSelector, allWorkspacesSelector } from "../../state/workspaces/selectors";
import WorkspaceTile from "./WorkspaceTile";
import ReportTable from "../DashboardView/ReportTable";
import { isClosed, remainingDays } from "../../utils/reportUtils";
import AddWorkspaceButton from "./AddWorkspaceButton";

const useStyles = makeStyles((theme) => ({
    homeViewContainer: {
        padding: theme.spacing(3),
    },
    accordion: {
        boxShadow: "none",
    },
    heading: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    tableContainer: {
        padding: [theme.spacing(2), theme.spacing(2)],
    },
    tileList: {
        padding: [theme.spacing(2), theme.spacing(1)],
        display: "flex",
        flexWrap: "wrap",
    },
}));

const HomeView = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const workspaces = useSelector(activeWorkspacesSelector());

    return (
        <Page title={t("homeRouteTitle")}>
            <Container maxWidth="md" className={classes.homeViewContainer}>
                <Typography className={classes.heading} variant="subtitle1">
                    {t("workspaces")}
                </Typography>
                <div className={classes.tileList}>
                    {workspaces.map((workspace) => (
                        <WorkspaceTile key={workspace.pk} workspace={workspace} />
                    ))}
                    <AddWorkspaceButton />
                </div>
            </Container>
        </Page>
    );
};

export default HomeView;
