import getVisibleSelectionRect from "draft-js/lib/getVisibleSelectionRect";
import {Link, Typography} from "@material-ui/core";
import {CompositeDecorator, convertFromRaw, convertToRaw, EditorState} from "draft-js";
import MentionLink from "./MentionLink";
import ContentState from "draft-js/lib/ContentState";

export const getEditorBounds = (editor) => {
    let fakeClientRect = getVisibleSelectionRect(window);
    return {
        selectionRect: fakeClientRect ? {
            top: fakeClientRect?.top,
            left: fakeClientRect?.left
        } : null,
        editorRect: editor.getBoundingClientRect()
    }
}

export const getLineNumber = (editorState) => {
    const currentBlockKey = editorState.getSelection().getStartKey()
    return editorState.getCurrentContent().getBlockMap()
        .keySeq().findIndex(k => k === currentBlockKey)
}

export const isMaxLengthHandled = (editorState, nextLength, maxLength) => {
    if (!maxLength) {
        return "not-handled";
    }
    const currentLength = editorState.getCurrentContent().getPlainText('').length
    return currentLength + nextLength > maxLength ? "handled" : "not-handled"
}

const findMentionEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'MENTION'
            );
        },
        callback
    );
}

const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
}

const findDecoWithRegex = (regex, contentBlock, callback) => {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        callback(start, start + matchArr[0].length);
    };
}

export const deserializeEditorState = (content, customDecorators = []) => {
    const decorators = [
        {
            strategy: findLinkEntities,
            component: Link,
        },
        {
            strategy: findMentionEntities,
            component: MentionLink,
        }
    ];
    if (customDecorators) {
        customDecorators.forEach(deco => decorators.push({
            strategy: (contentBlock, callback) => {
                findDecoWithRegex(deco.regex, contentBlock, callback)
            },
            component: deco.component
        }));
    }
    const decorator = new CompositeDecorator(decorators);
    if (!content) {
        return EditorState.createEmpty(decorator);
    }

    const isRichValue = Boolean(content) && typeof content === 'object';
    return isRichValue
        ? EditorState.createWithContent(convertFromRaw(content), decorator)
        : EditorState.createWithContent(ContentState.createFromText(content), decorator);
}

export const serializeEditorState = (editorState) => {
    return convertToRaw(editorState.getCurrentContent());
}

export const emptyEditorState = () => deserializeEditorState(null);

export const isEmptyState = (editorState) => {
    return !Boolean(editorState) || !editorState.getCurrentContent().hasText();
}