import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    CardHeader,
    Grid,
    TextField,
    CardActionArea,
    Container,
    List,
    ListItem,
    Paper,
    Popover,
    Popper,
    Fade,
    ListSubheader, ListItemText
} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {useTranslation} from "react-i18next";
import {TopBarHeight} from "../../constants";
import {groupBy} from "../../utils/arrayUtils";

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        width: theme.spacing(60),
        maxWidth: `calc(100vw - ${theme.spacing(2)}px)`,
        maxHeight: theme.spacing(45),
        //maxHeight: `calc(100vh - ${theme.spacing(2)}px)`,
        // [theme.breakpoints.down('sm')]: {
        //     maxWidth: `calc(100vw - ${theme.spacing(2)}px)`,
        //     maxHeight: `calc(100vh - ${theme.spacing(2)}px)`,
        // },
        //position: "absolute",
        zIndex: theme.zIndex.drawer + 2,
    },
    paper: {
        height: "100%",
        overflowX: "hidden",
        overflowY: "auto",
        borderRadius: "6px",
        maxHeight: theme.spacing(45),
        //maxHeight: `calc(100vh - ${theme.spacing(2)}px)`
    },
    noMatchHint: {
        padding: [theme.spacing(2), theme.spacing(2)],
        textAlign: "center",
        pointerEvents: "none"
    },
    list: {
        backgroundColor: 'inherit',
    },
    listSection: {
        backgroundColor: 'inherit'
    },
    ul: {
        backgroundColor: "inherit",
        padding: 0
    }
}));

const AutocompletePopper = ({ className, open, anchorEl, top, left, items, selectedKey, onClick, ...rest }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const grouped = groupBy(items, "group");

    const preventFocus = (e) => {
        e.preventDefault(); // prevents control from taking focus from rich editor
    }

    return (
        <Popper
            open={open}
            anchorEl={anchorEl}
            className={clsx(className, classes.autocomplete)}
            placement="bottom-start"
            {...rest}
            modifiers={{
                offset: {
                    enabled: true,
                    offset: `${left}, -${top}`
                }
            }}
            transition
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                    <Paper className={classes.paper} elevation={8}>
                        {items.length === 0 ? (
                            <div onMouseDown={preventFocus}>
                                <Typography
                                    className={classes.noMatchHint}
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {t("noMatchesFound")}
                                </Typography>
                            </div>
                        ) : (
                            <List className={classes.list} subheader={<li />}>
                                {Object.keys(grouped).map(group => (
                                    <li key={`group-${group}`} className={classes.listSection}>
                                        <ul className={classes.ul}>
                                            <ListSubheader onMouseDown={preventFocus}>
                                                <div style={{pointerEvents: "none"}}>
                                                    {group !== "undefined" ? group : t("other")}
                                                </div>
                                            </ListSubheader>
                                            {grouped[group].map((item, index) => (
                                                <ListItem
                                                    button
                                                    key={`${item.key}_${index}`}
                                                    className={classes.item}
                                                    selected={item.key === selectedKey}
                                                    onMouseDown={preventFocus}
                                                    onClick={() => onClick(item)}
                                                >
                                                    {item.content}
                                                </ListItem>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </List>
                        )}
                    </Paper>
                </Fade>
            )}

        </Popper>
    )
};

AutocompletePopper.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    top: PropTypes.number,
    left: PropTypes.number,
    selectedKey: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        content: PropTypes.any.isRequired
    })),
    onClick: PropTypes.func
};

AutocompletePopper.defaultProps = {
    top: 0,
    left: 0,
    selectedKey: "",
    items: [],
    onClick: () => {}
};

export default AutocompletePopper;
