import React, {useContext, useEffect, useState} from 'react';
import {
    Box,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    alpha,
    Grid, ListItemIcon, ListItemText,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import clsx from "clsx";
import StyledContainer from "../../../components/StyledContainer";
import InvitationsSection from "./InvitationsSection";
import MembersSection from "./MembersSection";
import {CardShadow} from "../../../constants";

const useStyles = makeStyles((theme) => ({
    root: {
        ...CardShadow
    },
    section: {
        margin: [theme.spacing(0), theme.spacing(4)]
    },
    inviteButton: {
        marginTop: theme.spacing(0.5),
        flex: "0 0 auto"
    },
    sectionContent: {
        marginTop: theme.spacing(2)
    },
    divider: {
        margin: [theme.spacing(4), 0]
    },
    headerCell: {
        color: theme.palette.text.secondary,
    },
    roleSelect: {
        width: theme.spacing(15),
    }
}));

// TODO: Split component into smaller subcomponents
const WorkspaceUsersSettings = ({className}) => {
    const classes = useStyles();

    return (
        <StyledContainer id="general-workspace-settings" maxWidth="md" className={clsx(classes.root, className)}>
            <InvitationsSection
                className={classes.section}
                classes={{
                    content: classes.sectionContent,
                    tableHeader: classes.headerCell
                }}
            />
            <Divider className={classes.divider}/>
            <MembersSection
                className={classes.section}
                classes={{
                    content: classes.sectionContent,
                    tableHeader: classes.headerCell
                }}
            />
        </StyledContainer>
    );
};

export default WorkspaceUsersSettings;