import React, {forwardRef, useContext, useEffect, useRef, useState} from 'react';
import {
    alpha,
    ButtonBase,
    Divider, IconButton, ListItemIcon, ListItemText,
    makeStyles, MenuItem, Typography
} from '@material-ui/core';
import PropTypes from "prop-types";
import clsx from "clsx";
import Menu from "@material-ui/core/Menu";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import StyledMenu from "./StyledMenu";

const useStyles = makeStyles((theme) => ({
    styledMenuItem: {
    },
    icon: {
        minWidth: 0,
        marginRight: theme.spacing(1.25)
    }
}));

const StyledMenuItem = forwardRef((props, ref) => {
    const {className, label, Icon, ...rest} = props;
    const classes = useStyles();

    return (
        <MenuItem className={clsx(classes.styledMenuItem, className)} ref={ref} {...rest}>
            <ListItemIcon className={classes.icon}>
                <Icon fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary={label} primaryTypographyProps={{variant: "body2"}}/>
        </MenuItem>
    );
});

StyledMenuItem.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    Icon: PropTypes.object,
    ...MenuItem.propTypes
};

StyledMenuItem.defaultProps = {
};

export default StyledMenuItem;