import React, {useContext} from 'react';
import {
    Box,
    Container, Divider,
    Grid, Link,
    makeStyles, Typography,
    useTheme
} from '@material-ui/core';
import Card from "../../components/Card";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {STATIC_DOCUMENTS} from "../../constants/documents";
import Page from "../../components/Page/Page";
import {useSelector} from "react-redux";
import {workspaceSelector} from "../../state/workspaces/selectors";
import {WorkspaceKeyContext} from "../../contexts/WorkspaceKeyContext";
import WorkspacePage from "../../components/WorkspacePage/WorkspacePage";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
        minHeight: "100%"
    },
    title: {
        marginBottom: theme.spacing(5)
    },
    rightMargin: {
        marginRight: theme.spacing(0)
    },
    card: {
        flex: "1 1 auto",
        maxWidth: theme.spacing(43),
        height: theme.spacing(37),
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(3),
        '&>*:not(:last-child) ': {
            marginBottom: theme.spacing(2)
        }
    },
    cardContent: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: theme.spacing(1),
        height: theme.spacing(21)
    },
    icon: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2)
    },
    extensionLabel: {
        // inline-block blocks underline when hovering on Link
        display: "inline-block"
    },
    nameLabel: {
        //maxHeight: `${theme.typography.body1.lineHeight * 2}em`,
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    cardActions: {
        flex: "0 0 auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    footerLabelContainer: {
        flex: "1 1 auto"
    }

}));

// TODO: Rename
const DocumentsView = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const renderDocumentCard = (document) => (
        <Card className={classes.card}>
            <Link href={document.url} target="_blank" download={document.name}>
                <div className={classes.cardContent}>
                    <div className={classes.icon}>
                        <Typography className={classes.extensionLabel} variant="h5" align="center">
                            {document.name.split('.').pop()?.toUpperCase()}
                        </Typography>
                    </div>
                    <Typography className={classes.nameLabel} variant="body2" color="textSecondary" align="center">
                        {document.name}
                    </Typography>
                </div>
            </Link>
            <Divider/>
            <div className={classes.cardActions}>
                <Box flex="1 1 auto">
                    <Typography variant="subtitle2" color="textSecondary">
                        {t("File size")}:
                    </Typography>
                    <Typography variant="body2">
                        {document.size}
                    </Typography>
                </Box>
                <Button size="small" color="primary" href={document.url} target="_blank" download={document.name}>
                    {t("Download")}
                </Button>
            </div>
        </Card>
    );

    return (
        <WorkspacePage>
            <Container maxWidth="lg" className={classes.container}>
                {/*<div className={classes.title}>*/}
                {/*    <Typography variant="h4" color="textSecondary" component="span">*/}
                {/*        {t("Whistleblowing")}*/}
                {/*    </Typography>*/}
                {/*</div>*/}
                <Grid container
                      alignItems="flex-start"
                      spacing={2}
                >
                    {STATIC_DOCUMENTS.map(doc => (
                        <Grid key={doc.name} item xs={12} sm={6} md={3}>
                            {renderDocumentCard(doc)}
                        </Grid>
                    ))}

                </Grid>
            </Container>
        </WorkspacePage>
    );
};

export default DocumentsView;