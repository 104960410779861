import * as types from "./actionTypes";

const initialState = {};

export default function formsReducer(state = initialState, action) {
    switch (action.type) {
        case types.FORMS_LOADED: {
            const newState = {...state};
            newState[action.payload.workspaceKey] = action.payload.forms;
            return newState;
        }
        case types.FORM_ADDED: {
            const newState = {...state};
            newState[action.payload.workspaceKey] = [...(state[action.payload.workspaceKey] ?? []), action.payload.form];
            return newState;
        }
        case types.FORM_UPDATED: {
            const forms = state[action.payload.workspaceKey].map(form => {
                if (form.pk !== action.payload.formKey) {
                    return form;
                }

                return {...form, ...action.payload.data};
            });

            const newState = {...state};
            newState[action.payload.workspaceKey] = forms;
            return newState;
        }
        case types.FORM_DELETED: {
            const newState = {...state};
            newState[action.payload.workspaceKey] = state[action.payload.workspaceKey].filter(t => t.pk !== action.payload.formKey);
            return newState;
        }
        default:
            return state
    }
}
