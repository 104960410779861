import React, { forwardRef, useContext, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles, Divider, Hidden, Typography, Tooltip, Box, alpha, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { StyledMenu, StyledMenuItem } from "../../components/StyledMenu";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { DragHandleOutlined } from "@material-ui/icons";
import DragIndicatorOutlinedIcon from "@material-ui/icons/DragIndicatorOutlined";
import { Draggable } from "react-beautiful-dnd";
import { CARD_SHADOW3, CardShadow, CardShadow2, CardShadow3, DEFAULT_FORM_SIDE_PADDING_SPACING } from "../../constants";
import FormBlock from "../../components/formBlocks";
import ContentSeparator from "./ContentSeparator";
import AddIcon from "@material-ui/icons/Add";

const hoveredStyle = (theme) => ({
    //backgroundColor: theme.palette.action.hover,
    //backgroundColor: alpha(theme.palette.grey[100], 0.8),
    backgroundColor: theme.palette.grey[50],
    //background: "white",
    //boxShadow: CardShadow3,
    "&:before": {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        // borderBottom: `1px dashed rgba(0, 0, 0, 0.42)`,
        // borderTop: `1px dashed rgba(0, 0, 0, 0.42)`,
        borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
        borderTop: `1px solid rgba(0, 0, 0, 0.42)`,
        // borderLeft: `4px solid #007DFF`,
        // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
        content: '"\\00a0"',
        pointerEvents: "none", // Transparent to the hover style.
    },
    "& $toolbar": {
        visibility: "visible",
    },
    "& $addButton": {
        visibility: "visible",
    },
    // "& .MuiOutlinedInput-root > fieldset": {
    //     borderStyle: "solid"
    // },
    // '& .MuiInput-underline': {
    //     '&:before': {
    //         borderColor: "initial"
    //     }
    // }
    // '& fieldset': {
    //     visibility: "visible"
    // },
    // '& .MuiInput-underline': {
    //     '&:before': {
    //         visibility: "visible"
    //     },
    //     '&:after': {
    //         visibility: "visible"
    //     }
    // },
    // '& div[strategies]>span': {
    //     visibility: "visible"
    // }
});

const useStyles = makeStyles((theme) => ({
    editableFormBlockContainer: {
        position: "relative",
        //padding: [theme.spacing(3), theme.spacing(4)],
        //paddingTop: theme.spacing(1),
        //paddingTop: theme.spacing(4),
        margin: 0,
        padding: [theme.spacing(0), theme.spacing(4), theme.spacing(4), theme.spacing(4)],
        "&:hover": hoveredStyle(theme),
        // '& fieldset': {
        //     visibility: "hidden"
        // },
        // '& .MuiInput-underline': {
        //     '&:before': {
        //         visibility: "hidden"
        //     },
        //     '&:after': {
        //         visibility: "hidden"
        //     }
        // },
        // '& div[strategies]>span': {
        //     visibility: "hidden"
        // }
        // '& .MuiInput-underline': {
        //     '&:before': {
        //         borderColor: theme.palette.grey[400]
        //         //borderBottomColor: 'rgba(0, 0, 0, 0.23)'
        //     }
        // },
        // "& .MuiOutlinedInput-root > fieldset": {
        //     borderStyle: "none"
        // },
    },
    content: {},
    focused: hoveredStyle(theme),
    disabled: {
        opacity: theme.palette.action.disabledOpacity,
    },
    toolbar: {
        // position: "absolute",
        // left: '100%',
        // top: 0,
        // paddingLeft: theme.spacing(0.5),
        // visibility: "hidden",
        // transition: theme.transitions.create(['visibility']),
        display: "flex",
        alignItems: "center",
        color: theme.palette.text.secondary,
        padding: [theme.spacing(1), 0],
        //height: theme.spacing(4.75),
        //padding: [0, theme.spacing(DEFAULT_FORM_SIDE_PADDING_SPACING)],
        //padding: [theme.spacing(0.5), theme.spacing(4)],
        visibility: "hidden",
        position: "relative",
    },
    dragIcon: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%) rotate(90deg)",
        //transform: "rotate(90deg)",
        color: theme.palette.text.hint,
    },
    hint: {
        color: theme.palette.text.hint,
    },
    dragging: {
        backgroundColor: theme.palette.grey[100],
        "& toolbar": {
            visibility: "visible",
        },
    },
    addButton: {
        position: "absolute",
        borderRadius: 8,
        background: theme.palette.background.paper,
        border: `1px solid rgba(0, 0, 0, 0.42)`,
        zIndex: theme.zIndex.drawer + 1,
        "&:hover": {
            background: theme.palette.background.paper,
        },
        visibility: "hidden"
    },
    topButton: {
        top: 0,
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    bottomButton: {
        bottom: 0,
        left: "50%",
        transform: "translate(-50%, 50%)",
    },
}));

const EditableFormBlockContainer = forwardRef((props, ref) => {
    const {
        className,
        classes: propClasses,
        children,
        blockType,
        isDragging,
        onDuplicate,
        onDelete,
        onAddBlockBeforeClick,
        onAddBlockAfterClick,
        ...rest
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const moreButtonRef = useRef();
    const [contextMenuOpen, setContextMenuOpen] = useState(false);

    const handleDuplicateClick = () => {
        setContextMenuOpen(false);
        onDuplicate();
    };

    const handleDeleteClick = () => {
        setContextMenuOpen(false);
        onDelete();
    };

    return (
        <div
            ref={ref}
            className={clsx(classes.editableFormBlockContainer, {
                [classes.focused]: contextMenuOpen,
                [classes.dragging]: isDragging,
            })}
            {...rest}
        >
            {/*<Button*/}
            {/*    className={clsx(classes.addButton, classes.topButton)}*/}
            {/*    variant="outlined"*/}
            {/*    size="small"*/}
            {/*    color="primary"*/}
            {/*    startIcon={<AddIcon />}*/}
            {/*    onClick={onAddBlockBeforeClick}*/}
            {/*>*/}
            {/*    {t("addNewBlock")}*/}
            {/*</Button>*/}
            <div>
                <div className={clsx(classes.toolbar, propClasses.toolbar)}>
                    <Typography className={classes.hint} variant="body2">
                        {t(blockType)}
                    </Typography>
                    <Box flex="1 1 auto" />
                    <Tooltip title={t("more")}>
                        <IconButton
                            ref={moreButtonRef}
                            size="small"
                            edge="end"
                            onClick={() => setContextMenuOpen(true)}
                        >
                            <MoreHorizOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <DragIndicatorOutlinedIcon className={classes.dragIcon} fontSize="small" />
                </div>
                <StyledMenu
                    keepMounted
                    anchorEl={moreButtonRef.current}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={contextMenuOpen}
                    onClose={() => setContextMenuOpen(false)}
                >
                    <StyledMenuItem label={t("duplicate")} Icon={FileCopyOutlinedIcon} onClick={handleDuplicateClick} />
                    <StyledMenuItem label={t("delete")} Icon={DeleteOutlinedIcon} onClick={handleDeleteClick} />
                </StyledMenu>
                {children}
            </div>
            {/*<Button*/}
            {/*    className={clsx(classes.addButton, classes.bottomButton)}*/}
            {/*    variant="outlined"*/}
            {/*    size="small"*/}
            {/*    color="primary"*/}
            {/*    startIcon={<AddIcon />}*/}
            {/*    onClick={onAddBlockAfterClick}*/}
            {/*>*/}
            {/*    {t("addNewBlock")}*/}
            {/*</Button>*/}
        </div>
    );
});

EditableFormBlockContainer.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    blockType: PropTypes.string.isRequired,
    isDragging: PropTypes.bool,
    onDuplicate: PropTypes.func,
    onDelete: PropTypes.func,
};

EditableFormBlockContainer.defaultProps = {
    classes: {},
    onDuplicate: () => {},
    onDelete: () => {},
};

export default EditableFormBlockContainer;
