import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    AppBar, Box, Toolbar, makeStyles, Typography, Divider
} from '@material-ui/core';
import clsx from 'clsx';
import UserAvatar from "../../components/UserAvatar";
import { useSelector } from "react-redux";
import { currentUserSelector } from "../../state/currentUser/selectors";
import { TopBarHeight } from "../../constants";
import { useTranslation } from "react-i18next";
import ProfileButton from "../../components/ProfileButton/ProfileButton";
import { toWorkspaceKey } from "../../utils/apiUtils";
import { workspaceSelector } from "../../state/workspaces/selectors";
import { currentUserRoleSelector } from "../../state/members/selectors";
import StyledTabs from "../../components/StyledTabs/StyledTabs";
import StyledTab from "../../components/StyledTabs/StyledTab";
import { isAdmin, isOwner } from "../../utils/user";

const useStyles = makeStyles((theme) => ({
    appBar: {
        flex: 0,
        backgroundColor: theme.palette.background.default
    },
    toolbar: {
        minHeight: TopBarHeight,
        height: TopBarHeight
    },
    logo: {
        marginRight: theme.spacing(2),
        height: theme.spacing(6),
        width: theme.spacing(6),
        fontSize: '1.5rem',
        borderRadius: 10
    },
    title: {
        marginTop: theme.spacing(1)
    },
    headerContainer: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowX: 'auto', // to allow the tabs to scroll horizontally when they exceed the screen width, making them more accessible on smaller devices..?
        '&>*:not(:first-child)': {
            marginLeft: "1px" // to vertically align with title
        }
    },
    toolbarTabs: {
        //marginLeft: theme.spacing(3),
        height: "100%"
    },
    toolbarTab:{
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        //marginTop: "24px", // to center tab labels vertically
        textDecoration: "none!important",
        textTransform: "none",
        ...theme.typography.body2,
        lineHeight: 1,
    },
}));

const workspaceRoutePatterns = [
    /\/portal\/[0-9]{9}\/overview/,
    /\/portal\/[0-9]{9}\/reports/,
    /\/portal\/[0-9]{9}\/documents/,
    /\/portal\/[0-9]{9}\/settings/,
    /\/portal\/[0-9]{9}\/forms/
]

const personalRoutes = [
    {
        pattern: /\/portal\/home/,
        titleKey: "home"
    }
]

const getWorkspaceId = (route) => {
    const regex = /\/portal\/([0-9]{9})/
    const match = route.match(regex);
    if (Boolean(match) && match.length > 1) {
        return match[1];
    }
    return null;
};

const getTitle = (route, workspace, t) => {
    if (workspace) {
        return workspace.name;
    }

    const matchingRoute = personalRoutes.find(pattern => Boolean(route.match(pattern.pattern)));
    return matchingRoute ? t(matchingRoute.titleKey) : "";
};

const getActiveTabValue = (route, isOwner, isAdmin) => {
    // If no workspace route is specified, return index of the reports tab by default.
    let index = 1;
    for (let i = 0; i < workspaceRoutePatterns.length; i += 1) {
        if (Boolean(route.match(workspaceRoutePatterns[i]))) {
            index = i;
            break;
        }
    }
    // Return 0 if the user is solver and is trying to access /settings or /forms by typing the url
    // TODO: Redirect to 404?
    if (!isAdmin && !isOwner && index > 2) {
        index = 1;
    }
    return index;
}

const PortalTopBar = ({ className }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const workspaceId = getWorkspaceId(pathname);
    const workspaceKey = workspaceId ? toWorkspaceKey(workspaceId) : null;
    const workspace = useSelector(workspaceSelector(workspaceKey));
    const currentUser = useSelector(currentUserSelector());
    const currentUserRole = useSelector(currentUserRoleSelector(workspace?.pk));
    const portalRoute = `/portal/${workspaceId}`;
    const title = getTitle(pathname, workspace, t);
    const activeTab = getActiveTabValue(pathname, workspace ? isOwner(workspace, currentUser) : false, isAdmin(currentUserRole));

    return (
        <AppBar className={clsx(classes.appBar, className)} position="sticky" color="transparent" elevation={0}>
            <Toolbar className={clsx(classes.toolbar)}>
                {workspace ? (
                    <>
                        <UserAvatar
                            className={classes.logo}
                            name={workspace.name ?? ""}
                            imageKey={workspace.picture}
                            component="span"
                            size="large"
                            variant={"rounded"}
                        />
                        <div className={classes.headerContainer}>
                            <Typography className={classes.title} variant="h5">
                                {title}
                            </Typography>
                            <StyledTabs className={classes.toolbarTabs} value={activeTab} variant="scrollable"  scrollButtons="auto">  
                                <StyledTab
                                    id="overview-tab"
                                    component={NavLink}
                                    to={`${portalRoute}/overview`}
                                    className={classes.toolbarTab}
                                    disableRipple
                                    label={t("overview")}
                                />
                                <StyledTab
                                    id="reports-tab"
                                    component={NavLink}
                                    to={`${portalRoute}/reports`}
                                    className={classes.toolbarTab}
                                    disableRipple
                                    label={t("reports")}
                                />
                                <StyledTab
                                    id="documents-tab"
                                    component={NavLink}
                                    to={`${portalRoute}/documents`}
                                    className={classes.toolbarTab}
                                    disableRipple
                                    label={t("documents")}
                                />
                                {(isOwner(workspace, currentUser) || isAdmin(currentUserRole)) && (
                                    <StyledTab
                                        id="settings-tab"
                                        component={NavLink}
                                        to={`${portalRoute}/settings`}
                                        className={classes.toolbarTab}
                                        disableRipple
                                        label={t("settings")}
                                    />
                                )}
                                {(isOwner(workspace, currentUser) || isAdmin(currentUserRole)) && (
                                    <StyledTab
                                        id="forms-tab"
                                        component={NavLink}
                                        to={`${portalRoute}/forms`}
                                        className={classes.toolbarTab}
                                        disableRipple
                                        label={t("forms")}
                                    />
                                )}
                            </StyledTabs>
                        </div>
                    </>
                ) : (
                    <Typography variant="h5">{title}</Typography>
                )}
                <Box flexGrow={1} />
                <ProfileButton />
            </Toolbar>
            <Divider />
        </AppBar>
    );
};

PortalTopBar.propTypes = {
    className: PropTypes.string
};

PortalTopBar.defaultProps = {
};

export default PortalTopBar