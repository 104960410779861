import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles,
    CssBaseline,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    TablePagination,
    List,
    ListItem,
    ListItemAvatar,
    useTheme,
    ListItemText,
    Divider,
    ListItemSecondaryAction,
    ListItemIcon,
    ButtonBase,
    Tooltip,
    Toolbar,
    TextField, Container, Fab, Button, MenuItem, Link, Popover, Chip
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AvatarMenuItem from "./AvatarMenuItem";
import {activeMembersSelector} from "../state/members/selectors";
import RichTextEditor2 from "./RichTextEditor2/RichTextEditor2";
import {emptyEditorState, serializeEditorState} from "./RichTextEditor2/editorState";
import {useParams} from "react-router";
import {toWorkspaceKey} from "../utils/apiUtils";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import {getAtMentionStrategy} from "./RichTextEditor2/autocompleteUtils";

const useStyles = makeStyles((theme) => ({
    chatInputBox: {
        // display: "flex",
        // flexDirection: "row",
        //alignItems: "flex-start",
        borderRadius: 6,
        // border: `1px solid ${theme.palette.border.default}`,
        padding: [0, theme.spacing(1), theme.spacing(1), theme.spacing(1)]
        // '&:hover': {
        //     borderColor: theme.palette.border.hover
        // }
    },
    submitButton: {
        flex: "0 0 auto",
        // marginBottom: 0
    },
    messageContainer: {
        // flex: "1 1 auto",
        // minWidth: 0,
        // marginTop: props => props.layout === "row" ? theme.spacing(1) : 0,
        // marginRight: props => props.layout === "row" ? theme.spacing(1.5) : 0,
        // marginLeft: props => props.layout === "row" ? theme.spacing(1.5) : 0,
        //marginBottom: theme.spacing(1)
        //margin: [theme.spacing(1), theme.spacing(1.5)]
    },
    textField: {
        padding: "18.5px 14px",
        //padding: [theme.spacing(2), theme.spacing(1.75), theme.spacing(0), theme.spacing(1.75)],
        "&>div": {
            padding: 0
        }
    },
    attachmentsContainer: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1.0)
    },
    standardSubmit: {
        // marginTop: theme.spacing(0.75),
        // marginRight: theme.spacing(0.75),
    },
    toolbar: {
        //marginTop: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        //paddingLeft: theme.spacing(0.75)
    },
    toolbarLeft: {
        flex: "0 0 auto",
        '&>*:not(:last-child) ': {
            //marginRight: theme.spacing(0.25),
        }
    },
    toolbarMiddle: {
        flex: "1 1 auto",
        marginRight: theme.spacing(1)
    },
    toolbarButton: {
        padding: theme.spacing(0.5),
    },
    toolbarDivider: {
        height: theme.spacing(2.5),
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(1),
    },
    notificationHint: {
        color: theme.palette.text.hint,
        // whiteSpace: "nowrap",
        // textOverflow: "ellipsis",
        // overflow: "hidden"
    }
}));

const userMentionPattern = new RegExp(/\"type\":\"MENTION\",\"mutability\":\"IMMUTABLE\",\"data\":{\"url\":\"#\",\"key\":\"(user#.{36})/g);

const RichChatInputBox = ({className, placeholder, onSubmit}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {workspaceId} = useParams();
    const workspaceKey = toWorkspaceKey(workspaceId);
    const [editorState, setEditorState] = useState(() => emptyEditorState());
    const [attachments, setAttachments] = useState([]);
    const activeMembers = useSelector(activeMembersSelector(workspaceKey));
    const autocompleteStrategies = useMemo(() => (
        [getAtMentionStrategy(activeMembers, t)]
    ), [activeMembers]);

    const handleSave = () => {
        const attachmentKeys = attachments.filter(a => Boolean(a.key)).map(a => a.key);
        const content = serializeEditorState(editorState);
        let mentionedUsers = [];
        for (const entityId of Object.keys(content.entityMap)) {
            const entity = content.entityMap[entityId];
            if (entity.type === "MENTION") {
                if (entity.data.key && entity.data.key.startsWith("user#")) {
                    mentionedUsers.push(entity.data.key);
                }
            }
        }
        onSubmit({content: content, attachments: attachmentKeys, mentionedUsers: mentionedUsers});
        setEditorState(emptyEditorState());
        setAttachments([]);
    }

    return (
        <div className={clsx(classes.chatInputBox, className)}>
            <RichTextEditor2
                editorState={editorState}
                attachments={attachments}
                placeholder={placeholder}
                variant="outlined"
                padding='dense'
                highlightOnHover={false}
                highlightOnFocus={false}
                options={["attachment", "mention", "filler", "send"]}
                autocompleteStrategies={autocompleteStrategies}
                onChange={setEditorState}
                onAttachmentsChange={setAttachments}
                customControls={[
                    {
                        name: "send",
                        component: (
                            <Button
                                size="medium"
                                color="primary"
                                variant="contained"
                                onClick={handleSave}
                            >
                                {t("send")}
                            </Button>
                        )
                    },
                    {
                        name: "mention",
                        type: "callback",
                        icon: <AlternateEmailIcon fontSize="small" />,
                        action: "addText",
                        text: "@"
                    },
                ]}
            />
        </div>
    );
}

RichChatInputBox.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onSubmit: PropTypes.func
}

RichChatInputBox.defaultProps = {
    onSubmit: () => {}
};

export default RichChatInputBox;