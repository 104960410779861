/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateResourcePub = /* GraphQL */ `
  subscription OnCreateResourcePub {
    onCreateResourcePub {
      pk
      sk
      workspace
      owners
      group
      readOnlyGroup
      subscription
      createdAt
      updatedAt
      name
      picture
      email
      plan
      address
      phone
      cin
      country
      locale
      role
      description
      solution
      state
      stateUpdatedAt
      excerpt
      excerptUpdatedAt
      sender
      text
      content
      attachments
      submitLayout
      disabledSubmitLanguages
      reportSettings
      lastReads
      pushSubscription
      signature
      approval
      assigned
      followers
      dueDate
      tags
      color
      termLength
      active
      assignTo
      language
      template
      languagePacks
      passwordProtected
      disabled
    }
  }
`;
export const onUpdateResourcePub = /* GraphQL */ `
  subscription OnUpdateResourcePub {
    onUpdateResourcePub {
      pk
      sk
      workspace
      owners
      group
      readOnlyGroup
      subscription
      createdAt
      updatedAt
      name
      picture
      email
      plan
      address
      phone
      cin
      country
      locale
      role
      description
      solution
      state
      stateUpdatedAt
      excerpt
      excerptUpdatedAt
      sender
      text
      content
      attachments
      submitLayout
      disabledSubmitLanguages
      reportSettings
      lastReads
      pushSubscription
      signature
      approval
      assigned
      followers
      dueDate
      tags
      color
      termLength
      active
      assignTo
      language
      template
      languagePacks
      passwordProtected
      disabled
    }
  }
`;
export const onDeleteResourcePub = /* GraphQL */ `
  subscription OnDeleteResourcePub {
    onDeleteResourcePub {
      pk
      sk
      workspace
      owners
      group
      readOnlyGroup
      subscription
      createdAt
      updatedAt
      name
      picture
      email
      plan
      address
      phone
      cin
      country
      locale
      role
      description
      solution
      state
      stateUpdatedAt
      excerpt
      excerptUpdatedAt
      sender
      text
      content
      attachments
      submitLayout
      disabledSubmitLanguages
      reportSettings
      lastReads
      pushSubscription
      signature
      approval
      assigned
      followers
      dueDate
      tags
      color
      termLength
      active
      assignTo
      language
      template
      languagePacks
      passwordProtected
      disabled
    }
  }
`;
export const onCreateResource = /* GraphQL */ `
  subscription OnCreateResource {
    onCreateResource {
      pk
      sk
      workspace
      owners
      group
      readOnlyGroup
      subscription
      createdAt
      updatedAt
      name
      picture
      email
      plan
      address
      phone
      cin
      country
      locale
      role
      description
      solution
      state
      stateUpdatedAt
      excerpt
      excerptUpdatedAt
      sender
      text
      content
      attachments
      submitLayout
      disabledSubmitLanguages
      reportSettings
      lastReads
      pushSubscription
      signature
      approval
      assigned
      followers
      dueDate
      tags
      color
      termLength
      active
      assignTo
      language
      template
      languagePacks
      passwordProtected
      disabled
    }
  }
`;
export const onUpdateResource = /* GraphQL */ `
  subscription OnUpdateResource {
    onUpdateResource {
      pk
      sk
      workspace
      owners
      group
      readOnlyGroup
      subscription
      createdAt
      updatedAt
      name
      picture
      email
      plan
      address
      phone
      cin
      country
      locale
      role
      description
      solution
      state
      stateUpdatedAt
      excerpt
      excerptUpdatedAt
      sender
      text
      content
      attachments
      submitLayout
      disabledSubmitLanguages
      reportSettings
      lastReads
      pushSubscription
      signature
      approval
      assigned
      followers
      dueDate
      tags
      color
      termLength
      active
      assignTo
      language
      template
      languagePacks
      passwordProtected
      disabled
    }
  }
`;
export const onDeleteResource = /* GraphQL */ `
  subscription OnDeleteResource {
    onDeleteResource {
      pk
      sk
      workspace
      owners
      group
      readOnlyGroup
      subscription
      createdAt
      updatedAt
      name
      picture
      email
      plan
      address
      phone
      cin
      country
      locale
      role
      description
      solution
      state
      stateUpdatedAt
      excerpt
      excerptUpdatedAt
      sender
      text
      content
      attachments
      submitLayout
      disabledSubmitLanguages
      reportSettings
      lastReads
      pushSubscription
      signature
      approval
      assigned
      followers
      dueDate
      tags
      color
      termLength
      active
      assignTo
      language
      template
      languagePacks
      passwordProtected
      disabled
    }
  }
`;
export const onCreateResourceAccessCredentials = /* GraphQL */ `
  subscription OnCreateResourceAccessCredentials {
    onCreateResourceAccessCredentials {
      resourceKey
      accessCode
      group
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateResourceAccessCredentials = /* GraphQL */ `
  subscription OnUpdateResourceAccessCredentials {
    onUpdateResourceAccessCredentials {
      resourceKey
      accessCode
      group
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteResourceAccessCredentials = /* GraphQL */ `
  subscription OnDeleteResourceAccessCredentials {
    onDeleteResourceAccessCredentials {
      resourceKey
      accessCode
      group
      createdAt
      updatedAt
    }
  }
`;
export const onCreateResourceAccessKey = /* GraphQL */ `
  subscription OnCreateResourceAccessKey {
    onCreateResourceAccessKey {
      id
      resourceKey
      email
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateResourceAccessKey = /* GraphQL */ `
  subscription OnUpdateResourceAccessKey {
    onUpdateResourceAccessKey {
      id
      resourceKey
      email
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteResourceAccessKey = /* GraphQL */ `
  subscription OnDeleteResourceAccessKey {
    onDeleteResourceAccessKey {
      id
      resourceKey
      email
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInvitation = /* GraphQL */ `
  subscription OnCreateInvitation {
    onCreateInvitation {
      id
      email
      workspace
      role
      workspaceName
      senderName
      locale
      group
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInvitation = /* GraphQL */ `
  subscription OnUpdateInvitation {
    onUpdateInvitation {
      id
      email
      workspace
      role
      workspaceName
      senderName
      locale
      group
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInvitation = /* GraphQL */ `
  subscription OnDeleteInvitation {
    onDeleteInvitation {
      id
      email
      workspace
      role
      workspaceName
      senderName
      locale
      group
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVoicemail = /* GraphQL */ `
  subscription OnCreateVoicemail {
    onCreateVoicemail {
      contactId
      workspaceId
      assetId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVoicemail = /* GraphQL */ `
  subscription OnUpdateVoicemail {
    onUpdateVoicemail {
      contactId
      workspaceId
      assetId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVoicemail = /* GraphQL */ `
  subscription OnDeleteVoicemail {
    onDeleteVoicemail {
      contactId
      workspaceId
      assetId
      createdAt
      updatedAt
    }
  }
`;
