import React, {useContext, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles,
    CssBaseline,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    TablePagination,
    List,
    ListItem,
    ListItemAvatar,
    useTheme,
    ListItemText,
    Divider,
    ListItemSecondaryAction,
    ListItemIcon,
    ButtonBase,
    Paper,
    TextField,
    ClickAwayListener, InputAdornment, FormControl, InputLabel, Input, Link, Button
} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import {ApiContext} from "../../contexts/ApiContext";
import UserPicker from "../../components/UserPicker";
import SettingsField from "../../components/SettingsField";
import {currentUserSelector} from "../../state/currentUser/selectors";
import ChatMessage from "../../components/chat/ChatMessage";
import {commentsSelector} from "../../state/comments/selectors";
import {useTranslation} from "react-i18next";
import {currentUserRoleSelector, allMembersSelector, activeMembersSelector} from "../../state/members/selectors";
import {ChatInputBox} from "../../components/chat";
import ChatMessageSkeleton from "../../components/chat/ChatMessageSkeleton";
import {scrollToView} from "../../utils/ui";
import {getMessageSender} from "../../utils/redux";
import {getReportName} from "../../utils/textUtils";
import {addOrUpdateReport} from "../../state/reports/actions";
import TitleIcon from "@material-ui/icons/Title";
import RichTextEditor2 from "../../components/RichTextEditor2/RichTextEditor2";
import RichChatInputBox from "../../components/RichChatInputBox";
import SettingsControl from "../../components/SettingsControl";
import DueDatePicker from "../../components/DueDatePicker";
import { isOpen } from "../../utils/reportUtils";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    content: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        padding: [0, theme.spacing(4), theme.spacing(3), theme.spacing(4)],
        '&>*:not(:first-child):not(:last-child)': {
            marginTop: theme.spacing(3)
        }
    },
    inputContainer: {
        flex: "0 0 auto",
        //padding: theme.spacing(1),
        margin: [0, theme.spacing(3), theme.spacing(3), theme.spacing(3)],
        backgroundColor: theme.palette.background.paper
    },
    userPickerContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: theme.spacing(3.25),
        marginBottom: theme.spacing(3.25)
    },
    userPicker: {
        display: "inline-block",
    },
    userPickerLabel: {
        flex: "0 0 auto",
        width: theme.spacing(13)
    },
    comments: {
        flex: "1 1 auto",
        marginTop: theme.spacing(5),
        '&>div:not(:last-child) ': {
            marginBottom: theme.spacing(3)
        }
    },
    fieldMargin: {
        marginBottom: theme.spacing(0)
    },
    commentContainer: {
        maxWidth: "100%"
    },
    comment: {
        maxWidth: "100%",
        padding: 0,
        border: 'initial',
        backgroundColor: 'initial',
        color: theme.palette.text.secondary
    },
    assigneeLabel: {
        //display: "inline-block",
        //marginRight: theme.spacing(1.5)
        marginBottom: theme.spacing(0.5)
    },
}));

let reportKey = null;

// TODO: Don't use readOnly, split components
// TODO: Reduce number of flexboxes
const ReportAttributesPanel = ({className, report, readOnly}) => {
    const classes = useStyles();
    const api = useContext(ApiContext);
    const { t } = useTranslation();
    const currentUser = useSelector(currentUserSelector());
    const currentUserRole = useSelector(currentUserRoleSelector(report.workspace));
    // TODO: Users should not be undefined
    // TODO: Don't call user selector twice
    const allMembers = useSelector(allMembersSelector(report.workspace));
    const comments = useSelector(commentsSelector(report.pk)) ?? null;
    const startRef = useRef(null);
    const endRef = useRef(null);
    const dispatch = useDispatch();
    const nameChanged = useRef(false);

    useEffect(() => {
        // Scroll to bottom only if a new comment is added. If the report argument has changed the scroll
        // will be reset to top.
        if (!comments) {
            return;
        }
        if (reportKey !== report.pk) {
            scrollToView(startRef);
            reportKey = report.pk;
            return;
        }
        scrollToView(endRef);
    }, [report.pk, comments?.length]);

    const onNameChange = (value) => {
        nameChanged.current = true;
        // TODO: Propagate change to other components as well
        //dispatch(addOrUpdateReport(report.workspace, report.pk,{name: value}));
    }

    const handleNameApplied = async (value) => {
        console.log("handleNameApplied, value: %o, nameChanged.current: %o", value, nameChanged.current);
        if (nameChanged.current) {
            dispatch(addOrUpdateReport(report.workspace, report.pk,{name: value}));
            nameChanged.current = false;
            await api.mutation.updateResource(report.pk, report.sk, {name: value});
        }
    }

    const handleDescriptionApplied = async (value) => {
        if (value !== report.description) {
            //dispatch(updateReport(report.workspace, report.pk,{description: value}));
            await api.mutation.updateReportDescription(report.pk, report.sk, value);
        }
    }

    const handleSolutionApplied = async (value) => {
        if (value !== report.solution) {
            //dispatch(updateReport(report.workspace, report.pk,{solution: value}));
            await api.mutation.updateReportSolution(report.pk, report.sk, value);
        }
    }

    const handleCommentSubmit = async ({content, attachments, mentionedUsers}) => {
        await api.mutation.createComment(report.workspace, report.pk, currentUser.pk, content, attachments);
        if (!mentionedUsers) {
            return;
        }
        const newFollowers = [...new Set([...(report.followers ?? []), ...mentionedUsers])];
        await api.mutation.updateResource(report.pk, report.sk, {followers: newFollowers});
        //dispatch(addComment(comment));
    };

    const handleAssigneeAdded = async (userId) => {
        //dispatch(updateReport(report.workspace, report.pk,{assigned: assignedNew}));
        await api.mutation.addReportAssignee(report, userId, currentUser.pk);
    }

    const handleAssigneeRemoved = async (userId) => {
        //dispatch(updateReport(report.workspace, report.pk,{assigned: assignedNew}));
        await api.mutation.removeReportAssignee(report, userId, currentUser.pk);
    }

    const handleDueDateChanged = async (date) => {
        const data = { dueDate: date?.toISOString() ?? null };
        dispatch(addOrUpdateReport(report.pk, report.sk, data));
        await api.mutation.updateResource(report.pk, report.sk, data);
    }

    // TODO: Don't use flexbox for fields
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.content}>
                <div ref={startRef} />
                    <SettingsField
                        className={classes.fieldMargin}
                        label={t("common.name")}
                        readOnly={readOnly}
                        value={getReportName(report, t)}
                        onChange={onNameChange}
                        onSave={handleNameApplied}
                    />
                    <SettingsField
                        className={classes.fieldMargin}
                        richMode
                        label={t("Description")}
                        readOnly={readOnly}
                        value={report.description}
                        onSave={handleDescriptionApplied}
                        placeholder={`${t("Report summary")}...`}
                    />
                    <SettingsField
                        className={classes.fieldMargin}
                        richMode
                        label={t("Solution")}
                        readOnly={readOnly}
                        value={report.solution}
                        onSave={handleSolutionApplied}
                        placeholder={`${t("Report solution")}...`}
                    />
                <SettingsControl labelKey={"dueDate"}>
                    <DueDatePicker
                        value={report.dueDate ? new Date(report.dueDate) : undefined}
                        disabled={!isOpen(report)}
                        onChange={handleDueDateChanged}
                    />
                </SettingsControl>
                    <SettingsControl labelKey={"assigned"}>
                            <UserPicker
                                userIDs={report.owners ?? []}
                                readOnly={currentUserRole === "approver"}
                                onAdded={handleAssigneeAdded}
                                onRemoved={handleAssigneeRemoved}
                            />
                    </SettingsControl>
                {/*<div>*/}
                {/*    <Typography className={classes.assigneeLabel} variant="subtitle2" color="textSecondary">*/}
                {/*        {t("collaborators")}*/}
                {/*    </Typography>*/}
                {/*    <UserPicker*/}
                {/*        variant="IconButton"*/}
                {/*        assignedIds={report.owners ?? []}*/}
                {/*        readOnly={currentUserRole === "approver"}*/}
                {/*        onAdded={handleAssigneeAdded}*/}
                {/*        onRemoved={handleAssigneeRemoved}*/}
                {/*    />*/}
                {/*</div>*/}
                <Divider/>
                <div className={classes.comments}>
                    { comments && comments.map(comment => {
                        const sender = getMessageSender(comment, allMembers);
                        if (!sender) {
                            // Sender can be a user that was deleted from the workspace. We wait until his data are
                            // fetched into redux.
                            return (<ChatMessageSkeleton key={comment.sk}/>)
                        }
                        return (
                            <ChatMessage
                                key={comment.sk}
                                message={comment}
                                sender={sender}
                                showSenderName
                                position="left"
                                labelPlacement="top"
                                attachmentSize="small"
                                classes={{
                                    container: classes.commentContainer,
                                    message: classes.comment
                                }}
                            />
                    )}) }
                </div>
                <div ref={endRef} />
            </div>
            <div className={classes.inputContainer}>
                <RichChatInputBox
                    placeholder={t("commentInputPlaceholder")}
                    onSubmit={handleCommentSubmit}
                />
                {/*<ChatInputBox*/}
                {/*    workspaceKey={report.workspace}*/}
                {/*    allowAttachments={false}*/}
                {/*    onSubmit={handleCommentSubmit}*/}
                {/*    buttonType="icon"*/}
                {/*    placeholder={t("commentInputPlaceholder")}*/}
                {/*/>*/}
            </div>
        </div>
    );
}

ReportAttributesPanel.propTypes = {
    className: PropTypes.string,
    readOnly: PropTypes.bool,
}

export default ReportAttributesPanel;