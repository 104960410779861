import { toId } from "../../utils/apiUtils";

export const reportSelector = (workspaceKey, reportKey) => state => {
    return state.reports[workspaceKey]?.find(t => t.pk === reportKey);
}
// TODO: Filter reports on backend!!!!
export const workspaceReports = (workspaceKey) => state => {
    if (!state.currentUser || !state.members[workspaceKey]) {
        return [];
    }

    // Return all reports if current user role is not approver
    const membership = state.members[workspaceKey].find(t => t.pk === state.currentUser.pk);
    if (membership.role !== "approver") {
        return state.reports[workspaceKey] ?? [];
    }

    // Return only approved reports or reports awaiting approval
    return state.reports[workspaceKey]?.filter(t => Boolean(t.approval) || t.followers?.includes(state.currentUser.pk) || t.owners?.includes(toId(state.currentUser.pk))) ?? [];
};

export const reportsAssignedToCurrentUser = () => state => {
    if (!state.currentUser) {
        return [];
    }

    return Object.entries(state.reports).flatMap(report => report.owners?.includes(state.currentUser.id)  ? [report] : []);
};
