import React, {useContext, useState, useEffect, useCallback, useRef} from 'react';
import {ApiContext} from "../contexts/ApiContext";
import {ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {addMessage, loadMessages} from "../state/messages/actions";
import {isComment, isMessage, isReport, toReportKey, toUserKey} from "../utils/apiUtils";
import {loadComments} from "../state/comments/actions";
import {addOrUpdateReport} from "../state/reports/actions";
import {addMember, addMembers, loadMembers} from "../state/members/actions";
import {loadWorkspaces} from "../state/workspaces/actions";

export const useReportAsGuest = (reportKey) => {
    const api = useContext(ApiContext);
    const dispatch = useDispatch();
    const { store } = useContext(ReactReduxContext);
    const subscriptionRef = useRef();

    const fetchReportMessages = async () => {
        return await api.query.listReportMessages(reportKey);
    }

    const fetchWorkspace = async (workspaceKey) => {
        return  await api.query.getWorkspace(workspaceKey);
    }

    const fetchSenders = async (messages, workspaceKey) => {
        const senders = messages.filter(msg => Boolean(msg.sender)).map(msg => msg.sender);
        const uniqueUsers = [...new Set(senders)];
        const existingUsers = store.getState().members[workspaceKey];
        // Omit keys of users that are already loaded in redux
        const keysToFetch = existingUsers
            ? uniqueUsers.flatMap(key =>!existingUsers.find(user => user.pk === key)  ? [key] : [])
            : uniqueUsers;
        if (keysToFetch.length === 0) {
            return;
        }

        const users = [];
        for (const key of keysToFetch) {
            const user = await api.query.getUserProfile(key);
            users.push(user);
        }
        return users;
    };

    const subscribeToChanges = (workspaceKey) => {
        if (subscriptionRef.current) {
            console.log("Cancelling existing message subscription");
            subscriptionRef.current.unsubscribe();
        }

        subscriptionRef.current = api.subscriptions.onReportResourceCreated(workspaceKey, reportKey,(resource) => {
            if (isMessage(resource)) {
                dispatch(addMessage(reportKey, resource));
            }
        });
    }

    useEffect(() => {
        if (!reportKey) {
            return;
        }
        const messages = store.getState().messages[reportKey];
        if (messages && messages.length > 0) {
            return;
        }
        (async () => {
            const messages = await fetchReportMessages();
            const workspace = await fetchWorkspace(messages[0].subscription);
            const users = await fetchSenders(messages, workspace.pk);
            subscribeToChanges(workspace.pk);

            dispatch(loadWorkspaces([workspace]));
            dispatch(loadMembers(workspace.pk, users));
            dispatch(loadMessages(reportKey, messages));

        })();

    }, [reportKey]);

    return subscriptionRef;
}

