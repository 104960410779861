import React, { forwardRef, useContext, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles, Divider, Hidden, Typography, Tooltip, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    formBlockContainer: {
        paddingTop: theme.spacing(4),
        [theme.breakpoints.up("xs")]: {
            margin: [0, theme.spacing(2)],
        },
        [theme.breakpoints.up("md")]: {
            margin: [0, theme.spacing(3)],
        },
        [theme.breakpoints.up("lg")]: {
            margin: [0, theme.spacing(6)],
        },
    },
}));

const FormBlockContainer = forwardRef((props, ref) => {
    const {
        className,
        children
    } = props;
    const classes = useStyles();

    return (
        <div ref={ref} className={clsx(classes.formBlockContainer, className)}>
            {children}
        </div>
    );
});

FormBlockContainer.propTypes = {
    className: PropTypes.string
};

FormBlockContainer.defaultProps = {
};

export default FormBlockContainer;
