import * as handlebars from "handlebars";
import {s4, s8} from "./idGenerator";
import cloneDeep from 'lodash/cloneDeep';
import {deminifyDraftEditorState, minifyDraftEditorState} from "./draftJsUtils";
import {deleteElementAtIndex} from "./arrayUtils";

export async function newForm(templateName, defaultLanguage) {
    const template = await import(`../templates/forms/${templateName}.json`).then(module => module.default);
    console.log("template: %o", template);
}

export async function loadFormTemplate(templateName) {
    return await import(`../templates/forms/${templateName}.json`).then(module => module.default);
}

export async function loadFormLanguagePacks(templateName, languages = []) {
    const languagePacks = {};
    for (const language of languages) {
        const languagePack = await import(`../locales/${language}/forms/${templateName}.json`).then(module => module.default);
        languagePacks[language] = languagePack;
    }
    return languagePacks;
}

export function hydrateLanguagePacks(languagePacks, variables = {}) {
    const hydrateFunc = handlebars.compile(JSON.stringify(languagePacks));
    const hydratedLanguagePacks = hydrateFunc(variables);
    return JSON.parse(hydratedLanguagePacks);
}

// TODO: Reuse language pack and template hydrate functions
// TODO: REduce amount of stringification and parsing
export function hydrateFormTemplate(template, languagePack, variables = {}) {
    const hydrateLanguagePackFunc = handlebars.compile(JSON.stringify(languagePack));
    const hydratedLanguagePack = hydrateLanguagePackFunc(variables);

    const hydrateTemplateFunc = handlebars.compile(JSON.stringify(template));
    const hydratedTemplate = hydrateTemplateFunc(JSON.parse(hydratedLanguagePack));

    return JSON.parse(hydratedTemplate);
}

export function createDataBlock(key, value, type = undefined) {
    return {key, value, type}
}

export function getFormBlockId(block) {
    return `block-${block.type}-${block.key}`;
}

export function getBlockLocalizationKeys(block) {
    let localizationKeys = [];
    for (const key in block) {
        if (key === 'key' || key === 'type') {
            continue;
        }

        if (Array.isArray(block[key])) {
            localizationKeys = localizationKeys.concat(block[key]);
        } else {
            localizationKeys.push(block[key]);
        }
    }

    return localizationKeys;
}

export function duplicateBlock(template, languagePacks, pageIndex, blockIndex) {
    // Replace block key and all localization keys in the cloned block
    const block = template.pages[pageIndex].blocks[blockIndex];
    const localizationKeys = getBlockLocalizationKeys(block);
    const newLocalizationKeys = localizationKeys.map(t => s8());
    let blockJson = JSON.stringify(block);
    for (let i = 0; i <  localizationKeys.length; i ++) {
        blockJson = blockJson.replace(localizationKeys[i], newLocalizationKeys[i]);
    }
    const newBlock = JSON.parse(blockJson);
    newBlock.key = s8();

    // Clone template
    // const newTemplate = cloneDeep(template);
    // newTemplate.pages[pageIndex].blocks.splice(blockIndex + 1, 0, newBlock);
    const newBlocks = [...template.pages[pageIndex].blocks];
    newBlocks.splice(blockIndex + 1, 0, newBlock);
    const newPage = {...template.pages[pageIndex], blocks: newBlocks};
    const newPages = [...template.pages];
    newPages[pageIndex] = newPage;
    const newTemplate =  {
        ...template,
        pages: newPages
    };

    // Update language packs
    const newLanguagePacks = {};
    for (const language in languagePacks) {
        const newLanguagePack = {...languagePacks[language]};
        for (let i = 0; i <  localizationKeys.length; i ++) {
            const originalKey = localizationKeys[i];
            const newKey = newLocalizationKeys[i];
            newLanguagePack[newKey] = cloneDeep(languagePacks[language][originalKey]);
        }
        newLanguagePacks[language] = newLanguagePack;
    }

    return [newTemplate, newLanguagePacks];
}

export function deleteBlock(template, languagePacks, pageIndex, blockIndex) {
    // Remove block from template
    // const newTemplate = cloneDeep(template);
    // newTemplate.pages[pageIndex].blocks.splice(blockIndex, 1);
    const newBlocks = [...template.pages[pageIndex].blocks];
    newBlocks.splice(blockIndex, 1);
    const newPage = {...template.pages[pageIndex], blocks: newBlocks};
    const newPages = [...template.pages];
    newPages[pageIndex] = newPage;
    const newTemplate = {...template, pages: newPages};

    // Remove localization entries from language packs
    const newLanguagePacks = {};
    const block = template.pages[pageIndex].blocks[blockIndex];
    const localizationKeys = getBlockLocalizationKeys(block);
    for (const language in languagePacks) {
        newLanguagePacks[language] = {...languagePacks[language]};
        for (const key of localizationKeys) {
            delete newLanguagePacks[language][key];
        }
    }

    return [newTemplate, newLanguagePacks];
}

export function normalizeLocalizationKey(localizationKey) {
    // TODO: Remove curly braces from IDs??
    return localizationKey.substring(2, localizationKey.length - 2);
}

export function minifyLanguagePacks(languagePacks) {
    if (!Boolean(languagePacks)) {
        return languagePacks;
    }

    const minifiedLanguagePacks = cloneDeep(languagePacks);
    for (const languagePack of Object.values(minifiedLanguagePacks)) {
        for (const key in languagePack) {
            if (typeof languagePack[key] !== 'object') {
                continue;
            }

            languagePack[key] = minifyDraftEditorState(languagePack[key]);
        }
    }

    return minifiedLanguagePacks;
}

export function deminifyLanguagePacks(languagePacks) {
    if (!Boolean(languagePacks)) {
        return languagePacks;
    }

    const deminifiedLanguagePacks = cloneDeep(languagePacks);
    for (const languagePack of Object.values(deminifiedLanguagePacks)) {
        for (const key in languagePack) {
            if (typeof languagePack[key] !== 'object') {
                continue;
            }

            languagePack[key] = deminifyDraftEditorState(languagePack[key]);
        }
    }

    return deminifiedLanguagePacks;
}

export const isConfirmationPage = (pageIndex, pagesCount) => {
    return pageIndex === pagesCount - 1;
}

export function newPage() {
    return {
        key: s8(),
        blocks: [],
        actions: {
            primary: s8(),
            secondary: s8()
        }
    }
}

// TODO: Clone deep also template
export function deletePage(template, languagePacks, pageIndex) {
    // Delete localization keys from language packs
    const page = template.pages[pageIndex];
    const newLanguagePacks = cloneDeep(languagePacks);
    for (const block of page.blocks) {
        const localizationKeys = getBlockLocalizationKeys(block);
        for (const language in languagePacks) {
            for (const key of localizationKeys) {
                delete newLanguagePacks[language][key];
            }
        }
    }

    const newTemplate = {...template, pages: deleteElementAtIndex(template.pages, pageIndex)};
    return [newTemplate, newLanguagePacks];
}

export function getDefaultFormLanguage(languagePacks, i18n = undefined) {
    const languages = Object.keys(languagePacks);
    return i18n && languages.includes(i18n.language) ? i18n.language : languages[0];
}