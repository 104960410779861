import React, { forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import { AppBar, Box, Divider, makeStyles, Toolbar, Typography } from "@material-ui/core";
import clsx from "clsx";
import { NavBarWidth, TopBarHeight } from "../../constants";
import { Helmet, HelmetProvider } from "react-helmet-async";

const useStyles = makeStyles((theme) => ({
    main: {
        height: "100%",
        overflow: "auto",
    },
    disabledScroll: {
        overflow: "hidden",
    },
}));

const Page = forwardRef(({ title, children, className, disableScroll }, ref) => {
    const showToolbar = Boolean(Toolbar);
    const classes = useStyles({ showToolbar });

    return (
        <main
            ref={ref}
            className={clsx(classes.main, className, {
                [classes.disabledScroll]: disableScroll,
            })}
        >
            <HelmetProvider>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            </HelmetProvider>
            {children}
        </main>
    );
});

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
    disableScroll: PropTypes.bool,
};

Page.defaultProps = {
    title: "Whispero",
};
export default Page;
