import gql from 'graphql-tag';
import * as subscriptions from "../graphql/subscriptions";
import {parseResourceFields} from "../utils/apiUtils";

export default class SubscriptionApi {
    constructor(appSyncClient) {
        this.client = appSyncClient;
    }

    onResourceCreated(workspaceKey, callback) {
        return this.client.subscribe({
            query: gql(subscriptions.onCreateResourcePub),
            variables: {subscription: workspaceKey}
        }).subscribe({
            next: response => {
                const resource = response.data.onCreateResourcePub;
                console.log("onResourceCreated response: %o", response);
                // Subscription filtering doesnt work on local mocked api, the application
                // would receive notifications if any report item is updated, including messages.
                if (resource.subscription !== workspaceKey) {
                    return;
                }

                callback(parseResourceFields(resource));
            },
            error: error => {
                console.warn(error);
            }
        })
    }

    onResourceUpdated(workspaceKey, callback) {
        return this.client.subscribe({
            query: gql(subscriptions.onUpdateResourcePub),
            variables: {subscription: workspaceKey}
        }).subscribe({
            next: response => {
                const resource = response.data.onUpdateResourcePub;
                console.log("onUpdateResource callback: %o", response);

                // Subscription filtering doesnt work on local mocked api, the application
                // would receive notifications if any report item is updated, including messages.
                if (resource.subscription !== workspaceKey) {
                    return;
                }
                callback(parseResourceFields(resource));
            },
            error: error => {
                console.warn(error);
            }
        })
    }

    onResourceDeleted(workspaceKey, callback) {
        return this.client.subscribe({
            query: gql(subscriptions.onDeleteResourcePub),
            variables: {subscription: workspaceKey}
        }).subscribe({
            next: response => {
                const resource = response.data.onDeleteResourcePub;
                console.log("onResourceDeleted callback: %o", response);

                // Subscription filtering doesnt work on local mocked api, the application
                // would receive notifications if any report item is updated, including messages.
                if (resource.subscription !== workspaceKey) {
                    return;
                }
                callback(parseResourceFields(resource));
            },
            error: error => {
                console.warn(error);
            }
        })
    }

    onInvitationCreated(workspaceKey, callback) {
        return this.client.subscribe({
            query: gql(subscriptions.onCreateInvitation),
            variables: {workspace: workspaceKey}
        }).subscribe({
            next: response => {
                const invitation = response.data.onCreateInvitation;
                console.log("onInvitationCreated response: %o", response);
                // Subscription filtering doesnt work on local mocked api, the application
                // would receive notifications if any report item is updated, including messages.
                if (invitation.workspace !== workspaceKey) {
                    return;
                }

                callback(invitation);
            },
            error: error => {
                console.warn(error);
            }
        })
    }

    onInvitationDeleted(workspaceKey, callback) {
        return this.client.subscribe({
            query: gql(subscriptions.onDeleteInvitation),
            variables: {workspace: workspaceKey}
        }).subscribe({
            next: response => {
                const invitation = response.data.onDeleteInvitation;
                console.log("onInvitationDeleted response: %o", response);
                // Subscription filtering doesnt work on local mocked api, the application
                // would receive notifications if any report item is updated, including messages.
                if (invitation.workspace !== workspaceKey) {
                    return;
                }

                callback(invitation);
            },
            error: error => {
                console.warn(error);
            }
        })
    }

    onReportResourceCreated(workspaceKey, reportKey, callback) {
        return this.client.subscribe({
            query: gql(subscriptions.onCreateResourcePub),
            variables: {subscription: workspaceKey, pk: reportKey}
        }).subscribe({
            next: response => {
                const resource = response.data.onCreateResourcePub;
                //console.log("onReportResourceCreated response: %o", response);
                // Subscription filtering doesnt work on local mocked api, the application
                // would receive notifications if any report item is updated, including messages.
                if (resource.subscription !== workspaceKey || resource.pk !== reportKey) {
                    return;
                }

                callback(parseResourceFields(resource));
            },
            error: error => {
                console.warn(error);
            }
        })
    }

    // TODO: Delete, remember observer pattern
    // onTeamResourceCreated(teamId, observer) {
    //     return this.client.subscribe({
    //         query: gql(subscriptions.onTeamResourceCreatedByTeamId),
    //         variables: {teamId}
    //     }).subscribe({
    //         next: response => {
    //             console.log("onTeamResourceCreated callback: %o", response);
    //             const resource = response.data.onTeamResourceCreatedByTeamId;
    //
    //             // Subscription filtering doesnt work on local mocked api, the application
    //             // would receive notifications if any report item is updated, including messages.
    //             if (resource.teamId !== teamId) {
    //                 return;
    //             }
    //
    //             switch(resource.type) {
    //                 case teamResourceTypes.Report:
    //                     if (observer.onReportCreated) observer.onReportCreated(parseReportResource(resource));
    //                     break;
    //                 case teamResourceTypes.Comment:
    //                     if (observer.onCommentCreated) observer.onCommentCreated(parseCommentResource(resource));
    //                     break;
    //                 case teamResourceTypes.Tag:
    //                     if (observer.onTagCreated) observer.onTagCreated(parseTagResource(resource));
    //                     break;
    //                 case teamResourceTypes.TagAssignment:
    //                     if (observer.onTagAssignmentCreated) observer.onTagAssignmentCreated(parseTagAssignmentResource(resource));
    //                     break;
    //                 default:
    //                     console.warn("A resource with unsupported resource type has been created: %o", resource);
    //             }
    //         },
    //         error: error => {
    //             console.warn(error);
    //         }
    //     })
    // }
}