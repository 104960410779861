import * as types from "./actionTypes";

const initialState = {};

export default function invitationsReducer(state = initialState, action) {
    switch (action.type) {
        case types.INVITATIONS_LOADED: {
            const newState = {...state};
            newState[action.payload.workspaceKey] = action.payload.invitations;
            return newState;
        }
        case types.INVITATIONS_ADDED: {
            const newState = {...state};
            newState[action.payload.workspaceKey] = [...state[action.payload.workspaceKey], ...action.payload.invitations];
            return newState;
        }
        case types.INVITATION_DELETED: {
            const newState = {...state};
            newState[action.payload.workspaceKey] = state[action.payload.workspaceKey].filter(t => t.id !== action.payload.invitationId);
            return newState;
        }
        case types.INVITATIONS_DELETED: {
            const newState = {...state};
            newState[action.payload.workspaceKey] = undefined;
            return newState;
        }
        default:
            return state
    }
}
