import React from 'react';
import clsx from 'clsx';
import {
    makeStyles,
    Divider
} from '@material-ui/core';
import ReportAttributesPanel from "./ReportAttributesPanel";
import ReportChat from "./ReportChat";
import ReportToolbar from "./ReportToolbar";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    reportWithChat: {
        //overflowX: 'auto',//not right here..
        flex: "1 1 auto",
        display: "flex",
    },
    chatContainer: {
        minWidth: 0,
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        [theme.breakpoints.up('md')]: {
            flex: "1 1 auto",
        },
        display: "flex",
        flexDirection: "column",
    },
    chat: {
        flex: "1 1 auto",
        minHeight: 0,
    },
    sidePanel: {
        backgroundColor: theme.palette.background.paper,
        flex: "0 0 auto",
        maxWidth: '50%',
        [theme.breakpoints.down('sm')]: {
            overflowX: 'auto'
        },
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(45),
        },
        [theme.breakpoints.up('xl')]: {
            width: theme.spacing(60),
        }
    },
}));

const ReportWithChat = ({className, report}) => {
    const classes = useStyles();

    return (
        <div className={clsx(className, classes.reportWithChat)}>
            <div className={classes.chatContainer}>
                <ReportToolbar report={report}/>
                <Divider/>
                <ReportChat className={classes.chat} report={report}/>
            </div>
            <Divider orientation="vertical"/>
            <ReportAttributesPanel
                className={clsx(classes.sidePanel)}
                report={report}
            />
        </div>
    );
}

ReportWithChat.propTypes = {
    className: PropTypes.string,
    report: PropTypes.object
}

ReportWithChat.defaultProps = {
};


export default ReportWithChat;