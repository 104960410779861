import { s4, s8 } from "./idGenerator";
import React from "react";
import {isValidDate, isValidEmail} from "./textUtils";
import { scrollToElement } from "./ui";

export function newFormBlock(type) {
    switch (type) {
        case "header": {
            return {
                key: s8(),
                type: type,
                content: s8(),
            };
        }
        case "text": {
            return {
                key: s8(),
                type: type,
                content: s8(),
            };
        }
        case "textField": {
            return {
                key: s8(),
                type: type,
                label: s8(),
            };
        }
        case "multilineTextField": {
            return {
                key: s8(),
                type: type,
                label: s8(),
            };
        }
        case "dateField": {
            return {
                key: s8(),
                type: type,
                label: s8(),
            };
        }
        case "dropdown": {
            return {
                key: s8(),
                type: type,
                label: s8(),
                options: [],
            };
        }
        case "checkboxList": {
            return {
                key: s8(),
                type: type,
                label: s8(),
                options: [],
            };
        }
        case "attachments": {
            return {
                key: s8(),
                type: type,
                label: s8(),
                buttonText: s8(),
                hintText: s8(),
            };
        }
        default:
            throw new Error("Cannot create block, an unknown form block type '%o' was specified.", type);
    }
}

export const isReportIdBlock = (block) => block.type === "reportIdField";

export const isReportEmailBlock = (block) => block.type === "reportEmailField";

export const isDateBlock = (block) => block.type === "dateField";

export const defaultTitle = (blockType, t, language) => {
    switch (blockType) {
        case "attachments": {
            return t("attachmentsTitle", { lng: language });
        }
        case "dropdown": {
            return t("dropdownTitle", { lng: language });
        }
        case "checkboxList": {
            return t("checkboxListTitle", { lng: language });
        }
        default: {
            return "";
        }
    }
}

export const convertToMessageSection = (block, languagePack, t, language) => {
    if (block.type === "text" || !Boolean(block.value)) {
        return null;
    }

    const label = languagePack[block.label] ?? defaultTitle(block.type, t, language);
    if (block.type === "attachments") {
        return newAttachmentsSection(label, block.value);
    } else if (block.type === 'checkboxList') {
        return newArraySection(label, block.value.map(item => languagePack[item]));
    } else if (block.type === "dropdown") {
        // Values of other block types, like dropdown, only contain an ID of a text in a language pack
        return newTextSection(label, languagePack[block.value]);
    } else if (block.type === "dateField") {
        return newDateSection(label, block.value);
    } else {
        // Other block types like textField or emailField
        return newTextSection(label, block.value);
    }
};

// TODO: Rename to newTextAnswer
export const newTextSection = (label, value) => ({
    type: "text",
    label: label,
    value: value,
});

export const newDateSection = (label, value) => ({
    type: "date",
    label: label,
    value: value,
});

export const newArraySection = (label, value) => ({
    type: "array",
    label: label,
    value: value,
});

export const newAttachmentsSection = (label, value) => ({
    type: "attachments",
    label: label,
    value: value,
});

export const isAttachmentSection = (section) => section.type === "attachments";

export const isDateSection = (section) => section.type === "date";