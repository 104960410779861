import React, {useContext, useEffect, useState} from 'react';
import {
    makeStyles,
    Divider,
    ThemeProvider,
    Hidden,
    Drawer,
    CircularProgress,
    Typography,
    Container, Paper
} from "@material-ui/core"
import {Navigate, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {currentUserSelector} from "../../state/currentUser/selectors";
import Card from "../../components/Card";
import WorkspaceWizard from "../../components/WorkspaceWizard";
import { activeWorkspacesSelector, allWorkspacesSelector } from "../../state/workspaces/selectors";
import {addOrUpdateWorkspace} from "../../state/workspaces/actions";
import {toId} from "../../utils/apiUtils";
import Page from "../../components/Page/Page";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    paper: {
        padding: [theme.spacing(2), theme.spacing(3), theme.spacing(3), theme.spacing(3)],
        backgroundColor: theme.palette.background.paper
    },
    wizard: {
    }
}));

// TODO: Wrap in Page
export const Onboarding = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const workspaces = useSelector(activeWorkspacesSelector());
    const user = useSelector(currentUserSelector());
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOnCompleted = (workspace) => {
        dispatch(addOrUpdateWorkspace(workspace));
        navigate(`/portal/${toId(workspace.pk)}/home`);
    }

    return workspaces.length > 0 ? (
        <Navigate to={`/portal/${toId(workspaces[0].pk)}/home`} />
    ) : (
        <Page title={t("onboardingRouteTitle")}>
            <Container className={classes.container} maxWidth="sm">
                <Card className={classes.paper}>
                    <WorkspaceWizard currentUser={user} onCompleted={handleOnCompleted}/>
                </Card>
            </Container>
        </Page>
    )
}

