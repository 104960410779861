import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
    makeStyles,
    Button,
    Toolbar,
    Typography,
    Box,
    TextField,
    AppBar,
    Divider,
    Popper,
    Fade,
    Paper,
    InputAdornment,
    Tooltip,
} from "@material-ui/core";
import { ApiContext } from "../../contexts/ApiContext";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LabelField from "../../components/LabelField";
import { useLocation, useNavigate } from "react-router";
import BorderlessTextField from "../../components/BorderlessTextField";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import SaveIcon from "@material-ui/icons/Save";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { toId } from "../../utils/apiUtils";
import { copyContentToClipboard, copyTextToClipboard } from "../../utils/clipboardUtils";
import { normalizeId } from "../../utils/textFormatter";
import ClipboardNotification from "../../components/ClipboardNotification";
import { CARD_SHADOW3, CardShadow, CardShadow2, CardShadow3 } from "../../constants";
import { StyledMenu, StyledMenuItem } from "../../components/StyledMenu";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import StyledTabs from "../../components/StyledTabs/StyledTabs";
import StyledTab from "../../components/StyledTabs/StyledTab";
import InfoIcon from "@material-ui/icons/Info";
import GroupIcon from "@material-ui/icons/Group";
import WorkspacePage from "../../components/WorkspacePage/WorkspacePage";
import LinkIcon from "@material-ui/icons/Link";
import IconButton from "@material-ui/core/IconButton";
import PublishMenu from "./PublishMenu";

const useStyles = makeStyles((theme) => ({
    formEditorToolbar: {
        flex: "0 0 auto",
        position: "relative",
        //boxShadow: theme.shadows[4],
        ...CARD_SHADOW3,
        //...CardShadow,
        zIndex: theme.zIndex.drawer,
        minHeight: 0,
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        // [theme.breakpoints.down('xl')]: {
        //     padding: [theme.spacing(2.5), theme.spacing(2)],
        // },
        // [theme.breakpoints.up('xl')]: {
        //     padding: [theme.spacing(2.5), theme.spacing(4)],
        // }
    },
    labelField: {
        // position: "absolute",
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)'
    },
    button: {
        float: "right",
        // Float: right reverses order of controls, last control is now first, etc.
        "&:not(:first-child) ": {
            marginRight: theme.spacing(2),
        },
    },
    saveButton: {
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.grey[900],
        }
    },
    toggleButtonGroup: {
        backgroundColor: theme.palette.grey[200],
        padding: theme.spacing(0.25),
        borderRadius: "6px"
    },
    toggleButton: {
        ...theme.typography.buttonMedium,
        color: theme.palette.text.secondary,
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5)
        }
    },
    toggleButtonSelected: {
        color: `${theme.palette.primary.main}!important`,
        backgroundColor: `${theme.palette.background.paper}!important`
    },
}));

const FormEditorToolbar = ({ className, workspaceKey, workspaceName, workspacePicture, formKey, formName, saveEnabled, mode, onSaveClick, onModeChange }) => {
    const classes = useStyles();
    const api = useContext(ApiContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [publishMenuAnchorEl, setPublishMenuAnchorEl] = useState();

    const handleBackButtonClick = () => {
        navigate("..");
    };

    const handleNameChange = async (newName) => {
        await api.mutation.updateResource(formKey, workspaceKey, { name: newName });
    };

    const handleModeChange = (event) => {
        onModeChange(!Boolean(mode) || mode === "edit" ? "preview" : "edit");
    };

    const handleModeChange1 = (event, newValue) => {
        if (!newValue) {
            return;
        }

        onModeChange(newValue);
    };

    return (
        <>
            <Toolbar className={classes.formEditorToolbar}>
                <Box flex="1 1 0">
                    <Button
                        color="primary"
                        variant="text"
                        size="large"
                        startIcon={<ArrowBackIcon />}
                        onClick={handleBackButtonClick}
                    >
                        {t("backToForms")}
                    </Button>
                    {/*<LabelField variant="h6" defaultValue={name} margin="dense" onSave={handleNameChange}/>*/}
                </Box>
                <Box flex="1 1 0" textAlign="center">
                    {/*<LabelField*/}
                    {/*    variant="h6"*/}
                    {/*    className={classes.labelField}*/}
                    {/*    defaultValue={formName}*/}
                    {/*    margin="dense"*/}
                    {/*    onSave={handleNameChange}*/}
                    {/*/>*/}
                    <ToggleButtonGroup
                        value={mode}
                        exclusive
                        onChange={handleModeChange1}
                        size="small"
                        aria-label="mode"
                    >
                        <ToggleButton className={classes.toggleButton} value='edit' aria-label="edit mode">
                            <EditOutlinedIcon fontSize="medium"/>
                            <Typography variant="button">
                                {t("editing")}
                            </Typography>
                        </ToggleButton>
                        <ToggleButton className={classes.toggleButton} value='preview' aria-label="preview mode">
                            <PlayArrowOutlinedIcon fontSize="small"/>
                            <Typography variant="button">
                                {t("preview")}
                            </Typography>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Box flex="1 1 0">
                    {/*Due to float property set in css all buttons has to be declared backwards, from last to first*/}
                    <Button
                        className={clsx(classes.button, classes.saveButton)}
                        variant="contained"
                        size="medium"
                        startIcon={<ShareOutlinedIcon />}
                        onClick={(e) => setPublishMenuAnchorEl(e.currentTarget)}
                    >
                        {t("publish")}
                    </Button>
                    <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        size="medium"
                        startIcon={<SaveIcon/>}
                        disabled={!saveEnabled}
                        onClick={onSaveClick}
                    >
                        {t("save")}
                    </Button>
                    {/*<Button*/}
                    {/*    className={classes.button}*/}
                    {/*    color="primary"*/}
                    {/*    variant="outlined"*/}
                    {/*    size="medium"*/}
                    {/*    startIcon={mode === "edit" ? <PlayArrowOutlinedIcon /> : <EditOutlinedIcon />}*/}
                    {/*    onClick={handleModeChange}*/}
                    {/*>*/}
                    {/*    {mode === "edit" ? t("preview") : t("editing")}*/}
                    {/*</Button>*/}
                    {/*<ToggleButtonGroup*/}
                    {/*    className={clsx(classes.button, classes.toggleButtonGroup)}*/}
                    {/*    value={mode}*/}
                    {/*    exclusive*/}
                    {/*    onChange={handleModeChange1}*/}
                    {/*    size="small"*/}
                    {/*    aria-label="mode"*/}
                    {/*>*/}
                    {/*    <ToggleButton*/}
                    {/*        className={classes.toggleButton}*/}
                    {/*        classes={{ selected: classes.toggleButtonSelected }}*/}
                    {/*        value="edit"*/}
                    {/*        aria-label="edit mode"*/}
                    {/*    >*/}
                    {/*        <EditOutlinedIcon fontSize="small" />*/}
                    {/*        <Typography variant="button">{t("editing")}</Typography>*/}
                    {/*    </ToggleButton>*/}
                    {/*    <ToggleButton*/}
                    {/*        className={classes.toggleButton}*/}
                    {/*        classes={{ selected: classes.toggleButtonSelected }}*/}
                    {/*        value="preview"*/}
                    {/*        aria-label="preview mode"*/}
                    {/*    >*/}
                    {/*        <PlayArrowOutlinedIcon fontSize="small" />*/}
                    {/*        <Typography variant="button">{t("preview")}</Typography>*/}
                    {/*    </ToggleButton>*/}
                    {/*</ToggleButtonGroup>*/}
                </Box>
            </Toolbar>
            {/*<Divider/>*/}
            <PublishMenu
                formKey={formKey}
                formName={formName}
                workspaceName={workspaceName}
                workspacePicture={workspacePicture}
                open={Boolean(publishMenuAnchorEl)}
                anchorEl={publishMenuAnchorEl}
                onClose={() => setPublishMenuAnchorEl(null)}
            />
        </>
    );
};

FormEditorToolbar.propTypes = {
    className: PropTypes.string,
    workspaceKey: PropTypes.string.isRequired,
    formKey: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    workspaceName: PropTypes.string.isRequired,
    workspacePicture: PropTypes.string,
    mode: PropTypes.oneOf(["edit", "preview"]),
    saveEnabled: PropTypes.bool,
    onSaveClick: PropTypes.func,
    onModeChange: PropTypes.func,
};

FormEditorToolbar.defaultProps = {
    mode: "edit",
    onSaveClick: () => {},
    onModeChange: () => {},
};

export default FormEditorToolbar;
