import React, {useContext, useState, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import AvatarPicker from "../../components/AvatarPicker";
import clsx from "clsx";
import {Autocomplete} from "@material-ui/lab";
import {COUNTRIES} from "../../constants/countries";
import {useDispatch, useSelector} from "react-redux";
import { deleteWorkspace, updateWorkspace } from "../../state/workspaces/actions";
import {currentUserSelector} from "../../state/currentUser/selectors";
import {workspaceSelector} from "../../state/workspaces/selectors";
import {ApiContext} from "../../contexts/ApiContext";
import StyledContainer from "../../components/StyledContainer";
import {CardShadow, PhoneNumbers} from "../../constants";
import {toId, toWorkspaceKey} from "../../utils/apiUtils";
import {useTranslation} from "react-i18next";
import {useFetchAvatarPicture} from "../../hooks/useFetchAvatarPicture";
import { useNavigate, useParams } from "react-router-dom";
import {LargePictureSuffix, SmallPictureSuffix} from "../../constants/storage";
import DeleteFormConfirmationDialog from "../FormsView/DeleteFormConfirmationDialog";
import { allMembersSelector } from "../../state/members/selectors";
import { AuthContext } from "../../contexts/AuthContext";
import { deleteAllMembers } from "../../state/members/actions";

const useStyles = makeStyles((theme) => ({
    root: {
        ...CardShadow
    },
    section: {
        margin: [theme.spacing(4), theme.spacing(3)]
    },
    sectionContent: {
        display: "flex",
        flexDirection: "row",
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    noFlex: {
        flex: "0 0 auto"
    },
    avatar: {
    },
    fields: {
        flex: "1 1 auto",
        marginLeft: theme.spacing(8),
        "&>div:not(:last-child)": {
            marginBottom: theme.spacing(3)
        }
    },
    actions: {
        marginTop: theme.spacing(3),
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    deleteButton: {
        backgroundColor: alpha(theme.palette.error.main, 0.85),
        color: theme.palette.error.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.error.main
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: theme.spacing(2)
        }
    }
}));

const GeneralWorkspaceSettings = ({className}) => {
    const classes = useStyles();
    const api = useContext(ApiContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {workspaceId} = useParams();
    const workspaceKey = toWorkspaceKey(workspaceId);
    const workspace = useSelector(workspaceSelector(workspaceKey));
    const [selectedPictureData, setSelectedPictureData] = useState();
    const [pictureUrl, setPictureUrl] = useFetchAvatarPicture(workspace.picture?.replace(SmallPictureSuffix, LargePictureSuffix));
    const [uploading, setUploading] = React.useState(false);
    const [uploadProgress, setUploadProgress] = React.useState(0);
    const [changed, setChanged] = React.useState(false);
    const [name, setName] = React.useState(workspace.name);
    const [nameError, setNameError] = useState();
    const [address, setAddress] = React.useState(workspace.address);
    const [country, setCountry] = React.useState(COUNTRIES.find(country => country.code === workspace.locale));
    const [phone, setPhone] = React.useState(workspace.phone ?? PhoneNumbers.cs);
    const [cin, setCin] = React.useState(workspace.cin);
    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
    const members = useSelector(allMembersSelector(workspaceKey));
    const navigate = useNavigate();
    const { refreshToken } = useContext(AuthContext);
    const currentUser = useSelector(currentUserSelector());

    const handleOnAvatarSelected = (file) => {
        if (selectedPictureData) {
            // Release previously created object URL if any
            URL.revokeObjectURL(pictureUrl);
        }

        if (file) {
            setPictureUrl(URL.createObjectURL(file));
            setSelectedPictureData(file);
        } else {
            setPictureUrl(null);
            setSelectedPictureData(null);
        }

        setChanged(true);
    }

    const handleNameChange = (event) => {
        const value = event.target.value;
        if (value.length === 0) {
            setNameError(true);
        } else if (nameError) {
            setNameError(false); // reset error if value is not empty
        }
        setName(event.target.value);
        setChanged(true);
    }

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
        setChanged(true);
    }

    const handleCinChange = (event) => {
        setCin(event.target.value);
        setChanged(true);
    }


    const handleAddressChange = (event) => {
        setAddress(event.target.value);
        setChanged(true);
    }

    const handleCountryChange = (value) => {
        setCountry(value);
        setChanged(true);
    }

    const handleSaveButtonClick = async () => {
        const data = {};
        if (name !== workspace.name)  {
            data.name = name;
        }
        if (address !== workspace.address) {
            data.address = address;
        }
        if (country?.code !== workspace.locale) {
            data.locale = country?.code;
        }
        if (phone !== workspace.phone) {
            data.phone = phone;
        }
        if (cin !== workspace.cin) {
            data.cin = cin;
        }

        // Remove team's original avatar if user clicked on Remove button
        if (!pictureUrl && workspace.picture) {
            await api.storage.deleteAvatarPictures(workspace.picture);
            data.picture = null;
        }

        if (selectedPictureData) {
            // Remove user's original avatar if another image was selected
            if (workspace.picture) {
                await api.storage.deleteAvatarPictures(workspace.picture);
            }

            setUploading(true);
            const key = await api.storage.uploadWorkspacePicture(selectedPictureData, toId(workspaceKey), setUploadProgress);
            data.picture = key;
            setUploading(false);
        }

        // Data can have zero keys, when for example name is changed and then changed
        // back to the original value. The value won't be added to the data object but
        // changed state will be true, which caused the save button to be enabled
        if (Object.keys(data) === 0) {
            return;
        }

        dispatch(updateWorkspace(workspaceKey, data));
        await api.mutation.updateWorkspace(workspaceKey, data);
        setChanged(false);
    };

    const handleDeleteButtonClick = () => {
        setDeleteConfirmationDialogOpen(true);
    }

    const handleDeleteWorkspaceDialogConfirm = async () => {
        setDeleteConfirmationDialogOpen(false);

        api.mutation.updateResource(workspaceKey, workspaceKey, { disabled: true });
        for (const member of members) {
            if (member.pk === currentUser.pk) {
                await api.mutation.deleteResource(member.pk, member.sk);
                await refreshToken();
            } else {
                api.mutation.deleteResource(member.pk, member.sk);
            }
        }
        
        dispatch(deleteAllMembers(workspaceKey));
        dispatch(deleteWorkspace(workspaceKey));
        navigate('/portal/home');
    }

    //// DEBUG:
    // console.log("workspace before switch button:", workspace);
    // const fetchedWs = api.query.getWorkspace(workspaceKey);
    // console.log("fetched workspace:", fetchedWs);

    const [antiSpamEnabled, setAntiSpamEnabled] = useState(workspace.antiSpam ?? false); // Default to OFF
    // const [antiSpamEnabled, setAntiSpamEnabled] = useState(!!workspace?.antiSpam); // Initialize with workspace value--by GoogleExpert LM

    //// Bunch of attempts with mostly the same aim: To have even better syncing of db--UI state:
    // useEffect(() => {
    //     // Update antiSpamEnabled whenever workspace changes
    //     setAntiSpamEnabled(!!workspace?.antiSpam);
    // }, [workspace]);

    // // Fetch initial anti-spam setting from the database
    // useEffect(() => {
    //     async function fetchAntiSpamSetting() {
    //         try {
    //             const initialSetting = workspace.antiSpam;
    //             setAntiSpamEnabled(initialSetting);
    //         } catch (error) {
    //             console.error('Error fetching anti-spam setting:', error);
    //         }
    //     }
    //     fetchAntiSpamSetting();
    // }, [workspace]);
    //
    // useEffect(() => {
    //     if (workspace && workspace.antiSpam !== undefined) {
    //         setAntiSpamEnabled(Boolean(workspace.antiSpam));
    //     }
    // }, [workspaceKey, workspace]);

    const handleAntiSpamToggle = async () => {
        const newAntiSpamEnabled = !antiSpamEnabled;
        if (newAntiSpamEnabled) {
            if (window.confirm("Spamový filtr zapne filtrování příchozích e-mailů. Odesilateli se odešle zpráva, že byl jeho e-mail vyhodnocen jako spam. V některých případech však může dojít k nedoručení oznámení. Chcete filtr skutečně zapnout?")) {
                try {
                    await api.mutation.updateResource(workspaceKey, workspaceKey, { antiSpam: newAntiSpamEnabled });
                    setAntiSpamEnabled(newAntiSpamEnabled);
                    // alert('Anti-spam setting turned on successfully.');
                    alert('Filtr zamítající spam byl úspěšně zapnut.');
                } catch (error) {
                    alert('Error updating anti-spam setting!');
                    console.error('Error updating anti-spam setting:', error);
                }
            }
        } else {
            // disabling, simpler
            try {
                await api.mutation.updateResource(workspaceKey, workspaceKey, { antiSpam: newAntiSpamEnabled });
                setAntiSpamEnabled(newAntiSpamEnabled);
                // alert('Anti-spam setting turned off successfully.');
                alert('Nastavení anti-spamu bylo úspěšně vypnuto.');
            } catch (error) {
                alert('Error updating anti-spam setting!');
                console.error('Error updating anti-spam setting:', error);
            }
        }
    };


    return (
        <StyledContainer id="general-workspace-settings" maxWidth="md" className={clsx(classes.root, className)}>
            <div className={classes.section}>
                <Typography variant="h5" gutterBottom>
                    {t("Basic information")}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {t("Information about your company that will be used in document templates and report forms")}
                </Typography>
                <div className={classes.sectionContent}>
                    <AvatarPicker
                        className={classes.noFlex}
                        name={name}
                        imageUrl={pictureUrl}
                        variant="rounded"
                        isUploading={uploading}
                        uploadProgress={uploadProgress}
                        classes={{avatar: classes.avatar}}
                        onAvatarSelected={handleOnAvatarSelected}
                        onAvatarCleared={() => handleOnAvatarSelected(null)}
                    />
                    <div className={classes.fields}>
                        <TextField required
                                   fullWidth
                                   label={t("workspaceName")}
                                   variant="outlined"
                                   value={name || ""}
                                   error={nameError}
                                   helperText={nameError ? t("{name} is required", {name: t("common.name")}) : null}
                                   onChange={handleNameChange}
                        />
                        <TextField
                            fullWidth
                            label={t("Address")}
                            variant="outlined"
                            value={address || ""}
                            onChange={handleAddressChange}
                        />
                        <Autocomplete
                            value={country || null}
                            inputValue={country?.label ?? ""}
                            options={COUNTRIES}
                            autoHighlight
                            onChange={(event, newValue) => {
                                handleCountryChange(newValue);
                            }}
                            getOptionLabel={(option) => option.label ?? ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("Country")}
                                    variant="outlined"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <TextField fullWidth label={t("common.phoneNumber")} variant="outlined" value={phone || ""} onChange={handlePhoneChange}/>
                        <TextField fullWidth label={t("VAT")} variant="outlined" value={cin || ""} onChange={handleCinChange}/>
                    </div>
                </div>
                <div className={classes.actions}>
                    <Button autoFocus color="primary" variant="contained" size="large" disabled={!changed || nameError || uploading} onClick={handleSaveButtonClick}>
                        {t("Save changes")}
                    </Button>
                </div>
            </div>
            <Divider/>
            <Box className={clsx(classes.section, classes.sectionContent)} display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems={{ xs: 'flex-start', md: 'flex-end' }}>
                <Box className={classes.noFlex}>
                    <Typography variant="h5" gutterBottom>
                        {t("deleteWorkspaceTitle")}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        {t("deleteWorkspaceSubtitle")}
                    </Typography>
                </Box>
                <Box flex="1 1 auto" display="flex" justifyContent={{ xs: 'flex-end', md: 'flex-end' }} alignItems="center">
                    <Button className={classes.deleteButton} variant="contained" size="large" onClick={handleDeleteButtonClick}>
                        {t("delete")}
                    </Button>
                </Box>
            </Box>
            <DeleteFormConfirmationDialog
                open={deleteConfirmationDialogOpen}
                workspaceKey={workspaceKey}
                onConfirm={handleDeleteWorkspaceDialogConfirm}
                onClose={() => setDeleteConfirmationDialogOpen(false)}
            />
            <Divider />
            <Box className={clsx(classes.section, classes.sectionContent)} display="flex" flexDirection="column" alignItems="flex-start">
                <Box display="flex" alignItems="center" mt={2}>
                    <Typography variant="body1" color="textSecondary">
                        {"Spam filter"} {/* Label text for the anti-spam switch */}
                    </Typography>
                    <Switch
                        checked={antiSpamEnabled}
                        onChange={handleAntiSpamToggle}
                        color="primary"
                        inputProps={{ 'aria-label': 'Anti-Spam Switch' }}
                    />
                </Box>
            </Box>
        </StyledContainer>
    );
};

export default GeneralWorkspaceSettings;