import React, { useContext, useState } from "react";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles, CssBaseline, ListItemIcon, LinearProgress, TableCell, useTheme, Chip
} from '@material-ui/core';
import {isFormKey} from "../../utils/apiUtils";
import FormPresentationView from "../../views/FormPresentationView";
import {cyan, green, grey, indigo, lightBlue, lime, purple, red} from "@material-ui/core/colors";
import ApprovalTag from "./ApprovalTag";
import { useSelector } from "react-redux";
import { formSelector } from "../../state/forms/selectors";
import { WorkspaceKeyContext } from "../../contexts/WorkspaceKeyContext";
import Tag from "../Tag";

// const pallete = {
//     gray6: "#F2F2F2",
//     gray4: "#BDBDBD",
//     gray3: "#828282"
// }

const useStyles = makeStyles((theme) => ({
    reportTags: {
        display: "flex",
        alignItems: "center",
        '&>*:not(:last-child) ': {
            marginRight: "0.75em"
        }
    }
}));


const ReportTags = ({className, report, onTagClick, onTagDelete}) => {
    const classes = useStyles();
    const workspaceKey = useContext(WorkspaceKeyContext);
    const formKey = report.tags ? report.tags.find(tag => isFormKey(tag)) : null;
    const form = useSelector(formSelector(workspaceKey, formKey));

    return (
        <div className={clsx(classes.reportTags, className)}>
            {form && (
                <Tag label={form.name}/>
            )}
            {report?.approval && (
                <ApprovalTag report={report}/>
            )}
        </div>
    )
};

ReportTags.propTypes = {
    className: PropTypes.string,
    report: PropTypes.object.isRequired,
    onTagClick: PropTypes.func,
    onTagDelete: PropTypes.func,
}

ReportTags.defaultProps = {
    onTagClick: () => {},
    onTagDelete: () => {},
};

export default ReportTags;