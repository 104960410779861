import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    CardHeader,
    Grid,
    TextField,
    CardActionArea,
    Container,
    Paper,
    FormControlLabel,
    Checkbox,
    alpha,
    MenuItem,
    InputAdornment,
    FormControl,
    FormHelperText,
    FormGroup,
    FormLabel,
} from "@material-ui/core";
import BlockHeader from "./BlockHeader";
import OptionFieldsList from "./OptionFieldsList";
import FormBlock from "./index";
import { useTranslation } from "react-i18next";
import { DEFAULT_FORM_SIDE_PADDING_SPACING } from "../../constants";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import { defaultTitle } from "../../utils/formBlockUtils";

const useStyles = makeStyles((theme) => ({
    checkboxListBlock: {},
    optionsContainer: {
        // backgroundColor: theme.palette.background.paper,
        // borderRadius: theme.shape.borderRadius,
        // border: `1px solid ${theme.palette.border.default}`,
        // overflowY: "hidden",
        marginTop: theme.spacing(1),
        // padding: [theme.spacing(1), theme.spacing(0)],
    },
    options: {
        overflow: "hidden",
    },
    formControlLabel: {
        marginBottom: 0,
        //marginLeft: -theme.spacing(1.25),
    },
    checkboxLabel: {
        ...theme.typography.body2,
    },
    checkbox: {
        "&.MuiButtonBase-root": {
            //padding: theme.spacing(1)
        },
    },
    addButton: {
        marginLeft: theme.spacing(3.75),
        marginTop: theme.spacing(0.5),
        color: theme.palette.text.secondary,
    },
    helperText: {
        position: "absolute",
        top: "100%",
    },
    optionsList: {
        marginTop: theme.spacing(0.5),
    },
}));

const CheckboxListFormBlock = ({
    blockTemplate,
    languagePack,
    language,
    errorText,
    editable,
    onTextChange,
    onTemplateChange,
    onTextDelete,
    onValueChange,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const valuesRef = useRef(blockTemplate.value ?? []);
    const [, setRenderTrigger] = useState(false);

    const handleChange = (checked, optionKey) => {
        if (checked) {
            valuesRef.current = [...valuesRef.current, optionKey];
        } else {
            const index = valuesRef.current.findIndex((key) => key === optionKey);
            valuesRef.current.splice(index, 1);
        }

        setRenderTrigger((prev) => !prev);
        onValueChange(valuesRef.current);
    };

    return (
        <FormControl fullWidth required={blockTemplate.required} error={Boolean(errorText)}>
            <>
                <BlockHeader
                    blockTemplate={blockTemplate}
                    languagePack={languagePack}
                    editable={editable}
                    defaultValue={defaultTitle(blockTemplate.type, t, language)}
                    onTemplateChange={onTemplateChange}
                    onTextChange={onTextChange}
                />
                {editable ? (
                    <OptionFieldsList
                        className={classes.optionsList}
                        blockTemplate={blockTemplate}
                        languagePack={languagePack}
                        onTemplateChange={onTemplateChange}
                        onTextChange={onTextChange}
                        onTextDelete={onTextDelete}
                        ItemIcon={CheckBoxOutlineBlankOutlinedIcon}
                        // TextFieldInputProps={{
                        //     startAdornment: (
                        //         <InputAdornment disablePointerEvents position="start">
                        //             <CheckBoxOutlineBlankOutlinedIcon color="disabled"/>
                        //         </InputAdornment>
                        //     )
                        // }}
                    />
                ) : (
                    <>
                        <FormGroup>
                            {blockTemplate.options &&
                                blockTemplate.options.map((optionKey) => (
                                    <FormControlLabel
                                        key={optionKey}
                                        className={classes.formControlLabel}
                                        classes={{ label: classes.checkboxLabel }}
                                        label={languagePack[optionKey]}
                                        control={
                                            <Checkbox
                                                className={classes.checkbox}
                                                checked={valuesRef.current.includes(optionKey)}
                                                onChange={(e) => handleChange(e.target.checked, optionKey)}
                                                name={optionKey}
                                                color="primary"
                                            />
                                        }
                                    />
                                ))}
                        </FormGroup>
                        {Boolean(errorText) && <FormHelperText>{errorText}</FormHelperText>}
                    </>
                )}
            </>
        </FormControl>
    );
};

CheckboxListFormBlock.propTypes = {
    blockTemplate: PropTypes.object.isRequired,
    languagePack: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    errorText: PropTypes.string,
    onTextChange: PropTypes.func,
    onTemplateChange: PropTypes.func,
    onTextDelete: PropTypes.func,
    onValueChange: PropTypes.func,
};

CheckboxListFormBlock.defaultProps = {
    onTextChange: () => {},
    onTemplateChange: () => {},
    onTextDelete: () => {},
    onValueChange: () => {},
};

export default CheckboxListFormBlock;
