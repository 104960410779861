import React, {useContext, useEffect, useState} from 'react';
import {
    alpha, AppBar,
    Avatar,
    Box, Button, ButtonBase, CircularProgress,
    Container, Divider,
    Grid, Link,
    makeStyles, Typography,
    useTheme
} from '@material-ui/core';
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {WorkspaceKeyContext} from "../../contexts/WorkspaceKeyContext";
import {workspaceSelector} from "../../state/workspaces/selectors";
import {ActiveLanguageContext} from "../../contexts/ActiveLanguageContext";
import Page from "../../components/Page/Page";
import {useTranslation} from "react-i18next";
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindowsOutlined';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import {CardShadow, CardShadow2, WHISPERO_PRIVACY_POLICY_URL} from "../../constants";
import UnderlinedLink from "../../components/UnderlinedLink";

const useStyles = makeStyles((theme) => ({
    submitHomeRoot: {
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        // justifyContent: "center",
        // overflowX: "hidden",
        //padding: [theme.spacing(2), 0]
    },
    body: {
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: [theme.spacing(2), 0]
    },
    actions: {
        display: "flex",
        marginTop: theme.spacing(4)
    },
    buttonGrid: {
        width: "initial",
        marginTop: theme.spacing(3)
    },
    gridItem: {
        textAlign: "center"
    },
    tileButton: {
        width: theme.spacing(22),
        //height: "100%",
        overflow: "hidden",
        margin: "auto",
        display: "block",
        padding: [theme.spacing(3), theme.spacing(1)],
        paddingTop: theme.spacing(3.5),
        //borderRadius: 24,
        borderRadius: 12,
        //border: `1px solid ${theme.palette.grey[300]}`,
        //border: `1px solid rgba(0, 125, 255, 0.5)`,
        //backgroundColor: theme.palette.common.white,
        //backgroundColor: " rgba(0, 125, 255, 0.08)",
        backgroundColor: theme.palette.grey[200],
        //transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        transition: theme.transitions.create(['box-shadow', "backgroundColor"]),
        boxShadow: "rgb(145 158 171 / 16%) 0px 1px 2px 0px",
        //boxShadow: "none",
        //boxShadow: "0px -4px 24px rgba(145, 158, 171, 0.12), 0px 0px 2px rgba(145, 158, 171, 0.2)",
        //...CardShadow,
        '&:hover': {
            //"boxShadow": theme.shadows[3],
            ...CardShadow2,
            //backgroundColor: "initial",
            //backgroundColor: theme.palette.grey[50],
        },
        '& .MuiButton-label': {
            flexDirection: "column",
            display: "inline-flex",
            textAlign: "center",
            color: theme.palette.primary.main,
            //color: theme.palette.text.primary,
        },
        '& .MuiButton-startIcon': {
            //color: theme.palette.primary.main,
            margin: 0,
            marginBottom: theme.spacing(1),
            '&>*:first-child': {
                fontSize: "24px"
            }
        }
    },
    tileButton2: {
        flexDirection: "column",
        width: theme.spacing(20),
        minWidth: theme.spacing(20),
        padding: [theme.spacing(2), theme.spacing(1)],
        justifyContent: "flex-start",
        height: "100%",
        //backgroundColor: "aliceblue",
        //padding: theme.spacing(2),
        textAlign: "center",
        borderRadius: 16,
        //color: theme.palette.text.secondary,
        //transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        ...theme.typography.button,
        //fontSize: "0.8125rem",
        //fontSize: "0.9375rem",
        //fontWeight: 400,
        '&:hover': {
            textDecoration: 'none',
            //backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
            backgroundColor: theme.palette.grey[200],
            ...CardShadow,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            }
        },
    },
    tileButtonAvatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        //marginBottom: theme.spacing(0.75),
        margin: [0, theme.spacing(1), theme.spacing(1), theme.spacing(1)],
        borderRadius: 16,
        fontSize: "32px",
        //backgroundColor: theme.palette.grey[400],
        backgroundColor: theme.palette.primary.main,
        background: "linear-gradient(180deg, rgba(27, 139, 255, 0.8) 0%, rgba(57, 154, 255, 0.8) 80.73%)"
    },
    footer: {
        flex: "0 0 auto",
        backgroundColor: theme.palette.grey[50],
        padding: [theme.spacing(4)]
    },
    footerRow: {
        maxWidth: theme.spacing(144), // 1152px
        margin: "auto",
        display: "flex",
        justifyContent: "space-between",
        padding: [0, theme.spacing(3)]
    },
    footerValue: {
        color: theme.palette.text.hint
    },
    link: {
        textDecoration: "underline",
        color: theme.palette.text.secondary,
        '&:hover': {
            color: theme.palette.text.secondary
        }
    },
}));

// TODO: Rename views folder to pages
// TODO: Move to components??por
const LandingPageView = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const language = useContext(ActiveLanguageContext);
    const workspaceKey = useContext(WorkspaceKeyContext);
    const workspace = useSelector(workspaceSelector(workspaceKey));

    // TODO: Merge with WorkspaceTile
    const TileButton2 = ({icon, children, href}) => (
        <ButtonBase
            className={classes.tileButton2}
            component={NavLink}
            to={href}
        >
            <Avatar className={classes.tileButtonAvatar} variant="rounded">
                {icon}
            </Avatar>
            <div>
                {children}
            </div>
        </ButtonBase>
    )

    const TileButton = ({startIcon, children, href}) => (
        <Button
            className={classes.tileButton}
            variant="contained"
            size="medium"
            component="div"
            startIcon={startIcon}
            component={NavLink}
            to={href}
        >
            {children}
        </Button>
    )

    return (
        <Page
            title={workspace.name}
            className={classes.submitHomeRoot}
            disableScroll
        >
            <Container className={classes.body}>
                <Typography color="textPrimary" variant="h3" align="center" paragraph>
                    {t("internalReportingSystem", {lng: language})}
                </Typography>
                <Typography color="textSecondary" align="center">
                    {t("submitHomeSubheader", {lng: language})}
                </Typography>
                <Grid container className={classes.buttonGrid} spacing={4} alignItems="stretch">
                    <Grid item xs={12} sm={4}>
                        <TileButton href="form" startIcon={<DesktopWindowsOutlinedIcon fontSize="large"/>}>
                            {t("bySubmitForm", {lng: language})}
                        </TileButton>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TileButton href="phone" startIcon={<PhoneOutlinedIcon/>}>
                            {t("byPhone", {lng: language})}
                        </TileButton>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TileButton href="email" startIcon={<EmailOutlinedIcon/>}>
                            {t("byEmail", {lng: language})}
                        </TileButton>
                    </Grid>
                </Grid>
                {/*<Grid container className={classes.buttonGrid} spacing={1} alignItems="stretch">*/}
                {/*    <Grid item className={classes.gridItem} xs={12} sm={4}>*/}
                {/*        <TileButton2 href="form" icon={<DesktopWindowsOutlinedIcon fontSize="inherit"/>}>*/}
                {/*            {t("bySubmitForm")}*/}
                {/*        </TileButton2>*/}
                {/*    </Grid>*/}
                {/*    <Grid item className={classes.gridItem} xs={12} sm={4}>*/}
                {/*        <TileButton2 href="phone" icon={<PhoneOutlinedIcon fontSize="inherit"/>}>*/}
                {/*            {t("byPhone")}*/}
                {/*        </TileButton2>*/}
                {/*    </Grid>*/}
                {/*    <Grid item className={classes.gridItem} xs={12} sm={4}>*/}
                {/*        <TileButton2 href="email" icon={<EmailOutlinedIcon fontSize="inherit"/>}>*/}
                {/*            {t("byEmail")}*/}
                {/*        </TileButton2>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Container>
            <Divider/>
            <div className={classes.footer}>
                <div className={classes.footerRow}>
                    {workspace.address && (
                        <Box flex="1 1 auto">
                            <Typography component="span" variant="body2">
                                {t("Address", {lng: language})}{": "}
                            </Typography>
                            <Typography className={classes.footerValue} component="span" variant="body2">
                                {workspace.address}
                            </Typography>
                        </Box>
                    )}
                    <UnderlinedLink href="https://www.whispero.cz/zasady-oznamovani" color="textSecondary" variant="body2">
                        {t("privacyPolicy", {lng: language})}
                    </UnderlinedLink>
                </div>
            </div>
            {/*<div className={classes.actions}>*/}
            {/*    <TileButton2 startIcon={<DesktopWindowsOutlinedIcon/>}>*/}
            {/*        {t("bySubmitForm")}*/}
            {/*    </TileButton2>*/}
            {/*    <TileButton2 startIcon={<PhoneOutlinedIcon/>}>*/}
            {/*        {t("byPhone")}*/}
            {/*    </TileButton2>*/}
            {/*    <TileButton2 startIcon={<EmailOutlinedIcon/>}>*/}
            {/*        {t("byEmail")}*/}
            {/*    </TileButton2>*/}
            {/*</div>*/}
        </Page>
    );
};

export default LandingPageView;