import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
    makeStyles,
    Button,
    Toolbar,
    Typography,
    Box,
    TextField,
    AppBar,
    Divider,
    Popper,
    Fade,
    Paper,
    InputAdornment,
    Tooltip,
    Popover,
    Tabs,
    Tab,
    useTheme,
} from "@material-ui/core";
import { ApiContext } from "../../contexts/ApiContext";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LabelField from "../../components/LabelField";
import { useLocation, useNavigate } from "react-router";
import BorderlessTextField from "../../components/BorderlessTextField";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import SaveIcon from "@material-ui/icons/Save";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { toId } from "../../utils/apiUtils";
import { copyContentToClipboard, copyTextToClipboard } from "../../utils/clipboardUtils";
import { normalizeId } from "../../utils/textFormatter";
import ClipboardNotification from "../../components/ClipboardNotification";
import { CARD_SHADOW3, CardShadow, CardShadow2, CardShadow3 } from "../../constants";
import { StyledMenu, StyledMenuItem } from "../../components/StyledMenu";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import StyledTabs from "../../components/StyledTabs/StyledTabs";
import StyledTab from "../../components/StyledTabs/StyledTab";
import InfoIcon from "@material-ui/icons/Info";
import GroupIcon from "@material-ui/icons/Group";
import WorkspacePage from "../../components/WorkspacePage/WorkspacePage";
import LinkIcon from "@material-ui/icons/Link";
import IconButton from "@material-ui/core/IconButton";
import TabPanel from "../../components/StyledTabs/TabPanel";
import GeneralWorkspaceSettings from "../PortalSettings/GeneralWorkspaceSettings";
//import QRCode from 'qrcode.react';
import { downloadFile } from "../../utils/storageUtils";
import { QRCode } from "react-qrcode-logo";
import {getAvatarColor, getInitials} from "../../utils/avatarUtils";
import StorageApi from "../../api/StorageApi";
import {useFetchAvatarPicture} from "../../hooks/useFetchAvatarPicture";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: theme.spacing(50),
    },
    header: {
        padding: [theme.spacing(3), theme.spacing(3), theme.spacing(0), theme.spacing(3)],
    },
    tabs: {
        padding: [0, theme.spacing(3)],
    },
    content: {
        padding: theme.spacing(3),
    },
    hint: {
        marginBottom: theme.spacing(1),
    },
    textFieldInput: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[200],
        "& input": {
            ...theme.typography.body2,
        },
    },
    materialIcon: {
        color: "inherit",
    },
    tabIndicator: {
        // The width of the tab indicator is not calculated correctly, since Tabs component
        // is used in a Popover and the initial width of the indicator is calculated when Popover
        // is not fully expanded. Forcing rerender after the popover is fully transitioned forces
        // Tabs component to correctly recalculate the width of the indicator. However the change
        // in the width of the indicator is noticeable to a user and it doesn't look good. Thus we
        // set the width of the indicator at first render to a fixed value that is close to the
        // final indicator width so that subsequent change in width is not that noticeable.
        width: (props) => (props.language === "cs" ? "70px!important" : "56px!important"),
    },
    qrCodeContainer: {
        padding: [theme.spacing(1), 0, theme.spacing(2), 0],
        "& canvas": {
            display: "block",
            margin: "auto"
        }
    },
    downloadButton: {
        color: `${theme.palette.primary.contrastText}!important`,
    },
}));

const PublishMenu = ({ open, anchorEl, formKey, formName, workspaceName, workspacePicture, onClose }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles({ language: i18n.language });
    const theme = useTheme();
    const api = useContext(ApiContext);
    const [tabValue, setTabValue] = useState(0);
    const [showCopiedNotification, setShowCopiedNotification] = useState(false);
    const link = `https://app.whispero.eu/f/${normalizeId(toId(formKey))}`;
    const [transitioned, setTransitioned] = useState(false);
    const downloadQrButtonRef = useRef();
    // const [workspaceAvatarUrl] = useFetchAvatarPicture(workspacePicture);
    // const qrLogoUrl = useMemo(() => {
    //     if (Boolean(workspaceAvatarUrl)) {
    //         return workspaceAvatarUrl;
    //     }
    //
    //     // Render workspace avatar on canvas.
    //     const fontSize = 200;
    //     const canvas = document.createElement('canvas');
    //     // Default size of a canvas is 300x150px. Using smaller size than default causes the canvas to become blurry.
    //     canvas.width = 300;
    //     canvas.height = 300;
    //     const context = canvas.getContext("2d");
    //     context.beginPath();
    //     context.arc(canvas.width/2, canvas.height/2, 196, 0, 2 * Math.PI);
    //     context.fillStyle = getAvatarColor(workspaceName);
    //     context.fill();
    //     context.font=`${fontSize}px ${theme.typography.fontFamily}`;
    //     context.fillStyle = theme.palette.primary.contrastText;
    //     context.textAlign = "center";
    //     context.fillText(getInitials(workspaceName), canvas.width/2, fontSize * 1.06);
    //     return canvas.toDataURL();
    // }, [workspaceAvatarUrl]);

    const handleCopyLinkButtonClick = async () => {
        await copyTextToClipboard(link);
        setShowCopiedNotification(true);
    };

    const handlePopoverEntered = () => {
        setTransitioned(true);
    };

    const handleQrDownload = () => {
        const canvas = document.querySelector("#form-qr-code");
        const resizedCanvas = document.createElement('canvas');
        resizedCanvas.width = 336;
        resizedCanvas.height = 336;
        const context = resizedCanvas.getContext('2d');
        context.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);

        downloadQrButtonRef.current.href = resizedCanvas.toDataURL();
        downloadQrButtonRef.current.download = `${workspaceName}-${formName}-QR.png`;
    };

    return (
        <>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                keepMounted
                classes={{ paper: classes.paper }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                TransitionProps={{
                    onEntered: handlePopoverEntered,
                }}
            >
                <Typography className={classes.header} variant="h6" gutterBottom>
                    {t("publishMenuHeader")}
                </Typography>
                <div className={classes.tabs}>
                    <StyledTabs
                        value={tabValue}
                        onChange={setTabValue}
                        classes={{
                            indicator: !transitioned ? classes.tabIndicator : undefined,
                        }}
                    >
                        <StyledTab label={t("link")} icon={<LinkIcon />} />
                        <StyledTab label={t("qrCode")} icon={<span className={"material-icons"}>qr_code_2</span>} />
                    </StyledTabs>
                </div>
                <Divider />
                <TabPanel value={tabValue} index={0} className={classes.content}>
                    <Typography className={classes.hint} variant="body2" color="textSecondary">
                        {t("publishMenuLinkHelperText")}
                    </Typography>
                    <TextField
                        fullWidth
                        hiddenLabel
                        variant="filled"
                        defaultValue={link}
                        InputProps={{
                            className: classes.textFieldInput,
                            readOnly: true,
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title={t("copy")}>
                                        <IconButton onClick={handleCopyLinkButtonClick} color="primary" edge="end">
                                            <FileCopyOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{ shrink: false }}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={1} className={classes.content}>
                    <Typography className={classes.hint} variant="body2" color="textSecondary">
                        {t("publishMenuQrHelperText")}
                    </Typography>
                    <div className={classes.qrCodeContainer}>
                        <QRCode
                            id="form-qr-code"
                            value={link}
                            size={160}
                            qrStyle="dots"
                            eyeRadius={5}
                            quietZone={theme.spacing(1)}
                            fgColor={theme.palette.text.primary}
                            // logoImage={qrLogoUrl}
                            // removeQrCodeBehindLogo={true}
                        />
                    </div>
                    <Button
                        ref={downloadQrButtonRef}
                        className={classes.downloadButton}
                        fullWidth
                        component="a"
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleQrDownload}
                    >
                        {t("downloadQrCode")}
                    </Button>
                </TabPanel>
            </Popover>
            <ClipboardNotification open={showCopiedNotification} onClose={() => setShowCopiedNotification(false)} />
        </>
    );
};

PublishMenu.propTypes = {
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    formKey: PropTypes.string.isRequired,
    formName: PropTypes.string,
    onClose: PropTypes.func,
};

PublishMenu.defaultProps = {
    onClose: () => {},
};

export default PublishMenu;
