import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Box,
    makeStyles,
    Avatar,
    Typography, Backdrop, CircularProgress, Tooltip, Toolbar, Link, useTheme, LinearProgress
} from '@material-ui/core';
import PropTypes from "prop-types";
import {
    cyan,
    green,
    grey,
    indigo,
    lightBlue,
    lime,
    purple,
    red
} from '@material-ui/core/colors';
import clsx from "clsx";
import DescriptionIcon from '@material-ui/icons/Description';
import {ApiContext} from "../../contexts/ApiContext";

const useStyles = makeStyles((theme) => ({
    attachmentUploader: {
        width: theme.spacing(20),
        height: theme.spacing(8),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 8,
        borderWidth: "thin",
        borderStyle: "solid",
        borderColor: theme.palette.divider,
        padding: theme.spacing(1),
        paddingRight: theme.spacing()
    },
    icon: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        color: theme.palette.grey[400],
        marginRight: theme.spacing(1)
    },
    linear: {
        height: theme.spacing(0.75),
        flex: "1 1 auto",
        borderRadius: 6,
        backgroundColor: theme.palette.grey[300]
    },
    barColor : {
        backgroundColor: theme.palette.grey[500]
    }
}));

const AttachmentUploadProgress = ({className, progress}) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.attachmentUploader, className)}>
            {/*<DescriptionOutlinedIcon className={classes.icon} stroke={theme.palette.background.paper}/>*/}
            <DescriptionIcon className={classes.icon} />
            <LinearProgress
                className={classes.linear}
                value={progress}
                variant="determinate"
                classes={{
                    barColorPrimary: classes.barColor
                }}
            />
        </div>
    )
};

AttachmentUploadProgress.propTypes = {
    className: PropTypes.string,
    progress: PropTypes.number.isRequired
}

AttachmentUploadProgress.defaultProps = {
};

export default AttachmentUploadProgress;