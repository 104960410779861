import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    staticDialogContent: {
        //height: "100%",
        minHeight: 0,
        flex: "1 1 auto",
        //overflow: "auto",
        padding: theme.spacing(2)
        // display: "flex",
        // flexDirection: "column",
        // '& > *' : {
        //     flex: "1 1 auto",
        //     minHeight: 0
        // }
    }
}));

const StaticDialogContent = ({ className, children }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.staticDialogContent, className)}>
            {children}
        </div>
    );
};

StaticDialogContent.propTypes = {
    className: PropTypes.string
};

StaticDialogContent.defaultProps = {
};

export default StaticDialogContent;
