import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import {
    makeStyles,
    Divider,
    Hidden,
    Typography,
    TextField,
    Button,
    Paper,
    IconButton,
    Tooltip,
    alpha,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { WorkspaceKeyContext } from "../../contexts/WorkspaceKeyContext";
import { allFormsSelector, formSelector } from "../../state/forms/selectors";
import TileButton2 from "../../components/TileButton2";
import TileButton from "../../components/TileButton/TileButton";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import AddTileButton from "../../components/AddTileButton";
import { Navigate, useParams } from "react-router-dom";
import { toFormKey, toId, toUserKey } from "../../utils/apiUtils";
import { formatFormId } from "../../utils/textFormatter";
import FormEditorToolbar from "./FormEditorToolbar";
import FormPage from "../../components/FormPage/FormPage";
import {
    deleteBlock,
    deminifyLanguagePacks,
    duplicateBlock,
    getFormActionsVariant,
    getFormBlockId,
} from "../../utils/formUtils";
import { ApiContext } from "../../contexts/ApiContext";
import { workspaceSelector } from "../../state/workspaces/selectors";
import { memberSelector } from "../../state/members/selectors";
import Loader from "../../components/Loader";
import { s8 } from "../../utils/idGenerator";
import cloneDeep from "lodash/cloneDeep";
import ElevationScroll from "../../components/ElevationScroll";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { reorder } from "../../utils/arrayUtils";
import PropTypes from "prop-types";
import FormPageActions from "../../components/FormPageActions";
import EditableFormBlockContainer from "./EditableFormBlockContainer";
import { StyledMenu, StyledMenuItem } from "../../components/StyledMenu";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { FORM_PAGE_BORDER_RADIUS } from "../../constants";
import MemoizedEditableFormBlock from "./MemoizedEditableFormBlock";

const useStyles = makeStyles((theme) => ({
    confirmationPage: {
        position: "relative",
    },
    pagePaper: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    overlay: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: alpha(theme.palette.grey[100], 0.9),
        //backgroundColor: theme.palette.action.disabledBackground,
        //backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: FORM_PAGE_BORDER_RADIUS,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: theme.zIndex.drawer,
        visibility: "hidden",
        opacity: 0,
        transition: theme.transitions.create("opacity", {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    visible: {
        visibility: "visible",
        opacity: 1,
    },
}));

const EditableConfirmationPage = ({
    pageTemplate,
    languagePack,
    language,
    workspace,
    showDragOverlay,
    pagesCount,
    onTemplateChange,
    onTextChange,
    onTextDelete,
    onBlockDelete,
    onBlockDuplicate,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const pageIndex = pagesCount - 1;

    return (
        <FormPage className={classes.confirmationPage} classes={{ paper: classes.pagePaper }} workspace={workspace}>
            {pageTemplate.blocks.map((block, blockIndex) => (
                <EditableFormBlockContainer
                    key={getFormBlockId(block)}
                    blockType={block.type}
                    onDuplicate={() => onBlockDuplicate(pageIndex, blockIndex)}
                    onDelete={() => onBlockDelete(pageIndex, blockIndex)}
                >
                    <MemoizedEditableFormBlock
                        pageIndex={pageIndex}
                        blockIndex={blockIndex}
                        blockTemplate={block}
                        languagePack={languagePack}
                        language={language}
                        onTextChange={onTextChange}
                        onTemplateChange={onTemplateChange}
                        onTextDelete={onTextDelete}
                    />
                </EditableFormBlockContainer>
            ))}
            <FormPageActions
                editable
                template={pageTemplate.actions}
                languagePack={languagePack}
                language={language}
                pageIndex={pagesCount - 1}
                pagesCount={pagesCount}
                onTextChange={onTextChange}
            />
            <div className={clsx(classes.overlay, { [classes.visible]: showDragOverlay })}>
                <Typography variant="h5" color="textSecondary">
                    {t("confirmationPageOverlayText")}
                </Typography>
            </div>
        </FormPage>
    );
}

EditableConfirmationPage.propTypes = {
    pageTemplate: PropTypes.object.isRequired,
    languagePack: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    workspace: PropTypes.object.isRequired,
    pagesCount: PropTypes.number.isRequired,
    showDragOverlay: PropTypes.bool,
    onTemplateChange: PropTypes.func,
    onTextChange: PropTypes.func,
    onTextDelete: PropTypes.func,
    onBlockDelete: PropTypes.func,
    onBlockDuplicate: PropTypes.func,
    onDelete: PropTypes.func,
};

EditableConfirmationPage.defaultProps = {
    classes: {},
    onTemplateChange: () => {},
    onTextChange: () => {},
    onTextDelete: () => {},
    onBlockDelete: () => {},
    onBlockDuplicate: () => {},
    onDelete: () => {},
};

export default EditableConfirmationPage;
