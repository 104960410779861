import React, {useContext, useState, useEffect, useCallback, useRef} from 'react';
import {ApiContext} from "../contexts/ApiContext";
import {ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {addForm, loadForms} from "../state/forms/actions";
import {formSelector} from "../state/forms/selectors";

export const useFetchForm = (formKey) => {
    const api = useContext(ApiContext);
    const dispatch = useDispatch();
    const [form, setForm] = useState();

    const fetchFormAsync = async () => {
        const resources = await api.query.listResources({pk: formKey});
        if (resources && resources.length > 0) {
            dispatch(addForm(resources[0].sk, resources[0]));
            setForm(resources[0]);
        }
    }

    useEffect(() => {
        if (!formKey) {
            throw new Error("Form key cannot be null or undefined");
        }

        fetchFormAsync();
    }, [formKey]);

    return form;
}

