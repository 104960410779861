import config from "../aws-exports";
import * as axios from "axios";
import {newAwsDateTime} from "../utils/time";

export async function getCredentials() {
    const identityIdResponse = await getIdentityId();
    if (!identityIdResponse) {
        return undefined;
    }

    const now = Date.now();
    const credentialsResponse = await getCredentialsForIdentity(identityIdResponse.IdentityId, new Date(now));
    if (!credentialsResponse) {
        return undefined;
    }

    const expiration = new Date(now + 3600000); // Tokens last for 1 hour. This duration is fixed and cannot be changed.
    return {
        "accessKeyId": credentialsResponse.Credentials.AccessKeyId,
        "authenticated": false,
        "expiration": expiration,
        "identityId": credentialsResponse.IdentityId,
        "secretAccessKey": credentialsResponse.Credentials.SecretKey,
        "sessionToken": credentialsResponse.Credentials.SessionToken,
    }
}

async function getIdentityId() {
    try {
        const body = { IdentityPoolId: config.aws_cognito_identity_pool_id };
        const resp = await axios.post('https://cognito-identity.eu-central-1.amazonaws.com/', body, {
            headers: {
                "Content-Type": "application/x-amz-json-1.1",
                "x-amz-target": "AWSCognitoIdentityService.GetId",
                "x-amz-date": newAwsDateTime()
            }
        });
        return resp.data;
    } catch (err) {
        // Handle Error Here
        console.error(err);
    }
}

async function getCredentialsForIdentity(identityId, date) {
    try {
        const body = { IdentityId: identityId };
        const resp = await axios.post('https://cognito-identity.eu-central-1.amazonaws.com/', body, {
            headers: {
                "Content-Type": "application/x-amz-json-1.1",
                "x-amz-target": "AWSCognitoIdentityService.GetCredentialsForIdentity",
                "x-amz-date": date.toISOString()
            }
        });
        return resp.data;
    } catch (err) {
        // Handle Error Here
        console.error(err);
    }
}

// function getUserId2() {
//     const data = new TextEncoder().encode(
//         JSON.stringify({
//             IdentityPoolId: poolId
//         })
//     )
//
//     const options = {
//         hostname: "cognito-identity.eu-central-1.amazonaws.com",
//         port: 443,
//         path: "/",
//         method: "POST",
//         headers: {
//             "Content-Type": "application/x-amz-json-1.1",
//             "Content-Length": data.length,
//             "x-amz-target": "AWSCognitoIdentityService.GetId",
//             "x-amz-date": newAwsDateTime()
//         }
//     }
//
//     const request = https.request(options, response => {
//         console.log(`statusCode: ${response.statusCode}`)
//
//         response.on("data", data => {
//             console.log("getID response: %o", data)
//         })
//     })
//     request.on("error", error => {
//         console.error(error)
//     })
//     request.write(data)
//     request.end()
// }