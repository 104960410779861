import React, {useContext, useState} from 'react';
import {
    AppBar,
    Container, Dialog, Divider,
    Grid,
    makeStyles, Toolbar, Typography,
    useTheme
} from '@material-ui/core';
import {Navigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {workspaceReports} from "../../state/reports/selectors";
import StyledTabs from "../../components/StyledTabs/StyledTabs";
import StyledTab from "../../components/StyledTabs/StyledTab";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import TabPanel from "../../components/StyledTabs/TabPanel";
import InfoIcon from '@material-ui/icons/Info';
import GroupIcon from '@material-ui/icons/Group';
import GeneralWorkspaceSettings from "./GeneralWorkspaceSettings";
import WorkspaceUsersSettings from "./WorkspaceUserSettings/WorkspaceUsersSettings";
import DialogsSettings from "./DialogsSettings";
import BallotIcon from '@material-ui/icons/Ballot';
import {useTranslation} from "react-i18next";
import {currentUserRoleSelector} from "../../state/members/selectors";
import {toId, toWorkspaceKey} from "../../utils/apiUtils";
import clsx from "clsx";
import Page from "../../components/Page/Page";
import {WorkspaceKeyContext} from "../../contexts/WorkspaceKeyContext";
import {workspaceSelector} from "../../state/workspaces/selectors";
import WorkspacePage from "../../components/WorkspacePage/WorkspacePage";
import { isAdmin, isOwner } from "../../utils/user";
import { currentUserSelector } from "../../state/currentUser/selectors";

const useStyles = makeStyles((theme) => ({
    settings: {
        width: '100%',
        height: "100%",
    },
    toolbarTab:{
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textTransform: "none",
        ...theme.typography.body2,
    },
    container: {
        padding: theme.spacing(3)
    },
    tabPanel: {
        paddingTop: theme.spacing(4)
    },
    avatar: {
        backgroundColor: theme.palette.primary.light
    },
    dialogSettings: {
        height: "100%"
    },
    toolbar: {
        padding: [0, theme.spacing(4)]
    }
}));

// TODO: Rename
const PortalSettings = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const workspaceKey = useContext(WorkspaceKeyContext);
    const workspace = useSelector(workspaceSelector(workspaceKey));
    const currentUser = useSelector(currentUserSelector(workspaceKey));
    const currentUserRole = useSelector(currentUserRoleSelector(workspaceKey));
    const [tabValue, setTabValue] = React.useState(0);

    if (!isOwner(workspace, currentUser) && !isAdmin(currentUserRole)) {
        return (
            <Navigate to={`/portal/${toId(workspaceKey)}`}  />
        )
    }

    return (
        <WorkspacePage>
            <div className={classes.toolbar}>
                <StyledTabs
                    value={tabValue}
                    onChange={setTabValue}
                    aria-label="setting-categories"
                >
                    <StyledTab id="general-settings-tab" className={classes.toolbarTab} label={t("General")} icon={<InfoIcon />} />
                    <StyledTab id="users-settings-tab" className={classes.toolbarTab} label={t("Users")} icon={<GroupIcon />} />
                    {/*<StyledTab id="dialogs-settings-tab" className={classes.toolbarTab} label={t("Dialogs")} icon={<BallotIcon />}/>*/}
                </StyledTabs>
            </div>
            <Divider />
            <TabPanel value={tabValue} index={0} className={classes.container}>
                <GeneralWorkspaceSettings/>
            </TabPanel>
            <TabPanel value={tabValue} index={1} className={classes.container}>
                <WorkspaceUsersSettings />
            </TabPanel>
            <TabPanel value={tabValue} index={2} className={classes.dialogSettings}>
                <DialogsSettings />
            </TabPanel>
        </WorkspacePage>
    );
};

export default PortalSettings;