import * as types from "./actionTypes";
import {stableSort} from "../../utils/arrayUtils";

export const loadMessages = (reportKey, messages) => async (dispatch, getState) => {
    const sorted = stableSort(messages, 'asc', "createdAt");
    dispatch({ type: types.MESSAGES_LOADED, payload: {reportKey: reportKey, messages: sorted} });
}

export const addMessage = (reportKey, message) => async (dispatch, getState) => {
    // messages[reportKey] can be null, even if report exists, for example if the report was created by voicemail
    const exists = getState().messages[reportKey]?.some(t => t.sk === message.sk);
    if (exists) {
        console.warn(`Message with id ${message.sk} already exists`);
        return;
    }

    dispatch({ type: types.MESSAGE_ADDED, payload: {reportKey: reportKey, message: message} });
}

export const deleteAllMessages = (workspaceKey) => async (dispatch, getState) => {
    dispatch({ type: types.MESSAGES_DELETED, payload: { workspaceKey } });
}