import React from 'react';
import {
    Box,
    Container, Dialog, DialogActions, DialogContent, Divider, alpha,
    Grid,
    makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
    useTheme
} from '@material-ui/core';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    styledContainer: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 6,
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(4)
    },
}));

const StyledContainer = ({className, children, ...rest}) => {
    const classes = useStyles();

    return (
        <Container className={clsx(classes.styledContainer, className)} {...rest}>
            {children}
        </Container>
    );
};

export default StyledContainer;