import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import {
    makeStyles,
    Divider,
    Hidden,
    Typography,
    TextField,
    Button,
    Paper,
    AppBar,
    Toolbar,
    IconButton,
    Select,
    Box,
    Drawer,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    useTheme, useMediaQuery, ListItemIcon
} from "@material-ui/core";
import FormPage from "../FormPage/FormPage";
import {getFormActionsVariant, getFormBlockId} from "../../utils/formUtils";
import FormPageActions from "../FormPageActions";
import PropTypes from "prop-types";
import ElevationScroll from "../ElevationScroll";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {NavLink} from "react-router-dom";
import {WorkspaceKeyContext} from "../../contexts/WorkspaceKeyContext";
import {ActiveLanguageContext} from "../../contexts/ActiveLanguageContext";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UserAvatar from "../UserAvatar";
import {useTranslation} from "react-i18next";
import {LANGUAGE_LABELS} from "../../constants/languages";
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import Menu from "@material-ui/core/Menu";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles((theme) => ({
    appBar: {
    },
    toolbar: {
        //width: "100%",
        //maxWidth: theme.spacing(144), // 1152px
        //margin: "auto",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 0,
            paddingRight: 0
        },
        [theme.breakpoints.down("xs")]: {
            minHeight: theme.spacing(6)
        },
    },
    logo: {
        marginRight: theme.spacing(1),
        display: "inline-flex" // TODO: Move to UserAvatar root style
    },
    languageSelect: {
        //marginRight: theme.spacing(3)
        marginLeft: "auto",
        marginRight: theme.spacing(4),
        minWidth: 120,
        ...theme.typography.body2,
        textTransform: "none",
        [theme.breakpoints.down("sm")]: {
            marginRight: theme.spacing(2),
        },
    },
    drawerPaper: {
        height: "auto",
        width: "100%",
        padding: theme.spacing(3),
        '&>*:not(:first-child)': {
            marginTop: theme.spacing(3),
        }
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1)
    },
    accordion: {
        boxShadow: "none",
        '&::before': {
            display: "none"
        }
    },
    accordionExpanded: {
        marginBottom: "0!important"
    },
    accordionHeader: {
        minHeight: "0!important",
        padding: 0,
        ...theme.typography.button,
        fontSize: "0.9375rem",
        '& .MuiIconButton-root': {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    menuList: {
        width: "100%"
    },
    menuListItem: {
        borderRadius: 8
    },
    noGutters: {
        margin: "0!important",
        padding: 0
    },
    languageIcon: {
        lineHeight: 0,
        width: 28,
        marginRight: theme.spacing(2),
        borderRadius: 4,
        overflow: "hidden",
        flexShrink: 0
    },
    menuItem: {
        ...theme.typography.body2
    }
}));

const FormPresentationToolbar = ({selectedLanguage, languages, workspace, position, onLanguageChange}) => {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const { t } = useTranslation();
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const appBarPosition = position === 'auto' ? (smallScreen ? "fixed" : "absolute") : position;

    const handleDrawerLanguageChanged = (value) => {
        onLanguageChange(value);
        setDrawerOpen(false);
    }

    const handleMenuItemClick = (value) => {
        onLanguageChange(value);
        setMenuAnchorEl(null);
    }

    const WorkspaceLogo = () => (
        <div className={classes.toolbarHeader}>
            <UserAvatar className={classes.logo} name={workspace.name} imageKey={workspace.picture} component="span" variant={"rounded"}/>
            <Typography variant="h5" color="textPrimary" component="span">{workspace.name}</Typography>
        </div>
    )

    return (
        <AppBar className={clsx(classes.appBar)} position={appBarPosition} color="transparent" elevation={0} >
            <Toolbar className={classes.toolbar} >
                {/*<Select*/}
                {/*    className={classes.languageSelect}*/}
                {/*    disableUnderline*/}
                {/*    color="secondary"*/}
                {/*    margin="dense"*/}
                {/*    value={selectedLanguage}*/}
                {/*    onChange={(event) => onLanguageChange(event.target.value)}*/}
                {/*>*/}
                {/*    {languages.map((lang) => (*/}
                {/*        <MenuItem key={`menu-item-${lang}`} className={classes.menuItem} value={lang}>*/}
                {/*            <div className={classes.languageIcon}>*/}
                {/*                <img alt={LANGUAGE_LABELS[lang]} src={`/static/icons/flags/${lang}.svg`} />*/}
                {/*            </div>*/}
                {/*            <ListItemText primary={LANGUAGE_LABELS[lang]} primaryTypographyProps={{variant: "body2"}}/>*/}
                {/*        </MenuItem>*/}
                {/*    ))}*/}
                {/*</Select>*/}
                <Button
                    className={classes.languageSelect}
                    endIcon={<ExpandMoreIcon/>}
                    onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                >
                    <span className={classes.languageIcon}>
                        <img alt={LANGUAGE_LABELS[selectedLanguage]} src={`/static/icons/flags/${selectedLanguage}.svg`} />
                    </span>
                    {LANGUAGE_LABELS[selectedLanguage]}
                </Button>
                <Menu
                    anchorEl={menuAnchorEl}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    getContentAnchorEl={null}
                    id="language-menu"
                    keepMounted
                    transformOrigin={{vertical: "top", horizontal: "center" }}
                    open={Boolean(menuAnchorEl)}
                    onClose={() => setMenuAnchorEl(null)}
                >
                    {languages.map((lang) => (
                        <MenuItem
                            key={`menu-item-${lang}`}
                            className={classes.menuItem}
                            onClick={() => handleMenuItemClick(lang)}
                        >
                            <div className={classes.languageIcon}>
                                <img alt={LANGUAGE_LABELS[lang]} src={`/static/icons/flags/${lang}.svg`} />
                            </div>
                            <ListItemText primary={LANGUAGE_LABELS[lang]} primaryTypographyProps={{variant: "body2"}}/>
                        </MenuItem>
                    ))}
                </Menu>
            </Toolbar>
        </AppBar>
    )

    // return (
    //     <>
    //         <AppBar className={clsx(classes.appBar)} position="fixed" color="transparent" elevation={0}>
    //             <Toolbar className={classes.toolbar}>
    //                 <Hidden smUp>
    //                     <IconButton
    //                         color="inherit"
    //                         aria-label="open drawer"
    //                         edge="end"
    //                         onClick={() => setDrawerOpen(true)}
    //                     >
    //                         <MenuIcon />
    //                     </IconButton>
    //                 </Hidden>
    //                 <Hidden xsDown>
    //                     <Select
    //                         className={classes.languageSelect}
    //                         disableUnderline
    //                         color="secondary"
    //                         value={selectedLanguage}
    //                         onChange={(event) => onLanguageChange(event.target.value)}
    //                     >
    //                         {languages.map((lang) => (
    //                             <MenuItem key={`menu-item-${lang}`} value={lang}>
    //                                 {LANGUAGE_LABELS[lang]}
    //                             </MenuItem>
    //                         ))}
    //                     </Select>
    //                 </Hidden>
    //             </Toolbar>
    //         </AppBar>
    //         <Drawer
    //             anchor="right"
    //             open={drawerOpen}
    //             onClose={() => setDrawerOpen(false)}
    //             classes={{
    //                 paper: classes.drawerPaper
    //             }}
    //         >
    //             <div className={classes.drawerHeader}>
    //                 <WorkspaceLogo/>
    //                 <IconButton
    //                     color="inherit"
    //                     aria-label="close drawer"
    //                     edge="end"
    //                     onClick={() => setDrawerOpen(false)}
    //                 >
    //                     <CloseIcon />
    //                 </IconButton>
    //             </div>
    //             <Accordion className={classes.accordion} classes={{expanded: classes.accordionExpanded}}>
    //                 <AccordionSummary
    //                     className={classes.accordionHeader}
    //                     expandIcon={<ExpandMoreIcon />}
    //                     aria-controls="language-panel-content"
    //                     id="language-panel-header"
    //                     classes={{
    //                         content: classes.noGutters
    //                     }}
    //                 >
    //                     <Typography variant="inherit" color="inherit">{t("Language", {lng: selectedLanguage})}</Typography>
    //                 </AccordionSummary>
    //                 <AccordionDetails className={classes.noGutters}>
    //                     <List className={classes.menuList} component="div" disablePadding>
    //                         {languages.map((lang) => (
    //                             <ListItem
    //                                 button
    //                                 key={`accordion-item-${lang}`}
    //                                 className={classes.menuListItem}
    //                                 selected={selectedLanguage === lang}
    //                                 onClick={() => handleDrawerLanguageChanged(lang)}
    //                             >
    //                                 <ListItemText primary={LANGUAGE_LABELS[lang]} />
    //                             </ListItem>
    //                         ))}
    //                     </List>
    //                 </AccordionDetails>
    //             </Accordion>
    //         </Drawer>
    //     </>
    // );
}

FormPresentationToolbar.propTypes = {
    selectedLanguage: PropTypes.string.isRequired,
    languages: PropTypes.array.isRequired,
    workspace: PropTypes.object.isRequired,
    position: PropTypes.oneOf(['auto', 'fixed', 'absolute']),
    onLanguageChange: PropTypes.func
};

FormPresentationToolbar.defaultProps = {
    position: 'auto',
    onLanguageChange: () => {}
};

export default FormPresentationToolbar;
