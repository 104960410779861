import React, {forwardRef, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Divider, FormControl,
    InputAdornment, InputBase,
    makeStyles,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from "@material-ui/core";
import clsx from "clsx";
import {NavBarWidth, TopBarHeight} from '../../constants';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {TextFieldProps} from "@material-ui/core/TextField/TextField";
import {useTranslation} from "react-i18next";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

const useStyles = makeStyles((theme) => ({
    labelField: props => ({
        display: "inline-grid",
        marginTop: 0,
        marginBottom: 0,
        '&::after': {
            content: "attr(data-value)",
            whiteSpace: "pre-wrap",
            visibility: "hidden",
            // paddingLeft: props.dense ? theme.spacing(1.5) : theme.spacing(1.75),
            // paddingRight: props.dense ? theme.spacing(1.5) : theme.spacing(1.75),
            paddingLeft: "14px",
            paddingRight: "14px",
            ...theme.typography[props.variant],
            lineHeight: 0   // to collapse vertical space taken by :after, must be placed after theme.typography.h5 to override system lineHeight
        },
        '& input': {
            ...theme.typography[props.variant],
            // padding: props.dense ? [theme.spacing(0.5), theme.spacing(1.5), theme.spacing(0.5), theme.spacing(1.5)] : "intial",
            // padding: props.dense ? [theme.spacing(0.5), theme.spacing(1.5), theme.spacing(0.5), theme.spacing(1.5)] : "intial",
        },
        '& fieldset': {
            borderStyle: props.hover || props.active ? "solid" : "none"
        }
    })
}));

const LabelField = ({className, variant, defaultValue, onSave, ...rest}) => {
    const { t } = useTranslation();
    const fieldRef = useRef();
    const inputRef = useRef();
    const [active, setActive] = useState(false);
    const [hover, setHover] = useState(false);
    const [value, setValue] = useState(defaultValue);
    const [error, setError] = useState("");
    const classes = useStyles({variant, hover, active});

    const handleOnChange = (event) => {
        setError(null);
        setValue(event.target.value);
        fieldRef.current.dataset.value = event.target.value;    // needed to make field input grow as text is typed in
    }

    const handleOnClick= () => {
        setActive(true);
        setHover(true);
    }

    const handleTextFieldKeyDown = (event) => {
        if(event.keyCode == 13){
            setTimeout(() => inputRef.current.blur(), 1);
        }
    }

    const handleOnBlur = () => {
        if (!Boolean(value)) {
            setError(t("labelCannotBeEmpty"));
        } else {
            setActive(false);
            if (value !== defaultValue) {
                onSave(value);
            }
        }
    }

    return (
        <Tooltip title={t("changeLabel")}>
            <TextField
                className={clsx(classes.labelField, className)}
                {...rest}
                ref={fieldRef}
                inputRef={inputRef}
                variant="outlined"
                required
                hiddenLabel
                value={value}
                error={Boolean(error)}
                helperText={error}
                onChange={handleOnChange}
                onFocus={(e) => e.target.select()}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={handleOnClick}
                onKeyDown={handleTextFieldKeyDown}
                onBlur={handleOnBlur}
                inputProps={{min: 1, style: { textAlign: 'center' }}}
            />
        </Tooltip>
    );
};

LabelField.propTypes = {
    className: PropTypes.string,
    defaultValue: PropTypes.string,
    dense: PropTypes.bool,
    variant: PropTypes.oneOf(["body1", "body2", "h1", "h2", "h3", "h4", "h5", "h6", "caption", "subtitle1", "subtitle2"]),
    onSave: PropTypes.func,
    ...TextField.propTypes
};

LabelField.defaultProps = {
    variant: "body1",
    defaultValue: "",
    onSave: () => {}
};

export default LabelField;