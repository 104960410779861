import * as types from "./actionTypes";

const initialState = {};

//  Messages state is a collection of messages grouped by report ID
export default function commentsReducer(state = initialState, action) {
    switch (action.type) {
        case types.COMMENTS_LOADED: {
            const newState = {...state};
            newState[action.payload.reportKey] = action.payload.comments;
            return newState;
        }
        case types.COMMENTS_ADDED: {
            const newState = {...state};
            newState[action.payload.reportKey] = [...(state[action.payload.reportKey] ?? []), ...action.payload.comments];
            return newState;
        }
        case types.COMMENT_ADDED: {
            const newState = {...state};
            newState[action.payload.reportKey] = [...(state[action.payload.reportKey] ?? []), action.payload.comment];
            return newState;
        }
        case types.COMMENTS_DELETED: {
            const newState = {...state};
            newState[action.payload.workspaceKey] = undefined;
            return newState;
        }
        default:
            return state
    }
}
