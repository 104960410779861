import React, {forwardRef, useContext, useEffect, useState} from 'react';
import {
    Box,
    makeStyles,
    Avatar,
    Typography, useTheme, alpha
} from '@material-ui/core';
import PropTypes from "prop-types";
import PersonIcon from '@material-ui/icons/Person';
import DueDatePicker from "./Pickers/DueDatePicker";
import {
    cyan,
    green,
    grey,
    indigo,
    lightBlue,
    lime,
    purple,
    red
} from '@material-ui/core/colors';
import clsx from "clsx";
import {ApiContext} from "../contexts/ApiContext";
import {AVATAR_SIZES, FONT_SIZES, getAvatarColor, getInitials} from "../utils/avatarUtils";
import {Skeleton} from "@material-ui/lab";
import {useFetchAvatarPicture} from "../hooks/useFetchAvatarPicture";
import {numericId} from "../utils/idGenerator";

const useStyles = makeStyles((theme) => ({
    styledAvatarRoot: props => ({
        height: theme.spacing(AVATAR_SIZES[props.size]),
        width: theme.spacing(AVATAR_SIZES[props.size]),
        fontSize: `${FONT_SIZES[props.size]}`,
    }),
    avatarImage: {
        objectFit: "initial"
    },
    roundedAvatar: {
        borderRadius: 8
    },
    avatarBackground: {
        backgroundColor: props => props.background
    }
}));

const anonymousBackground = (theme) => `linear-gradient(${theme.palette.primary.main}, ${alpha(theme.palette.primary.light, 0.75)})`;

// TODO: Rename to S3Avatar or some other name
const UserAvatar = forwardRef((props, ref) => {
    const {className, name, imageKey, size, variant, ...rest} = props;
    const theme = useTheme();
    const background = imageKey ? null : (name ? getAvatarColor(name) : anonymousBackground(theme));
    const classes = useStyles({size, background, variant});
    const skeletonVariant = variant === "square" || variant === "rounded" ? "rect" : "circle";
    const [url] = useFetchAvatarPicture(imageKey);

    if (!url && imageKey) {
        return (
            <Skeleton
                className={clsx(classes.styledAvatarRoot, className, {[classes.roundedAvatar]: variant === "rounded"})}
                variant={skeletonVariant}
            />
        )
    }

    return (
        <Avatar
            ref={ref}
            className={clsx(classes.styledAvatarRoot, classes.avatarBackground, className, {[classes.roundedAvatar]: variant === "rounded"})}
            classes={{img: classes.avatarImage}}
            src={url}
            variant={variant}
            {...rest}
        >
            {name ? getInitials(name) : (<PersonIcon />)}
        </Avatar>
    )
});

UserAvatar.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    imageKey: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large', 'xSmall'])
}

UserAvatar.defaultProps = {
    size: 'medium',
};

export default React.memo(UserAvatar);