import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    makeStyles,
    useTheme,
    Dialog,
    useMediaQuery, DialogTitle
} from '@material-ui/core';
import ExportDialogContent from "./ExportDialogContent";
import {getReportName} from "../../utils/textUtils";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {currentUserRoleSelector} from "../../state/members/selectors";
import {isApprover} from "../../utils/user";

const useStyles = makeStyles((theme) => ({
    exportDialog: {
    },
    dialogTitle: {
        paddingBottom: theme.spacing(1)
    },
    dialogContent: {
        paddingBottom: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    dialogActions: {
        padding: theme.spacing(3)
    }
}));

const ExportToPdfDialog = ({className, report, open, onClose, onCompleted}) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    return (
        <Dialog
            id="pdf-export-dialog"
            className={clsx(classes.exportDialog, className)}
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
            aria-labelledby="export-dialog-title"
        >
            <DialogTitle id="export-dialog-title" className={classes.dialogTitle}>
                {t("Export report {name}", {name: getReportName(report, t)})}
            </DialogTitle>
            <div className={classes.dialogContent}>
                <ExportDialogContent report={report} onCompleted={onCompleted}/>
            </div>
        </Dialog>
    )
}

ExportToPdfDialog.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func
}

ExportToPdfDialog.defaultProps = {
    onClose: () => {}
};

export default ExportToPdfDialog;