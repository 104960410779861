import React, {useContext, useEffect, useState} from 'react';
import {
    Box,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    alpha,
    Grid, ListItemIcon, ListItemText,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import AvatarPicker from "../../../components/AvatarPicker";
import clsx from "clsx";
import StyledContainer from "../../../components/StyledContainer";
import {CardShadow, NavBarWidth} from "../../../constants";
import DialogMenu from "./DialogMenu";
import SubmitDialog from "../../../components/reportDialogs/SubmitDialog";
import {useDispatch, useSelector} from "react-redux";
import {workspaceSelector} from "../../../state/workspaces/selectors";
import ConfirmationDialog from "../../../components/reportDialogs/ConfirmationDialog";
import {ApiContext} from "../../../contexts/ApiContext";
import {updateWorkspace} from "../../../state/workspaces/actions";
import DisclaimerDialog from "../../../components/reportDialogs/DisclaimerDialog";
import LanguageMenu from "./LanguageMenu";
import {currentUserRoleSelector, memberSelector} from "../../../state/members/selectors";
import {toUserKey, toWorkspaceKey} from "../../../utils/apiUtils";
import {Navigate, useParams} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    dialogSettings: {
        display: "flex",
        height: "100%"
    },
    menu: {
        overflow: "auto",
        flex: "0 0 auto",
        width: NavBarWidth
        // [theme.breakpoints.up('md')]: {
        //     width: theme.spacing(20),
        // },
        // [theme.breakpoints.up('xl')]: {
        //     width: theme.spacing(30),
        // }
    },
    previewContainer: {
        flex: "1 1 auto",
        overflow: "auto",
        backgroundColor: theme.palette.grey[50],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    preview: {
        height: "100%"
        //padding: theme.spacing(4),
        // pointerEvents: "none"
    },
    propertiesContainer: {
        flex: "0 0 auto",
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(40),
        },
        [theme.breakpoints.up('xl')]: {
            width: theme.spacing(50),
        }
    }
}));

const DialogsSettings = ({className}) => {
    const classes = useStyles();
    const api = useContext(ApiContext);
    const dispatch = useDispatch();
    const {workspaceId} = useParams();
    const workspaceKey = toWorkspaceKey(workspaceId);
    const workspace = useSelector(workspaceSelector(workspaceKey)) ?? null;
    const owner = useSelector(memberSelector(workspace.pk, toUserKey(workspace.owners[0])));
    const currentUserRole = useSelector(currentUserRoleSelector(workspaceKey));
    const [languageIndex, setLanguageIndex] = useState(0);
    const [language, setLanguage] = useState("en");
    const [dialogIndex, setDialogIndex] = useState(0);
    const submitLayout = workspace.submitLayout ?? {};

    if (currentUserRole !== "admin") {
        return (
            <Navigate to={`/portal/${workspaceId}`} />
        )
    }

    const handleLanguageListSelected = (language, index) => {
        setLanguage(language);
        setLanguageIndex(index);
    }

    const handleLanguageListLanguageDisabled = (language) => {
        if (workspace.disabledSubmitLanguages?.includes(language)) {
            return;
        }

        const newDisabled = workspace.disabledSubmitLanguages ? [...workspace.disabledSubmitLanguages, language] : [language];
        api.mutation.updateWorkspace(workspaceKey, {disabledSubmitLanguages: newDisabled});
        dispatch(updateWorkspace(workspaceKey, {disabledSubmitLanguages: newDisabled}));
    }

    const handleLanguageListLanguageEnabled = (language) => {
        if (!workspace.disabledSubmitLanguages?.includes(language)) {
            return;
        }

        const newDisabled = workspace.disabledSubmitLanguages?.filter(id => id !== language);
        api.mutation.updateWorkspace(workspaceKey, {disabledSubmitLanguages: newDisabled});
        dispatch(updateWorkspace(workspaceKey, {disabledSubmitLanguages: newDisabled}));
    }

    const handleDisclaimerDialogOnSave = (data) => {
        const newSubmitLayout = {...submitLayout};
        newSubmitLayout[language] = {...(newSubmitLayout[language] ?? {}), disclaimer: data};
        const newSubmitLayoutString = JSON.stringify(newSubmitLayout);

        api.mutation.updateWorkspace(workspaceKey, {submitLayout: newSubmitLayoutString});
        dispatch(updateWorkspace(workspaceKey, {submitLayout: newSubmitLayoutString}));
    }

    const handleSubmitDialogOnSave = (data) => {
        const newSubmitLayout = {...submitLayout};
        newSubmitLayout[language] = {...(newSubmitLayout[language] ?? {}), submit: data};
        const newSubmitLayoutString = JSON.stringify(newSubmitLayout);

        api.mutation.updateWorkspace(workspaceKey, {submitLayout: newSubmitLayoutString});
        dispatch(updateWorkspace(workspaceKey, {submitLayout: newSubmitLayoutString}));
    }

    const handleConfirmationDialogOnSave = (data) => {
        const newSubmitLayout = {...submitLayout};
        newSubmitLayout[language] = {...(newSubmitLayout[language] ?? {}), confirmation: data};
        const newSubmitLayoutString = JSON.stringify(newSubmitLayout);

        api.mutation.updateWorkspace(workspaceKey, {submitLayout: newSubmitLayoutString});
        dispatch(updateWorkspace(workspaceKey, {submitLayout: newSubmitLayoutString}));
    }

    return (
        <div className={clsx(classes.dialogSettings, className)}>
            <LanguageMenu
                className={classes.menu}
                selectedIndex={languageIndex}
                disabledLanguages={workspace.disabledSubmitLanguages ?? []}
                onSelected={handleLanguageListSelected}
                onLanguageEnabled={handleLanguageListLanguageEnabled}
                onLanguageDisabled={handleLanguageListLanguageDisabled}
            />
            <Divider orientation="vertical"/>
            <div className={classes.previewContainer} >
                <div className={classes.preview}>
                    {dialogIndex === 0 && (
                        <DisclaimerDialog editable workspace={workspace} owner={owner} language={language} layout={submitLayout[language]?.disclaimer} onSave={handleDisclaimerDialogOnSave}/>
                    )}
                    {dialogIndex === 1 && (
                        <SubmitDialog editable workspace={workspace} language={language} layout={submitLayout[language]?.submit} onSave={handleSubmitDialogOnSave}/>
                    )}
                    {dialogIndex === 2 && (
                        <ConfirmationDialog editable accessId="" workspace={workspace} language={language} layout={submitLayout[language]?.confirmation} onSave={handleConfirmationDialogOnSave}/>
                    )}
                </div>
            </div>
            <Divider orientation="vertical"/>
            <DialogMenu
                className={classes.menu}
                selectedIndex={dialogIndex}
                onSelectedChanged={setDialogIndex}
            />
        </div>
    );
};

export default DialogsSettings;