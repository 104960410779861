import React, {useContext, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles, CardHeader, Grid, TextField, CardActionArea, Container, Link
} from '@material-ui/core';
import TitleIcon from "@material-ui/icons/Title";
import FormatBoldIcon from '@material-ui/icons/FormatBold'
import FormatItalicIcon from '@material-ui/icons/FormatItalic'
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined'
import StrikethroughIcon from '@material-ui/icons/StrikethroughS'
import HighlightIcon from '@material-ui/icons/Highlight'
import InsertLinkIcon from '@material-ui/icons/InsertLink'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'
import FormatClearIcon from '@material-ui/icons/FormatClear'
import SaveIcon from '@material-ui/icons/Save'
import UndoIcon from '@material-ui/icons/Undo'
import RedoIcon from '@material-ui/icons/Redo'
import ToolbarButton from "./ToolbarButton";
import {getContentBlockType, getStyleName} from "./styling";
import {useTranslation} from "react-i18next";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import AddAttachmentButton from "../attachments/AddAttachmentButton";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import SendIcon from "@material-ui/icons/Send";
import {ToolbarFiller} from "./ToolbarFiller";
import {ToolbarDivider} from "./ToolbarDivider";
import IconButton from "@material-ui/core/IconButton";

const DEFAULT_CONTROLS = [
    {
        name: "bold",
        type: "inline",
        icon: <FormatBoldIcon fontSize="small" />
    },
    {
        name: "italic",
        type: "inline",
        icon: <FormatItalicIcon fontSize="small" />
    },
    {
        name: "underline",
        type: "inline",
        icon: <FormatUnderlinedIcon fontSize="small" />
    },
    {
        name: "strikethrough",
        type: "inline",
        icon: <StrikethroughIcon fontSize="small" />
    },
    {
        name: "highlight",
        type: "inline",
        icon: <HighlightIcon fontSize="small" />
    },
    {
        name: "unordered-list-item",
        type: "block",
        icon: <FormatListBulletedIcon fontSize="small" />,
    },
    {
        name: "ordered-list-item",
        type: "block",
        icon: <FormatListNumberedIcon fontSize="small" />,
    },
    {
        name: "blockquote",
        type: "block",
        icon: <FormatQuoteIcon fontSize="small" />,
    },
    {
        name: "header-one",
        type: "block",
        icon: <TitleIcon fontSize="small" />,
    },
    {
        name: "header-two",
        type: "block",
        icon: <TitleIcon fontSize="small" />,
    },
    {
        name: "header-three",
        type: "block",
        icon: <TitleIcon fontSize="small" />,
    },
    {
        name: "header-four",
        type: "block",
        icon: <TitleIcon fontSize="small" />,
    },
    {
        name: "header-five",
        type: "block",
        icon: <TitleIcon fontSize="small" />,
    },
    {
        name: "header-six",
        type: "block",
        icon: <TitleIcon fontSize="small" />,
    },
    {
        name: "undo",
        type: "callback",
        icon: <UndoIcon fontSize="small" />,
        action: "undo",
    },
    {
        name: "redo",
        type: "callback",
        icon: <RedoIcon fontSize="small" />,
        action: "redo" // TODO: REDO ?
    },
    {
        name: "link",
        type: "callback",
        icon: <InsertLinkIcon fontSize="small" />,
        action: 'link', // TODO: LINK ?
    },
    {
        name: "clear",
        type: "callback",
        icon: <FormatClearIcon fontSize="small" />,
        action: 'clear'
    },
    {
        name: "filler",
        component: <ToolbarFiller/>
    },
    {
        name: "divider",
        component: <ToolbarDivider/>
    },
]

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: "flex",
        alignItems: "center"
    },
    toolbarControl: {
        padding: theme.spacing(0.5)
    }
}));

const findControl = (name, customControls, t) => {
    const customControl = customControls.find(control => control.name === name);
    if (customControl) {
        return customControl;
    }

    const defaultControl = DEFAULT_CONTROLS.find(control => control.name === name);
    if (defaultControl) {
        return {
            ...defaultControl,
            label: Boolean(defaultControl.type) ? t(defaultControl.name) : undefined    // don't translate names of untyped controls
        }
    } else {
        return null;
    }
}

// TODO: Highlight control not working
// TODO: Rename onClick handler
const Toolbar = ({ className, editorState, editorHasFocus, options, customControls, onClick, onControlClicked, onAttachmentAdded, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const controls = useMemo(() => getControls(),[options, customControls]);
    const currentInlineStyle = editorState.getCurrentInlineStyle();
    const currentBlockKey = editorState.getSelection().getStartKey();
    const currentBlock = editorState.getCurrentContent().getBlockForKey(currentBlockKey);

    function getControls() {
        let controls = options.map(name => findControl(name, customControls, t)).filter(Boolean);
        const attachmentOptionIndex = options.findIndex(option => option === "attachment");
        if (attachmentOptionIndex >= 0) {
            const attachmentControl = {
                name: "attachment",
                component: (
                    <AddAttachmentButton
                        className={classes.toolbarControl}
                        onFileAdded={onAttachmentAdded}
                        variant='icon'
                        component="span"
                        size="small"
                    >
                        <AttachFileOutlinedIcon fontSize="small"/>
                    </AddAttachmentButton>
                )
            };
            controls.splice(attachmentOptionIndex, 0, attachmentControl);
        }
        return controls;
    }

    const handleContainerClick = (e) => {
        // Invoke onClick handle only if the user clicked on the div and not on the toolbar controls
        if (e.currentTarget === e.target) {
            onClick();
        }
    }

    return (
        <div className={clsx(classes.toolbar, className)} {...rest} onClick={handleContainerClick}>
            {controls.map((control, index) => {
                let active = false;
                if (editorHasFocus) {
                    if (control.type === "inline" && currentInlineStyle) {
                        active = currentInlineStyle.has(getStyleName(control));
                    } else if (control.type === "block" && currentBlock) {
                        active = getContentBlockType(control) === currentBlock.getType();
                    }
                    else if (control.name === "link") {
                        // active = control.style === getSelectionInfo(editorState).entityType
                    }
                }

                return control.component ? (
                    // <control.component />
                    <React.Fragment key={`${control.name}-rteToolbarControl-${index}`}>
                        {control.component}
                    </React.Fragment>
                ) : (
                    <ToolbarButton
                        key={`${control.name}-rteToolbarButton-${index}`}
                        className={classes.toolbarControl}
                        icon={control.icon}
                        label={control.label ?? control.name}
                        active={active}
                        onClick={() => onControlClicked(control)}
                    />
                );
            })}
        </div>
    );
};

Toolbar.propTypes = {
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    customControls: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        icon: PropTypes.object,
        label: PropTypes.string,
    })),
    onClick: PropTypes.func,
    onControlClicked: PropTypes.func,
    onAttachmentAdded: PropTypes.func
};

Toolbar.defaultProps = {
    customControls: [],
    onClick: () => {},
    onControlClicked: () => {},
    onAttachmentAdded: () => {}
};

export default Toolbar;
