import React, {useContext, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles,
    CssBaseline,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    TablePagination,
    List,
    ListItem,
    ListItemAvatar, useTheme, ListItemText, Divider, ListItemSecondaryAction, ListItemIcon, ButtonBase
} from '@material-ui/core';
import {Skeleton} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    messageSkeleton: {
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: props => props.position === "right" ? "row": "row-reverse",
        alignItems: "flex-start"
    },
    messageContainer: {
        width: "60%",
        display: "flex",
        flexDirection: "column",
        alignItems: props => props.position === "right" ? "flex-end" : "flex-start"
    },
    avatar: {
        marginRight: theme.spacing(1)
    }
}));

const ChatMessageSkeleton = ({className, position}) => {
    const classes = useStyles({position});

    return (
        <div className={clsx(classes.messageSkeleton, className)}>
            <div className={classes.messageContainer}>
                <Skeleton width="100%">
                    <Typography variant="body2">.</Typography>
                </Skeleton>
                <Skeleton width="60%">
                    <Typography variant="body2">.</Typography>
                </Skeleton>
            </div>
            {position === "left" && (
                <Skeleton className={classes.avatar} variant="circle" width={40} height={40}/>
            )}
        </div>
    )
}

ChatMessageSkeleton.propTypes = {
    position: PropTypes.oneOf(["left", "right"])
}

ChatMessageSkeleton.defaultProps = {
    position: "left",
};

export default ChatMessageSkeleton;