export const isImage = (filename) => filename?.match(/.(jpg|jpeg|png|gif)$/i);
export const isAudio = (filename) => filename?.match(/.(wav|mp3|flac)$/i);
export const getExtension = (filename) => '.' + filename.split('.').pop();

export const getFileKey = (file) => (`${file.name}-${file.lastModified}-${file.size}`);

export const isSvgFile = (filename) => {
    const parts = filename.split(".");
    if( parts.length === 1 || ( parts[0] === "" && parts.length === 2 ) ) {
        return false;
    }
    const extension = parts.pop();
    return extension === "svg";
}