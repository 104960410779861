import React, {forwardRef, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles, CardHeader, Grid, TextField, CardActionArea, Container, Paper, useTheme, Slide, FormControl
} from '@material-ui/core';
import RichTextEditor2 from "../RichTextEditor2/RichTextEditor2";
import {
    deserializeEditorState,
    emptyEditorState,
    isEmptyState,
    serializeEditorState
} from "../RichTextEditor2/editorState";
import {deminifyDraftEditorState} from "../../utils/draftJsUtils";
import {useTranslation} from "react-i18next";
import FormBlock from "./index";
import PickerContainer from "../Pickers/PickerBase";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import {
    DatePicker,
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import BlockHeader from "./BlockHeader";
import "moment/locale/fr";
import "moment/locale/cs";
import {isValidDate} from "../../utils/textUtils";

moment.locale("en"); // it is required to select default locale manually

const useStyles = makeStyles((theme) => ({
    dateFormBlock: {

    },
    helperText: {
        position: "absolute",
        top: "100%"
    }
}));

const getDefaultValue = (blockTemplate) => {
    if (Boolean(blockTemplate.value) && moment(blockTemplate.value).isValid()) {
        return new Date(blockTemplate.value);
    } else {
        return null;
    }
}

const DateFormBlock = ({ blockTemplate, languagePack, language, editable, errorText, onTextChange, onTemplateChange, onValueChange}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [format, setFormat] = useState("MM/dd/yyyy");
    const [value, setValue] = useState(getDefaultValue(blockTemplate));
    const [hint, setHint] = useState();

    useEffect(() => {
        moment.locale(language);
        const localeData = moment.localeData();
        const localeFormat = localeData.longDateFormat('L');
        setFormat(localeFormat);
        // Create date hint, first replace '/' and '.' format dividers
        let dateHint = localeFormat.replace(/\//g, ", ").replace(/\./g, ", ");
        dateHint = dateHint.replace("DD", t("day", {lng: language}));
        dateHint = dateHint.replace("MM", t("month", {lng: language}));
        dateHint = dateHint.replace("YYYY", t("year", {lng: language}));
        setHint(dateHint);
    }, [language]);

    const handleDateChange = (date, value) => {
        setValue(date);
        onValueChange(date?.isValid() ? date.toString() : value);
    }

    const InputField = useCallback((props) => {
        const inputValue = Boolean(props.value) ? props.value : blockTemplate.value ?? "";

        return <TextField {...props} value={inputValue}/>;
    }, []);

    return (
        <FormControl fullWidth required={blockTemplate.required} error={Boolean(errorText)}>
            <BlockHeader
                editable={editable}
                blockTemplate={blockTemplate}
                languagePack={languagePack}
                onTemplateChange={onTemplateChange}
                onTextChange={onTextChange}
            />
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={language}>
                <KeyboardDatePicker
                    autoOk
                    fullWidth
                    hiddenLabel
                    variant="inline"
                    openTo="year"
                    views={["year", "month", "date"]}
                    // format="MM/dd/yyyy"
                    format={format}
                    placeholder={format}
                    //initialFocusedDate={new Date()}
                    //margin="normal"
                    inputVariant="outlined"
                    disabled={editable}
                    value={value}
                    onChange={handleDateChange}
                    error={Boolean(errorText)}
                    helperText={Boolean(errorText) ? errorText : hint}
                    TextFieldComponent={InputField}
                    FormHelperTextProps={{classes: {root: classes.helperText}}}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    KeyboardButtonProps={{
                        edge: "end"
                    }}
                />
            </MuiPickersUtilsProvider>
        </FormControl>
    )
};

DateFormBlock.propTypes = {
    blockTemplate: PropTypes.object.isRequired,
    languagePack: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    errorText: PropTypes.string,
    onTextChange: PropTypes.func,
    onTemplateChange: PropTypes.func,
    onValueChange: PropTypes.func,
};

DateFormBlock.defaultProps = {
    onTextChange: () => {},
    onTemplateChange: () => {},
    onValueChange: () => {},
};

export default DateFormBlock;
