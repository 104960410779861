import React, {useContext, useEffect, useState} from 'react';
import {
    alpha, AppBar,
    Avatar,
    Box, Button, ButtonBase, CircularProgress,
    Container, Divider, Drawer,
    Grid, Link,
    makeStyles, Typography,
    useTheme
} from '@material-ui/core';
import clsx from "clsx";
import PropTypes from "prop-types";
import DisclaimerDialog from "./reportDialogs/DisclaimerDialog";

const useStyles = makeStyles((theme) => ({
    styledLink: {
        textDecoration: "underline",
        color: theme.palette.text.hint,
        //transition: theme.transitions.create(['color']),
        '&:hover': {
            color: theme.palette.text.hint
        }
    },
}));

const UnderlinedLink = ({className, href, children, ...rest}) => {
    const classes = useStyles();

    return (
        <Link className={clsx(classes.styledLink, className)} href={href} color="inherit" target="_blank" variant="caption" {...rest}>
            {children}
        </Link>
    );
};

UnderlinedLink.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired
};

UnderlinedLink.defaultProps = {
};

export default UnderlinedLink;