import React, {useContext, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles,
    CssBaseline,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    TablePagination,
    List,
    ListItem,
    ListItemAvatar,
    useTheme,
    ListItemText,
    Divider,
    ListItemSecondaryAction,
    ListItemIcon,
    ButtonBase,
    Dialog,
    useMediaQuery, DialogTitle, DialogContent, DialogActions, Tabs, Tab, Button, Tooltip, DialogContentText
} from '@material-ui/core';
import WorkspaceInvitationPanel from "../../../components/WorkspaceInvitationPanel";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    dialogTitle: {
        //paddingBottom: theme.spacing(1)
    },
    dialogContent: {
        paddingBottom: 0
    },
    dialogActions: {
        padding: theme.spacing(3)
    }
}));

// TODO: Rename
// TODO: Add Exit icon to the top of the dialog
const InvitationDialog = ({className, open, onSendInvitations, onClose}) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    const [invitations, setInvitations] = useState([{id: 0, email: "", role: "solver"}]);
    const isSendDisabled = invitations.length === 0 || !invitations[0].email;

    const handleSendButtonClick = () => {
        setInvitations([]);
        onSendInvitations(invitations.filter(invitation => Boolean(invitation.email.trim())));
    }

    return (
        <Dialog
            id="invite-member-dialog"
            className={className}
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
            aria-labelledby="invite-member-dialog-title"
        >
            <DialogTitle id="invite-member-dialog-title" className={classes.dialogTitle}>
                {t("Invite people to your workspace")}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <DialogContentText variant="body2" color="textSecondary">
                    {t("Your teammates will get an email that gives them access to your workspace")}
                </DialogContentText>
                <WorkspaceInvitationPanel invitations={invitations} onInvitationsChanged={setInvitations}/>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button autoFocus color="primary" variant="contained" disabled={isSendDisabled} onClick={handleSendButtonClick}>
                    {t("send")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

InvitationDialog.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func
}

InvitationDialog.defaultProps = {
    onClose: () => {}
};

export default InvitationDialog;