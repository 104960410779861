import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles, CardHeader, Grid, TextField, CardActionArea, Container, Toolbar, Tooltip
} from '@material-ui/core';
import ReportProgress from "../../../components/ReportProgress";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {ApiContext} from "../../../contexts/ApiContext";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import UserAvatar from "../../../components/UserAvatar";
import {Skeleton} from "@material-ui/lab";
import Chat from "../../../components/chat/Chat";
import ChatMessage from "../../../components/chat/ChatMessage";
import ChatSkeleton from "../../../components/chat/ChatSkeleton";
import {useSelector} from "react-redux";
import {messagesSelector} from "../../../state/messages/selectors";
import {toId} from "../../../utils/apiUtils";
import {isReportClosed, isSystemMessage} from "../../../utils/reportUtils";
import SystemMessage from "../../../components/chat/SystemMessage";
import ChatMessageSkeleton from "../../../components/chat/ChatMessageSkeleton";
import {useTranslation} from "react-i18next";
import {useReportAsGuest} from "../../../hooks/useReportAsGuest";
import {workspaceSelector} from "../../../state/workspaces/selectors";
import ReportStatus from "../../../components/ReportStatus";

const useStyles = makeStyles((theme) => ({
    chatDialog: {
        height: "100%",
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    chat: {
        flex: "1 1 auto",
        minHeight: 0,
        //height: "100%",
        //padding: [theme.spacing(1), theme.spacing(3), theme.spacing(4), theme.spacing(3)],
    },
    // header: {
    //     display: "flex",
    //     flexDirection: "column",
    //     height: "100%"
    // },
    content: {
        flex: "1 1 auto",
        minHeight: 0,
        display: "flex",
        flexDirection: "column",
        height: "100%"
    },
    input: {
        // flex: "0 0 auto",
        // margin: [theme.spacing(1), theme.spacing(1)]
    },
    disabledInput: {
        opacity: "0.5",
        pointerEvents: "none"
    },
    headerIcon: {
        maxWidth: theme.spacing(6),
    },
    progress: {
        maxWidth: theme.spacing(50),
        marginRight: theme.spacing(1)
    },
    header: {
        padding: [theme.spacing(4), theme.spacing(4), theme.spacing(2), theme.spacing(4)],
        flex: "0 0 auto",
        display: "flex",
        alignItems: "flex-start"
    },
    headerData: {
        flex: "1 1 auto"
    },
    logo: {
        marginBottom: theme.spacing(2.5),
        display: "flex",
        alignItems: "center"
    },
    avatar: {
        marginRight: theme.spacing(1)
    },
    divider: {
        margin: [0, theme.spacing(4)]
    },
    notification: {
        display: "inline-block",
        margin: "auto",
        borderRadius: "8px",
        borderWidth: "thin",
        borderStyle: "solid",
        borderColor: theme.palette.divider,
        color: theme.palette.text.hint,
        padding: theme.spacing(1)
    },
}));

// TODO: Rename to guest chat
const ChatDialog = ({ className, accessId, reportKey, onChatClose}) => {
    const classes = useStyles();
    const api = useContext(ApiContext);
    const { t } = useTranslation();
    const messages = useSelector(messagesSelector(reportKey));
    const workspaceKey = messages ? messages[0].subscription : null;
    const workspace = useSelector(workspaceSelector(workspaceKey));
    const subscriptionRef = useReportAsGuest(reportKey);
    const reportClosed = isReportClosed(messages);

    const handleChatSubmit = async ({content, attachments}) => {
        // TODO: Add message to redux??
        await api.mutation.createGuestMessage(workspace.pk, accessId, reportKey, content, attachments);
    };

    const handleChatClose = () => {
        if (subscriptionRef.current) {
            subscriptionRef.current.unsubscribe();
        }
        onChatClose();
    }

    const header = () => (
        <div className={classes.header}>
            <Box flex="1 1 auto">
                <div className={classes.logo}>
                    <UserAvatar className={classes.avatar} name={workspace.name} imageKey={workspace.picture} component="span" variant={"rounded"}/>
                    <Typography variant="h3">
                        {workspace.name}
                    </Typography>
                </div>
                <Typography variant="h4" >
                    {`Report ${accessId}`}
                </Typography>
                {/*<ReportProgress className={classes.progress} createdAt={messages?.[0].createdAt}/>*/}
                {/*<ReportStatus report={{createdAt: messages?.[0].createdAt, state: messages?.[messages.length - 1].state}}/>*/}
            </Box>
            {/*<Tooltip title="Export">*/}
            {/*    <IconButton aria-label="export">*/}
            {/*        <CloudDownloadOutli nedIcon fontSize="small" />*/}
            {/*    </IconButton>*/}
            {/*</Tooltip>*/}
            {/*<Tooltip title="More">*/}
            {/*    <IconButton aria-label="export">*/}
            {/*        <MoreVertOutlinedIcon fontSize="small" />*/}
            {/*    </IconButton>*/}
            {/*</Tooltip>*/}
            <Tooltip title="Exit report">
                <IconButton aria-label="export" onClick={handleChatClose} edge="end">
                    <CloseOutlinedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </div>
    );

    const headerSkeleton = () => (
        <>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
        </>
    )

    // TODO: Reduce number of divs, remove card elements
    return (
        <Container id="chat-dialog" maxWidth="md" className={clsx(classes.chatDialog, className)}>
            <Card className={classes.card}>
                { workspace ? header() : headerSkeleton() }
                <Divider className={classes.divider}/>
                { messages ? (
                    <Chat
                        className={classes.chat}
                        classes={{input: clsx(classes.input, {[classes.disabledInput]: reportClosed})}}
                        messages={messages}
                        workspaceKey={workspace?.pk}
                        onSubmit={handleChatSubmit}
                        inputDisabled={reportClosed}
                        inputPlaceholder={reportClosed ? t("disabledChatInputPlaceholder") : t("guestChatInputPlaceholder")}
                        header={(
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <div className={classes.notification}>
                                    <LockOutlinedIcon/>
                                    <Typography variant="caption" color="inherit">
                                        {t("anonymousChatNotification")}
                                    </Typography>
                                </div>
                            </Box>
                        )}
                    />
                ) : (
                    <ChatSkeleton/>
                )}
            </Card>
        </Container>
    );
};

ChatDialog.propTypes = {
    className: PropTypes.string,
    reportKey: PropTypes.string.isRequired,
    accessId: PropTypes.string.isRequired
};

export default ChatDialog;
