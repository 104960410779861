import {DefaultDraftBlockRenderMap} from "draft-js";
import Immutable from "immutable";

export const getStyleName = (control) => {
    return control.name.toUpperCase();
}

export const getContentBlockType = (control) => {
   return control.name.toLowerCase();
}

export const getBlockMap = (customControls) => {
    const customBlockMap = {};
    customControls
        ?.filter(control => control.type === "block" && (control.blockElement || control.blockWrapper))
        .forEach(control => {
            customBlockMap[getContentBlockType(control)] = {
                element: control.blockElement ?? "div",
                wrapper: control.blockWrapper
            }
        });
    return DefaultDraftBlockRenderMap.merge(Immutable.Map(customBlockMap));
}

export const getStyleMap = (customControls) => {
    const customStyleMap = {};
    customControls
        ?.filter(control => control.type === "inline" && control.inlineStyle)
        .forEach(control => {
            customStyleMap[getStyleName(control)] = control.inlineStyle
        })
    return customStyleMap;
}