import React, {useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles, CardHeader, Grid, TextField, CardActionArea, Container, Paper
} from '@material-ui/core';
import RichTextEditor2 from "../RichTextEditor2/RichTextEditor2";
import {
    deserializeEditorState,
    emptyEditorState,
    isEmptyState,
    serializeEditorState
} from "../RichTextEditor2/editorState";
import {deminifyDraftEditorState} from "../../utils/draftJsUtils";
import {useTranslation} from "react-i18next";
import FormBlock from "./index";

const useStyles = makeStyles((theme) => ({
    formText: {
        backgroundColor: theme.palette.background.paper
    },
    editorToolbar: {
        position: "sticky",
        justifyContent: "center",
        //backgroundColor: theme.palette.background.paper,
        backgroundColor: theme.palette.grey[50],
        bottom: 0,
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        borderTop: `1px solid ${theme.palette.border.default}`,
        // top: 0,
        // borderTopLeftRadius: theme.shape.borderRadius,
        // borderTopRightRadius: theme.shape.borderRadius,
        // borderBottom: `1px solid ${theme.palette.border.default}`,
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
        '&>button': {
            margin: [0, theme.spacing(0.25)]
        }
    },
    editorToolbarHidden: {
        opacity: 0,
        '&>*': {
            pointerEvents: "none"
        }
    }
}));

const TextFormBlock = ({ editable, blockTemplate, languagePack, onTextChange}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [editorState, setEditorState] = useState(() => emptyEditorState());
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (languagePack[blockTemplate.content]) {
            setEditorState(deserializeEditorState(languagePack[blockTemplate.content]));
        } else {
            setEditorState(emptyEditorState());
        }
    }, [languagePack])

    const handleChange = (newEditorState) => {
        setEditorState(newEditorState);
        // Invoke change handler just to announce to the parent component that the text has changed.
        // We use the a dummy value to avoid serializing the editor state on each text change.
        // The real changes are propagated to the parent component after the editor loses focus
        onTextChange({key: blockTemplate.content, value: languagePack[blockTemplate.content]});
    }

    const handleFocus = () => {
        setFocused(true);
    }

    const handleBlur = () => {
        const content = serializeEditorState(editorState);
        onTextChange({key: blockTemplate.content, value: content});
        setFocused(false);
    }

    // Don't render text editor in presentational view if the editor state is empty
    if (!editable && isEmptyState(editorState)) {
        return <></>
    }

    return (
        <RichTextEditor2
            className={classes.formText}
            classes={{toolbar: clsx(classes.editorToolbar, {[classes.editorToolbarHidden]: !focused})}}
            variant={editable ? "outlined" : "readOnly"}
            toolbarPosition="bottom"
            placeholder={`${t("addText")}...`}
            editorState={editorState}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            options={["title1", "title2", "divider", "bold", "italic", "underline", "divider", "unordered-list-item", "ordered-list-item"]}
            customControls={[
                {
                    name: "title1",
                    label: `${t("Title")} 1`,
                    type: "block",
                    blockElement: "span",
                    blockWrapper: <Typography variant="h3"/>
                },
                {
                    name: "title2",
                    label: `${t("Title")} 2`,
                    type: "block",
                    blockElement: "span",
                    blockWrapper: <Typography variant="h5"/>
                }
            ]}
        />
    )
};

TextFormBlock.propTypes = {
    blockTemplate: PropTypes.object.isRequired,
    languagePack: PropTypes.object.isRequired,
    editable: PropTypes.bool,
    onTextChange: PropTypes.func
};

TextFormBlock.defaultProps = {
    onTextChange: () => {}
};

export default TextFormBlock;
