import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    CardHeader,
    Grid,
    TextField,
    CardActionArea,
    Container,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import RichTextEditor from "../RichTextEditor";
import DialogContainer from "../DialogContainer";
import StaticDialog from "../staticDialog/StaticDialog";
import StaticDialogHeader from "../staticDialog/StaticDialogHeader";
import StaticDialogContent from "../staticDialog/StaticDialogContent";
import StaticDialogActions from "../staticDialog/StaticDialogActions";
import AddAttachmentButton from "../attachments/AddAttachmentButton";
import AttachmentsContainer from "../AttachmentsContainer";
import {LocalAttachment} from "../attachments";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import {scrollToView} from "../../utils/ui";
import {getFileKey} from "../../utils/fileUtils";
import UnderlinedLink from "../UnderlinedLink";
import {WHISPERO_PRIVACY_POLICY_URL} from "../../constants";
import {createDataBlock} from "../../utils/formUtils";
import {SpinnerButton} from "../ConfirmationButton/SpinnerButton";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";

const useStyles = makeStyles((theme) => ({
    textOffset: {
        // to compensate padding of rtf editor when its editable. Horizontal margin is 2px smaller than
        // rtf editor padding, this is to keep border visible when rtf is in scrollable container
        margin: [-theme.spacing(2), -theme.spacing(1.75)]
    },
    inputArea: {
        pointerEvents: props => props.editable ? "none" : "initial",
        width: "100%",
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "flex-end",
        marginTop: theme.spacing(1)     // only 1 because the attachment button has also margin (originally was 2)
    },
    inputToolbar: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end"
    },
    messageContainer: {
        width: "100%",
        margin: 0,
        flex: "1 1 auto",
        minWidth: 0
    },
    actions: {
        pointerEvents: props => props.editable ? "none" : "initial",
        display: "block",
        textAlign: "center"
    },
    submitButton: {
        marginRight: 0,
        marginBottom: theme.spacing(1),
        flex: "0 0 auto"
    },
    messageInput: {
        height: "14.275rem",
        //height: "100%",
        alignItems: "initial"
    },
    attachmentsContainer: {
        marginTop: theme.spacing(0.5)
    },
    legal: {
        color: theme.palette.text.hint
    },
    facultySelect: {
        margin: [theme.spacing(4), 0, theme.spacing(3), 0]
    }
}));

const isLoaded = false;

// TODO: Delete
// TODO: Refactor
// TODO: Rename to MessageDialog/InputDialog
// TODO: Rename layout parameter
const SubmitDialog = ({ className, classes: propClasses, showLogo, workspace, language, layout, editable, onSubmit, onSave }) => {
    const classes = useStyles({editable});
    const { t } = useTranslation(['translation', 'dialogs']);
    const endRef = useRef(null);
    const rtfData = layout ?? t("submit.defaultText", {ns: "dialogs", lng: language});
    const [text, setText] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [idle, setIdle] = useState(false);
    // ---- Added only for demo purposes -----
    // TODO: Remove when forms are fully customizable
    const [faculty, setFaculty] = useState();
    const faculties = ["faculty0", "faculty1", "faculty2", "faculty3", "faculty4", "faculty5"];

    useEffect(() => {
        // To prevent scrolling to bottom immediately when a dialog is rendered
        if (attachments.length === 0) {
            return;
        }
        scrollToView(endRef);
    }, [attachments.length]);

    const handleSubmit = (event) => {
        event.persist()
        event.preventDefault();
        setIdle(true);
        onSubmit({text: text, attachments: attachments.filter(a => Boolean(a.key)).map(a => a.key)});
    };

    const handleFileAdded = (file) => {
        const attachmentId = getFileKey(file);
        const isAttached = attachments.some(attachment => attachment.id === attachmentId);
        if (!isAttached) {
            setAttachments(prev => {
                const attachment = { file: file, id: attachmentId };
                return [...prev, attachment];
            });
        }
    }

    const handleAttachmentUploaded = (attachmentId, key) => {
        setAttachments(prev => {
            const index = prev.findIndex(attachment => attachment.id === attachmentId);
            const newState = [...prev];
            newState[index].key = key;
            return newState;
        });
    }

    const handleAttachmentDeleted = (attachmentId) => {
        setAttachments(prev => {
            const index = prev.findIndex(attachment => attachment.id === attachmentId);
            const newState = [...prev];
            newState.splice(index, 1);
            return newState;
        });
    }

    // TODO: Refactor localization entries
    return (
        <form autoComplete="off" onSubmit={handleSubmit} style={{height: "100%"}}>
            <DialogContainer className={className}>
                    <StaticDialog className={propClasses.dialog}>
                        {/*{showLogo && (*/}
                        {/*    <StaticDialogHeader workspace={workspace}/>*/}
                        {/*)}*/}
                        <StaticDialogContent>
                            <RichTextEditor className={clsx({[classes.textOffset]: editable})} readOnly={!editable} value={rtfData} onSave={onSave}/>
                            {workspace.pk === "team#261425420" && (
                                <FormControl className={classes.facultySelect} variant="outlined" fullWidth hiddenLabel>
                                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                        {t("faculty", {lng: language})}
                                    </Typography>
                                    {/*<InputLabel id="faculty-select-label">{t("faculty", {lng: language})}</InputLabel>*/}
                                    <Select
                                        // labelId="faculty-select-label"
                                        // id="faculty-select"
                                        value={faculty ?? ""}
                                        onChange={(event) => setFaculty(event.target.value)}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        {faculties.map(value => (
                                            <MenuItem key={value} value={t(value, {lng: language})}>{t(value, {lng: language})}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            <div className={clsx(classes.inputArea, className)}>
                                <div className={classes.inputToolbar}>
                                    {/*// TODO: Convert to control*/}
                                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                        {t("Description", {lng: language})}
                                    </Typography>
                                    <AddAttachmentButton
                                        language={language}
                                        variant='icon'
                                        component="span"
                                        edge="end"
                                        onFileAdded={handleFileAdded}
                                    >
                                        <AttachFileOutlinedIcon />
                                    </AddAttachmentButton>
                                </div>
                                <div className={classes.messageContainer}>
                                    <TextField
                                        value={text}
                                        // label={t("Description", {lng: language})}
                                        placeholder={t("Describe your experience", {lng: language})}
                                        required
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        maxRows="10"
                                        name="message"
                                        hiddenLabel
                                        onChange={(e) => setText(e.target.value)}
                                        InputProps={{
                                            classes: {
                                                root: classes.messageInput
                                            }
                                        }}
                                    />
                                    { (attachments.length > 0) && (
                                        <AttachmentsContainer className={classes.attachmentsContainer}>
                                            {attachments.map((attachment) => (
                                                <LocalAttachment
                                                    key={attachment.id}
                                                    file={attachment.file}
                                                    onUploaded={(key) => handleAttachmentUploaded(attachment.id, key)}
                                                    onDeleted={() => handleAttachmentDeleted(attachment.id)}
                                                />
                                            ))}
                                        </AttachmentsContainer>
                                    )}
                                </div>
                                <div ref={endRef} />
                            </div>
                        </StaticDialogContent>
                        <StaticDialogActions className={classes.actions}>
                            <SpinnerButton
                                className={classes.submitButton}
                                showSpinner={idle}
                                fullWidth
                                type="submit"
                                size="large"
                                color="primary"
                                variant="contained"
                            >
                                {t("send", {lng: language})}
                            </SpinnerButton>
                            <Typography className={classes.legal} variant="caption" color="textSecondary" align="center">
                                {`${t("bySubmittingReportYouAgreeWith", {lng: language})} `}
                                <UnderlinedLink href={WHISPERO_PRIVACY_POLICY_URL}>
                                    {t("withPrivacyPolicy", {lng: language})}
                                </UnderlinedLink>
                            </Typography>
                        </StaticDialogActions>
                    </StaticDialog>
            </DialogContainer>
        </form>
    );
};

SubmitDialog.propTypes = {
    className: PropTypes.string,
    workspace: PropTypes.shape({
        name: PropTypes.string,
        picture: PropTypes.string,
    }).isRequired,
    language: PropTypes.string,
    layout: PropTypes.string
};

SubmitDialog.defaultProps = {
    language: "en",
    showLogo: true,
    classes: {}
};

export default SubmitDialog;
