import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    CardHeader,
    Grid,
    TextField,
    CardActionArea,
    Container,
    Paper,
    FormControlLabel,
    Checkbox,
    alpha,
    ButtonBase,
    FormHelperText, FormControl,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CheckBox } from "@material-ui/icons";
import BlockHeader from "./BlockHeader";
import FormTextField from "../FormTextField/FormTextField";
import FormBlock from "./index";
import { getFileKey } from "../../utils/fileUtils";
import AttachmentsContainer from "../AttachmentsContainer";
import { LocalAttachment, S3Attachment } from "../attachments";
import AddAttachmentButton from "../attachments/AddAttachmentButton";
import { MAX_ATTACHMENT_SIZE } from "../../constants";
import ErrorDialog from "../ErrorDialog";
import AttachmentPreview from "../attachments/AttachmentPreview";
import { deleteElementByValue, deleteMatchingElement, updateMatchingElementField } from "../../utils/arrayUtils";
import {defaultTitle} from "../../utils/formBlockUtils";

const useStyles = makeStyles((theme) => ({
    attachmentsArea: {
        backgroundColor: theme.palette.grey[50],
        borderRadius: theme.shape.borderRadius,
        border: `2px dashed ${theme.palette.grey[300]}`,
        textAlign: "center",
        padding: theme.spacing(3),
        position: "relative",
    },
    button: {
        marginBottom: theme.spacing(1),
    },
    disabledContainedButton: {
        borderRadius: theme.shape.borderRadius,
        //color: theme.palette.action.disabled,
        //backgroundColor: theme.palette.action.disabledBackground,
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.getContrastText(theme.palette.grey[300]),
        boxShadow: theme.shadows[2],
        padding: "4px 16px",
    },
    buttonTextField: {
        pointerEvents: "all",
    },
    presentationalAttachmentsBlock: {
        position: "relative",
    },
    attachmentsContainer: {
        marginTop: theme.spacing(3),
    },
    helperText: {
        position: "absolute",
        top: "100%",
        left: 0
    },
    highlighted: {
        borderColor: theme.palette.primary.main,
    },
    imageAttachment: {
        maxWidth: theme.spacing(40),
        height: theme.spacing(8),
    }
}));

const defaultButtonText = (t, language) => t("selectFiles", { lng: language });
const defaultHintText = (t, language) => t("dragDropHint", { lng: language });

const PresentationalAttachmentsFormBlock = ({
    blockTemplate,
    languagePack,
    language,
    errorText,
    onValueChange
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    // blockTemplate.value are the keys of the attachments that are already uploaded, for example they were uploaded
    // before a user went back to a previous page in a form and then came back to the page that contained attachment
    // area block
    const [defaultAttachmentKeys, setDefaultAttachmentKeys] = useState(blockTemplate.value ?? []);
    const [attachments, setAttachments] = useState([]);
    const [invalidFile, setInvalidFile] = useState("");
    const [highlighted, setHighlighted] = useState(false);
    const dragCounterRef = useRef(0);
    const currentAttachmentKeysRef = useRef(blockTemplate.value ?? []);

    const handleFileAdded = (file) => {
        const attachmentId = getFileKey(file);
        const isAttached = attachments.some((attachment) => attachment.id === attachmentId);
        if (!isAttached) {
            setAttachments((prev) => [...prev, { file: file, id: attachmentId }]);
        }
    };

    const handleAttachmentUploaded = (attachmentId, key) => {
        currentAttachmentKeysRef.current = [...currentAttachmentKeysRef.current, key];
        onValueChange(currentAttachmentKeysRef.current);

        setAttachments((prev) => {
            return updateMatchingElementField(prev, (attachment) => attachment.id === attachmentId, "key", key);
        });
    };

    const handleAttachmentDeleted = (attachmentId) => {
        const s3Key = attachments.find((attachment) => attachment.id === attachmentId).key;
        deletePreviouslyUploadedAttachment(s3Key);

        setAttachments((prev) => {
            return deleteMatchingElement(prev, (attachment) => attachment.id === attachmentId);
        });
    };

    const deletePreviouslyUploadedAttachment = (s3Key) => {
        currentAttachmentKeysRef.current = deleteElementByValue(currentAttachmentKeysRef.current, s3Key);
        onValueChange(currentAttachmentKeysRef.current);
        setDefaultAttachmentKeys(prev => deleteElementByValue(prev, s3Key));
    };

    const handleDragEnter = (event) => {
        event.preventDefault();
        dragCounterRef.current++;
        if (dragCounterRef.current === 1) {
            setHighlighted(true);
        }
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        dragCounterRef.current--;
        if (dragCounterRef.current === 0) {
            setHighlighted(false);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        let files = event.dataTransfer.files;
        dragCounterRef.current = 0;
        setHighlighted(false);

        for (const file of files) {
            if (file.size > MAX_ATTACHMENT_SIZE) {
                setInvalidFile(file.name);
            } else {
                handleFileAdded(file);
            }
        }
    };

    // TODO: Convert file size error dialog to component, used also in addAttachment button
    return (
        <FormControl
            fullWidth
            required={blockTemplate.required}
            error={Boolean(errorText)}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <BlockHeader
                blockTemplate={blockTemplate}
                languagePack={languagePack}
                defaultValue={defaultTitle(blockTemplate.type, t, language)}
            />
            <div
                className={clsx(classes.attachmentsArea, {
                    [classes.highlighted]: highlighted,
                })}
            >
                <AddAttachmentButton
                    className={classes.button}
                    language={language}
                    variant="standard"
                    component="div"
                    onFileAdded={handleFileAdded}
                >
                    {languagePack[blockTemplate.buttonText] ?? defaultButtonText(t, language)}
                </AddAttachmentButton>
                <Typography variant="body2">
                    {languagePack[blockTemplate.hintText] ?? defaultHintText(t, language)}
                </Typography>
                {(attachments.length > 0 || defaultAttachmentKeys.length > 0) && (
                    <AttachmentsContainer className={classes.attachmentsContainer}>
                        {attachments.map((attachment) => (
                            <LocalAttachment
                                key={attachment.id}
                                file={attachment.file}
                                onUploaded={(key) => handleAttachmentUploaded(attachment.id, key)}
                                onDeleted={() => handleAttachmentDeleted(attachment.id)}
                            />
                        ))}
                        {defaultAttachmentKeys.map((s3Key) => (
                            <S3Attachment
                                key={s3Key}
                                s3Key={s3Key}
                                language={language}
                                canDelete
                                classes={{imageAttachment: classes.imageAttachment}}
                                onDelete={() => deletePreviouslyUploadedAttachment(s3Key)}
                            />
                        ))}
                    </AttachmentsContainer>
                )}
            </div>
            {Boolean(errorText) && (
                <FormHelperText className={classes.helperText} error variant='outlined'>
                    {errorText}
                </FormHelperText>
            )}
            <ErrorDialog
                open={Boolean(invalidFile)}
                title={t("cannotUploadAttachment", { name: invalidFile, lng: language })}
                text={t("attachmentSizeError", { lng: language })}
                onClose={() => setInvalidFile(null)}
            />
        </FormControl>
    );
};

const EditableAttachmentsFormBlock = ({ blockTemplate, languagePack, language, onTextChange, onTemplateChange }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <FormControl fullWidth required={blockTemplate.required}>
            <BlockHeader
                editable
                blockTemplate={blockTemplate}
                languagePack={languagePack}
                defaultValue={defaultTitle(blockTemplate.type, t, language)}
                onTemplateChange={onTemplateChange}
                onTextChange={onTextChange}
            />
            <div className={classes.attachmentsArea}>
                <ButtonBase className={clsx(classes.disabledContainedButton, classes.button)} disabled component="div">
                    <FormTextField
                        className={classes.buttonTextField}
                        templateKey={blockTemplate.buttonText}
                        languagePack={languagePack}
                        defaultValue={defaultButtonText(t, language)}
                        placeholder={t("buttonText")}
                        alignment="center"
                        disableUnderline
                        textVariant="buttonMedium"
                        onTextChange={onTextChange}
                    />
                </ButtonBase>
                <div>
                    <FormTextField
                        templateKey={blockTemplate.hintText}
                        languagePack={languagePack}
                        defaultValue={defaultHintText(t, language)}
                        placeholder={t("text")}
                        alignment="center"
                        onTextChange={onTextChange}
                    />
                </div>
            </div>
        </FormControl>
    );
};

const AttachmentsFormBlock = (props) => {
    return props.editable ? (
        <EditableAttachmentsFormBlock {...props}/>
    ) : (
        <PresentationalAttachmentsFormBlock {...props}/>
    );
};

AttachmentsFormBlock.propTypes = {
    blockTemplate: PropTypes.object.isRequired,
    languagePack: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    errorText: PropTypes.string,
    onTextChange: PropTypes.func,
    onTemplateChange: PropTypes.func,
    onValueChange: PropTypes.func
};

AttachmentsFormBlock.defaultProps = {
    onTextChange: () => {},
    onTemplateChange: () => {},
    onValueChange: () => {},
};

export default AttachmentsFormBlock;
