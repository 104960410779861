import {EditorState, Modifier} from "draft-js";

export const addText = (editorState, text) => {
    const addSpaceState = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        text,
        editorState.getCurrentInlineStyle());
    return EditorState.push(editorState, addSpaceState, "insert-characters");
}
