import React, {useContext, useState, useEffect, useCallback, useRef} from 'react';
import {ApiContext} from "../contexts/ApiContext";
import {ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {addForm, loadForms} from "../state/forms/actions";
import {formSelector} from "../state/forms/selectors";
import {addOrUpdateAccessCredentials} from "../state/accessCredentials/actions";
import {accessCodeSelector} from "../state/accessCredentials/selectors";

export const useFetchAccessCode = (resource) => {
    const api = useContext(ApiContext);
    const dispatch = useDispatch();
    const accessCodeRedux = useSelector(accessCodeSelector(resource.workspace, resource.pk));
    const [accessCode, setAccessCode] = useState(accessCodeRedux);

    const fetchAsync = async () => {
        const credentials = await api.query.getResourceAccessCredentials(resource.pk);
        if (credentials && credentials.accessCode) {
            dispatch(addOrUpdateAccessCredentials(resource.workspace, resource.pk, credentials.accessCode));
            setAccessCode(credentials.accessCode);
        }
    }

    useEffect(() => {
        if (!resource) {
            throw new Error("Resource cannot be null or undefined");
        }

        if (accessCode) {
            return;
        }

        if (resource.passwordProtected) {
            fetchAsync();
        }
    }, [resource.pk]);

    return [accessCode, setAccessCode];
}

