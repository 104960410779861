import React, {useContext, useState} from 'react';
import {Link as RouterLink, NavLink, useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    AppBar,
    Container,
    Badge,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    alpha,
    makeStyles,
    Avatar,
    Icon,
    Typography,
    CssBaseline,
    Tooltip,
    ListItemIcon,
    ListItemText,
    Divider,
    Select,
    Button,
    FormControl, Accordion, AccordionSummary, AccordionDetails, List, ListItem, Drawer
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import {useTranslation} from "react-i18next";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AddIcon from "@material-ui/icons/Add";
import {BUTTON_BORDER_RADIUS, CardShadow, TopBarHeight} from "../../../constants";
import {LANGUAGES} from "../../../constants/languages";
import ElevationScroll from "../../../components/ElevationScroll";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UserAvatar from "../../../components/UserAvatar";

const useStyles = makeStyles((theme) => ({
    appBar: {
        flex: 0,
        backgroundColor: theme.palette.background.paper
    },
    toolbar: {
        justifyContent: "space-between",
        width: "100%",
        maxWidth: theme.spacing(144), // 1152px
        margin: "auto",
    },
    logo: {
        marginRight: theme.spacing(1),
        display: "inline-flex" // TODO: Move to UserAvatar root style
    },
    languageSelect: {
        marginRight: theme.spacing(3)
    },
    drawerPaper: {
        height: "auto",
        width: "100%",
        padding: theme.spacing(3),
        '&>*:not(:first-child)': {
            marginTop: theme.spacing(3),
        }
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1)
    },
    accordion: {
        boxShadow: "none",
        '&::before': {
            display: "none"
        }
    },
    accordionExpanded: {
        marginBottom: "0!important"
    },
    accordionHeader: {
        minHeight: "0!important",
        padding: 0,
        ...theme.typography.button,
        fontSize: "0.9375rem",
        '& .MuiIconButton-root': {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    menuList: {
        width: "100%"
    },
    menuListItem: {
        borderRadius: 8
    },
    noGutters: {
        margin: "0!important",
        padding: 0
    },
    button: {
        //borderRadius: BUTTON_BORDER_RADIUS,
        '&:hover': {
            color: theme.palette.primary.contrastText
        }
    },
    toolbarHeader: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center"
    }
}));

const SubmitToolbar =  ({className, workspace, language, availableLanguages, onLanguageChange}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleLanguageChanged = (value) => {
        onLanguageChange(value);
        setDrawerOpen(false);
    }

    const WorkspaceLogo = () => (
        <div className={classes.toolbarHeader}>
            <UserAvatar className={classes.logo} name={workspace.name} imageKey={workspace.picture} component="span" variant={"rounded"}/>
            <Typography variant="h5" color="textPrimary" component="span">{workspace.name}</Typography>
        </div>
    )

    return (
        <>
            <ElevationScroll>
                <AppBar className={clsx(classes.appBar)} position="sticky" color="transparent" elevation={0}>
                    <Toolbar className={classes.toolbar}>
                        <WorkspaceLogo/>
                        <Hidden smUp>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleDrawerOpen}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                        <Hidden xsDown>
                            <Select
                                className={classes.languageSelect}
                                disableUnderline
                                color="secondary"
                                value={language}
                                onChange={(event) => onLanguageChange(event.target.value)}
                            >
                                {availableLanguages.map((lang) => (
                                    <MenuItem key={lang.code} value={lang.code}>
                                        {lang.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Button
                                className={classes.button}
                                component={NavLink}
                                to="/followup"
                                underline="none"
                                size="medium"
                                color="primary"
                                variant="contained"
                            >
                                {t("loginToReport", {lng: language})}
                            </Button>
                        </Hidden>
                    </Toolbar>
                    <Divider/>
                </AppBar>
            </ElevationScroll>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleDrawerClose}
                classes={{
                    paper: classes.drawerPaper
                }}
            >
                <div className={classes.drawerHeader}>
                    <WorkspaceLogo/>
                    <IconButton
                        color="inherit"
                        aria-label="close drawer"
                        edge="end"
                        onClick={handleDrawerClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <Accordion className={classes.accordion} classes={{expanded: classes.accordionExpanded}}>
                    <AccordionSummary
                        className={classes.accordionHeader}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="language-panel-content"
                        id="language-panel-header"
                        classes={{
                            content: classes.noGutters
                        }}
                    >
                        <Typography variant="inherit" color="inherit">{t("Language", {lng: language})}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.noGutters}>
                        <List className={classes.menuList} component="div" disablePadding>
                            {availableLanguages.map((lang) => (
                                <ListItem
                                    button
                                    key={lang.code}
                                    className={classes.menuListItem}
                                    selected={language === lang.code}
                                    onClick={() => handleLanguageChanged(lang.code)}
                                >
                                    <ListItemText primary={lang.label} />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Button
                    className={classes.button}
                    component={NavLink}
                    to="/followup"
                    underline="none"
                    size="large"
                    color="primary"
                    variant="contained"
                >
                    {t("loginToReport", {lng: language})}
                </Button>
            </Drawer>
        </>
    )

    return (
        <AppBar className={clsx(classes.appBar, className)} position="sticky" color="transparent">
            <Toolbar className={classes.toolbar}>
                <Box flexGrow={1} />
                <Select
                    variant="outlined"
                    margin="dense"
                    value={language}
                    onChange={(event) => onLanguageChange(event.target.value)}
                >
                    {availableLanguages.map((lang) => (
                        <MenuItem key={lang.code} value={lang.code}>
                            {lang.label}
                        </MenuItem>
                    ))}
                </Select>
            </Toolbar>
            <Divider/>
        </AppBar>
    )
}

SubmitToolbar.propTypes = {
    className: PropTypes.string,
    language: PropTypes.string.isRequired,
    disabledLanguages: PropTypes.arrayOf(PropTypes.string),
    onLanguageChange: PropTypes.func
};

SubmitToolbar.defaultProps = {
    disabledLanguages: [],
    onLanguageChange: () => {}
};

export default SubmitToolbar