import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, CircularProgress, Typography, Box} from "@material-ui/core"
import PropTypes from "prop-types";
import ChangePasswordPanel from "../ProfileButton/ChangePasswordPanel";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    loaderRoot: {
        height: '100%',
        width: '100%',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.text.secondary,
        '&>p': {
            marginTop: theme.spacing(1)
        },
    }
}));

export const Loader = ({className, size, component, label, LabelProps}) => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.loaderRoot, className)} component={component}>
            <CircularProgress size={size}/>
            { label && (
                <Typography color="textSecondary" {...LabelProps}>{label}</Typography>
            )}
        </Box>
    )
}

Loader.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    LabelProps: PropTypes.object,
    component: PropTypes.string,
    size: PropTypes.number
}

Loader.defaultProps = {
    size: 40,
    component: "div"
};