import React, {useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    CardHeader,
    Grid,
    TextField,
    CardActionArea,
    Container,
    Paper,
    FormControlLabel,
    Checkbox,
    alpha,
    InputAdornment, Tooltip
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import {copyContentToClipboard} from "../../utils/clipboardUtils";
import FormBlock from "./index";

const useStyles = makeStyles((theme) => ({
    reportIdFormBlock: {
    },
}));

const ReportIdFormBlock = ({ blockTemplate, editable, language}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleCopyButtonOnClick = () => {
        copyContentToClipboard("report-id-field");
    };

    return (
        <TextField
            fullWidth
            hiddenLabel
            className={classes.reportIdFormBlock}
            id="report-id-field"
            variant="filled"
            disabled={editable}
            defaultValue={blockTemplate.value ?? ""}
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <Tooltip title={t("copy", {lng: language})}>
                            <>
                                <IconButton onClick={handleCopyButtonOnClick} disabled={editable}>
                                    <FileCopyOutlinedIcon/>
                                </IconButton>
                            </>
                        </Tooltip>
                    </InputAdornment>
                )
            }}
            InputLabelProps={{shrink: false}}
        />
    )
};

ReportIdFormBlock.propTypes = {
    blockTemplate: PropTypes.shape({
        value: PropTypes.string,
    }),
    editable: PropTypes.bool,
    language: PropTypes.string.isRequired
};

ReportIdFormBlock.defaultProps = {
};

export default ReportIdFormBlock;
