import React, {useContext, useEffect, useMemo, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    makeStyles,
    useTheme,
    Divider,
    Dialog,
    useMediaQuery, DialogTitle, DialogActions, DialogContent, TextField, FormControlLabel, Checkbox, Typography
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import {newAwsDateTime} from "../../utils/time";
import {ApiContext} from "../../contexts/ApiContext";
import {useDispatch, useSelector} from "react-redux";
import {currentUserSelector} from "../../state/currentUser/selectors";
import {
    PendingApproval,
    RejectedApproval
} from "../../utils/reportUtils";
import {getReportName} from "../../utils/textUtils";
import SettingsField from "../../components/SettingsField";
import {activeMembersSelector} from "../../state/members/selectors";
import RichTextEditor2 from "../../components/RichTextEditor2/RichTextEditor2";
import {
    deserializeEditorState,
    emptyEditorState, isEmptyState,
    serializeEditorState
} from "../../components/RichTextEditor2/editorState";

const useStyles = makeStyles((theme) => ({
    closureDialog: {
        borderRadius: "8px"
    },
    title: {
        padding: [theme.spacing(3), theme.spacing(4)]
    },
    content: {
        padding: [theme.spacing(0), theme.spacing(4)]
    },
    text: {
        marginBottom: theme.spacing(3)
    },
    actions: {
        padding: [theme.spacing(4), theme.spacing(4), theme.spacing(3), theme.spacing(4)],
        '&>*:not(:last-child) ': {
            marginRight: theme.spacing(1)
        }
    },
    fieldInput: {
        // height: "14.275rem",
        // alignItems: "initial",
        '& .DraftEditor-root': {
            height: "14.275rem"
        }
    }
}));

const getDialogContent = (variant, report, t) => {
    const name = getReportName(report, t);
    if (variant === "requestedApproval") {
        return {title: t("requestApprovalDialogTitle", {name}), text: t("requestApprovalDialogText"), confirmButtonText: t("requestApproval")};
    } else if (variant === "rejectedApproval") {
        return {title: t("rejectApprovalDialogTitle", {name}), text: t("rejectApprovalDialogText"), confirmButtonText: t("send")};
    } else {
        return {title: t("solveDialogTitle", {name}), text: t("solveDialogText"), confirmButtonText: t("solve")};
    }
}

// TODO: Solution is delayed when clicking on Solve button directly from the solution setting field
const ClosureDialog = ({open, variant, report, onClose}) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const api = useContext(ApiContext);
    const { t } = useTranslation();
    const currentUser = useSelector(currentUserSelector());
    const dialogContent = getDialogContent(variant, report, t);
    const [pendingEditorState, setPendingEditorState] = useState(() => emptyEditorState()); // TODO: Remove state initialization, not needed

    useMemo(() => {
        setPendingEditorState(deserializeEditorState(report.solution));
    }, [report.solution])

    const sendMessageWithSolution = async () => {
        if (!isEmptyState(pendingEditorState)) {
            //dispatch(addReportMessages(report.workspaceId, report.id, newMessage(report.id, content, currentUser.id, attachments)))
            // TODO: Add message to redux??
            await api.mutation.sendReportSolution(report.pk, report.workspace, serializeEditorState(pendingEditorState));
        }
    }

    const handleConfirmButtonClick = async () => {
        const data = {};
        const serialized = JSON.stringify(serializeEditorState(pendingEditorState));
        if (report.solution !== serialized) {
            data.solution = serialized;
        }

        if (variant === "requestedApproval") {
            data.approval = PendingApproval;
            await api.mutation.updateReportState(report.pk, report.sk, currentUser.pk, "solved", data);
            await api.mutation.createSystemMessage(report.pk, report.sk, currentUser.pk, {state: "solved", approval: PendingApproval});
        } else if (variant === "rejectedApproval") {
            data.approval = RejectedApproval;
            await api.mutation.updateResource(report.pk, report.sk, data);
            await sendMessageWithSolution();
        } else {
            await api.mutation.updateReportState(report.pk, report.sk, currentUser.pk, "solved", data);
            await api.mutation.createSystemMessage(report.pk, report.sk, currentUser.pk, {state: "solved"});
            await sendMessageWithSolution();
        }

        onClose();
    }

    return (
        <Dialog
            className={classes.closureDialog}
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
            aria-labelledby="report-close-dialog-title"
        >
            <DialogTitle className={classes.title} id="report-close-dialog-title">
                {dialogContent.title}
            </DialogTitle>
            <DialogContent className={classes.content}>
                <DialogContentText className={classes.text}>
                    {dialogContent.text}
                </DialogContentText>
                <Typography variant="subtitle2" color="textSecondary">
                    {t("Solution")}
                </Typography>
                <RichTextEditor2
                    className={classes.fieldInput}
                    editorState={pendingEditorState}
                    placeholder={`${t("Report solution")}...`}
                    highlightOnFocus
                    highlightOnHover
                    variant="outlined"
                    padding="dense"
                    options={["bold", "italic", "underline", "divider", "unordered-list-item", "ordered-list-item"]}
                    onChange={setPendingEditorState}
                />
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button onClick={onClose} color="primary" variant="outlined">
                    {t("Cancel")}
                </Button>
                <Button onClick={handleConfirmButtonClick} color="primary" variant="contained">
                    {dialogContent.confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ClosureDialog.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(["reportSolved", "requestedApproval", "rejectedApproval"])
}

ClosureDialog.defaultProps = {
    variant: "reportSolved",
    onClose: () => {}
};

export default ClosureDialog;