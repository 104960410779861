import React, { useState } from 'react';
import {makeStyles, Divider, alpha} from "@material-ui/core"
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    layoutRoot: {
        overflow: "hidden",
        height: '100%',
        backgroundColor: theme.palette.grey[50]
        // background: "linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)",
        // backgroundSize: "400% 400%",
        // animation: "$gradient 15s ease infinite"
    },
    // "@keyframes gradient": {
    //     "0%": {backgroundPosition: "0% 50%"},
    //     "50%": {backgroundPosition: "100% 50%"},
    //     "100%": {backgroundPosition: "0% 50%"}
    // },
}));

// TODO: Rename
const MainLayout = () => {
    const classes = useStyles();

    return (
        <div className={classes.layoutRoot}>
            <Outlet/>
        </div>
    )
}

export default MainLayout