import React, {useContext, useEffect, useState} from 'react';
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Paper,
    Typography
} from "@material-ui/core";
import TextFieldsIcon from '@material-ui/icons/TextFields';
import {useTranslation} from "react-i18next";
import SubjectIcon from '@material-ui/icons/Subject';
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import ShortTextOutlinedIcon from '@material-ui/icons/ShortTextOutlined';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import PropTypes from "prop-types";

const components = [
    {
        type: "text",
        icon: <TextFieldsIcon/>,
    },
    {
        type: "textField",
        icon: <ShortTextOutlinedIcon/>,
    },
    {
        type: "multilineTextField",
        icon: <SubjectIcon/>,
    },
    {
        type: "dateField",
        icon: <EventOutlinedIcon/>,
    },
    {
        type: "dropdown",
        icon: <ArrowDropDownCircleOutlinedIcon/>,
    },
    {
        type: "checkboxList",
        icon: <CheckBoxOutlinedIcon/>,
    },
    {
        type: "attachments",
        icon: <AttachFileOutlinedIcon/>,
    }
];

const useStyles = makeStyles((theme) => ({
    navigationPanel: {
        height: "100%",
        width: theme.spacing(40),
        padding: [theme.spacing(3), theme.spacing(1)],
        overflow: "auto"
    },
    listItem: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        }
    },
    componentAvatar: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.text.secondary
    },
    clonedItem: {
        '& ~ *': {
            transform: "none!important"
        }
    },
    label: {
        marginLeft: theme.spacing(2)
    },
    hint: {
        color: theme.palette.text.hint,
        textAlign: "center",
        margin: [theme.spacing(3), theme.spacing(3)]
    }
}));

const FormComponentsPanel = ({onItemClick}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.navigationPanel}>
            <Typography className={classes.label} variant="subtitle2" color="textSecondary" gutterBottom>
                {t("addComponents")}
            </Typography>
            <Droppable droppableId={`components`} isDropDisabled>
                {(provided) => (
                    <List
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {components.map((component, index) => (
                            <Draggable key={`component-${component.type}`} draggableId={`component-${component.type}`} index={index}>
                                {(provided, snapshot) => (
                                    <>
                                        <ListItem
                                            button
                                            className={classes.listItem}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            onClick={() => onItemClick(component.type)}
                                        >
                                            <ListItemAvatar>
                                                <Avatar className={classes.componentAvatar} variant="rounded">
                                                    {component.icon}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={t(component.type)}
                                                // secondary={t(`${component.type}Secondary`)}
                                                primaryTypographyProps={{variant: "body2"}}
                                                secondaryTypographyProps={{variant: "caption"}}
                                            />
                                        </ListItem>
                                        {snapshot.isDragging && (
                                            <ListItem className={classes.clonedItem}>
                                                <ListItemAvatar>
                                                    <Avatar className={classes.componentAvatar} variant="rounded">
                                                        {component.icon}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={t(component.type)}
                                                    // secondary={t(`${component.type}Secondary`)}
                                                    primaryTypographyProps={{variant: "body2"}}
                                                    secondaryTypographyProps={{variant: "caption"}}
                                                />
                                            </ListItem>
                                        )}
                                    </>
                                )}
                            </Draggable>
                        ))}
                        <div style={{display: "none"}}>
                            {provided.placeholder}
                        </div>
                    </List>
                )}
            </Droppable>
            <Typography className={classes.hint} variant="body2" component="p">
                <i>
                    {t("componentsDragDropHint")}
                </i>
            </Typography>
        </div>
    );
};

FormComponentsPanel.propTypes = {
    onItemClick: PropTypes.func
};

FormComponentsPanel.defaultProps = {
    onItemClick: () => {}
};

export default FormComponentsPanel;