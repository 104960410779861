import React from 'react';
import PropTypes from 'prop-types';
import {
    List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, makeStyles, Switch,
} from '@material-ui/core';
import DialogMenuItem from "./DialogMenuItem";
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import {useTranslation} from "react-i18next";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import {LANGUAGES} from "../../../constants/languages";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    list: {
    },
}));

const LanguageMenu = ({className, disabledLanguages, selectedIndex, onSelected, onLanguageEnabled, onLanguageDisabled}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const items = LANGUAGES.map(lang => ({
        code: lang.code,
        enabled: !disabledLanguages.includes(lang.code)
    }));
    const enabledLanguagesCount = LANGUAGES.length - disabledLanguages.length;
    //     .sort((a, b) => {
    //     if(!a.enabled && b.enabled) { return 1; }
    //     if(a.enabled && !b.enabled) { return -1; }
    //     return 0;
    // });

    const handleSwitchChanged = (event, code) => {
        if (event.target.checked) {
            onLanguageEnabled(code);
        } else {
            onLanguageDisabled(code);
        }
    }

    return (
        <List className={clsx(classes.list, className)} subheader={<ListSubheader>{t("dialogSettings.languages")}</ListSubheader>}>
            { items.map((item, index) => {
                // Disable switch if this is the only language that is still enabled. At least one language
                // must remain enabled.
                const isSwitchDisabled = enabledLanguagesCount === 1 && item.enabled;
                return (
                    <ListItem
                        button
                        key={`language-${item.code}`}
                        selected={selectedIndex === index}
                        onClick={() => onSelected(item.code, index)}
                    >
                        <ListItemText id={`${item.code}-switch-label`} primary={t(item.code)} />
                        <ListItemSecondaryAction>
                            <Switch
                                color="primary"
                                edge="end"
                                checked={item.enabled}
                                disabled={isSwitchDisabled}
                                onChange={event => handleSwitchChanged(event, item.code)}
                                inputProps={{ 'aria-labelledby': `${item.code}-switch-label` }}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
            )})}
        </List>
    )
}

LanguageMenu.propTypes = {
    className: PropTypes.string,
    disabledLanguages: PropTypes.arrayOf(PropTypes.string),
    onSelected: PropTypes.func.isRequired,
    onLanguageEnabled: PropTypes.func.isRequired,
    onLanguageDisabled: PropTypes.func.isRequired
};

LanguageMenu.defaultProps = {
    disabledLanguages: []
};

export default LanguageMenu