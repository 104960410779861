import * as types from "./actionTypes";
import {toId} from "../../utils/apiUtils";

const addIds = (workspaces) => {
    return workspaces.map(workspace => ({...workspace, id: toId(workspace.pk)}))
}

const addId = (workspace) => {
    return {...workspace, id: toId(workspace.pk)}
}

export const loadWorkspaces = (workspaces) => async (dispatch, getState) => {
    dispatch({ type: types.WORKSPACES_LOADED, payload: addIds(workspaces) });
}

// TODO: Refactor
export const addOrUpdateWorkspace = (workspace) => async (dispatch, getState) => {
    const existing = getState().workspaces.find(t => t.pk === workspace.pk);

    if (!existing) {
        dispatch({ type: types.WORKSPACE_ADDED, payload: addId(workspace) });
    } else {
        const payload = {...existing, ...workspace};
        dispatch({ type: types.WORKSPACE_UPDATED, payload });
    }
}

export const updateWorkspace = (key, fields) => async (dispatch, getState) => {
    const existing = getState().workspaces.find(t => t.pk === key);

    if (!existing) {
        console.warn("updateTeam - team with ID %o doesn't exist, existing workspaces: %o", key, getState().workspaces);
    } else {
        const payload = {...existing, ...fields};
        dispatch({ type: types.WORKSPACE_UPDATED, payload });
    }
}

export const deleteWorkspace = (key) => async (dispatch, getState) => {
    dispatch({ type: types.WORKSPACE_DELETED, payload: {pk: key} });
}