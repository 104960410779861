import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, CircularProgress, Typography, Box, DialogActions} from "@material-ui/core"
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Loader from "../Loader";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    confirmationButton: {
        // '&.Mui-disabled': {
        //     color: props => props.busy ? "transparent" : "initial"
        // }
    },
    busy: {
        color: "transparent!important"
    },
    loader: {
        position: "absolute",
    }
}));

// TODO: Rename
export const SpinnerButton = ({className, children, disabled, autoFocus, showSpinner, ...rest}) => {
    const classes = useStyles({showSpinner});

    return (
        <Button
            className={clsx(className, {[classes.busy]: showSpinner})}
            autoFocus={autoFocus}
            color="primary"
            variant="contained"
            disabled={disabled || showSpinner}
            {...rest}
        >
            {children}
            {showSpinner && (<Loader className={classes.loader} component="span" size={24}/>)}
        </Button>
    )
}

SpinnerButton.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    showSpinner: PropTypes.bool
}

SpinnerButton.defaultProps = {
};