import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Typography,
    colors,
    makeStyles,
    LinearProgress,
    Tooltip
} from '@material-ui/core';
import {millisecondsToDays} from "../../utils/time";
import DueDatePicker from "../Pickers/DueDatePicker";
import {useTranslation} from "react-i18next";
import {elapsedDays, termDays} from "../../utils/reportUtils";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: props => props.labelPlacement === "left" ? "row" : "row-reverse",
        alignItems: "center"
    },
    label: {
        flex: "0 0 auto",
        marginLeft: props => props.labelPlacement === "right" ? theme.spacing(0.5) : 0,
        marginRight: props => props.labelPlacement === "left" ? theme.spacing(0.5) : 0,
        color: theme.palette.text.hint
    },
    progressContainer: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        '&>div:not(:last-child) ': {
            marginRight: theme.spacing(0.25)
        }
    },
    progressBar: {
        height: "6px",
        flex: "1 1 auto",
        borderRadius: "4px",
        backgroundColor: theme.palette.grey[300],
        position: "relative",
        '&:after': {
            content: '""',
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: "4px",
            border: `1px solid ${theme.palette.divider}`
        },
    },
    barColorFirstTerm: {
        backgroundColor: theme.palette.secondary.light
    },
    barColorSecondTerm: {
        backgroundColor: theme.palette.secondary.main
    },
    barColorOverdue : {
        backgroundColor: theme.palette.secondary.dark
    }
}));

// TODO: Delete
const ReportProgress = ({className, createdAt, labelPlacement, LabelProps, classes: propClasses}) => {
    const classes = useStyles({labelPlacement});
    const { t } = useTranslation();
    const elapsed = elapsedDays({createdAt});
    // TODO: Move logic to container component
    // const isOverdue = elapsed > 2 * termDays;
    // const isExtended = elapsed > termDays;
    // const remaining = Math.abs(!isExtended ? termDays - elapsed : 2 * termDays - elapsed);
    const isOverdue = elapsed > termDays;
    const isExtended = false;
    const remaining = Math.abs(termDays - elapsed);

    return (
        <div id="report-progress" className={clsx(classes.root, className)}>
            <Typography className={clsx(classes.label, propClasses.label)} variant="caption" {...LabelProps}>
                {isOverdue ? (t("days overdue", {count: remaining})) :  (t("days remaining", {count: remaining}))}
            </Typography>
            <div className={classes.progressContainer}>
                <Tooltip title={t("Legal term")}>
                    <LinearProgress
                        className={clsx(classes.progressBar, propClasses.progressBar)}
                        color="secondary"
                        variant="determinate"
                        classes={{
                            barColorSecondary: clsx({
                                [classes.barColorFirstTerm] : !isOverdue,
                                [classes.barColorOverdue] : isOverdue,
                            })
                        }}
                        value={Math.min(elapsed / termDays, 1) * 100}
                    />
                </Tooltip>
                { isExtended && (
                    <Tooltip title={t("Extension term")}>
                        <LinearProgress
                            className={clsx(classes.progressBar, propClasses.progressBar)}
                            color="secondary"
                            variant="determinate"
                            classes={{
                                barColorSecondary: clsx({
                                    [classes.barColorSecondTerm] : !isOverdue,
                                    [classes.barColorOverdue] : isOverdue,
                                })
                            }}
                            value={Math.min((elapsed - termDays) / termDays, 1) * 100}
                        />
                    </Tooltip>
                )}
            </div>
        </div>
    )
};

ReportProgress.propTypes = {
    className: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    labelPlacement: PropTypes.oneOf(["left", "right"]),
}

ReportProgress.defaultProps = {
    reverseDirection: true,
    classes: {},
    labelPlacement: "right",
    LabelProps: {}
};


export default ReportProgress;