import React, {useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    CardHeader,
    Grid,
    TextField,
    CardActionArea,
    Container,
    Paper,
    FormControlLabel,
    Checkbox,
    alpha,
    MenuItem, InputAdornment, FormControl
} from '@material-ui/core';
import BlockHeader from "./BlockHeader";
import OptionFieldsList from "./OptionFieldsList";
import FormBlock from "./index";
import {useTranslation} from "react-i18next";
import {DEFAULT_FORM_SIDE_PADDING_SPACING} from "../../constants";
import {defaultTitle} from "../../utils/formBlockUtils";

const useStyles = makeStyles((theme) => ({
    dropdownFormBlock: {
    },
    disabledSelect: {
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            // borderColor: alpha(theme.palette.text.primary, 0.16)
        },
        pointerEvents: "none"
    },
    optionsContainer: {
        // backgroundColor: theme.palette.background.paper,
        // borderRadius: theme.shape.borderRadius,
        // border: `1px solid ${theme.palette.border.default}`,
        // overflowY: "hidden",
        marginTop: theme.spacing(1),
        // padding: [theme.spacing(1), theme.spacing(0)],

    },
    options: {
        overflow: "hidden",
    },
    addButton: {
        marginLeft: theme.spacing(3.75),
        marginTop: theme.spacing(0.5),
        color: theme.palette.text.secondary
    },
    helperText: {
        position: "absolute",
        top: "100%"
    },
    optionsList: {
        marginTop: theme.spacing(0.5),
    }
}));

const DropdownFormBlock = ({ blockTemplate, languagePack, language, editable, errorText, onTextChange, onTemplateChange, onTextDelete, onValueChange}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleChange = (event) => {
        onValueChange(event.target.value);
    }

    return (
        <FormControl fullWidth required={blockTemplate.required} error={Boolean(errorText)}>
            <BlockHeader
                editable={editable}
                blockTemplate={blockTemplate}
                languagePack={languagePack}
                defaultValue={defaultTitle(blockTemplate.type, t, language)}
                onTemplateChange={onTemplateChange}
                onTextChange={onTextChange}
            />
            <TextField
                select
                fullWidth
                hiddenLabel
                className={editable ? classes.disabledSelect : undefined}
                variant="outlined"
                disabled={editable}
                defaultValue={blockTemplate.value ?? "" }
                value={editable ? "" : undefined}
                error={Boolean(errorText)}
                helperText={Boolean(errorText) ? errorText : null}
                FormHelperTextProps={{classes: {root: classes.helperText}}}
                onChange={handleChange}
            >
                {!editable && !blockTemplate.required && (
                    <MenuItem value="">
                        <em>{t("none", { lng: language })}</em>
                    </MenuItem>
                )}
                {blockTemplate.options.map((optionKey) => (
                    <MenuItem key={optionKey} value={optionKey}>
                        {languagePack[optionKey] ?? ""}
                    </MenuItem>
                ))}
            </TextField>
            {editable && (
                <OptionFieldsList
                    className={classes.optionsList}
                    blockTemplate={blockTemplate}
                    languagePack={languagePack}
                    onTemplateChange={onTemplateChange}
                    onTextChange={onTextChange}
                    onTextDelete={onTextDelete}
                />
            )}
        </FormControl>
    )
}

DropdownFormBlock.propTypes = {
    blockTemplate: PropTypes.object.isRequired,
    languagePack: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    errorText: PropTypes.string,
    onTextChange: PropTypes.func,
    onTemplateChange: PropTypes.func,
    onTextDelete: PropTypes.func,
    onValueChange: PropTypes.func
}

DropdownFormBlock.defaultProps = {
    onTextChange: () => {},
    onTemplateChange: () => {},
    onTextDelete: () => {},
    onValueChange: () => {},
}

export default DropdownFormBlock;
