import React, { useContext, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
    AppBar,
    Container,
    Badge,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    alpha,
    List,
    makeStyles,
    Avatar,
    Icon,
    Drawer,
    Divider,
    ListItemIcon,
    ListItemText,
    CssBaseline,
    useTheme,
    FormControl,
    InputLabel,
    Select,
    Typography,
    withStyles,
    MenuList,
    Button, Tooltip, ListItem, useMediaQuery,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import NavItem from "./NavItem";
import clsx from "clsx";
import BusinessIcon from "@material-ui/icons/Business";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import AddIcon from "@material-ui/icons/Add";
import {CardShadow, NavBarWidth, TopBarHeight} from "../../../constants";
import SpeedIcon from "@material-ui/icons/Speed";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import CreateWorkspaceDialog from "../../../components/CreateWorkspaceDialog/CreateWorkspaceDialog";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import UserAvatar from "../../../components/UserAvatar";
import { useSelector } from "react-redux";
import { activeWorkspacesSelector, allWorkspacesSelector } from "../../../state/workspaces/selectors";
import { ApiContext } from "../../../contexts/ApiContext";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import {toId, toWorkspaceKey} from "../../../utils/apiUtils";
import {currentUserRoleSelector} from "../../../state/members/selectors";
import {Text, View} from "@react-pdf/renderer";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

const items = [
    {
        href: "/portal/home",
        icon: <HomeOutlinedIcon/>,
        title: "home",
    },
    // {
    //     href: "./reports",
    //     icon: <ChatBubbleOutlineIcon/>,
    //     title: "Reports",
    // },
];

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: NavBarWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerPaper: {
        backgroundColor: theme.palette.grey[100],
        overflow: "hidden",
    },
    drawerOpen: {
        width: NavBarWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerCollapsed: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(10),
    },
    drawerHeader: (props) => ({
        display: "flex",
        alignItems: "center",
        minHeight: TopBarHeight,
        paddingLeft: props.collapsed ? theme.spacing(3) : theme.spacing(4)
    }),
    logo: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: theme.spacing(0.75)
    },
    title: {
        fontWeight: 600
    },
    firstSection: {
        //marginTop: theme.spacing(2),
        padding: 0
    },
    section: {
        //marginTop: theme.spacing(2),
        padding: 0
    },
    navItem: (props) => ({
        marginLeft: props.collapsed ? theme.spacing(1) : theme.spacing(2),
        marginRight: props.collapsed ? theme.spacing(1) : theme.spacing(2),
        width: "initial"
    }),
    sectionHeader: (props) => ({
        height: theme.spacing(6),
        marginTop: theme.spacing(2),
        // paddingLeft: theme.spacing(2),
        // paddingRight: theme.spacing(2),
    }),
    scrollable: {
        overflow: "auto"
    },
    workspaceSectionHeader: (props) => ({
        minHeight: theme.spacing(6),
        marginTop: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginLeft: props.collapsed ? theme.spacing(1) : theme.spacing(2),
        marginRight: props.collapsed ? theme.spacing(1) : theme.spacing(2),
        padding: [theme.spacing(1), theme.spacing(2)]
    }),
    sectionTitle: {
        width: "100%"
    },
    workspaceSkeleton: {
        padding: [theme.spacing(1.5), theme.spacing(2)]
    },
    workspaceItem: {
        paddingLeft: theme.spacing(2.25)
    },
    workspaceAvatar: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
        fontSize: "1rem"
    }
}));

const NavBar = ({className, showSkeletons, skeletonCount}) => {
    const theme = useTheme();
    const collapsed = useMediaQuery(theme.breakpoints.down('lg'));
    //const collapsed = false;
    const classes = useStyles({ collapsed });
    const { t } = useTranslation();
    const workspaces = useSelector(activeWorkspacesSelector()) ?? [];
    const [workspaceDialogOpen, setWorkspaceDialogOpen] = useState(false);

    return (
        <>
            <Drawer
                variant="permanent"
                component="nav"
                className={clsx(classes.drawer, className, {
                    [classes.drawerOpen]: !collapsed,
                    [classes.drawerCollapsed]: collapsed,
                })}
                classes={{
                    paper: clsx(classes.drawerPaper, {
                        [classes.drawerOpen]: !collapsed,
                        [classes.drawerCollapsed]: collapsed,
                    }),
                }}
            >
                <div className={classes.drawerHeader}>
                    <Avatar className={classes.logo} variant="rounded" src="/static/images/logo_transparent.png"/>
                    {!collapsed && (
                        <Typography className={classes.title} variant="h4">
                            whispero
                        </Typography>
                    )}

                </div>
                {/*{ !activeWorkspaceLoaded && (*/}
                {/*    <>*/}
                {/*        <Skeleton height={48} />*/}
                {/*        <Skeleton height={48} />*/}
                {/*        <Skeleton height={48} />*/}
                {/*        <Skeleton height={48} />*/}
                {/*    </>*/}
                {/*)}*/}
                <List className={classes.section}>
                    {items.map((item) => (
                        <NavItem
                            className={classes.navItem}
                            size={collapsed ? "collapsed" : "default"}
                            href={item.href}
                            key={item.title}
                            title={t(item.title)}
                            icon={item.icon}
                        />
                    ))}
                </List>
                <div className={classes.workspaceSectionHeader}>
                    { !collapsed && (
                        <Typography className={classes.sectionTitle} variant="subtitle2" color="textSecondary">
                            {t("workspaces")}
                        </Typography>
                    )}
                    <Tooltip title={t("addWorkspace")}>
                        <IconButton size="small" onClick={() => setWorkspaceDialogOpen(true)} edge="end">
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <List className={clsx(classes.section, classes.scrollable)}>
                    {/*<ListItem className={classes.sectionHeader}>*/}
                    {/*    { !collapsed && (*/}
                    {/*        <Typography className={classes.sectionTitle} variant="subtitle2" color="textSecondary">*/}
                    {/*            {t("workspaces")}*/}
                    {/*        </Typography>*/}
                    {/*    )}*/}
                    {/*    <Tooltip title={t("addWorkspace")}>*/}
                    {/*        <IconButton size="small" onClick={() => setWorkspaceDialogOpen(true)} edge="end">*/}
                    {/*            <AddIcon />*/}
                    {/*        </IconButton>*/}
                    {/*    </Tooltip>*/}
                    {/*</ListItem>*/}
                    {/*TODO: Create as many skeleton as there are workspaces*/}
                    { showSkeletons ? (
                        Array(skeletonCount).fill().map((_, index) => (
                            <div key={index} className={clsx(classes.navItem, classes.workspaceSkeleton)}>
                                <Skeleton variant="rect" height={28}/>
                            </div>
                        ))
                    ) : (
                        workspaces.map((workspace) => (
                            <NavItem
                                key={workspace.pk}
                                className={clsx(classes.workspaceItem, classes.navItem)}
                                size={collapsed ? "collapsed" : "default"}
                                title={workspace.name}
                                href={`/portal/${toId(workspace.pk)}/reports`}
                                icon={
                                    <UserAvatar
                                        className={classes.workspaceAvatar}
                                        name={workspace.name}
                                        imageKey={workspace.picture}
                                        component="span"
                                        size="large"
                                        variant={"rounded"}
                                    />
                                }
                            />
                        ))
                    )}


                </List>
            </Drawer>
            <CreateWorkspaceDialog
                open={workspaceDialogOpen}
                onClose={() => setWorkspaceDialogOpen(false)}
            />
        </>
    );
};

NavBar.propTypes = {
    className: PropTypes.string,
    showSkeletons: PropTypes.bool,
    skeletonCount: PropTypes.number
};

NavBar.defaultProps = {
    skeletonCount: 2
};

export default React.memo(NavBar);
