import {API} from "@aws-amplify/api";
import gql from 'graphql-tag';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import awsconfig from '../aws-exports';
import QueryApi from "./QueryApi";
import MutationApi from "./MutationApi";
import SubscriptionApi from "./SubscriptionApi";
import StorageApi from "./StorageApi";
import {Auth} from "aws-amplify";
import AWS from "aws-sdk";

const privateClient = new AWSAppSyncClient({
    url: awsconfig.aws_appsync_graphqlEndpoint,
    region: awsconfig.aws_appsync_region,
    auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
    },
    offlineConfig: {
        keyPrefix: 'private'
    },
    // if a user signs out and another user signs in and disableOffline is false or not specified then api client stops mutation requests
    // The problem can be solved by using redux to cache changes locally? -> check if subscriptions work
    disableOffline: true
});

export default class AuthorizedAppSyncApi {
    constructor(appSyncClient = null) {
        // TODO: Rename api constructor arguments
        //this.query = new QueryApi(userClient);
        //this.mutation = new MutationApi(userClient);
        //this.subscriptions = new SubscriptionApi(userClient);
        this.storage = new StorageApi();
    }

    get query() {
        Object .defineProperty (
            this,
            "query",
            { value: new QueryApi(privateClient), writable: false}
        )

        return this.query;
    }

    get mutation() {
        Object .defineProperty (
            this,
            "mutation",
            { value: new MutationApi(privateClient), writable: false}
        )
        return this.mutation;
    }

    get subscriptions() {
        Object .defineProperty (
            this,
            "subscriptions",
            { value: new SubscriptionApi(privateClient), writable: false}
        )
        return this.subscriptions;
    }

    // get client() {
    //     console.log("UserApi.client getter");
    //     const value = new AWSAppSyncClient({
    //         url: awsconfig.aws_appsync_graphqlEndpoint,
    //         region: awsconfig.aws_appsync_region,
    //         auth: {
    //             type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    //             jwtToken: async () => {
    //                 try {
    //                     return (await Auth.currentSession()).getIdToken().getJwtToken()
    //                 } catch {
    //                     return null;
    //                 }},
    //         }
    //     });
    //
    //     Object .defineProperty (
    //         this,
    //         "client",
    //         { value: value, writable: false}
    //     )
    //     return this.client;
    // }
}