import React, {useContext, useEffect, useState} from 'react';
import {
    Box,
    makeStyles,
    Avatar,
    Typography, Backdrop, CircularProgress, Tooltip, Toolbar, Link, useTheme
} from '@material-ui/core';
import PropTypes from "prop-types";
import PersonIcon from '@material-ui/icons/Person';
import DueDatePicker from "../Pickers/DueDatePicker";
import {
    cyan,
    green,
    grey,
    indigo,
    lightBlue,
    lime,
    purple,
    red
} from '@material-ui/core/colors';
import clsx from "clsx";
import PickerContainer from "../Pickers/PickerBase";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import {isAudio, isImage} from "../../utils/fileUtils";
import AudioPlayer from "material-ui-audio-player";
import {ApiContext} from "../../contexts/ApiContext";
import {Skeleton} from "@material-ui/lab";
import {getSignedUrl} from "../../utils/apiUtils";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    attachmentDeleteButton: {
        position: "absolute",
        right: 0,
        top: "50%",
        transform: "translate(0, -50%)",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: theme.spacing(0.5),
        zIndex: theme.zIndex.drawer + 2,
        color: theme.palette.grey[200]
    },
    hidden: {
        display: "none"
    }
}));

const AttachmentDeleteButton = ({className, visible, language, onClick, ...rest}) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const activeLanguage = language ?? i18n.language;

    return (
        <div className={clsx(classes.attachmentDeleteButton, className, {[classes.hidden]: !visible})}>
            <Tooltip title={t("delete", {lng: activeLanguage})}>
                <IconButton aria-label="delete" size="small" onClick={onClick} {...rest}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </div>
    );
};

AttachmentDeleteButton.propTypes = {
    className: PropTypes.string,
    visible: PropTypes.bool,
    language: PropTypes.string,
    onClick: PropTypes.func
}

AttachmentDeleteButton.defaultProps = {
    onClick: () => {},
    visible: true
};

export default AttachmentDeleteButton;