import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Toolbar,
    makeStyles,
    lighten,
    Select,
    MenuItem,
    Button, FormControl, AppBar
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {toWorkspaceKey} from "../../utils/apiUtils";
import {useSelector} from "react-redux";
import {currentUserRoleSelector} from "../../state/members/selectors";
import {isApprover} from "../../utils/user";

const useStyles = makeStyles((theme) => ({
    reportListToolbar: {
        flex: "0 0 auto",
        minHeight: 0,
        padding: [theme.spacing(3.75), theme.spacing(4)]
    },
    stateSelect: {
        flex: "1 1 auto",
        // marginRight: theme.spacing(6),
        backgroundColor: theme.palette.grey[50],
        width: "100%"
    },
    select : {
        //padding: [theme.spacing(1.5), theme.spacing(1)],
        '&:focus': {
            backgroundColor: "inherit",
        },
        // TODO: Replace padding with sane values
        paddingTop: "14.5px",
        paddingBottom: "14.5px"
    },
    selectIcon: {
        color: theme.palette.text.secondary,
        width: theme.spacing(2.5),
        height: theme.spacing(2.5)
    },
    selectText : {
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(1)
    },
    right: {
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        textTransform: "none",
        ...theme.typography.body2
    },
}));

const ListToolbar = ({className, state, currentUserRole, onStatusSelected}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const statusLabels = isApprover(currentUserRole) ? {
        all: t("allReports"),
        pending: t("approvalPending"),
        approved: t("approvalAccepted"),
        rejected: t("approvalRejected"),
    } : {
        all: t("allReports"),
        open: t("openReports"),
        solved: t("solvedReports"),
        junk: t("junkReports"),
    }

    return (
        <Toolbar className={clsx(classes.reportListToolbar, className)}>
            <FormControl className={classes.stateSelect} variant="outlined" margin="none">
                <Select
                    value={statusLabels[state]}
                    style={{borderRadius: 6}}
                    classes={{select: classes.select}}
                    // renderValue={(value) => (
                    //     <Box display="flex" alignItems="center">
                    //         {/*<CheckCircleOutlineIcon className={classes.selectIcon}/>*/}
                    //         <Typography className={classes.selectText} variant="body2" noWrap>
                    //             {value}
                    //         </Typography>
                    //     </Box>
                    // )}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                >
                    {Object.entries(statusLabels).map(([key, value]) => (
                        <MenuItem key={key} value={value} onClick={() => onStatusSelected(key)}>{value}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Box id="filter-button-placeholder" flex="1 1 auto"/>
            {/*<Button className={classes.right} size="small" startIcon={<FilterListIcon />}>*/}
            {/*    Filter*/}
            {/*</Button>*/}
        </Toolbar>
    )
};

ListToolbar.propTypes = {
    className: PropTypes.string,
    currentUserRole: PropTypes.string.isRequired,
    state: PropTypes.oneOf(['all', 'open', 'solved', 'junk']),
}

ListToolbar.defaultProps = {
    state: 'all'
};

export default ListToolbar;