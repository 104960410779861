import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Typography, colors, makeStyles, LinearProgress, Tooltip } from "@material-ui/core";
import { millisecondsToDays } from "../../utils/time";
import DueDatePicker from "../Pickers/DueDatePicker";
import { useTranslation } from "react-i18next";
import { elapsedDays, isOpen, isOverdue, remainingDays, termDays } from "../../utils/reportUtils";
import TermProgress from "./TermProgress";
import StatusIndicator from "./StatusIndicator";
import {isFormKey} from "../../utils/apiUtils";

const ReportStatus = ({ className, classes: propClasses, report}) => {
    return isOpen(report) ? (
        <TermProgress className={className} classes={propClasses} report={report} />
    ) : (
        <StatusIndicator className={className} classes={propClasses} report={report} />
    );
};

ReportStatus.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.exact({
        label: PropTypes.string,
        progressBar: PropTypes.string,
    }),
    report: PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
        state: PropTypes.string,
        dueDate: PropTypes.string,
    }).isRequired,
};

ReportStatus.defaultProps = {
    classes: {}
};

export default ReportStatus;
