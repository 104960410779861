import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { CssBaseline, Slide, Snackbar, ThemeProvider } from '@material-ui/core';
import theme from './theme';
import './custom.css';
import GlobalStyles from "./GlobalStyles";
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import jssExpand from 'jss-plugin-expand';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { Provider } from "react-redux";
import configureStore from "./store";
import AppRoutes from "./routes/Routes";
import './i18n';
import { Alert } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {
    newNotificationService,
    NotificationContext
} from "./contexts/NotificationContext";

Amplify.configure(awsExports);

// This fixes not working composite padding and margin in jss
const jss = create({
    plugins: (() =>
    {
        const items = [...jssPreset().plugins];
        items.splice(5, 0, jssExpand())
        return items;
    })(),
});

function SlideTransition(props) {
    return <Slide {...props} direction="right" />;
}

const App = (props) => {
    const { t } = useTranslation();
    const waitingWorkerRef = useRef();
    const [showUpdateNotification, setShowUpdateNotification] = useState(false);
    const store = useMemo(() => configureStore({}), []);
    const notificationService = useMemo(() => newNotificationService(), []);

    useLayoutEffect(() => {
        serviceWorkerRegistration.register({ onUpdate: onServiceWorkerUpdate });
    }, [])

    const onServiceWorkerUpdate = (registration, firstRenderUpdate) => {
        if (registration) {
            waitingWorkerRef.current = registration.waiting;
            // Reload page immediately if the update was found on first page render
            if (firstRenderUpdate) {
                updateServiceWorker();
            } else {
                setShowUpdateNotification(true);
            }
        }
    };

    const updateServiceWorker = () => {
        waitingWorkerRef.current.postMessage({ type: "SKIP_WAITING" });
        setShowUpdateNotification(false);
        window.location.reload();
    }

    const onUpdateNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowUpdateNotification(false);
    };

    return (
        <StylesProvider jss={jss}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles/>
                <Provider store={store}>
                    <Snackbar
                        open={showUpdateNotification}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        onClose={onUpdateNotificationClose}
                        TransitionComponent={SlideTransition}
                    >
                        <Alert
                            onClose={onUpdateNotificationClose}
                            elevation={6}
                            variant="filled"
                            severity="success"
                            action={(
                                <Button size="small" style={{color: "#f50057"}} onClick={updateServiceWorker}>
                                    {t("refresh")}
                                </Button>
                            )}
                        >
                            {t("updateFound")}
                        </Alert>
                    </Snackbar>
                    <NotificationContext.Provider value={notificationService}>
                        <AppRoutes/>
                    </NotificationContext.Provider>
                </Provider>
            </ThemeProvider>
        </StylesProvider>
    )
}

// export default class App extends React.Component {
//   static displayName = App.name;
//
//   render () {
//     return (
//       <Router>
//           <Routes>
//               <Route exact path='/' element={Home} />
//               <Route path='/counter' element={Counter} />
//               <Route path='/fetch-data' element={FetchData} />
//           </Routes>
//       </Router>
//     );
//   }
// }

const signUpConfig = {
    header: 'My Customized Sign Up',
    hideAllDefaults: true,
    defaultCountryCode: '1',
    formFields: [
        {
            label: 'Name',
            key: 'name',
            required: true,
            displayOrder: 1,
            type: 'string'
        },
        {
            label: 'Email',
            key: 'email',
            required: true,
            displayOrder: 2,
            type: 'string'
        },
        {
            label: 'Password',
            key: 'password',
            required: true,
            displayOrder: 3,
            type: 'password'
        }
    ]
};

// export default withAuthenticator(App, {
//     usernameAlias: 'email',
//     signUpConfig
// });
export default App;