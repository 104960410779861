import * as types from "./actionTypes";

const initialState = {};

export default function accessCredentialsReducer(state = initialState, action) {
    switch (action.type) {
        case types.ACCESS_CREDENTIALS_UPDATED: {
            let newAccessCodes = state[action.payload.workspaceKey];
            if (!Boolean(newAccessCodes)) {
                newAccessCodes = {};
            }
            newAccessCodes[action.payload.resourceKey] = action.payload.accessCode;

            const newState = {...state};
            newState[action.payload.workspaceKey] = newAccessCodes;
            return newState;
        }
        case types.ACCESS_CODE_DELETED: {
            const newState = {...state};
            newState[action.payload.workspaceKey][action.payload.resourceKey] = undefined;
            return newState;
        }
        default:
            return state
    }
}
