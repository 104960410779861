export const STATIC_DOCUMENTS = [
  {
    name: "Whispero - Manuál.pdf",
    url:
        "https://files.whispero.cz/manual.pdf",
    size: "1.6 MB",
  },
  {
    name: "Návod k implementaci.pdf",
    url:
        "https://files.whispero.cz/implementace.pdf",
    size: "137.7 KB",
  },
  {
    name: "Vzor - interní směrnice.docx",
    url:
      "https://files.whispero.cz/smernice.docx",
    size: "12.9 KB",
  },
  {
    name: "Vzor - Záznam o určení a poučení příslušné osoby.docx",
    url:
      "https://files.whispero.cz/zaznam.docx",
    size: "10.0 KB",
  },
  {
    name: "Zákon o ochraně oznamovatelů.pdf",
    url:
      "https://files.whispero.cz/zakon-o-ochrane-oznamovatelu.pdf",
    size: "144.6 KB",
  },
  {
    name: "Směrnice (EU) 1937/2019 o ochraně osob porušíjích právo Unie.pdf",
    url:
      "https://files.whispero.cz/smernice-1937-2019.pdf",
    size: "144.6 KB",
  },
];
