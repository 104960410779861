import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import {
    makeStyles,
    Divider,
    Hidden,
    Typography,
    TextField,
    Button,
    Paper,
    CardContent,
    CardHeader, Card
} from "@material-ui/core";
import FormPage from "../../components/FormPage/FormPage";
import {
    deminifyLanguagePacks,
    getDefaultFormLanguage,
    getFormActionsVariant,
    getFormBlockId,
} from "../../utils/formUtils";
import FormPageActions from "../../components/FormPageActions";
import PropTypes from "prop-types";
import FormPresentationToolbar from "../../components/FormPresentationToolbar/FormPresentationToolbar";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toFormAccessId, toFormKey, toId, toUserKey } from "../../utils/apiUtils";
import { formatFormId, formatWorkspaceId, normalizeId } from "../../utils/textFormatter";
import { WorkspaceKeyContext } from "../../contexts/WorkspaceKeyContext";
import { useSelector } from "react-redux";
import { workspaceSelector } from "../../state/workspaces/selectors";
import cloneDeep from "lodash/cloneDeep";
import { useFetchForm } from "../../hooks/useFetchForm";
import Loader from "../../components/Loader";
import { useFetchWorkspaces } from "../../hooks/useFetchWorkspaces";
import { scrollToElement, scrollToView } from "../../utils/ui";
import { convertToMessageSection, isDateBlock, isReportEmailBlock, isReportIdBlock } from "../../utils/formBlockUtils";
import { ApiContext } from "../../contexts/ApiContext";
import { isValidDate, isValidEmail } from "../../utils/textUtils";
import { DEFAULT_FORM_TOP_PADDING_SPACING } from "../../constants";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page/Page";
import { updateElementByIndex } from "../../utils/arrayUtils";
import FormBlockContainer from "../../components/FormBlockContainer/FormBlockContainer";
import FormBlock from "../../components/formBlocks";

const useStyles = makeStyles((theme) => ({
    presentationView: {
        minHeight: "100%",
        //overflow: "auto",
        backgroundColor: theme.palette.grey[50],
        position: "relative",
    },
    pagesContainer: {
        paddingTop: theme.spacing(DEFAULT_FORM_TOP_PADDING_SPACING), // to accommodate appbar
    },
    page: {
        transition: theme.transitions.create(["height"], {
            duration: theme.transitions.duration.standard,
        }),
    },
    pagePaper: {
        //padding: [theme.spacing(2), 0],
        paddingTop: theme.spacing(5),
    },
    accessCodeFieldContainer: {
        position: "relative",
        boxSizing: "content-box",
        minHeight: theme.spacing(7)
    },
    accessCodeField: {
        position: "absolute"
    }
}));

const getConfirmationPage = (template) => template.pages[template.pages.length - 1];

const FormPresentationView = ({}) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const api = useContext(ApiContext);
    const { formId } = useParams();
    const formKey = toFormKey(formId);
    const form = useFetchForm(formKey);
    useFetchWorkspaces(form ? [form.sk] : null);
    const workspace = useSelector(workspaceSelector(form?.sk));
    const [language, setLanguage] = useState();
    const [languages, setLanguages] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [isBusy, setIsBusy] = useState();
    const [pageErrors, setPageErrors] = useState([]);
    const [invalidLogin, setInvalidLogin] = useState(false);
    const [showLoginPage, setShowLoginPage] = useState();
    const templateRef = useRef();
    const languagePacksRef = useRef();
    const blocksRef = useRef({});
    const rootRef = useRef();
    const [accessCode, setAccessCode] = useState("");

    useEffect(() => {
        if (!Boolean(form) || Boolean(templateRef.current)) {
            return;
        }

        templateRef.current = cloneDeep(form.template);
        languagePacksRef.current = cloneDeep(form.languagePacks);

        setLanguage(getDefaultFormLanguage(form.languagePacks, i18n));
        setLanguages(Object.keys(form.languagePacks));
        setShowLoginPage(form.passwordProtected);
    }, [form]);

    useEffect(() => {
        rootRef.current?.scrollTo({ top: 0 });
    }, [pageIndex]);

    const resetBlockRefs = () => {
        blocksRef.current = {};
    };

    const handleBlockValueChange = (pageIndex, blockIndex, value) => {
        templateRef.current.pages[pageIndex].blocks[blockIndex].value = value;
        if (Boolean(pageErrors[blockIndex])) {
            setPageErrors((prev) => updateElementByIndex(prev, blockIndex, null));
        }
    };

    const handleSecondaryButtonClick = () => {
        resetBlockRefs();
        setPageErrors([]);
        setPageIndex((prev) => prev - 1);
    };

    // TODO: Move error checking to utils class
    const handlePrimaryButtonClick = () => {
        // Check which required blocks do not have a value
        const currentPage = templateRef.current.pages[pageIndex];
        const errors = [];
        for (let i = 0; i < currentPage.blocks.length; i++) {
            const block = currentPage.blocks[i];
            if (block.required && (!Boolean(block.value) || (Array.isArray(block.value) && block.value.length === 0))) {
                errors[i] = t("requiredFieldError", { lng: language });
            } else if (isReportEmailBlock(block) && Boolean(block.value) && !isValidEmail(block.value)) {
                errors[i] = t("invalidEmailError", { lng: language });
            } else if (isDateBlock(block) && Boolean(block.value) && !isValidDate(block.value)) {
                errors[i] = t("invalidDateError", { lng: language });
            } else {
                errors[i] = null;
            }
        }
        setPageErrors(errors);

        const firstErrorBlockIndex = errors.findIndex((error) => Boolean(error));
        if (firstErrorBlockIndex !== -1) {
            // scroll to the first block that has error
            const blockElement = blocksRef.current[firstErrorBlockIndex];
            scrollToElement(blockElement);
            //blockElement.focus();
            return;
        }

        resetBlockRefs();

        if (pageIndex === templateRef.current.pages.length - 2) {
            // A second to last page is a submit page, the last page is a confirmation page
            createReport();
        } else if (pageIndex === templateRef.current.pages.length - 1) {
            confirmReportSubmission();
        } else {
            setPageIndex((prev) => prev + 1);
        }
    };

    const createReport = async () => {
        setIsBusy(true);

        let content = [];
        const languagePack = languagePacksRef.current[language];
        for (const page of templateRef.current.pages) {
            const sections = page.blocks.map((block) => convertToMessageSection(block, languagePack, t, language));
            const nonNullSections = sections.filter((section) => Boolean(section));
            content = content.concat(nonNullSections);
        }
        const createdReport = await api.mutation.createReport(
            workspace.pk,
            content,
            language,
            form.assignTo,
            [form.pk],
            form.termLength
        );

        // Set access id as the value of the reportID block
        const confirmationPage = getConfirmationPage(templateRef.current);
        const reportIdBlockIndex = confirmationPage.blocks.findIndex(isReportIdBlock);
        confirmationPage.blocks[reportIdBlockIndex].value = createdReport.accessId;

        setIsBusy(false);
        setPageIndex((prev) => prev + 1);
    };

    const confirmReportSubmission = async () => {
        const confirmationPage = getConfirmationPage(templateRef.current);
        const emailBlock = confirmationPage.blocks[confirmationPage.blocks.findIndex(isReportEmailBlock)];
        if (Boolean(emailBlock.value)) {
            // Update report email
            const reportIdBlockIndex = confirmationPage.blocks.findIndex(isReportIdBlock);
            const accessId = confirmationPage.blocks[reportIdBlockIndex].value;
            // TODO: Move email to new table
            await api.mutation.updateResourceAccessKey(accessId, { email: emailBlock.value });
        }

        // Redirect to login dialog after clicking on continue in confirmation dialog
        navigate("/followup");
    };

    const handleLoginButtonClick = async () => {
        setIsBusy(true);
        const credentials = await api.query.getResourceAccessCredentials(formKey);
        // trim() is called because the acceess code is " " instead of an empty string
        if ((credentials.accessCode === " " && !Boolean(accessCode)) || credentials.accessCode === accessCode) {
            setShowLoginPage(false);
        } else {
            setInvalidLogin(true);
        }
        setIsBusy(false);
    };

    const handleAccessCodeChange = (event) => {
        setAccessCode(event.target.value);
        if (invalidLogin) {
            setInvalidLogin(false);
        }
    };

    if (!Boolean(languagePacksRef.current) || !Boolean(workspace)) {
        return <Loader />;
    }

    if (form.disabled || workspace.disabled) {
        return <Navigate replace to='/404'/>
    }

    // TODO: Refactor Page actions
    const loginPage = () => (
        <>
            <FormBlockContainer>
                <Typography variant="h4" align="center">
                    {t("formPasswordDialogHeader", {lng: language})}
                </Typography>
            </FormBlockContainer>
            <FormBlockContainer className={classes.accessCodeFieldContainer}>
                <TextField
                    className={classes.accessCodeField}
                    required
                    fullWidth
                    label={t("password", {lng: language})}
                    placeholder={t("enterPassword", {lng: language})}
                    variant="outlined"
                    error={invalidLogin}
                    helperText={invalidLogin ? t("incorrectPasswordError", {lng: language}) : undefined}
                    onChange={handleAccessCodeChange}
                />
            </FormBlockContainer>
            <FormPageActions
                template={{ primary: "" }}
                languagePack={{}}
                language={language}
                isBusy={isBusy}
                pageIndex={pageIndex}
                pagesCount={templateRef.current.pages.length}
                onPrimaryButtonClick={handleLoginButtonClick}
            />
        </>
    );

    const formPages = () => (
        <>
            {templateRef.current.pages[pageIndex].blocks.map((block, blockIndex) => (
                <FormBlockContainer key={getFormBlockId(block)} ref={(el) => (blocksRef.current[blockIndex] = el)}>
                    <FormBlock
                        blockTemplate={block}
                        languagePack={languagePacksRef.current[language]}
                        language={language}
                        errorText={pageErrors[blockIndex]}
                        onValueChange={(value) => handleBlockValueChange(pageIndex, blockIndex, value)}
                    />
                </FormBlockContainer>
            ))}
            <FormPageActions
                template={templateRef.current.pages[pageIndex].actions}
                languagePack={languagePacksRef.current[language]}
                language={language}
                isBusy={isBusy}
                pageIndex={pageIndex}
                pagesCount={templateRef.current.pages.length}
                onPrimaryButtonClick={handlePrimaryButtonClick}
                onSecondaryButtonClick={handleSecondaryButtonClick}
            />
        </>
    );

    return (
        <Page ref={rootRef} title={`${form.name} - Whispero`} className={classes.presentationView}>
            <FormPresentationToolbar
                selectedLanguage={language}
                languages={languages}
                workspace={workspace}
                onLanguageChange={setLanguage}
            />
            <div className={classes.pagesContainer}>
                <FormPage
                    className={classes.page}
                    classes={{ paper: classes.pagePaper }}
                    workspace={workspace}
                    maxWidth={showLoginPage ? "sm" : "sm"}
                >
                    {showLoginPage ? loginPage() : formPages()}
                </FormPage>
            </div>
        </Page>
    );
};

FormPresentationView.propTypes = {};

FormPresentationView.defaultProps = {};

export default FormPresentationView;
