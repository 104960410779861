import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { isAudio, isImage } from "../../utils/fileUtils";
import { Skeleton } from "@material-ui/lab";
import AttachmentPreview from "./AttachmentPreview";
import { downloadFile } from "../../utils/storageUtils";
import { ThumbnailMaxWidth, ThumbnailHeight } from "../../constants";
import clsx from "clsx";
import {ApiContext} from "../../contexts/ApiContext";
import {ThumbnailPictureSuffix} from "../../constants/storage";

const borderRadius = 8;
const useStyles = makeStyles((theme) => ({
    imageAttachmentSkeleton: {
        width: (props) => (props.size === "small" ? theme.spacing(20) : ThumbnailMaxWidth),
        height: (props) => (props.size === "small" ? theme.spacing(10) : ThumbnailHeight),
        borderRadius,
        backgroundColor: theme.palette.common.white,
    },
    attachmentSkeleton: {
        width: theme.spacing(40),
        height: theme.spacing(8),
        borderRadius,
        backgroundColor: theme.palette.common.white,
    },
}));

const getName = (s3Key) => s3Key.split("/")?.pop();

const S3Attachment = ({ className, classes: propClasses, s3Key, size, canDelete, language, onRendered, onDelete, ...rest }) => {
    const classes = useStyles({ size });
    const api = useContext(ApiContext);
    const [url, setUrl] = useState();
    const blobUrlRef = useRef();
    const isImageKey = isImage(s3Key);
    const isAudioKey = isAudio(s3Key);
    const name = isImageKey ? getName(s3Key).replace(ThumbnailPictureSuffix, "") : getName(s3Key);
    const isMounted = useRef(true);
    const isV0 = !s3Key.startsWith("v1");

    useEffect(() => {
        if (url && isMounted.current) {
            onRendered();
        }
    }, [url]);

    useEffect(() => {
        if (!s3Key) {
            // S3Attachment with already loaded images might have their key set to null if a user for example would
            // want to reset the attachment preview. The blobUrl must be then revoked and the url state set to null
            // to avoid rendering the old image in the preview.
            if (blobUrlRef.current) {
                URL.revokeObjectURL(blobUrlRef.current);
                setUrl(null);
            }
            return;
        }

        (async () => {
            if (!isV0 && (isImageKey || isAudioKey)) {
                const downloaded = await api.storage.downloadObject(s3Key);
                // Top avoid react errors we update state only if the component is still mounted. It can happen that
                // the component is unmounted before the data are downloaded. Updating state of unmounted component leads
                // to react errors.
                if (isMounted.current) {
                    const blobUrl = URL.createObjectURL(downloaded.Body);
                    blobUrlRef.current = blobUrl;
                    setUrl(blobUrl);
                }
            } else {
                // Show only simple public url for file attachment, signed url will be generated
                // when user will click on the link
                const attachmentUrl = api.storage.getPublicUrl(s3Key);
                setUrl(attachmentUrl);
            }
        })();

        // Return cleanup function
        return () => {
            isMounted.current = false;
            if (blobUrlRef.current) {
                URL.revokeObjectURL(blobUrlRef.current);
            }
        };
    }, [s3Key]);

    const handleDownload = async (event) => {
        event.preventDefault();
        if (isV0) {
            downloadFile(url, name, true);
            return;
        }

        const attachmentKey = isImageKey ? s3Key.replace(ThumbnailPictureSuffix, "") : s3Key;
        const attachmentUrl = await api.storage.getSignedUrl(attachmentKey);
        downloadFile(attachmentUrl, name, true);
    };

    const handleDelete = () => {
        api.storage.deleteAttachment(s3Key);
        onDelete();
    };

    if (url) {
        return (
            <AttachmentPreview
                classes={{image: propClasses.imageAttachment}}
                url={url}
                name={name}
                size={size}
                language={language}
                canDelete={canDelete}
                onDownload={handleDownload}
                onDelete={handleDelete}
                {...rest}
            />
        );
    }

    if (isImageKey) {
        return <Skeleton variant="rect" className={clsx(classes.imageAttachmentSkeleton, className, propClasses.imageAttachment)} />;
    } else {
        return <Skeleton variant="rect" className={clsx(classes.attachmentSkeleton, className)} />;
    }
};

S3Attachment.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    s3Key: PropTypes.string.isRequired,
    canDelete: PropTypes.bool,
    language: PropTypes.string,
    size: PropTypes.oneOf(["small", "normal"]),
    onRendered: PropTypes.func,
    onDelete: PropTypes.func,
};

S3Attachment.defaultProps = {
    classes: {},
    size: "normal",
    onRendered: () => {},
    onDelete: () => {},
};

export default S3Attachment;
