import React, { useCallback } from "react";
import FormBlock from "../../components/formBlocks";
import EditableFormBlockContainer from "./EditableFormBlockContainer";

const MemoizedFormBlock = React.memo(FormBlock);

export const MemoizedEditableFormBlock = ({
    pageIndex,
    blockIndex,
    blockTemplate,
    languagePack,
    language,
    onTextChange,
    onTemplateChange,
    onTextDelete,
}) => {
    const handleTemplateChange = useCallback(
        (change) => {
            onTemplateChange(pageIndex, blockIndex, change);
        },
        [pageIndex, blockIndex]
    );

    return (
        <MemoizedFormBlock
            editable
            blockTemplate={blockTemplate}
            languagePack={languagePack}
            language={language}
            onTextChange={onTextChange}
            onTemplateChange={handleTemplateChange}
            onTextDelete={onTextDelete}
        />
    );
};

export default MemoizedEditableFormBlock;
