import React, {useContext, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles,
    CssBaseline,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    TablePagination,
    List,
    ListItem,
    ListItemAvatar,
    useTheme,
    ListItemText,
    Divider,
    ListItemSecondaryAction,
    ListItemIcon,
    ButtonBase,
    Dialog,
    useMediaQuery, DialogTitle, DialogContent, DialogActions, Tabs, Tab, Button, Tooltip, DialogContentText, alpha
} from '@material-ui/core';
import {useTranslation} from "react-i18next";

// TODO: Extract common dialog styles to dialog component
const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingBottom: 0
    },
    dialogActions: {
        padding: theme.spacing(3)
    },
    deleteButton: {
        backgroundColor: alpha(theme.palette.error.main, 0.85),
        color: theme.palette.error.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.error.main
        },
    }
}));

// TODO: Add Exit icon to the top of the dialog
const DeleteMemberConfirmationDialog = ({className, open, onConfirm, onClose, userName, workspaceName}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const firstName = userName.split(' ')[0];

    return (
        <Dialog
            id="delete-member-confirmation-dialog"
            className={className}
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
            aria-labelledby="delete-member-confirmation-dialog-title"
        >
            <DialogTitle id="delete-member-confirmation-dialog-title" className={classes.dialogTitle}>
                {t("Remove {user} from {workspace}", {user: firstName, workspace: workspaceName})}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <DialogContentText variant="body2" color="textSecondary">
                    {t("{user} will no longer be able to access {workspace}, including reports that were assigned to them", {user: firstName, workspace: workspaceName})}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button autoFocus color="primary" variant="outlined" onClick={onClose}>
                    {t("Cancel")}
                </Button>
                <Button className={classes.deleteButton} autoFocus variant="contained" onClick={onConfirm}>
                    {t("delete")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

DeleteMemberConfirmationDialog.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func
}

DeleteMemberConfirmationDialog.defaultProps = {
    onConfirm: () => {},
    onClose: () => {}
};

export default DeleteMemberConfirmationDialog;