import React, {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    makeStyles,
    DialogContent,
    DialogActions,
    TextField
} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import {ApiContext} from "../../contexts/ApiContext";
import AvatarPicker from "../AvatarPicker";
import {ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {currentUserSelector} from "../../state/currentUser/selectors";
import {loadOrUpdateCurrentUser} from "../../state/currentUser/actions";
import {allWorkspacesSelector} from "../../state/workspaces/selectors";
import {getSignedUrl, toId} from "../../utils/apiUtils";
import {Autocomplete} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import {LANGUAGES, PORTAL_LANGUAGES} from "../../constants/languages";
import {updateMember} from "../../state/members/actions";
import {useFetchAvatarPicture} from "../../hooks/useFetchAvatarPicture";
import {I18n} from "aws-amplify";
import Loader from "../Loader";
import {SpinnerButton} from "../ConfirmationButton/SpinnerButton";
import {LargePictureSuffix, SmallPictureSuffix} from "../../constants/storage";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        paddingBottom: 0
    },
    avatarContainer: {
        padding: theme.spacing(0.5),
        border: `1px dashed ${theme.palette.grey[400]}`,
        borderRadius: "50%",
        display: "inline-block",
        margin: "auto",
        position: "relative",
        zIndex: 0
    },
    backdrop: {
        position: "absolute",
        zIndex: theme.zIndex.drawer + 1,
        borderRadius: "50%",
        margin: theme.spacing(0.5),
    },
    fields: {
        marginLeft: theme.spacing(5),
        "&>div:not(:last-child)": {
            marginBottom: theme.spacing(3)
        }
    },
    backdropButton: {
        color: theme.palette.grey[300],
        height: "100%",
        width: "100%"
    },
    avatarActions: {
        marginTop: theme.spacing(1),
    },
    errorLabel: {
        textAlign: "center"
    },
    progress: {
        flexGrow: "0.6",
        height: theme.spacing(0.75),
        borderRadius: 6,
        backgroundColor: theme.palette.grey[300]
    },
    barColor : {
        backgroundColor: theme.palette.grey[500]
    },
    dialogActions: {
        padding: theme.spacing(3)
    }
}));

// TODO: Use avatarUrl on team object, don't download avatar each time panel is opened, beware that signed url is valid only for 15 minutes
// TODO: ObjectURL not released when dialog is closed
const GeneralProfileSettings = ({className, onClose}) => {
    const classes = useStyles();
    const api = useContext(ApiContext);
    const dispatch = useDispatch();
    const { store } = useContext(ReactReduxContext)
    const workspaces = useSelector(allWorkspacesSelector());
    const user = useSelector(currentUserSelector());
    const { t, i18n } = useTranslation();
    const [pictureUrl, setPictureUrl] = useFetchAvatarPicture(user.picture?.replace(SmallPictureSuffix, LargePictureSuffix));
    const [changed, setChanged] = React.useState(false);
    const [name, setName] = React.useState(user.name);
    const [phone, setPhone] = React.useState(user.phone);
    const locale = user.locale ?? i18n.language;    // TODO: Extract to hook
    const [language, setLanguage] = React.useState(PORTAL_LANGUAGES.find(lang => locale.startsWith(lang.code)));    // i18n.language can be "en-US" while PORTAL_LANGUAGES contains only "en" code
    const [nameError, setNameError] = useState();
    const [selectedPictureData, setSelectedPictureData] = useState();
    const [updating, setUpdating] = React.useState();
    const [uploading, setUploading] = React.useState();
    const [uploadProgress, setUploadProgress] = React.useState(0);

    const handleAvatarSelected = (file) => {
        if (selectedPictureData) {
            // Release previously created object URLge if any
            URL.revokeObjectURL(pictureUrl);
        }
        if (file) {
            setPictureUrl(URL.createObjectURL(file));
            setSelectedPictureData(file);
        } else {
            setPictureUrl(null);
            setSelectedPictureData(null);
        }

        setChanged(true);
    }

    const handleNameChange = (event) => {
        const value = event.target.value;
        if (value.length === 0) {
            setNameError(true);
        } else if (nameError) {
            setNameError(false); // reset error if value is not empty
        }
        setName(event.target.value);
        setChanged(true);
    }

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
        setChanged(true);
    }

    const handleLanguageChange = (value) => {
        setLanguage(value);
        setChanged(true);
    }

    // TODO: Same as in Portal's General settings panel, move to a single method
    const handleSaveButtonClick = async () => {
        const data = {};
        if (name !== user.name) {
            data.name = name;
        }
        if (phone !== user.phone) {
            data.phone = phone;
        }
        if (language.code !== user.locale) {
            data.locale = language.code;
            // Change app localization
            await i18n.changeLanguage(language.code);
            I18n.setLanguage(language.code);
        }

        // Remove user's original avatar if user clicked on Remove button
        if (!pictureUrl && user.picture) {
            await api.storage.deleteAvatarPictures(user.picture);
            data.picture = null;
        }

        if (selectedPictureData) {
            setUploading(true);
            // Remove user's original avatar if another image was selected
            if (user.picture) {
                await api.storage.deleteAvatarPictures(user.picture);
            }
            const key = await api.storage.uploadProfilePicture(selectedPictureData, toId(user.pk), setUploadProgress);
            data.picture = key;
            setUploading(false);
        }

        // Data can have zero keys, when for example name is changed and then changed
        // back to the original value. The value won't be added to the data object but
        // changed state will be true, which caused the save button to be enabled
        if (Object.keys(data) === 0) {
            onClose();
            return;
        }

        setUpdating(true);
        // Update data about the current user in all workspaces in redux
        dispatch(loadOrUpdateCurrentUser(data));
        const members = store.getState().members;
        workspaces.forEach(workspace => {
            const member = members[workspace.pk]?.find(m => m.pk === user.pk);
            // Some workspaces and their members may not be loaded yet. When such workspaces
            // are loaded they will already contain the updated information about current user.
            if (!member) {
                return;
            }

            dispatch(updateMember(workspace.pk, member.pk, data))
        });

        await api.mutation.updateUser(user.pk, workspaces.map(w => w.pk), data);

        onClose();
    };

    return (
        <>
            <DialogContent id="general-settings-content" className={clsx(classes.root, className)}>
                <AvatarPicker
                    name={name}
                    imageUrl={pictureUrl}
                    isUploading={uploading}
                    uploadProgress={uploadProgress}
                    onAvatarSelected={handleAvatarSelected}
                    onAvatarCleared={() => handleAvatarSelected(null)}
                />
                <div className={classes.fields}>
                    <TextField
                        required
                        fullWidth
                        id="user-name"
                        label={t("common.name")}
                        variant="outlined"
                        value={name}
                        error={nameError}
                        helperText={nameError ? t("{name} is required", {name: t("common.name")}) : null}
                        onChange={handleNameChange}
                    />
                    <TextField disabled fullWidth id="user-email" label={t("common.email")} variant="outlined" value={user.email ?? ""}/>
                    <TextField fullWidth id="user-phone" label={t("common.phoneNumber")} variant="outlined" value={phone ?? ""} onChange={handlePhoneChange}/>
                    <Autocomplete
                        value={language || null}
                        inputValue={language ? t(language.code) : ""}
                        options={PORTAL_LANGUAGES}
                        autoHighlight
                        disableClearable
                        onChange={(event, newValue) => {
                            handleLanguageChange(newValue);
                        }}
                        getOptionLabel={(option) => t(option.code)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("Language")}
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps
                                }}
                            />
                        )}
                    />
                </div>
            </DialogContent>
            <DialogActions id="general-settings-actions" className={classes.dialogActions}>
                <Button autoFocus color="primary" variant="outlined" disabled={updating || uploading} onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <SpinnerButton disabled={!changed || nameError} showSpinner={updating || uploading} onClick={handleSaveButtonClick}>
                    {t('Save changes')}
                </SpinnerButton>
            </DialogActions>
        </>
    )
}

GeneralProfileSettings.propTypes = {
    className: PropTypes.string,
}

GeneralProfileSettings.defaultProps = {
    onClose: () => {}
};

export default GeneralProfileSettings;