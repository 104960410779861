import React, {useContext, useEffect, useState} from 'react';
import {Box, CircularProgress, Container, makeStyles, Step, StepLabel, Stepper, Typography} from "@material-ui/core"
import PropTypes from "prop-types";
import GeneralProfileSettings from "../ProfileButton/GeneralProfileSettings";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import WorkspaceDetailsPage from "./WorkspaceDetailsPage";
import {ApiContext} from "../../contexts/ApiContext";
import WorkspaceInvitationPage from "./WorkspaceInvitationPage";
import {useSelector} from "react-redux";
import {currentUserSelector} from "../../state/currentUser/selectors";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../contexts/AuthContext";
import {isValidEmail} from "../../utils/textUtils";
import {SpinnerButton} from "../ConfirmationButton/SpinnerButton";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper
        //display: "flex",
        //flexDirection: "column"
        //padding: [theme.spacing(5), theme.spacing(12)],
        //minWidth: theme.spacing(75)
    },
    stepper: {
        //flex: "0 0 auto"
        //padding: [theme.spacing(2), theme.spacing(3)]
    },
    content: {
        marginTop: theme.spacing(2),
        //flex: "1 1 auto"
        //padding: [theme.spacing(1), theme.spacing(3)],
    },
    actions: {
        //flex: "0 0 auto",
        display: "flex",
        justifyContent: "space-between",
        marginTop: theme.spacing(3),
    }
}));

function getSteps(t) {
    return [t("Workspace details"), t("Invite teammates")];
}

const WorkspaceWizard = ({className, onCompleted}) => {
    const classes = useStyles();
    const api = useContext(ApiContext);
    const { refreshToken } = useContext(AuthContext);
    const { t } = useTranslation();
    const steps = getSteps(t);
    const currentUser = useSelector(currentUserSelector());
    const [activeStep, setActiveStep] = React.useState(0);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [invitations, setInvitations] = useState([]);
    const [idle, setIdle] = useState(false);

    const handleNameChanged = (value) => {
        setName(value);
        setNameError(value.length === 0);
    }

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (<WorkspaceDetailsPage name={name} error={nameError} onNameChanged={handleNameChanged}/>);
            case 1:
                return (<WorkspaceInvitationPage invitations={invitations} onInvitationsChanged={setInvitations}/>)
            default:
                return <Typography>Unknown stepIndex</Typography>;
        }
    }

    const handleCompleted = async () => {
        setIdle(true);
        const workspace = await api.mutation.createWorkspace(name, "standard", currentUser.name, currentUser.picture);
        await refreshToken();
        const validInvitations = invitations.filter(item => Boolean(item.email) && isValidEmail(item.email));
        for (const invitation of validInvitations) {
            if (invitation.email === currentUser.email) {
                console.log("Skipping invitation: %o", invitation);
                continue;
            }
            await api.mutation.createInvitation(invitation.email, workspace.pk, invitation.role, currentUser.name, name, currentUser.locale);
        }

        onCompleted(workspace);
        //navigate(`/portal/${workspaceId}/home`);
    }

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            handleCompleted();
            //onCompleted();
            return;
        }

        if (activeStep == 0 && name === "") {
            setNameError(true);
            return;
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const nextButtonText = () => {
        return activeStep === steps.length - 1 ? t("Finish") : t("Next")
    }

    return (
        <div id="workspace-wizard" className={clsx(classes.root, className)}>
            <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div className={classes.content}>
                {getStepContent(activeStep)}
            </div>
            <div className={classes.actions}>
                <Button
                    className={classes.backButton}
                    color="primary"
                    variant="outlined"
                    size="large"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                >
                    {t("Back")}
                </Button>
                <SpinnerButton showSpinner={idle} size="large" onClick={handleNext}>
                    {nextButtonText()}
                </SpinnerButton>
            </div>
        </div>
    )
}

WorkspaceWizard.propTypes = {
    className: PropTypes.string
}

WorkspaceWizard.defaultProps = {
    onCompleted: () => {}
};

export default WorkspaceWizard;