import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {FormControl, InputLabel, makeStyles, OutlinedInput, Typography} from '@material-ui/core';
import {
    convertToRaw,
    DefaultDraftBlockRenderMap,
    Editor,
    EditorState,
    getDefaultKeyBinding,
    Modifier,
    RichUtils
} from 'draft-js'
import Immutable from 'immutable'
import {
    getEditorBounds,
    deserializeEditorState,
    getLineNumber,
    isMaxLengthHandled, isEmptyState, serializeEditorState
} from "./editorState";
import {
    filterAutocompleteItems, getAtMentionStrategy,
    insertAutocompleteSuggestionAsAtomicBlock,
    insertAutocompleteSuggestionAsText
} from "./autocompleteUtils";
import Toolbar from "./Toolbar";
import {getBlockMap, getContentBlockType, getStyleMap, getStyleName} from "./styling";
import AutocompletePopper from "./AutocompletePopper";
import {useTheme} from "@emotion/react";
import AttachmentsContainer from "../AttachmentsContainer";
import {LocalAttachment} from "../attachments";
import {TEXT_FIELD_BORDER_RADIUS} from "../../constants";
import {getFileKey} from "../../utils/fileUtils";
import {addText} from "./modifierUtils";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {activeMembersSelector} from "../../state/members/selectors";
import {useParams} from "react-router-dom";
import {toWorkspaceKey} from "../../utils/apiUtils";
import EditorContainer from "./EditorContainer";

const lineHeight = 26

const useStyles = makeStyles((theme) => ({
    toolbar: {
        padding: [theme.spacing(1)],
    },
    autocomplete: {
        marginBottom: theme.spacing(0.5)
    },
    attachmentsContainer: {
        padding: [0, theme.spacing(1.75)],
        marginBottom: theme.spacing(1),
    },
    hidden: {
        visibility: "hidden"
    }
}));

const blockRenderer = (contentBlock, editorState, customControls = []) => {
    const blockType = contentBlock.getType();
    if (blockType === 'atomic') {
        const contentState = editorState.getCurrentContent();
        const entity = contentBlock.getEntityAt(0);
        if (entity) {
            const typeName = contentState.getEntity(entity).getType().toLowerCase();
            const block = customControls.find(control => control.name === typeName && control.type === "atomic"
                && control.atomicComponent);
            if (block) {
                return {
                    component: block.atomicComponent,
                    editable: false,
                    props: contentState.getEntity(contentBlock.getEntityAt(0)).getData()
                };
            }
        }
    }

    return null
}

const styleRenderer = (style, styleMap) => {
    const styleNames = style.toJS();
    return styleNames.reduce((styles, styleName) => {
        styles = {...styles, ...styleMap[styleName]};
        return styles;
    }, {});
}

// TODO: Refactor props, remove workspaceKey??
// TODO: Optimize rerenders
// TODO: Create text field label
// TODO: At mention names not changed when user changes name
// TODO: Add errors/hints
// TODO: Call onChange on blur??
const RichTextEditor2 = ({
    className,
    classes: propClasses,
    editorState,
    attachments,
    variant,
    defaultTextSize,
    padding,
    highlightOnHover,
    highlightOnFocus,
    placeholder,
    options,
    customControls,
    maxLength,
    showToolbar,
    toolbarPosition,
    onChange,
    onAttachmentsChange,
    atMentionItems,
    autocompleteStrategies,
    ...rest
}) => {
    const classes = useStyles();
    const containerRef = useRef();
    const editorRef = useRef();
    const blockRenderMapRef = useRef(DefaultDraftBlockRenderMap);
    const styleMapRef = useRef({});
    const autocompletePopoverPositionRef = useRef({});
    const [focus, setFocus] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [searchTerm, setSearchTerm] = useState("");
    const [autocompleteStrategy, setAutocompleteStrategy] = useState();
    const [autocompleteAnchor, setAutocompleteAnchor] = useState();
    const [autocompleteItems, setAutocompleteItems] = useState([]);
    const isReadOnly = variant === 'readOnly';

    // TODO: Use useEffect, merge with value and decorators dependencies
    useMemo(() => {
        blockRenderMapRef.current = getBlockMap(customControls);
        styleMapRef.current = getStyleMap(customControls);
    }, [customControls]);

    const handleChange = (newEditorState) => {
        if (autocompleteAnchor) {
            const selection = newEditorState.getSelection();
            const anchorKey = selection.getAnchorKey();
            const anchorOffset = selection.getAnchorOffset();
            if (anchorKey !== autocompleteAnchor.key ||
                anchorOffset < autocompleteAnchor.offset ||
                anchorOffset > autocompleteAnchor.offset + searchTerm.length) {
                closeAutocompletePopover();
            }
        }

        onChange(newEditorState);
    }

    const handleEditorFocus = () => {
        setFocus(true);
    }

    const handleEditorBlur = () => {
        setFocus(false);
        closeAutocompletePopover();
    }

    // TODO: Move to utils??
    const focusEditor = () => {
        //setTimeout(() => editorRef.current.blur(), 0);
        setTimeout(() => editorRef.current.focus(), 1);
    }

    const updateAutocompletePosition = () => {
        const editor = editorRef.current?.editor;
        const { editorRect, selectionRect } = getEditorBounds(editor);
        const line = getLineNumber(editorState);
        const top = selectionRect ? selectionRect.top : editorRect.top + (lineHeight * line);
        const left = selectionRect ? selectionRect.left : editorRect.left;
        const position = {
            top: editor.offsetTop + (top - editorRect.top),
            left: editor.offsetLeft + (left - editorRect.left)
        }
        autocompletePopoverPositionRef.current = position;
    }

    const openAutocompleteIfStrategyFound = (triggerChars) => {
        const strategy = autocompleteStrategies?.find(strategy => strategy.triggerChar === triggerChars);
        if (strategy) {
            updateAutocompletePosition();
            setAutocompleteStrategy(strategy);
            setAutocompleteItems(strategy.items);
            const selection = editorState.getSelection();
            setAutocompleteAnchor({
                key: selection.getAnchorKey(),
                offset: selection.getAnchorOffset() + strategy.triggerChar.length
            });
        }
    }

    const closeAutocompletePopover = () => {
        if (!autocompleteStrategy) {
            return;
        }

        setAutocompleteStrategy(null);
        setAutocompleteAnchor(null);
        setSearchTerm("");
        setSelectedIndex(0);
    }

    const handleAutocompleteSelected = (item) => {
        const selection = editorState.getSelection();
        const newAnchorOffset = selection.getAnchorOffset() - searchTerm.length - 1;
        const newSelection = selection.merge({
            'anchorOffset': newAnchorOffset
        });

        let newEditorState;
        if (autocompleteStrategy.atomicBlockName) {
            newEditorState = insertAutocompleteSuggestionAsAtomicBlock(
                editorState,
                newSelection,
                autocompleteStrategy.atomicBlockName,
                item.data
            );
        } else {
            newEditorState = insertAutocompleteSuggestionAsText(
                editorState,
                newSelection,
                item.value,
                item.data,
                autocompleteStrategy.insertSpaceAfter
            );
        }

        closeAutocompletePopover();
        focusEditor();
        onChange(newEditorState);
    }

    const keyBindingFn = (e) => {
        if (autocompleteStrategy) {
            switch (e.key) {
                case "ArrowDown":
                    return "autocomplete-navigate-down"
                case "ArrowUp":
                    return "autocomplete-navigate-up"
                case "Enter":
                    return "autocomplete-insert"
                case "Escape":
                    return "autocomplete-close"
            }
        }

        const keyBinding = getDefaultKeyBinding(e);
        if (keyBinding === "backspace" && autocompleteStrategy) {
            const text = editorState.getCurrentContent().getLastBlock().getText();
            if (text[text.length - 1] === autocompleteStrategy.triggerChar) {
                closeAutocompletePopover();
            } else {
                const newSearchTerm = searchTerm.length > 0 ? searchTerm.substr(0, searchTerm.length - 1) : "";
                setAutocompleteItems(filterAutocompleteItems(autocompleteStrategy.items, newSearchTerm));
                setSearchTerm(newSearchTerm);
            }
        }

        return keyBinding;
    }

    const handleKeyCommand = (command, currentEditorState) => {
        const newState = RichUtils.handleKeyCommand(currentEditorState, command);
        if (newState) {
            onChange(newState);
            return "handled";
        }

        switch (command) {
            case "autocomplete-insert": {
                handleAutocompleteSelected(autocompleteItems[selectedIndex]);
                return "handled";
            }
            case "autocomplete-close": {
                closeAutocompletePopover();
                return "handled";
            }
            case "autocomplete-navigate-up": {
                if (selectedIndex - 1 < 0) {
                    setSelectedIndex(autocompleteItems.length - 1);
                } else {
                    setSelectedIndex(selectedIndex - 1);
                }
                return "handled";
            }
            case "autocomplete-navigate-down": {
                if (selectedIndex + 1 >= autocompleteItems.length) {
                    setSelectedIndex(0);
                } else {
                    setSelectedIndex(selectedIndex + 1);
                }
                return "handled";
            }
            default:
                return "not-handled";
        }
    }

    const handleBeforeInput = (chars, currentEditorState) => {
        if (autocompleteStrategy) {
            const newSearchTerm = searchTerm + chars;
            setAutocompleteItems(filterAutocompleteItems(autocompleteStrategy.items, newSearchTerm));
            setSearchTerm(newSearchTerm);
        } else {
            openAutocompleteIfStrategyFound(chars);
        }
        return isMaxLengthHandled(currentEditorState, 1, maxLength);
    }

    const handleToolbarControlClicked = (control) => {
        if (control.type === "inline") {
            const newEditorState = RichUtils.toggleInlineStyle(editorState, getStyleName(control));
            onChange(newEditorState);
        } else if (control.type === "block") {
            const newEditorState = RichUtils.toggleBlockType(editorState, getContentBlockType(control));
            onChange(newEditorState);
        } else if (control.type === "callback") {
            switch (control.action) {
                case 'addText': {
                    const newEditorState = addText(editorState, control.text);
                    onChange(newEditorState);
                    openAutocompleteIfStrategyFound(control.text);
                    break;
                }
            }
        }
    }

    const handleAttachmentAdded = (file) => {
        const attachmentId = getFileKey(file);
        const isAttached = attachments?.some(attachment => attachment.id === attachmentId);
        if (!isAttached) {
            const attachment = {file: file, id: attachmentId};
            onAttachmentsChange([...(attachments ?? []), attachment]);
        }
    }

    const handleAttachmentUploaded = (attachmentId, key) => {
        const newAttachments = [...attachments];
        const index = newAttachments.findIndex(attachment => attachment.id === attachmentId);
        newAttachments[index].key = key;
        onAttachmentsChange(newAttachments);
    }

    const handleAttachmentDeleted = (attachmentId) => {
        const newAttachments = [...attachments];
        const index = newAttachments.findIndex(attachment => attachment.id === attachmentId);
        newAttachments.splice(index, 1);
        onAttachmentsChange(newAttachments);
    }

    const renderToolbar = () => (
        <Toolbar
            className={clsx(classes.toolbar, propClasses.toolbar)}
            editorState={editorState}
            editorHasFocus={focus}
            options={options}
            customControls={customControls}
            onControlClicked={handleToolbarControlClicked}
            onAttachmentAdded={handleAttachmentAdded}
            onClick={() => !focus && focusEditor()}
        />
    )

    return (
        <EditorContainer
            className={className}
            ref={containerRef}
            variant={variant}
            padding={padding}
            defaultTextSize={defaultTextSize}
            highlightOnHover={highlightOnHover}
            highlightOnFocus={highlightOnFocus}
            placeholder={Boolean(placeholder) && isEmptyState(editorState) ? placeholder : undefined}
            {...rest}
        >
            {showToolbar && options && options.length > 0 && !isReadOnly && toolbarPosition === 'top' && renderToolbar()}
            {autocompleteStrategies && !isReadOnly && (
                <AutocompletePopper
                    className={classes.autocomplete}
                    open={Boolean(autocompleteStrategy)}
                    anchorEl={containerRef.current}
                    items={autocompleteItems}
                    top={autocompletePopoverPositionRef.current?.top}
                    left={autocompletePopoverPositionRef.current?.left}
                    onClick={handleAutocompleteSelected}
                    selectedKey={autocompleteItems[selectedIndex]?.key}
                />
            )}
            <Editor
                blockRenderMap={blockRenderMapRef.current}
                blockRendererFn={(contentBlock) => blockRenderer(contentBlock, editorState, customControls)}
                customStyleFn={(style, _) => styleRenderer(style, styleMapRef.current)}
                editorState={editorState}
                onChange={handleChange}
                onFocus={handleEditorFocus}
                onBlur={handleEditorBlur}
                readOnly={isReadOnly}
                keyBindingFn={keyBindingFn}
                handleKeyCommand={handleKeyCommand}
                handleBeforeInput={handleBeforeInput}
                handlePastedText={(text, html, editorState) => (
                    isMaxLengthHandled(editorState, text.length, maxLength)
                )}
                handleReturn={(e, editorState) =>  (
                    isMaxLengthHandled(editorState, 1, maxLength)
                )}
                ref={editorRef}
            />
            {attachments && attachments.length > 0 &&  (
                <AttachmentsContainer className={classes.attachmentsContainer}>
                    {attachments.map((attachment) => (
                        <LocalAttachment
                            key={attachment.id}
                            file={attachment.file}
                            onUploaded={(key) => handleAttachmentUploaded(attachment.id, key)}
                            onDeleted={() => handleAttachmentDeleted(attachment.id)}
                        />
                    ))}
                </AttachmentsContainer>
            )}
            {showToolbar && options && options.length > 0 && !isReadOnly && toolbarPosition === 'bottom' && renderToolbar()}
        </EditorContainer>
    );
};

RichTextEditor2.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    variant: PropTypes.oneOf(["standard", "outlined", "readOnly"]),
    defaultTextSize: PropTypes.oneOf(["body1", "body2"]),
    padding: PropTypes.oneOf(["normal", "dense", "none"]),
    editorState: PropTypes.object.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.shape({
        file: PropTypes.any,
        key: PropTypes.string
    })),
    strategies: PropTypes.arrayOf(PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
            value: PropTypes.any,
            content: PropTypes.any.isRequired
        })),
        triggerChar: PropTypes.string,
        atomicBlockName: PropTypes.string,
        insertSpaceAfter: PropTypes.bool,
    })),
    options: PropTypes.arrayOf(PropTypes.string),
    maxLength: PropTypes.number,
    readOnly: PropTypes.bool,
    highlightOnHover: PropTypes.bool,
    highlightOnFocus: PropTypes.bool,
    showToolbar: PropTypes.bool,
    toolbarPosition: PropTypes.oneOf(['top', 'bottom']),
    placeholder: PropTypes.string,
    members: PropTypes.array,
    autocompleteStrategies: PropTypes.arrayOf(PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.object).isRequired,
        triggerChar: PropTypes.string.isRequired
    })),
    onChange: PropTypes.func,
    onAttachmentsChange: PropTypes.func
};

RichTextEditor2.defaultProps = {
    classes: {},
    variant: "standard",
    padding: "normal",
    defaultTextSize: "body2",
    strategies: [],
    options: [],
    attachments: [],
    readOnly: false,
    autocompleteStrategies: [],
    showToolbar: true,
    toolbarPosition: 'bottom',
    onChange: () => {},
    onAttachmentsChange: () => {},
};

export default RichTextEditor2;