import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    CardHeader,
    Grid,
    TextField,
    CardActionArea,
    Container,
    InputAdornment,
    FormControl,
    Input,
    FormHelperText, InputLabel, Tooltip
} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { useNavigate } from 'react-router-dom';
import * as texts from "../../constants/texts";
import {CONFIRMATION_PROP_TYPES, DEFAULT_CONFIRMATION_SETTINGS} from "../../constants/dialogs";
import DialogContainer from "../DialogContainer";
import StaticDialog from "../staticDialog/StaticDialog";
import StaticDialogContent from "../staticDialog/StaticDialogContent";
import RichTextEditor from "../RichTextEditor";
import {useTranslation} from "react-i18next";
import StaticDialogActions from "../staticDialog/StaticDialogActions";
import StaticDialogHeader from "../staticDialog/StaticDialogHeader";
import {formatReportId} from "../../utils/textFormatter";
import {toId} from "../../utils/apiUtils";
import {ApiContext} from "../../contexts/ApiContext";
import {isValidEmail} from "../../utils/textUtils";

const useStyles = makeStyles((theme) => ({
    avatar: {
        maxWidth: "100%",
    },
    avatarContainer: {
        maxWidth: "45%",
    },
    header: {
        flexDirection: "column",
        justifyContent: "center"
    },
    idField: {
        marginTop: theme.spacing(3)
    },
    // Not used
    placeholder: {
        color: theme.palette.text.secondary
    },
    emailField: {
        marginTop: theme.spacing(0.25)
    },
    textOffset: {
        // to compensate padding of rtf editor when its editable. Horizontal margin is 2px smaller than
        // rtf editor padding, this is to keep border visible when rtf is in scrollable container
        margin: [-theme.spacing(2), -theme.spacing(1.75)]
    },
    actions: {
        pointerEvents: props => props.editable ? "none" : "initial"
    },
}));

const copyIdToClipboard = () => {
    const copyText = document.getElementById("report-id-box");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
}

const ConfirmationDialog = ({className, classes: propClasses, showLogo, accessId, workspace, language, layout, editable, onSave, ...rest}) => {
    const classes = useStyles({editable});
    const navigate = useNavigate();
    const { t } = useTranslation(['translation', 'dialogs']);
    const api = useContext(ApiContext);
    const rtfData = layout ?? t("confirmation.defaultText", {ns: "dialogs", lng: language});
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState();

    const handleContinueClick = async (event) => {
        event.persist()
        event.preventDefault();
        if (email && !isValidEmail(email)) {
            setEmailError(true);
            return;
        } else if (email) {
            await api.mutation.updateResourceAccessKey(accessId, {email: email});
        }
        navigate('/followup'); //navigate('/app');
    };

    const handleEmailChange = (event) => {
        setEmailError(false); // reset error if value becomes valid
        setEmail(event.target.value);
    }

    const handleCopyButtonOnClick = () => {
        copyIdToClipboard();
    };

    return (
        <DialogContainer className={className}>
            <StaticDialog className={propClasses.dialog}>
                {/*<CardHeader*/}
                {/*    className={classes.header}*/}
                {/*    classes={{avatar: classes.avatarContainer}}*/}
                {/*    titleTypographyProps={{gutterBottom: true, align: "center", variant: "h4"}}*/}
                {/*    subheaderTypographyProps={{variant: "subtitle1", color:"textSecondary"}}*/}
                {/*    avatar={*/}
                {/*        <img className={classes.avatar} alt="Report sent" src="/static/images/sent.jpg"/>*/}
                {/*    }*/}
                {/*/>*/}
                {/*{showLogo && (*/}
                {/*    <StaticDialogHeader workspace={workspace}/>*/}
                {/*)}*/}
                <StaticDialogContent>
                    <RichTextEditor className={clsx({[classes.textOffset]: editable})} readOnly={!editable} value={rtfData} onSave={onSave}/>
                    <TextField
                        className={classes.idField}
                        fullWidth
                        hiddenLabel
                        id="report-id-box"
                        variant="filled"
                        defaultValue={accessId}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title={t("copy", {lng: language})}>
                                        <IconButton onClick={handleCopyButtonOnClick}>
                                            <FileCopyOutlinedIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            )
                        }}
                        InputLabelProps={{shrink: false}}
                    />
                    <Box pt={4}>
                        <Typography variant="subtitle1" style={{fontWeight: 500}}>
                            {t("confirmationDialogEmailHeader", {lng: language})}
                        </Typography>
                        <Typography gutterBottom variant="body2" color="textSecondary">
                            {t("confirmationDialogEmailText", {lng: language})}
                        </Typography>
                        <TextField
                            className={classes.emailField}
                            fullWidth
                            hiddenLabel
                            name="Email"
                            variant="outlined"
                            placeholder={t("emailAddress", {lng: language})}
                            value={email}
                            error={emailError}
                            helperText={emailError ? t("invalidEmailError", {lng: language}) : null}
                            onChange={handleEmailChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment disablePointerEvents position="start">
                                        <EmailOutlinedIcon className={classes.placeholder}/>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </StaticDialogContent>
                <StaticDialogActions className={classes.actions}>
                    <Button
                        fullWidth
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={handleContinueClick}
                    >
                        {t("continue", {lng: language})}
                    </Button>
                </StaticDialogActions>
            </StaticDialog>
        </DialogContainer>
    );
};

ConfirmationDialog.propTypes = {
    className: PropTypes.string,
    accessId: PropTypes.string.isRequired,
    language: PropTypes.string,
    layout: PropTypes.string
};

ConfirmationDialog.defaultProps = {
    language: "en",
    showLogo: true,
    classes: {}
};

export default ConfirmationDialog;
