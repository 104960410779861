import React, {useContext} from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import {getAvatarColor, getInitials} from "../../utils/avatarUtils";
import {isImage} from "../../utils/fileUtils";
import {getSignedUrl, toUserKey} from "../../utils/apiUtils";
import PropTypes from "prop-types";
import {getReportName} from "../../utils/textUtils";
import {ApiContext} from "../../contexts/ApiContext";
import {useTranslation} from "react-i18next";
import AttachmentsContainer from "../AttachmentsContainer";
import {getSystemMessageContent, isClosed, isSystemMessage} from "../../utils/reportUtils";
import {useSelector} from "react-redux";
import {currentUserRoleSelector} from "../../state/members/selectors";
import {deserializeEditorState} from "../RichTextEditor2/editorState";

Font.register({ family: 'Montserrat', fonts: [
        //{ src: source1 }, // font-style: normal, font-weight: normal
        { src: 'https://fonts.gstatic.com/s/montserrat/v18/JTUSjIg1_i6t8kCHKm45xW4.ttf', fontStyle: 'normal', fontWeight: '400' },
        { src: 'https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_ZpC7g4.ttf', fontStyle: 'normal', fontWeight: '500' },
        { src: 'https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF7g4.ttf', fontStyle: 'normal', fontWeight: '600' },
    ]});

Font.register({ family: 'Lora', fonts: [
        //{ src: source1 }, // font-style: normal, font-weight: normal
        { src: 'https://fonts.gstatic.com/s/lora/v17/0QI6MX1D_JOuGQbT0gvTJPa787weuyJG.ttf', fontStyle: 'normal', fontWeight: '400' },
        { src: 'https://fonts.gstatic.com/s/lora/v17/0QI6MX1D_JOuGQbT0gvTJPa787wsuyJG.ttf', fontStyle: 'normal', fontWeight: '500' },
        { src: 'https://fonts.gstatic.com/s/lora/v17/0QI6MX1D_JOuGQbT0gvTJPa787zAvCJG.ttf', fontStyle: 'normal', fontWeight: '600' },
    ]});

Font.register({ family: 'Poppins', fonts: [
        //{ src: source1 }, // font-style: normal, font-weight: normal
        { src: 'https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrFJA.ttf', fontStyle: 'normal', fontWeight: '400' },
        { src: 'https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9V1s.ttf', fontStyle: 'normal', fontWeight: '500' },
        { src: 'https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLEj6V1s.ttf', fontStyle: 'normal', fontWeight: '600' },
    ]});

Font.register({ family: 'JetBrains Mono', fonts: [
    { src: 'https://fonts.gstatic.com/s/jetbrainsmono/v6/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8yKxjPQ.ttf', fontStyle: 'normal', fontWeight: '400' }
]});

Font.register({ family: 'Fira Code', fonts: [
    { src: 'https://fonts.gstatic.com/s/firacode/v12/uU9dCBsR6Z2vfE9aq3bpdva8iVtipIat--AvNZ-YE8Y.ttf', fontStyle: 'normal', fontWeight: '400' }
]});

Font.register({ family: 'Source Code Pro', fonts: [
    { src: 'https://fonts.gstatic.com/s/sourcecodepro/v14/HI_SiYsKILxRpg3hIP6sJ7fM7PqVOg.ttf', fontStyle: 'normal', fontWeight: '400' }
]});

const colors = {
    primary: '#212121',
    secondary: '#565656'
}

const styles = StyleSheet.create({
    body: {
        padding: "2.54cm"
    },
    footer: {
        position: 'absolute',
        bottom: 30,
        left: "2.54cm",
        right: "2.54cm",
        display: "flex",
        flexDirection: "row"
    },
    footerText: {
        fontFamily: "Poppins",
        fontSize: 11,
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    logo: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 24,
        marginTop: 0
    },
    companyName: {
        fontFamily: "Montserrat",
        fontSize: 20,
        fontWeight: 500,
        color: colors.primary,
        marginLeft: 6
    },
    title: {
        fontFamily: "Lora",
        fontSize: 24,
        fontWeight: 400,
        marginBottom: 8,
        color: colors.primary
    },
    subtitle: {
        fontFamily: "Lora",
        fontWeight: 400,
        fontSize: 18,
        marginVertical: 8,
        color: colors.primary
    },
    textRegular: {
        fontFamily: "Poppins",
        fontSize: 11,
        textAlign: 'justify',
        color: colors.secondary,
    },
    textSmall: {
        fontFamily: "Poppins",
        fontSize: 10,
        color: colors.secondary
    },
    primaryColor: {
        color: colors.primary
    },
    boldText: {
        fontWeight: 600,
    },
    divider: {
        borderBottomColor: '#E0E0E0',
        borderBottomWidth: 1
    },
    paragraph: {
        marginVertical: 8,
        wordWrap: "break-word"
    },
    messageContent: {
        marginTop: 2
    },
    inline: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center'
    },
    topAlignment: {
        alignItems: 'flex-start'
    },
    avatar: {
        width: 32,
        height: 32,
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        color: "white"
    },
    imageAttachment: {
        marginVertical: 4,
        objectFit: "scale-down",
        objectPosition: "0 0",
    },
    fileAttachment: {
        fontFamily: "Source Code Pro",
        marginVertical: 4,
        fontSize: 11,
        color: colors.secondary,
        wordBreak: "break-all",
        wordWrap: "break-word",
        hyphens: "none"
    }
});

const renderLogo = (workspace) => {
    const background = getAvatarColor(workspace.name);

    return (
        <View style={[styles.inline, styles.logo]}>
            {Boolean(workspace.picture) ? (
                <Image
                    style={styles.avatar}
                    src={getSignedUrl(workspace.picture)}
                />
            ) : (
                <View style={[styles.avatar, {backgroundColor: background}]}>
                    <Text>{getInitials(workspace.name)}</Text>
                </View>
            )}
            <Text style={styles.companyName}>{workspace.name}</Text>
        </View>
    )
}

const renderFileAttachment = (key) => {
    const url = getSignedUrl(key);
    const parts = url.match(/.{1,66}/g);

    // Attachment url must be rendered as a set of separate text components to avoid hyphenation of the url.
    return (
        <View key={key} style={styles.fileAttachment} wrap={false}>
            {parts.map(part => (
                <Text key={part}>
                    {part}
                </Text>
            ))}
        </View>
    )
}

// TODO: Refactor, merge with chatMessage rendering
const renderMessage = (message, users, t) => {
    const user = !message.sender ? undefined : users.find(user => user.pk === message.sender);
    const messageText = isSystemMessage(message) ? getSystemMessageContent(message, user.name, t) : message.text;
    let parsedContent = null;
    if (message.content) {
        if (Array.isArray(message.content)) {
            parsedContent = message.content.filter(entry => entry.type !== "attachments").map(entry => {
                const parsedValue = Array.isArray(entry.value) ? entry.value.join("\r\n") : entry.value;
                return `${entry.label}\r\n${parsedValue}`;
            }).join("\r\n\r\n");
        } else {
            parsedContent = getText(message.content)
        }
    }
    //const messageContent = message.content ? getText(message.content) : null;

    return (
        <View style={styles.paragraph} key={message.createdAt}>
            {user ? (
                <View style={styles.inline}>
                    <Text style={[styles.textRegular, styles.primaryColor, styles.boldText]}>{`${user.name}  `}</Text>
                    <Text style={[styles.textRegular, styles.primaryColor]}>{user.email}</Text>
                </View>
            ) : (
                <Text style={[styles.textRegular, styles.primaryColor, styles.boldText]}>{t("Anonymous")}</Text>
            )}
            <Text style={[styles.textRegular, styles.primaryColor]}>
                {t("{dateLong}", {date: new Date(message.createdAt)})}
            </Text>
            {messageText && (
                <Text style={[styles.textRegular, styles.messageContent]}>
                    {messageText}
                </Text>
            )}
            {parsedContent && (
                <Text style={[styles.textRegular, styles.messageContent]}>
                    {parsedContent}
                </Text>
            )}

            {/*{ message.attachments && message.attachments.map(attachment => (*/}
            {/*    isImage(attachment) ? (*/}
            {/*        <Image key={attachment} style={styles.imageAttachment} src={getSignedUrl(attachment)}/>*/}
            {/*    ) : (*/}
            {/*        renderFileAttachment(attachment)*/}
            {/*    )*/}
            {/*))}*/}
        </View>
    );
}

const renderAssignedUser = (user) => {
    return (
        <View key={user.pk} style={styles.inline}>
            <Text style={[styles.textSmall, styles.primaryColor]}>{`${user.name}  `}</Text>
            <Text style={[styles.textSmall]}>{user.email}</Text>
        </View>
    )
}

const getText = (content) => {
    return deserializeEditorState(content).getCurrentContent().getPlainText();
}

// TODO: Extract common styles to global classes
const ReportPDF = ({report, messages, workspace, users, currentUser, exportMessages}) => {
    const { t } = useTranslation();
    const assignedUsers = report.owners ? report.owners.map(id => users.find(user => user.id === id)) : null;
    const reportName = getReportName(report, t);

    return (
        <Document title={reportName} author={currentUser.name}>
            <Page style={styles.body}>
                {renderLogo(workspace)}
                <Text style={styles.title}>{reportName}</Text>
                <View style={[styles.inline, styles.paragraph, styles.topAlignment]}>
                    <View style={{marginRight: 16}}>
                        <Text style={styles.textSmall}>{`${t("From")}: `}</Text>
                        <Text style={styles.textSmall}>{`${t("Created")}: `}</Text>
                        {isClosed(report) && (
                            <>
                                <Text style={styles.textSmall}>{`${t("Closed")}: `}</Text>
                                <Text style={styles.textSmall}>{`${t("Closed reason")}: `}</Text>
                            </>
                        )}
                        { assignedUsers && (
                            <Text style={styles.textSmall}>{`${t("assigned")}: `}</Text>
                        )}
                    </View>
                    <View>
                        <Text style={[styles.textSmall, styles.primaryColor]}>{/*report.sender ?? */t("Anonymous")}</Text>
                        <Text style={[styles.textSmall, styles.primaryColor]}>{t("{dateLong}", {date: new Date(report.createdAt)})}</Text>
                        {isClosed(report) && (
                            <>
                                <Text style={[styles.textSmall, styles.primaryColor]}>{t("{dateLong}", {date: new Date(report.stateUpdatedAt)})}</Text>
                                <Text style={[styles.textSmall, styles.primaryColor]}>{report.state === "solved" ? t("solved") : t("Marked as junk")}</Text>
                            </>
                        )}
                        { assignedUsers && (assignedUsers.map(user => renderAssignedUser(user)))}
                    </View>
                </View>
                <View style={[styles.divider, styles.paragraph]}/>
                {Boolean(report.description) && (
                    <>
                        <Text style={styles.subtitle}>{t("Description")}</Text>
                        <Text style={[styles.textRegular, styles.paragraph]}>
                            {getText(report.description)}
                        </Text>
                    </>
                )}
                {Boolean(report.solution) && (
                    <>
                        <Text style={styles.subtitle}>{t("Solution")}</Text>
                        <Text style={[styles.textRegular, styles.paragraph]}>
                            {getText(report.solution)}
                        </Text>
                    </>
                )}
                {exportMessages && (
                    <>
                        <Text style={styles.subtitle}>{t("Messages")}</Text>
                        {messages.map(message => renderMessage(message, users, t))}
                    </>
                )}
                <View style={styles.footer} fixed>
                    <Text style={[styles.footerText, {flexGrow: "1"}]} render={({pageNumber, totalPages}) => (
                        `${pageNumber} | ${reportName}`
                    )}/>
                    <Text style={styles.footerText}>{workspace.name}</Text>
                </View>
            </Page>
        </Document>
    )
}

ReportPDF.propTypes = {
    report: PropTypes.shape({
        name: PropTypes.string,
        assigned: [PropTypes.string],
        createdAt: PropTypes.string,
        description: PropTypes.string,
        solution: PropTypes.string
    }).isRequired,
    workspace: PropTypes.shape({
        name: PropTypes.string.isRequired,
        picture: PropTypes.string
    }).isRequired,
    messages: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string.isRequired,
        createdAt: PropTypes.object.isRequired,
        sender: PropTypes.string,
        attachments: PropTypes.arrayOf(PropTypes.string)
    })).isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({
        pk: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired
    })),
    onCompleted: PropTypes.func
}

AttachmentsContainer.defaultProps = {
    users: []
};

export default ReportPDF;