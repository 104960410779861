import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import {
    makeStyles,
    Divider,
    Hidden,
    Typography,
    TextField,
    Button,
    Paper,
    AppBar,
    Toolbar,
    IconButton,
    Select,
    Box,
    Drawer,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    useTheme, useMediaQuery, Container
} from "@material-ui/core";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {FORM_PAGE_WIDTH} from "../../constants";

const useStyles = makeStyles((theme) => ({
    pageSeparator: {
        // margin: [theme.spacing(1), 0],
        display: "flex",
        alignItems: "center",
        maxWidth: FORM_PAGE_WIDTH,
    },
    divider: {
        flex: "1 1 auto",
    },
    content: {
        margin: [0, theme.spacing(1)]
    }
}));

const ContentSeparator = ({className, children}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Container className={clsx(classes.pageSeparator, className)}>
            <Divider className={classes.divider} orientation="horizontal"/>
            <div className={classes.content}>
                {children}
            </div>
            <Divider className={classes.divider} orientation="horizontal"/>
        </Container>
    )
}

ContentSeparator.propTypes = {
    className: PropTypes.string,
};

ContentSeparator.defaultProps = {
};

export default ContentSeparator;
