import React, {useContext, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles,
    CssBaseline,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    TablePagination,
    List,
    ListItem,
    ListItemAvatar, useTheme, ListItemText, Divider, ListItemSecondaryAction, ListItemIcon, ButtonBase
} from '@material-ui/core';
import {Skeleton} from "@material-ui/lab";
import ChatMessageSkeleton from "./ChatMessageSkeleton";
import ChatMessage from "./ChatMessage";

const useStyles = makeStyles((theme) => ({
    chatSkeleton: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    messageContainer: {
        flex: "1 1 auto",
        padding: [theme.spacing(4)],
        '&>div:not(:last-child) ': {
            marginBottom: theme.spacing(3),
        }
    },
    inputSkeleton: {
        margin: [0, theme.spacing(4), theme.spacing(4), theme.spacing(4)],
        flex: "0 0 auto"
    }
}));

const ChatSkeleton = ({className}) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.chatSkeleton, className)}>
            <div className={classes.messageContainer}>
                <ChatMessageSkeleton/>
                <ChatMessageSkeleton/>
                <ChatMessageSkeleton position="right"/>
            </div>
            <Skeleton className={classes.inputSkeleton} variant="rect" height={48}/>
        </div>
    );
}
ChatSkeleton.propTypes = {
    className: PropTypes.string
}

export default ChatSkeleton;