import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    Typography,
    makeStyles,
    CircularProgress
} from '@material-ui/core';
import {useSelector} from "react-redux";
import {currentUserSelector} from "../../state/currentUser/selectors";
import { usePDF } from '@react-pdf/renderer';
import ReportPDF from "./ReportPDF";
import {workspaceSelector} from "../../state/workspaces/selectors";
import {getReportName} from "../../utils/textUtils";
import {messagesSelector} from "../../state/messages/selectors";
import {useTranslation} from "react-i18next";
import {allMembersSelector, currentUserRoleSelector} from "../../state/members/selectors";
import {downloadFile} from "../../utils/storageUtils";
import {useParams} from "react-router-dom";
import {toWorkspaceKey} from "../../utils/apiUtils";
import {isApprover} from "../../utils/user";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    toolbar: {
        flex: "0 0 auto",
        padding: [theme.spacing(2), theme.spacing(3), theme.spacing(0), theme.spacing(3)],
        display: "flex",
        justifyContent: "flex-end"
    },
    content: {
        width: theme.breakpoints.values.sm,

        padding: [theme.spacing(0), theme.spacing(3), theme.spacing(3), theme.spacing(3)],
        overflowY: "auto"
        //padding: [theme.spacing(5), theme.spacing(12)],
    },
    body: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.text.secondary,
        '&>p': {
            marginTop: theme.spacing(1)
        },
    }
}));

// TODO: Rename, add comment why it cannot be in the export dialog
const ExportDialogContent = ({report, onCompleted}) => {
    const { t } = useTranslation();
    const {workspaceId} = useParams();
    const workspaceKey = toWorkspaceKey(workspaceId);
    const workspace = useSelector(workspaceSelector(workspaceKey));
    const users = useSelector(allMembersSelector(workspaceKey));
    const messages = useSelector(messagesSelector(report.pk));
    const currentUser = useSelector(currentUserSelector());
    const currentUserRole = useSelector(currentUserRoleSelector(report.workspace));
    const exportMessages = !isApprover(currentUserRole);
    const [instance, updateInstance] = usePDF({ document: ReportPDF({report, messages, workspace, users, currentUser, exportMessages}) });
    //const [instance, updateInstance] = useState({})

    useEffect(() => {
        if (instance.blob) {
            downloadFile(instance.url, `${getReportName(report, t)}.pdf`);
            onCompleted(instance.blob);
        }
    }, [instance])

    if (instance.error) {
        return (
            <Typography>
                {t("Something went wrong: {error}", {error: instance.error})}
            </Typography>
        )
    }

    return (
        <>
            <CircularProgress />
            <Typography color="textSecondary">
                {`${t("Generating PDF")}...`}
            </Typography>
        </>
    )
}

ExportDialogContent.propTypes = {
    className: PropTypes.string,
    report: PropTypes.object.isRequired,
    onCompleted: PropTypes.func
}

ExportDialogContent.defaultProps = {
    onCompleted: () => {}
};

export default ExportDialogContent;