import React, {useContext, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles,
    CssBaseline,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    TablePagination,
    List,
    ListItem,
    ListItemAvatar, useTheme, ListItemText, Divider, ListItemSecondaryAction, ListItemIcon, ButtonBase, Container
} from '@material-ui/core';
import ReportLoginDialog from "./ReportLoginDialog";
import ChatDialog from "./ChatDialog"
import Page from "../../../components/Page/Page";
import { useDispatch } from "react-redux";
import { clearState } from "../../../state/rootActions";

const useStyles = makeStyles((theme) => ({
    followupView: {
        display: "flex",
        alignItems: "center"
    }
}));

const FollowupView = () => {
    const classes = useStyles();
    const [accessId, setAccessId] = useState();
    const [reportKey, setReportKey] = useState();
    const dispatch = useDispatch();

    const handleReportLogin = (args) => {
        setReportKey(args.reportKey);
        setAccessId(args.accessId);
    }

    const handleChatClose = () => {
        setReportKey(null);
        setAccessId(null);
        dispatch(clearState());
    }

    return (
        <Page title="Report follow up" className={classes.followupView} disableScroll>
            { reportKey && accessId ? (
                <ChatDialog accessId={accessId} reportKey={reportKey} onChatClose={handleChatClose}/>
            ) : (
                <ReportLoginDialog onLogin={handleReportLogin}/>
            )}
        </Page>
    );
}

export default FollowupView;