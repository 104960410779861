import React, {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Button,
    makeStyles,
    TextField, Typography,
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import RichTextEditor2 from "../RichTextEditor2/RichTextEditor2";
import {ToolbarFiller} from "../RichTextEditor2/ToolbarFiller";
import {deserializeEditorState, emptyEditorState, serializeEditorState} from "../RichTextEditor2/editorState";
import {useParams} from "react-router";
import {toWorkspaceKey} from "../../utils/apiUtils";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
    headerWithAction: {
        display: "flex",
        alignItems: "center",
    },
    headerAction: {
        marginLeft: "auto"
    }
}));

const SettingsControl = ({className, labelKey, action, children}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={className}>
            <div className={Boolean(action) ? classes.headerWithAction : undefined}>
                <Typography className={classes.label} variant="subtitle2" color="textSecondary">
                    {t(labelKey)}
                </Typography>
                {action && (
                    <div className={classes.headerAction}>
                        {action}
                    </div>
                )}
            </div>
            {children}
        </div>
    );
}

SettingsControl.propTypes = {
    className: PropTypes.string,
    labelKey: PropTypes.string.isRequired,
    action: PropTypes.object
}

SettingsControl.defaultProps = {
};

export default SettingsControl;