import React from 'react';
import clsx from 'clsx';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        border: `1px solid ${theme.palette.grey[300]}`,
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
    },
}));

const Card = ({className, children, ...rest}) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.cardRoot, className)} {...rest}>
            {children}
        </div>
    )
};

export default Card;