export function isApprover(userRole) {
    return userRole === 'approver';
}

export function isSolver(userRole) {
    return userRole === 'solver';
}

export function isAdmin(userRole) {
    return userRole === 'admin';
}

export function isInvestigator(userRole) {
    return userRole === "solver";
}

export function isOwner(workspace, member) {
    return workspace.owners[0] === member.id;
}