import React, {useContext, useEffect, useState} from 'react';
import {
    Box,
    makeStyles,
    Avatar,
    Typography, Backdrop, CircularProgress, Tooltip, Toolbar, Link, useTheme
} from '@material-ui/core';
import PropTypes from "prop-types";
import clsx from "clsx";
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import {isAudio, isImage} from "../../utils/fileUtils";
import AudioPlayer from "material-ui-audio-player";
import {ApiContext} from "../../contexts/ApiContext";
import {Skeleton} from "@material-ui/lab";
import {getSignedUrl} from "../../utils/apiUtils";
import {useTranslation} from "react-i18next";
import AttachmentDeleteButton from "./AttachmentDeleteButton";
import {ThumbnailHeight, ThumbnailMaxWidth} from "../../constants";

const borderRadius = 8;

// TODO: Move sizes to contants
const useStyles = makeStyles((theme) => ({
    imagePreview: {
        display: "inline-block",
        maxWidth: props => props.size === "small" ? theme.spacing(20) : ThumbnailMaxWidth,
        height: props => props.size === "small" ? theme.spacing(10) : ThumbnailHeight,
        position: "relative",
        zIndex: 0,
    },
    filePreview: {
        width: theme.spacing(40), // 25
        maxWidth: "100%",
        //height: theme.spacing(8),
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        borderRadius,
        borderWidth: "thin",
        borderStyle: "solid",
        borderColor: theme.palette.grey[300]
    },
    audioPreview: {
        width: theme.spacing(40),
        maxWidth: "100%",
        //height: theme.spacing(8),
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        borderRadius,
        borderWidth: "thin",
        borderStyle: "solid",
        borderColor: theme.palette.grey[300],
        paddingRight: props => props.canDelete ? theme.spacing(6) : 0,
        '&>div:first-child': {
            margin: 0,
            backgroundColor: "transparent"
        },
    },
    backdrop: {
        position: "absolute",
        zIndex: theme.zIndex.drawer + 1,
        borderRadius
    },
    image: {
        maxHeight: "100%",
        maxWidth: "100%",
        borderRadius,
    },
    icon: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        color: theme.palette.grey[400],
        marginRight: theme.spacing(1)
    },
    filePreviewContent: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: theme.spacing(1),
        paddingRight: props => props.canDelete ? theme.spacing(6) : theme.spacing(2)
    },
    placeholder: {
        width: "100%",
        height: "100%"
    },
    text: {
        minWidth: 0
    }
}));

// TODO: Reduce number of divs
const AttachmentPreview = ({className, classes: propClasses, name, language, imageShowName, url, size, canDelete, onDownload, onDelete}) => {
    //const attachmentName = url.split('/')?.pop();
    const [hover, setHover] = React.useState(false);
    const classes = useStyles({canDelete, size});

    const imagePreview = () => (
        <div
            className={clsx(classes.imagePreview, className, propClasses.image)}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <img className={classes.image} src={url}/>
            <Link href={url} target="_blank" download onClick={onDownload}>
                <Backdrop open={hover} className={classes.backdrop}/>
            </Link>
            { canDelete &&
                <AttachmentDeleteButton visible={hover} language={language} onClick={onDelete} color="inherit"/>
            }
        </div>
    );

    const filePreview = () => (
        <div className={clsx(classes.filePreview, className, propClasses.file)}>
            <Link href={url} underline="none" color="textPrimary" target="_blank" download onClick={onDownload}>
                <div className={classes.filePreviewContent}>
                    {/*<DescriptionOutlinedIcon className={propClasses.icon} stroke={theme.palette.background.paper}/>*/}
                    <DescriptionIcon className={classes.icon} />
                    <Typography variant="body2" noWrap>
                        {name}
                    </Typography>
                </div>
            </Link>
            { canDelete &&
                <AttachmentDeleteButton onClick={onDelete}/>
            }
        </div>
    );

    const audioPreview = () => (
        <div className={clsx(classes.audioPreview, className, propClasses.audio)}>
            <AudioPlayer
                elevation={0}
                variation="primary"
                download={true}
                spacing={0}
                src={url}
                time="single"
                volume={false}
            />
            { canDelete &&
                <AttachmentDeleteButton onClick={onDelete}/>
            }
        </div>
    );

    if (isImage(name)) {
        return imagePreview();
    } else if (isAudio(name)) {
        return audioPreview();
    } else {
        return filePreview();
    }
};

AttachmentPreview.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    downloadUrl: PropTypes.string,
    imageShowName: PropTypes.bool,
    size: PropTypes.oneOf(["small", "normal"]),
    onDownload: PropTypes.func,
    language: PropTypes.string
}

AttachmentPreview.defaultProps = {
    classes: {},
    size: "normal",
    onDownload: () => {}
};

export default AttachmentPreview;