import React, { forwardRef, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    CardHeader,
    Grid,
    TextField,
    CardActionArea,
    Container,
    Paper,
    FormControlLabel,
    Checkbox,
    alpha,
    MenuItem,
    InputAdornment,
    Tooltip,
} from "@material-ui/core";
import { CardShadow2, DEFAULT_FORM_SIDE_PADDING_SPACING } from "../../constants";
import { useTranslation } from "react-i18next";
import { CheckBox } from "@material-ui/icons";
import BlockHeader from "./BlockHeader";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { s4 } from "../../utils/idGenerator";
import IconButton from "@material-ui/core/IconButton";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import DragIndicatorOutlinedIcon from "@material-ui/icons/DragIndicatorOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { Draggable } from "react-beautiful-dnd";
import BorderlessTextField from "../BorderlessTextField";

const useStyles = makeStyles((theme) => ({
    selectOptionRoot: {
        // marginLeft: `calc(${theme.spacing(DEFAULT_FORM_SIDE_PADDING_SPACING)}px - 24px)`,
        // marginRight: `calc(${theme.spacing(DEFAULT_FORM_SIDE_PADDING_SPACING)}px - 4px)`,
        // marginLeft: "-24px",
        // marginRight: "-4px",
        //padding: [theme.spacing(0.5), theme.spacing(0.5)],
        display: "flex",
        alignItems: "center",
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        "&:hover": {
            // backgroundColor: theme.palette.grey[300],
            "& $dragIcon": {
                visibility: "visible",
            },
        },
    },
    dragging: {
        //backgroundColor: theme.palette.action.selected,
        backgroundColor: theme.palette.grey[300],
        "& $dragIcon": {
            visibility: "visible",
        },
    },
    dragIcon: {
        visibility: "hidden",
        color: theme.palette.text.hint,
    },
    textField: {
        //padding: [theme.spacing(0.5), 0],
        flex: "1 1 auto",
        // borderTopLeftRadius: "6px",
        // borderTopRightRadius: "6px",
        // marginLeft: theme.spacing(0.5),
        // marginRight: theme.spacing(2.5),
        "& .MuiInputBase-root": {
            //backgroundColor: theme.palette.background.paper,
            //paddingLeft: "4px",
            //paddingRight: "4px",
        },
        "& input": {
            ...theme.typography.body2,
            // paddingTop: "7.5px",
            // paddingBottom: "7.5px",
        },
        "& .MuiOutlinedInput-root": {
            //backgroundColor: theme.palette.background.paper,
        },
        "& fieldset": {
            borderStyle: props => props.showBorder ? "solid" : "none"
        },
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
            // "& fieldset": {
            //     borderStyle: props => props.showBorder ? "solid" : "none"
            // },
        }
        // '& .MuiInput-underline': {
        //     '&:before': {
        //         borderColor: theme.palette.grey[400]
        //     }
        // },
        // '&:hover': {
        //     '& .MuiInput-underline': {
        //         '&:before': {
        //             borderColor: 'rgba(0, 0, 0, 0.42)'
        //         }
        //     }
        // }
    },
    icon: {
        marginRight: theme.spacing(1)
    }
}));

const OptionField = ({ className, index, optionKey, languagePack, Icon, autoFocus, onChange, onDelete, ...rest }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const classes = useStyles({showBorder: isActive || isMouseOver});

    useEffect(() => {
        setValue(languagePack[optionKey] ?? "");
    }, [languagePack]);

    const handleChange = (event) => {
        setValue(event.target.value);
        onChange(event.target.value);
    };

    const handleOnMouseEnter = () => {
        if (!isActive) {
            setIsMouseOver(true);
        }
    }

    const handleOnMouseLeave = () => {
        if (isMouseOver) {
            setIsMouseOver(false);
        }
    }

    const handleOnBlur = () => {
        setIsActive(false);
    }

    const handleOnClick= () => {
        setIsActive(true);
    }

    return (
        <Draggable draggableId={`option-${optionKey}`} index={index}>
            {(provided, snapshot) => (
                <div
                    className={clsx(classes.selectOptionRoot, className, { [classes.dragging]: snapshot.isDragging })}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    {...rest}
                >
                    <DragIndicatorOutlinedIcon className={classes.dragIcon} fontSize="small" />
                    {Boolean(Icon) && (
                        <Icon className={classes.icon} color="disabled"/>
                    )}
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        size="small"
                        fullWidth
                        hiddenLabel
                        autoFocus={autoFocus}
                        value={value}
                        onChange={handleChange}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                        onClick={handleOnClick}
                        onBlur={handleOnBlur}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title={t("delete")}>
                                        <IconButton onClick={onDelete} size="small">
                                            <ClearOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            )
                        }}
                    />
                    {/*<BorderlessTextField*/}
                    {/*    className={classes.textField}*/}
                    {/*    variant="outlined"*/}
                    {/*    size="small"*/}
                    {/*    fullWidth*/}
                    {/*    hiddenLabel*/}
                    {/*    autoFocus={autoFocus}*/}
                    {/*    value={value}*/}
                    {/*    onChange={handleChange}*/}
                    {/*    InputProps={{*/}
                    {/*        endAdornment: (*/}
                    {/*            <InputAdornment position="end">*/}
                    {/*                <Tooltip title={t("delete")}>*/}
                    {/*                    <IconButton onClick={onDelete} size="small">*/}
                    {/*                        <ClearOutlinedIcon />*/}
                    {/*                    </IconButton>*/}
                    {/*                </Tooltip>*/}
                    {/*            </InputAdornment>*/}
                    {/*        )*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<Tooltip title={t("delete")}>*/}
                    {/*    <IconButton onClick={onDelete} size="small">*/}
                    {/*        <ClearOutlinedIcon />*/}
                    {/*    </IconButton>*/}
                    {/*</Tooltip>*/}
                </div>
            )}
        </Draggable>
    );
};

OptionField.propTypes = {
    className: PropTypes.string,
    index: PropTypes.number.isRequired,
    optionKey: PropTypes.string.isRequired,
    languagePack: PropTypes.object.isRequired,
    autoFocus: PropTypes.bool,
    Icon: PropTypes.object,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
};

OptionField.defaultProps = {
    onChange: () => {},
    onDelete: () => {},
};

export default OptionField;
