import React, {useContext, useState} from 'react';
import {Link as RouterLink, NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    AppBar,
    Container,
    Badge,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    alpha,
    List,
    makeStyles,
    Avatar,
    Icon,
    Drawer,
    Divider,
    ListItemIcon,
    ListItemText,
    CssBaseline,
    useTheme,
    FormControl,
    InputLabel, Select, Typography, withStyles, MenuList, Button, ListItem, Tooltip
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    iconContainer: {
        color: "inherit",
        minWidth: 0
    },
    defaultGutters: {
        padding: [theme.spacing(1), theme.spacing(2)]
    },
    collapsedGutters: {
        padding: [theme.spacing(1.5), theme.spacing(2.5)]
    },
    text: {
        marginLeft: theme.spacing(2),
    },
    itemDefault: {
        //marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: 6,
        color: theme.palette.grey[700],
        '&:hover': {
            color: theme.palette.grey[700],
            backgroundColor: alpha(theme.palette.primary.main, 0.10),
        },
    },
}));

const DialogMenuItem = ({title, icon: Icon, ...rest}) => {
    const classes = useStyles();

    return (
        <ListItem
            className={classes.itemDefault}
            button
            {...rest}
        >
            <ListItemIcon className={classes.iconContainer}>
                <Icon/>
            </ListItemIcon>
            <ListItemText className={classes.text} primary={title}/>
        </ListItem>
    );
}

DialogMenuItem.propTypes = {
};

DialogMenuItem.defaultProps = {
};

export default DialogMenuItem